import { TFastifyRouteGenericInterface } from '@typsy/rest-api/dist/server/endpoint/endpointValidation.models';
import {
    ITimeSeriesReportBase,
    TFetchReportCommonApiInputClient,
    IIdToNameMap,
    ITimeSeriesItemBase, TFetchReportCommonApiBody, TAcceptLanguageReportApiHeaders,
} from '../commonReport.models';

export interface IFetchRoboAumReportApiContract extends TFastifyRouteGenericInterface {
    Body: TFetchRoboAumReportApiBody;
    Headers: TFetchRoboAumReportApiHeaders;
    Reply: IRoboAumReport;
}

export type TFetchRoboAumReportApiBody = TFetchReportCommonApiBody & {
    aggregateBy: AggregateRoboAumBy;
};

export type TFetchRoboAumReportApiHeaders = TAcceptLanguageReportApiHeaders;

export type TFetchRoboAumReportApiInputClient = TFetchReportCommonApiInputClient
& Pick<TFetchRoboAumReportApiBody, 'aggregateBy'> & TFetchRoboAumReportApiHeaders;

export enum AggregateRoboAumBy {
    GOAL = 'GOAL',
    HORIZON = 'HORIZON',
    POLICY = 'POLICY',
}

export interface IRoboAumReport extends ITimeSeriesReportBase<IRoboAumReportTimeSeriesItem> {
    /* maps each id (policy, goal or horizon) to the corresponding name of the input language */
    id_to_name_map: IIdToNameMap;
}

export interface IRoboAumReportTimeSeriesItem extends ITimeSeriesItemBase {
    items: {
        id: string; /* the id of either the policy, goal or horizon */
        amount: number;
    }[];
}
