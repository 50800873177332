import React from 'react';
import { Z_INDEX } from 'config/styling/elevation';
import { makeStyles, mixins } from 'views/styling';
import { IAskConfirmationConfig } from 'views/common/buttons/types';
import { getTextButton } from 'views/common/buttons/buttonsNoCircularDependencies';
import Text from 'views/common/widget/Text';
import Modal, { IModalProps } from './Modal';

interface IPublicProps
    extends Pick<IAskConfirmationConfig, 'titleLabel' | 'messageLabel' | 'confirmLabel' | 'cancelLabel'>,
    Omit<IModalProps, 'title' | 'emphasizeTitle' | 'children'> {
    onConfirm: () => void;
}

const useStyles = makeStyles((theme) => ({
    ConfirmationModalRoot: {
        zIndex: Z_INDEX.POPOVER,
    },
    ConfirmationModal: {
        ...mixins.flexColTopLeft(),
        ...mixins.widthMax(),

        '& .__message': {
            paddingBottom: theme.spacing(3),
        },

        '& .__actions': {
            ...mixins.flexRowCenterRight(),
            ...mixins.widthMax(),
        },
    },
}));

export default function ConfirmationModal({
    titleLabel,
    messageLabel,
    confirmLabel,
    cancelLabel,
    onConfirm,
    ...otherModalProps
}: IPublicProps) {
    const classes = useStyles();

    const TextButton = getTextButton();

    return (
        <Modal
            {...otherModalProps}
            className={classes.ConfirmationModalRoot}
            title={titleLabel || 'common.confirmation.title'}
            maxWidth={otherModalProps.maxWidth || 'xs'}
        >
            <div className={classes.ConfirmationModal}>
                {messageLabel && (
                    <div className="__message">
                        <Text label={messageLabel} />
                    </div>
                )}

                <div className="__actions">
                    <TextButton
                        id={`${otherModalProps.id}_no`}
                        label={cancelLabel || 'common.confirmation.no'}
                        variant="bare"
                        noMargin={false}
                        color="secondary"
                        onClick={otherModalProps.onClose}
                    />

                    <TextButton
                        id={`${otherModalProps.id}_yes`}
                        label={confirmLabel || 'common.confirmation.yes'}
                        variant="filled"
                        color="primary"
                        onClick={onConfirm}
                    />
                </div>
            </div>
        </Modal>
    );
}
