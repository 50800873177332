import React from 'react';
import clsx from 'clsx';
import Popover from '@mui/material/Popover';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import Typography from '@mui/material/Typography';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isBoolean from '@snipsonian/core/cjs/is/isBoolean';
import assert from '@snipsonian/core/cjs/assert';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { Z_INDEX } from 'config/styling/elevation';
import { APP_COLORS } from 'config/styling/colors';
import {
    clearTooltipKeyAutomaticallyShownAndDetermineTheNextOneIfAny,
    markTooltipAsLive,
    unMarkTooltipAsLive,
} from 'state/ui/tooltipManager';
import { shouldShowTooltipAutomatically } from 'state/ui/tooltips.selectors';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { makeStyles, mixins } from 'views/styling';
import IconButton from 'views/common/buttons/IconButton';
import { InfoIcon } from 'views/common/icons';
import Card from 'views/common/layout/Card';
import { IObserveProps, observe } from 'views/observe';
import TextButton from 'views/common/buttons/TextButton';
import Text from 'views/common/widget/Text';
import {
    IInfoIconTooltipProps,
    IInfoIconTooltipStyleProps,
    TInfoTooltipPlacement,
} from 'views/common/widget/InfoIconTooltip.models';

const useStyles = makeStyles((theme) => ({
    InfoIconTooltip: {},
    InfoTooltipWrapper: {
        zIndex: Z_INDEX.POPOVER,

        '& .MuiPopover-paper': {
            maxWidth: 340,
            borderRadius: 4,
            backgroundColor: APP_COLORS.PRIMARY['500'],
            // boxShadow: BOX_SHADOW.DEFAULT,
            overflowX: 'unset',
            overflowY: 'unset',
            marginTop: theme.spacing(-1),

            /* for the tooltip-arrow */
            '&::before': {
                content: '""',
                position: 'absolute',
                marginRight: '-0.71em',
                bottom: 0,
                right: ({ arrowFromRightPercentage }: IInfoIconTooltipStyleProps) => `${arrowFromRightPercentage}%`,
                width: 10,
                height: 10,
                backgroundColor: APP_COLORS.PRIMARY['500'],
                // boxShadow: theme.shadows[1],
                transform: 'translate(-50%, 50%) rotate(135deg)',
                clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
            },
        },

        '& .__tooltipContent': {
            ...mixins.typo({ size: 14, color: APP_COLORS.SYSTEM.WHITE }),
            ...mixins.flexCol({ alignMain: 'flex-start', alignCross: 'flex-start' }),
        },

        '& .__simpleContent': {
            padding: theme.spacing(3, 2, 3, 2),

            '& .__title': {
                paddingBottom: theme.spacing(2),
            },
        },

        '& .__shownAutomaticallyButtons': {
            ...mixins.flexRowCenterRight(),
            ...mixins.widthMax(),

            '& .BaseButton-bare': {
                padding: theme.spacing(0, 2, 1, 0),
                marginTop: theme.spacing(-2),
                textDecoration: 'underline',
            },
        },
    },
}));

function InfoIconTooltip({
    className,
    name,
    simpleContent,
    cardContent,
    placement = 'top',
    infoIconSize = 'XS',
    infoIconColor = 'text',
    showUntilDismissed,
    arrowFromRightPercentage = 50,
    state,
}: IInfoIconTooltipProps & IObserveProps) {
    const classes = useStyles({ arrowFromRightPercentage });
    const tooltipKey = name;

    const iconButtonRef = React.useRef(null);
    const [didUserOpenTooltip, setDidUserOpenTooltip] = React.useState<boolean>(false);

    assert(
        '',
        () => isSet(simpleContent) || isSet(cardContent),
        'Either simpleContent or cardContent should be specified for the InfoIconTooltip.',
    );

    const isTooltipShownAutomatically = isSet(showUntilDismissed) && shouldShowTooltipAutomatically(state, {
        tooltipKey,
    });

    const isTooltipOpen = didUserOpenTooltip || isTooltipShownAutomatically;

    useExecuteOnMount({
        execute: () => {
            if (showUntilDismissed) {
                const priority = isBoolean(showUntilDismissed)
                    ? null
                    : showUntilDismissed.priority;

                markTooltipAsLive({
                    tooltipKey,
                    priority,
                });
            }
        },
        cleanupOnUnmount: () => {
            if (showUntilDismissed) {
                unMarkTooltipAsLive({
                    tooltipKey,
                });
            }
        },
    });

    return (
        <div className={clsx(classes.InfoIconTooltip, className)}>
            <div ref={iconButtonRef}>
                <IconButton
                    id={`info-tooltip_${name}`}
                    className="__info-icon"
                    variant="bare"
                    color={infoIconColor}
                    size={infoIconSize}
                    svgSize={infoIconSize === 'XS' ? 20 : 24}
                    isCircle={false}
                    icon={<InfoIcon />}
                    onClick={openInfoPopover}
                />
            </div>

            <Popover
                open={isTooltipOpen}
                anchorEl={iconButtonRef ? iconButtonRef.current : null}
                onClose={() => closeInfoPopover(false)}
                className={classes.InfoTooltipWrapper}
                {...toPopoverPlacement(placement)}
                marginThreshold={16}
            >
                <TooltipContent />
            </Popover>
        </div>
    );

    function openInfoPopover() {
        setDidUserOpenTooltip(true);
    }

    function closeInfoPopover(isTooltipNotToBeShownAgain: boolean) {
        if (isTooltipShownAutomatically) {
            clearTooltipKeyAutomaticallyShownAndDetermineTheNextOneIfAny({
                tooltipKey,
                isTooltipNotToBeShownAgain,
            });
        }

        setDidUserOpenTooltip(false);
    }

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function TooltipContent() {
        return (
            <div className="__tooltipContent">
                {simpleContent && (
                    <div className="__simpleContent">
                        {simpleContent.title && (
                            <Typography variant="h6" className="__title">
                                <Text label={simpleContent.title} />
                            </Typography>
                        )}

                        <div className="__content">
                            <Text label={simpleContent.info} />
                        </div>
                    </div>
                )}

                {!simpleContent && cardContent && (
                    <Card>
                        {cardContent.items}
                    </Card>
                )}

                {isTooltipShownAutomatically && (
                    <div className="__shownAutomaticallyButtons">
                        <TextButton
                            id={`tooltip_${tooltipKey}_dismiss`}
                            label="common.tooltip.action.dismiss"
                            onClick={() => closeInfoPopover(true)}
                            variant="bare"
                            fontWeight="initial"
                            color="white"
                            fontSize="M"
                        />

                        <TextButton
                            id={`tooltip_${tooltipKey}_ok`}
                            label="common.tooltip.action.ok"
                            onClick={() => closeInfoPopover(false)}
                            variant="bare"
                            fontWeight="initial"
                            color="white"
                            fontSize="M"
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default observe<IInfoIconTooltipProps>(
    [StateChangeNotification.UI_TOOLTIPS_AUTO_SHOW],
    InfoIconTooltip,
);

interface IPopoverPlacement {
    anchorOrigin: PopoverOrigin;
    transformOrigin: PopoverOrigin;
}

function toPopoverPlacement(infoTooltipPlacement: TInfoTooltipPlacement): IPopoverPlacement {
    if (infoTooltipPlacement === 'top') {
        return {
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
            transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
        };
    }
    if (infoTooltipPlacement === 'bottom-left') {
        return {
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
        };
    }

    return null;
}
