import { TConsoleApiErrorClientSide } from '@typsy/console-api-client/dist/models/consoleApiError.models';
import { TApiEntityId } from '@console/core-api/models/api.models';

type TBusinessLogicErrorResponseDetail = IBusinessLogicErrorEntity[];

interface IBusinessLogicErrorEntity {
    id: string;
    external_id: string;
    reason: string;
}

export function extractEntityIdsFromBusinessLogicErrorDetailText(
    apiError: TConsoleApiErrorClientSide,
): TApiEntityId[] {
    const detailText = apiError?.response?.data?.detailText || '';

    const entities: TBusinessLogicErrorResponseDetail = JSON.parse(ensureErrorDetailIsValidForJsonParsing(detailText));

    return entities.map(({ id }) => id);
}

export function extractEntityIdFromEntityNotFoundErrorDetailText(
    apiError: TConsoleApiErrorClientSide,
): TApiEntityId {
    try {
        const { detailText = '' } = apiError?.response?.data || {};
        const entityIdStartIndex = detailText.indexOf('\'') + 1;
        const entityIdEndIndex = detailText.indexOf('\'', entityIdStartIndex);

        return detailText.substring(entityIdStartIndex, entityIdEndIndex);
    } catch {
        return '-';
    }
}

export function ensureErrorDetailIsValidForJsonParsing(errorDetail: string): string {
    return errorDetail.replaceAll('\'', '"').replaceAll('None', 'null');
}
