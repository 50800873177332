import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { TLabel } from 'models/general.models';
import { APP_COLORS, OPACITY, hexToRgba } from 'config/styling/colors';
import { makeStyles, mixins } from 'views/styling';
import Text from 'views/common/widget/Text';

export interface IPageTitleProps {
    className?: string;
    label: TLabel;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    PageTitleWrapper: {
        ...mixins.flexRowCenterLeft(),

        '& svg': {
            marginTop: theme.spacing(1),
        },
        '& .__titleBefore': {
            marginRight: theme.spacing(1),
        },
        '& .__titleAfter': {
            marginLeft: theme.spacing(1),
        },
    },
    PageTitle: {
        color: hexToRgba(APP_COLORS.TEXT['500'], OPACITY.LOW),
        padding: theme.spacing(1, 0),
        minHeight: 51,
    },
}));

export default function PageTitle({
    className,
    label,
    startIcon,
    endIcon,
}: IPageTitleProps) {
    const classes = useStyles();

    return (
        <div className={classes.PageTitleWrapper}>
            {startIcon && (
                <div className="__titleBefore">
                    {startIcon}
                </div>
            )}

            <Typography variant="h3" className={clsx(classes.PageTitle, className)}>
                <Text label={label} />
            </Typography>

            {endIcon && (
                <div className="__titleAfter">
                    {endIcon}
                </div>
            )}
        </div>
    );
}
