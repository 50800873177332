import { User, UserManager, WebStorageStateStore } from 'oidc-client'; // openid connect client library
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { ONE_MINUTE_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import { getConsoleConfigAuthentication, isConsoleConfigAuthenticationOtherTypeGuard } from 'config/auth.config';
import { getBaseSiteUrl } from 'utils/env/url';
import { IS_LOCALHOST_ENV } from 'utils/env/environment';
import { initUserActivityMonitor, IUserActivityMonitor } from 'utils/browser/userActivityMonitor';

const shouldLogEventsToConsole = IS_LOCALHOST_ENV;
const MAX_IDLE_THRESHOLD_IN_MINUTES_TO_PROLONG_SESSION = 5;

export type OidcUser = User;

export type TSilentRenewedUserHandler = (renewedUser: User) => void;
export type TBaseEventHandler = () => void;

let userActivityMonitor: IUserActivityMonitor = null;
let checkUserIdleInterval: number = null;

let silentRenewedUserHandler: TSilentRenewedUserHandler = null;
let accessTokenExpiredHandler: TBaseEventHandler = null;

function setEventHandlers({
    onSilentRenewedUser,
    onAccessTokenExpired,
}: {
    onSilentRenewedUser: TSilentRenewedUserHandler;
    onAccessTokenExpired: TBaseEventHandler;
}) {
    silentRenewedUserHandler = onSilentRenewedUser;
    accessTokenExpiredHandler = onAccessTokenExpired;
}

const LOGIN_URI = `${getBaseSiteUrl()}/login`;
const SILENT_RENEW_URI = `${getBaseSiteUrl()}/auth-silent-renew`;
const OPENID_BASE_CONFIG = determineOpenIdBaseConfig();

const OPENID_SIGN_IN_CONFIG = {
    authority: OPENID_BASE_CONFIG.authority.signIn,
    client_id: OPENID_BASE_CONFIG.clientId,
    redirect_uri: LOGIN_URI,
    post_logout_redirect_uri: LOGIN_URI,
    response_type: 'code',
    response_mode: OPENID_BASE_CONFIG.response_mode,
    scope: OPENID_BASE_CONFIG.scope,
    automaticSilentRenew: false,
    silent_redirect_uri: SILENT_RENEW_URI,
    revokeAccessTokenOnSignout: true,
    /* The nr of seconds before an access token is to expire to raise the accessTokenExpiring event (default: 60) */
    // accessTokenExpiringNotificationTime: 60,
    filterProtocolClaims: true,
    loadUserInfo: false,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const OPENID_RESET_PW_CONFIG = {
    ...OPENID_SIGN_IN_CONFIG,
    automaticSilentRenew: false,
    authority: OPENID_BASE_CONFIG.authority.resetPw,
};

const signInManager = new UserManager(OPENID_SIGN_IN_CONFIG);
const resetPwManager = new UserManager(OPENID_RESET_PW_CONFIG);

signInManager.events.addAccessTokenExpiring(() => {
    if (!userActivityMonitor || !userActivityMonitor.wasUserRecentlyActive({
        idleThresholdInMinutes: MAX_IDLE_THRESHOLD_IN_MINUTES_TO_PROLONG_SESSION,
    })) {
        if (shouldLogEventsToConsole) {
            console.log('[OIDC] Access token expiring BUT user has been idle for quite some time, SO not triggering silent renew.');
        }
        return;
    }

    if (shouldLogEventsToConsole) {
        console.log('[OIDC] Access token expiring. Triggering silent renew ...');
    }
    signInManager.signinSilent(OPENID_SIGN_IN_CONFIG)
        .then((user) => {
            if (silentRenewedUserHandler) {
                silentRenewedUserHandler(user);
            }
        });
});

signInManager.events.addAccessTokenExpired(() => {
    if (shouldLogEventsToConsole) {
        console.log('[OIDC] Access token expired.');
    }

    if (accessTokenExpiredHandler) {
        accessTokenExpiredHandler();
    }
});

signInManager.events.addSilentRenewError((error) => {
    if (shouldLogEventsToConsole) {
        console.log('[OIDC] Silent renew failed.', error);
    }
});

export const authService = {
    isAuthenticated,
    getUser,
    login,
    loginCallback,
    logout,
    getAccessToken,
    resetPassword,
    resetPasswordCallback,
    setEventHandlers,
    startUserActivityMonitor,
    stopUserActivityMonitor,
};

/**
 * The 'signinRedirect' awaits until 'signinRedirectCallback' is executed,
 * which gets called from the /callback/login.html static page
 * indirectly resulting in the onLoginSuccess being called.
 */
async function login() {
    await signInManager.signinRedirect();
}

async function loginCallback(url: string): Promise<OidcUser> {
    try {
        await signInManager.signinRedirectCallback(url);

        const { isLoggedIn, authenticatedUser } = await isAuthenticated();

        if (!isLoggedIn) {
            return null;
        }

        startUserActivityMonitor();

        return authenticatedUser;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('loginCallback error', error);

        if (isAuthorityMismatchError(error)) {
            /**
             * This error occurs when the user 'successfully' did the RESET_PASSWORD flow
             * but then there's a mismatch as the authority does not match with the SIGN_IN flow.
             * Therefore we here do a logout so that the user can re-login with his new password.
             */
            window.location.replace(LOGIN_URI);
        }

        return null;
    }
}

function logout() {
    stopUserActivityMonitor();

    return signInManager.signoutRedirect();
}

async function getUser(): Promise<User> {
    const authenticatedUser = await signInManager.getUser();

    return authenticatedUser;
}

async function isAuthenticated(): Promise<{ isLoggedIn: boolean; authenticatedUser: User }> {
    const authenticatedUser = await getUser();

    if (authenticatedUser) {
        const isExpired = await isUserExpiredAndRemoveIfExpired(authenticatedUser);

        if (!isExpired) {
            return {
                isLoggedIn: true,
                authenticatedUser,
            };
        }
    }

    return {
        isLoggedIn: false,
        authenticatedUser: null,
    };
}

async function isUserExpiredAndRemoveIfExpired(authenticatedUser: User) {
    if (authenticatedUser.expired) {
        await signInManager.removeUser();
        return true;
    }

    return false;
}

async function getAccessToken() {
    const authenticatedUser = await getUser();

    if (!authenticatedUser) {
        return null;
    }

    return authenticatedUser.access_token;
}

async function resetPassword() {
    await resetPwManager.signinRedirect();
}

async function resetPasswordCallback(url: string): Promise<null> {
    try {
        await signInManager.signinRedirectCallback(url);

        return null;
    } catch (error) {
        if (isAuthorityMismatchError(error)) {
            /**
             * This error occurs when the user 'successfully' did the RESET_PASSWORD flow
             * but then there's a mismatch as the authority does not match with the SIGN_IN flow.
             * Therefore we here do a logout so that the user can re-login with his new password.
             */
            return null;
        }

        // eslint-disable-next-line no-console
        console.log('resetPasswordCallback error', error);

        throw error;
    }
}

function startUserActivityMonitor() {
    stopUserActivityMonitor();

    userActivityMonitor = initUserActivityMonitor();

    /**
     * As long as https://investsuite.atlassian.net/browse/IVS-1624 is not done, the actual timeout of the tokens is too long.
     * --> so when there was no user activity for some time, we will force a logout by calling the accessTokenExpiredHandler.
     */
    checkUserIdleInterval = window.setInterval(
        () => {
            if (userActivityMonitor && !userActivityMonitor.wasUserRecentlyActive({
                idleThresholdInMinutes: MAX_IDLE_THRESHOLD_IN_MINUTES_TO_PROLONG_SESSION,
            })) {
                if (accessTokenExpiredHandler) {
                    accessTokenExpiredHandler();
                }
            }
        },
        ONE_MINUTE_IN_MILLIS,
    );
}

function stopUserActivityMonitor() {
    if (checkUserIdleInterval) {
        window.clearInterval(checkUserIdleInterval);
        checkUserIdleInterval = null;
    }
    if (userActivityMonitor) {
        userActivityMonitor.stopMonitoring();
        userActivityMonitor = null;
    }
}

function isAuthorityMismatchError(error: unknown): boolean {
    return (error as { message: string })
        .message.indexOf('authority mismatch') > -1;
}

interface IOpenIdBaseConfig {
    clientId: string;
    scope: string;
    authority: {
        signIn: string;
        resetPw: string;
    };
    response_mode: string;
}

function determineOpenIdBaseConfig(): IOpenIdBaseConfig {
    const authConfig = getConsoleConfigAuthentication();

    if (isConsoleConfigAuthenticationOtherTypeGuard(authConfig)) {
        return {
            clientId: authConfig.clientId,
            scope: determineOpenIdScope(authConfig),
            authority: {
                signIn: authConfig.authority,
                resetPw: null,
            },
            response_mode: authConfig.provider === 'idcs'
                /**
                 * Empty-value-workaround needed for 'idcs' because otherwise
                 * it complains of the default 'query' mode.
                 */
                ? ' '
                : null,
        };
    }

    const adb2cAuthorityBase = `https://${authConfig.tenantName}.b2clogin.com/${authConfig.tenantName}.onmicrosoft.com`;

    return {
        clientId: authConfig.clientId,
        scope: determineOpenIdScope(authConfig),
        authority: {
            signIn: `${adb2cAuthorityBase}/${authConfig.signInPolicy}`,
            resetPw: `${adb2cAuthorityBase}/${authConfig.passwordResetPolicy}`,
        },
        response_mode: null,
    };
}

function determineOpenIdScope(authConfig: TConsoleConfigAuthentication): string {
    if (isConsoleConfigAuthenticationOtherTypeGuard(authConfig)) {
        if (isSetString(authConfig.scope)) {
            /**
             * The default scope 'openid' can be overridden if needed.
             *
             * Example:
             * - BDP is using, on UAT and above, Azure AD as identity provider, which requires
             *   an extra scope to be set for the api. Otherwise the custom claims (like 'extension_tenant'
             *   and 'extension_ulid') will not be present in the access_token.
             *   So for those environments, the env var will set the config to something like
             *   "api://console-web-app/read openid profile".
             */
            return authConfig.scope;
        }
    }

    return 'openid';
}
