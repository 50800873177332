import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { APP_COLORS, hexToRgba, MENU_COLORS, OPACITY } from 'config/styling/colors';
import { BOX_SHADOW } from 'config/styling/elevation';
import { calculateSpacing } from 'config/styling/theme';
import { INSTANCE_CONFIG } from 'config/instance.config';
import { shouldShowResetPasswordFlow } from 'config/auth.config';
import { getAuthFlowStatus, isAuthenticatedOidc } from 'state/auth/selectors';
import {
    logout,
    openLoginModal,
    completeLoginOnCodeCallback,
    triggerResetPasswordFlow,
    completeResetPasswordOnCodeCallback,
    setLoginRedirectUrl,
} from 'state/auth/actions';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { getUrlQueryPart } from 'utils/env/url';
import useQuery from 'utils/routing/hooks/useQuery';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles, UtilityClass, mixins } from 'views/styling';
import {
    AppsIcon, AnalyticsIcon, BillingIcon,
    DashboardIcon, HomeIcon, IntegrationIcon,
    PortfolioMgmtIcon, SettingsIcon, UsersIcon,
} from 'views/common/icons';
import TextButton from 'views/common/buttons/TextButton';
import SpinnerOverlay from 'views/common/loading/SpinnerOverlay';
import AnimatedDiamonds from './AnimatedDiamonds';

const ERROR_DESCRIPTION = {
    FORGOT_PASSWORD_TRIGGER: 'AADB2C90118',
};
const ICON_SIZE = 64;
const SPACE_BETWEEN_ICONS = 64;
const ICONS_SQUARE_SIZE = (ICON_SIZE * 3) + (2 * SPACE_BETWEEN_ICONS);

const useStyles = makeStyles((theme) => ({
    Login: {
        ...mixins.widthHeightMax(),
        backgroundColor: APP_COLORS.NAV.DARKEST,
        paddingBottom: ICON_SIZE + calculateSpacing(6),

        '& .instanceSection': {
            ...mixins.flexRowCenterLeft(),
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
        },
        '& .instanceLogo': {
            margin: theme.spacing(3),

            '& img': {
                height: ICON_SIZE,
            },
        },
        '& .instanceName': {
            ...mixins.typo({ size: 16, weight: 'bold', color: APP_COLORS.SYSTEM.WHITE }),
        },

        '& .icons': {
            ...mixins.flexCol({ alignMain: 'space-between', alignCross: 'center' }),
            height: ICONS_SQUARE_SIZE,
            marginTop: ICON_SIZE + calculateSpacing(6),
        },
        '& .iconRow': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center', wrap: 'nowrap' }),
            width: ICONS_SQUARE_SIZE,

            '& svg': {
                zIndex: 1,
            },
        },
        '& .MuiSvgIcon-root': {
            width: ICON_SIZE,
            height: ICON_SIZE,
        },
        '& .iconFlavorA': {
            fill: MENU_COLORS.ICON.BASIC.A,
        },
        '& .iconFlavorB': {
            fill: MENU_COLORS.ICON.BASIC.B,
        },
        '& .iconFlavorC': {
            fill: MENU_COLORS.ICON.BASIC.C,
        },
        '& .iconFlavorD': {
            fill: MENU_COLORS.ICON.BASIC.D,
        },
        '& .iconFlavorE': {
            fill: MENU_COLORS.ICON.BASIC.E,
        },

        '& .toLogin': {
            width: ICONS_SQUARE_SIZE,
            marginTop: SPACE_BETWEEN_ICONS,
        },
        '& .toLogin:hover': {
            boxShadow: BOX_SHADOW.BUTTON_LARGE,
        },
        '& .toResetPassword': {
            color: hexToRgba(APP_COLORS.SYSTEM.WHITE, OPACITY.LOW),
            marginTop: theme.spacing(1),
        },
        '& .toResetPassword:hover': {
            color: APP_COLORS.SYSTEM.WHITE,
        },

        '& .auth-error': {
            marginTop: theme.spacing(1),
            color: APP_COLORS.FEEDBACK.ERROR,
        },

        '& .loginSpinner svg': {
            // fill: MENU_COLORS.ICON.BASIC.B,
        },
    },
}));

function Login({ dispatch, state }: IObserveProps) {
    const classes = useStyles();
    const location = useLocation();
    const [code, errorDescription] = useQuery('code', 'error_description');

    const flowStatus = getAuthFlowStatus(state);

    useExecuteOnMount({
        /* logout when this Login component is mounted AND if user was logged in */
        execute: () => {
            if (isSet(code)) {
                if (flowStatus === 'reset_pw_triggered') {
                    dispatch(completeResetPasswordOnCodeCallback({ urlQueryPart: getUrlQueryPart() }));
                } else {
                    // login_busy
                    dispatch(completeLoginOnCodeCallback({ urlQueryPart: getUrlQueryPart() }));
                }
            } else if (errorDescription === ERROR_DESCRIPTION.FORGOT_PASSWORD_TRIGGER) {
                dispatch(triggerResetPasswordFlow());
            } else {
                const redirectUrl = location.state && location.state.redirectUrl;

                if (isSet(redirectUrl)) {
                    dispatch(setLoginRedirectUrl({ redirectUrl }));
                }

                isAuthenticatedOidc()
                    .then((isAuthenticated) => {
                        if (isAuthenticated && !doesUrlContainCodeQueryParam(getUrlQueryPart())) {
                            dispatch(logout());
                        }
                    });
            }
        },
        dispatchResult: false,
    });

    const strippedErrorDescription = stripErrorDescription(errorDescription);

    return (
        <div className={clsx(classes.Login, UtilityClass.layout.colCenter)}>
            <SpinnerOverlay
                open={flowStatus === 'login_busy' || flowStatus === 'reset_pw_busy'}
                className="loginSpinner"
                size="XL"
                color="secondary"
                position="fixed"
            />
            <AnimatedDiamonds />

            <div className="instanceSection">
                <div className="instanceLogo">
                    <img src={INSTANCE_CONFIG.logoUrl} alt={INSTANCE_CONFIG.name} />
                </div>
                <div className="instanceName">
                    {INSTANCE_CONFIG.name}
                </div>
            </div>

            <div className="icons">
                <div className="iconRow">
                    <AnalyticsIcon className="iconFlavorB" />
                    <AppsIcon className="iconFlavorD" />
                    <PortfolioMgmtIcon className="iconFlavorE" />
                </div>
                <div className="iconRow">
                    <DashboardIcon className="iconFlavorE" />
                    <IntegrationIcon className="iconFlavorA" />
                    <BillingIcon className="iconFlavorB" />
                </div>
                <div className="iconRow">
                    <HomeIcon className="iconFlavorA" />
                    <UsersIcon className="iconFlavorC" />
                    <SettingsIcon className="iconFlavorE" />
                </div>
            </div>

            <TextButton
                id="go-to-login"
                color="primary"
                className="toLogin"
                onClick={goToLogin}
                label="auth.login.go_to_login"
            />

            {shouldShowResetPasswordFlow && (
                <TextButton
                    id="go-to-reset-password"
                    color="secondary"
                    variant="bare"
                    size="M"
                    className="toResetPassword"
                    onClick={goToResetPassword}
                    label="auth.login.go_to_reset_password"
                />
            )}

            {strippedErrorDescription && (
                <div className="auth-error">
                    {strippedErrorDescription}
                </div>
            )}
        </div>
    );

    function goToLogin() {
        dispatch(openLoginModal());
    }

    function goToResetPassword() {
        dispatch(triggerResetPasswordFlow());
    }
}

export default observe(
    [StateChangeNotification.AUTH_FLOW_STATUS],
    Login,
);

function doesUrlContainCodeQueryParam(url: string) {
    return url.indexOf('code=') > -1;
}

function stripErrorDescription(errorDescription: string) {
    if (isSetString(errorDescription)) {
        /* Strip away some stuff we don't want to show to the user
         (like the "Correlation ID: <corr id> Timestamp: <timestamp>"
         that's at the end of the error description */
        const index = errorDescription.indexOf('Correlation ID:');

        return index > 0
            ? errorDescription.substring(0, index - 1)
            : errorDescription;
    }

    return null;
}
