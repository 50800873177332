import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import * as ConsoleBff from '@console/bff/export';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getSelfMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { api } from 'api';
import {
    enrichReportResponseWithFetchDate,
    isEnrichedReportOutdated,
    TEnrichedReportResponse,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';

// eslint-disable-next-line max-len
export const selfAumReportEntity = getEntitiesManager().registerEntity<TEnrichedReportResponse<ConsoleBff.TFetchSelfAumReportApiReply>>({
    asyncEntityKey: AsyncEntityKeys.selfAumReport,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_SELF_AUM_DATA],
    includeUpdaters: false,
});

/* currently, the self report = the common report model */
export function selectSelfAumReportAsCommonReport() {
    return selfAumReportEntity.select();
}

export function triggerFetchSelfAumReport({
    forceRefresh = false,
}: IForceStateRefreshFilter = {}) {
    const {
        period,
    } = getSelfMgmtReportingPageVars();

    // eslint-disable-next-line max-len
    return selfAumReportEntity.async.fetch<ConsoleBff.TFetchSelfAumReportApiInputClient, TEnrichedReportResponse<ConsoleBff.TFetchSelfAumReportApiReply>, ConsoleBff.TFetchSelfAumReportApiReply>({
        api: api.bff.mgmtReporting.fetchSelfAumReport,
        apiInputSelector: () => ({
            period,
        }),
        mapApiResponse: ({ response }) => enrichReportResponseWithFetchDate(response),
        refreshMode: () => forceRefresh || isEnrichedReportOutdated(selfAumReportEntity.select().data),
        resetDataOnTriggerMode: 'always',
    });
}
