import React from 'react';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { THEME } from 'config/styling/theme';
import { IPublicPropsWithChildren } from 'views/observe';

/** Augmentation so that you can do theme.spacing() */
declare module '@mui/styles' {
    // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

/** 'injectFirst' is to have a good css order (so that our custom styles come AFTER the mui ones) */
export default function AppThemeProvider({ children }: IPublicPropsWithChildren) {
    return (
        <ThemeProvider theme={THEME}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                {children}
            </StyledEngineProvider>
        </ThemeProvider>
    );
}
