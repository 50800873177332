import isNumber from '@snipsonian/core/cjs/is/isNumber';
import isString from '@snipsonian/core/cjs/is/isString';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';

interface IFormatOptions {
    surroundWithQuotes?: boolean;
    number?: {
        nrOfDecimals?: number;
        stripTrailingDecimalZeros?: boolean;
    };
    date?: {
        format?: string;
    };
}

export default function formatValueForDisplay(
    value: unknown,
    options: IFormatOptions = {},
): string {
    const { surroundWithQuotes = true } = options;
    const formattedValue = formatValueBasedOnType(
        value,
        options,
    );

    return surroundWithQuotes
        ? surroundInputWithQuotes(formattedValue)
        : formattedValue;
}

function formatValueBasedOnType(
    value: unknown,
    options: IFormatOptions,
): string {
    if (isNumber(value)) {
        const {
            nrOfDecimals = 4,
            stripTrailingDecimalZeros = true,
        } = options.number || {};

        return formatFloat(
            value,
            { nrOfDecimals, stripTrailingDecimalZeros },
        );
    }

    if (isString(value)) {
        if (value.search(/^\d{4}-\d{2}-\d{2}T/) > -1) {
            const {
                format = DATE_FORMAT.DAY_MONTH_YEAR_WITH_TIME,
            } = options.date || {};

            return formatDate({
                date: value,
                format,
            });
        }

        return value;
    }

    return prettifyValue(value);
}

function surroundInputWithQuotes(value: string) {
    return `'${value}'`;
}

function prettifyValue(value: unknown) {
    return JSON.stringify(value, null, '\t').replaceAll('"', '');
}
