import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { ROOT_ELEMENT_ID } from 'config/dom.config';
import initPreRender from 'state/setup/initPreRender';
import initOnLoad from 'state/setup/initOnLoad';
import 'views/assets/scss/global.scss';
import App from 'views/appShell/App';

import * as serviceWorker from './serviceWorker';

function initApp() {
    initPreRender();

    const container = document.getElementById(ROOT_ELEMENT_ID);
    const root = createRoot(container);

    root.render(<AppWithCallbackAfterRender />);
}

function AppWithCallbackAfterRender() {
    useEffect(() => {
        initOnLoad();
    });

    return <App />;
}

initApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
