import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { getEnvVar } from '@typsy/core/dist/env/getEnvVar';
// eslint-disable-next-line import/no-relative-packages
import packageJsonFromConsoleWeb from '../../../console-web/package.json';

/**
 * The server-side fastify server expects path params in the format of '/:pathParam/'
 * while the client-side requestWrapper expects them in the format '/{pathParam}'.
 * --> here we use the '/:pathParam/' format, which will be replaced automatically in the requestWrapper !!!
 */
export enum ConsoleBffPath {
    HEALTH_CHECK = '/health',
    API_INFO = '/info',

    TENANT_SETTINGS = '/settings/tenant',

    POLICIES = '/policies',
    POLICY_DETAILS = '/policies/:policyId',
    POLICY_CHAIN = '/policies/:policyId/chain',
    POLICY_OPTIMIZATION = '/policies/:policyId/optimization',

    PORTFOLIOS = '/portfolios',
    PORTFOLIO_DETAILS = '/portfolios/:portfolioId',
    PORTFOLIO_CASH = '/portfolios/:portfolioId/cash',
    ENHANCE_PORTFOLIO_HOLDINGS = '/portfolios/:portfolioId/holdings/enhance',
    PORTFOLIO_TRANSACTIONS = '/portfolios/:portfolioId/transactions',
    PORTFOLIO_PERFORMANCE = '/portfolios/:portfolioId/performance',
    PORTFOLIO_ALLOCATION = '/portfolios/:portfolioId/allocation',
    PORTFOLIO_OPTIMIZATIONS = '/portfolios/:portfolioId/optimizations',
    PORTFOLIO_OPTIMIZATION = '/portfolios/:portfolioId/optimizations/:optimizationId',
    PORTFOLIO_OPTIMIZATION_COMPARISON = '/portfolios/:portfolioId/optimizations/:optimizationId/comparison',

    STORY_MANAGER_DATABASES = '/story-manager/databases',
    STORY_MANAGER_DATABASE = '/story-manager/databases/:databaseId',
    STORY_MANAGER_DATABASE_CONFIG = '/story-manager/databases/:databaseId/config',
    STORY_MANAGER_DATABASE_PUBLISH_CONFIG = '/story-manager/databases/:databaseId/config/publish',
    STORY_MANAGER_DATABASE_RESET_CONFIG = '/story-manager/databases/:databaseId/config/reset',

    MGMT_REPORTING_INFO = '/mgmt-reporting/info',
    MGMT_REPORTING_ROBO_AUM = '/mgmt-reporting/robo/aum',
    MGMT_REPORTING_ROBO_CLIENT_CASH_TRANSFER = '/mgmt-reporting/robo/client_cash_transfer',
    MGMT_REPORTING_ROBO_CLIENT_GROWTH = '/mgmt-reporting/robo/client_growth',
    MGMT_REPORTING_ROBO_PORTFOLIO_PERFORMANCE = '/mgmt-reporting/robo/portfolio_performance',
    MGMT_REPORTING_SELF_AUM = '/mgmt-reporting/self/aum',
    MGMT_REPORTING_SELF_CLIENT_CASH_TRANSFER = '/mgmt-reporting/self/client_cash_transfer',
    MGMT_REPORTING_SELF_CLIENT_GROWTH = '/mgmt-reporting/self/client_growth',
}

export const CORE_API_BASE_URL = getEnvVar({ name: 'CORE_API_URL', defaultValue: getCoreApiUrlOnLocalhost });
export const CMS_API_BASE_URL = getEnvVar({ name: 'CMS_API_URL', defaultValue: getCmsApiUrlOnLocalhost });
export const REPORTING_API_BASE_URL = getEnvVar({
    name: 'REPORTING_API_URL',
    defaultValue: getReportingApiUrlOnLocalhost,
});

function getCoreApiUrlOnLocalhost(): string {
    const {
        backendEnv = 'DEV_CBD',
    } = packageJsonFromConsoleWeb.localDev || {};

    return (packageJsonFromConsoleWeb.localDev.coreApi as TAnyObject)[backendEnv] as string;
}

function getCmsApiUrlOnLocalhost(): string {
    const {
        backendEnv = 'DEV_CBD',
        useLocalCmsApi = true,
    } = packageJsonFromConsoleWeb.localDev || {};

    if (useLocalCmsApi) {
        return 'http://localhost:4001';
    }

    return (packageJsonFromConsoleWeb.localDev.cmsApi as TAnyObject)[backendEnv] as string;
}

function getReportingApiUrlOnLocalhost(): string {
    const {
        backendEnv = 'DEV_CBD',
        useLocalReportingApi = true,
    } = packageJsonFromConsoleWeb.localDev || {};

    if (useLocalReportingApi) {
        return 'http://127.0.0.1:4003';
    }

    return (packageJsonFromConsoleWeb.localDev.reportingApi as TAnyObject)[backendEnv] as string;
}
