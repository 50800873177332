import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as AddIconComp } from 'views/assets/img/icons/add.svg';
import { ReactComponent as AnalyticsIconComp } from 'views/assets/img/icons/analytics.svg';
import { ReactComponent as AppsIconComp } from 'views/assets/img/icons/apps.svg';
import { ReactComponent as ArrowDownIconComp } from 'views/assets/img/icons/arrow-down.svg';
import { ReactComponent as ArrowLeftIconComp } from 'views/assets/img/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIconComp } from 'views/assets/img/icons/arrow-right.svg';
import { ReactComponent as ArrowUpIconComp } from 'views/assets/img/icons/arrow-up.svg';
import { ReactComponent as ArrowSubIconComp } from 'views/assets/img/icons/arrow-sub.svg';
import { ReactComponent as BillingIconComp } from 'views/assets/img/icons/billing.svg';
import { ReactComponent as BranchMergeComp } from 'views/assets/img/icons/branch-merge.svg';
import { ReactComponent as BranchNewComp } from 'views/assets/img/icons/branch-new.svg';
import { ReactComponent as CashIconComp } from 'views/assets/img/icons/enhancedPortfolioStatus/cash.svg';
import { ReactComponent as CheckboxOffIconComp } from 'views/assets/img/icons/checkbox-off.svg';
import { ReactComponent as CheckboxOnIconComp } from 'views/assets/img/icons/checkbox-on.svg';
import { ReactComponent as ChildPolicyIconComp } from 'views/assets/img/icons/child_policy.svg';
import { ReactComponent as ClapperBoardIconComp } from 'views/assets/img/icons/clapper-board.svg';
import { ReactComponent as ClientsIconComp } from 'views/assets/img/icons/clients.svg';
import { ReactComponent as CloseIconComp } from 'views/assets/img/icons/close.svg';
import { ReactComponent as CopyIconComp } from 'views/assets/img/icons/copy.svg';
import { ReactComponent as CsvIconComp } from 'views/assets/img/icons/csv.svg';
import { ReactComponent as DashboardIconComp } from 'views/assets/img/icons/dashboard.svg';
import { ReactComponent as DownloadIconComp } from 'views/assets/img/icons/download.svg';
import { ReactComponent as DuplicateIconComp } from 'views/assets/img/icons/duplicate.svg';
import { ReactComponent as EditIconComp } from 'views/assets/img/icons/edit.svg';
import { ReactComponent as EditFullIconComp } from 'views/assets/img/icons/edit_full.svg';
import { ReactComponent as ErrorIconComp } from 'views/assets/img/icons/error.svg';
import { ReactComponent as FindAndReplaceIconComp } from 'views/assets/img/icons/find_and_replace.svg';
import { ReactComponent as HomeIconComp } from 'views/assets/img/icons/home.svg';
import { ReactComponent as InfoIconComp } from 'views/assets/img/icons/info.svg';
import { ReactComponent as InsertIconComp } from 'views/assets/img/icons/insert.svg';
import { ReactComponent as InstrumentsIconComp } from 'views/assets/img/icons/instruments.svg';
import { ReactComponent as InstrumentGroupsIconComp } from 'views/assets/img/icons/instrument_groups.svg';
import { ReactComponent as IntegrationIconComp } from 'views/assets/img/icons/integration.svg';
import { ReactComponent as KeyIconComp } from 'views/assets/img/icons/key.svg';
import { ReactComponent as MoreIconComp } from 'views/assets/img/icons/more.svg';
import { ReactComponent as NavCollapseIconComp } from 'views/assets/img/icons/nav_collapse.svg';
import { ReactComponent as NavUncollapseIconComp } from 'views/assets/img/icons/nav_uncollapse.svg';
import { ReactComponent as BasePolicyIconComp } from 'views/assets/img/icons/base_policy.svg';
import { ReactComponent as PortfolioMgmtIconComp } from 'views/assets/img/icons/portfolio-mgmt.svg';
import { ReactComponent as PortfolioReportIconComp } from 'views/assets/img/icons/portfolio-report.svg';
import { ReactComponent as PortfoliosGroupsIconComp } from 'views/assets/img/icons/portfolio-groups.svg';
import { ReactComponent as PerformanceIconComp } from 'views/assets/img/icons/performance.svg';
import {
    ReactComponent as PfMandateAdvisorAdvisoryRealIconComp,
} from 'views/assets/img/icons/portfolioMandate/advisor-advisory_real.svg';
import {
    ReactComponent as PfMandateAdvisorAdvisoryVirtualIconComp,
} from 'views/assets/img/icons/portfolioMandate/advisor-advisory_virtual.svg';
import {
    ReactComponent as PfMandateAdvisorDiscretionaryRealIconComp,
} from 'views/assets/img/icons/portfolioMandate/advisor-discretionary_real.svg';
import {
    ReactComponent as PfMandateAdvisorDiscretionaryVirtualIconComp,
} from 'views/assets/img/icons/portfolioMandate/advisor-discretionary_virtual.svg';
import {
    ReactComponent as PfMandateRoboAdvisoryRealIconComp,
} from 'views/assets/img/icons/portfolioMandate/robo-advisory_real.svg';
import {
    ReactComponent as PfMandateRoboAdvisoryVirtualIconComp,
} from 'views/assets/img/icons/portfolioMandate/robo-advisory_virtual.svg';
import {
    ReactComponent as PfMandateRoboDiscretionaryRealIconComp,
} from 'views/assets/img/icons/portfolioMandate/robo-discretionary_real.svg';
import {
    ReactComponent as PfMandateRoboDiscretionaryVirtualIconComp,
} from 'views/assets/img/icons/portfolioMandate/robo-discretionary_virtual.svg';
import {
    ReactComponent as PfMandateSelfRealIconComp,
} from 'views/assets/img/icons/portfolioMandate/self_real.svg';
import {
    ReactComponent as PfMandateSelfVirtualIconComp,
} from 'views/assets/img/icons/portfolioMandate/self_virtual.svg';
import {
    ReactComponent as PortfolioActiveStatusIconComp,
} from 'views/assets/img/icons/enhancedPortfolioStatus/portfolio_active.svg';
import {
    ReactComponent as PortfolioInactiveStatusIconComp,
} from 'views/assets/img/icons/enhancedPortfolioStatus/portfolio_inactive.svg';
import {
    ReactComponent as PortfolioWaitingStatusIconComp,
} from 'views/assets/img/icons/enhancedPortfolioStatus/portfolio_waiting.svg';
import { ReactComponent as ReadOnlyIconComp } from 'views/assets/img/icons/read_only.svg';
import { ReactComponent as RenderIconComp } from 'views/assets/img/icons/render.svg';
import { ReactComponent as ReportIconComp } from 'views/assets/img/icons/report.svg';
import { ReactComponent as ScenarioEditorIconComp } from 'views/assets/img/icons/scenario_editor.svg';
import { ReactComponent as SettingsIconComp } from 'views/assets/img/icons/settings.svg';
import { ReactComponent as StripeIconComp } from 'views/assets/img/icons/stripe.svg';
import { ReactComponent as SuccessIconComp } from 'views/assets/img/icons/success.svg';
import { ReactComponent as TextEditorIconComp } from 'views/assets/img/icons/text_editor.svg';
import { ReactComponent as TrashIconComp } from 'views/assets/img/icons/trash.svg';
import { ReactComponent as RemoveIconComp } from 'views/assets/img/icons/remove.svg';
import { ReactComponent as UnlistIconComp } from 'views/assets/img/icons/unlist.svg';
import { ReactComponent as UsersIconComp } from 'views/assets/img/icons/users.svg';
import { ReactComponent as ViewIconComp } from 'views/assets/img/icons/view.svg';
import { ReactComponent as WarningIconComp } from 'views/assets/img/icons/warning.svg';
import { ReactComponent as ThematicSearchIconComp } from 'views/assets/img/icons/thematic_search.svg';
import { ReactComponent as OrdersIconComp } from 'views/assets/img/icons/enhancedPortfolioStatus/orders.svg';
import {
    ReactComponent as OptimizationErrorStatusIconComp,
} from 'views/assets/img/icons/enhancedPortfolioStatus/optimization_error.svg';
import {
    ReactComponent as OptimizationNotRecommendedStatusIconComp,
} from 'views/assets/img/icons/enhancedPortfolioStatus/optimization_not_recommended.svg';
import {
    ReactComponent as OptimizationPendingStatusIconComp,
} from 'views/assets/img/icons/enhancedPortfolioStatus/optimization_pending.svg';
import {
    ReactComponent as OptimizationRecommendedStatusIconComp,
} from 'views/assets/img/icons/enhancedPortfolioStatus/optimization_recommended.svg';

export { ReactComponent as ErrorInlineSvg } from 'views/assets/img/icons/error.svg';
export const AnSvgIcon = SvgIcon;

/**
 * Custom icons
 *
 * Example of how to use:
 *   <SvgIcon component={MenuToggleIcon} />
 */

const TYPE_ICON_VIEWBOX = '0 0 32 32';

export interface ICustomIconProps {
    className?: string;
}

export function AddIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={AddIconComp} />);
}
export function AnalyticsIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={AnalyticsIconComp} />);
}
export function AppsIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={AppsIconComp} />);
}
export function ArrowDownIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ArrowDownIconComp} />);
}
export function ArrowLeftIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ArrowLeftIconComp} />);
}
export function ArrowRightIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ArrowRightIconComp} />);
}
export function ArrowUpIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ArrowUpIconComp} />);
}
export function ArrowSubIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ArrowSubIconComp} />);
}
export function BillingIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={BillingIconComp} />);
}
export function BranchMergeIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={BranchMergeComp} />);
}
export function BranchNewIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={BranchNewComp} />);
}
export function CashIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={CashIconComp} />);
}
export function CheckboxOffIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={CheckboxOffIconComp} />);
}
export function CheckboxOnIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={CheckboxOnIconComp} />);
}
export function ChildPolicyIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={ChildPolicyIconComp} />);
}
export function CopyIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={CopyIconComp} />);
}
export function ClapperBoardIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ClapperBoardIconComp} />);
}
export function CloseIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={CloseIconComp} />);
}
export function ClientsIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ClientsIconComp} />);
}
export function CsvIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={CsvIconComp} />);
}
export function DashboardIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={DashboardIconComp} />);
}
export function DownloadIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={DownloadIconComp} />);
}
export function DuplicateIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={DuplicateIconComp} />);
}
export function EditIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={EditIconComp} />);
}
export function ErrorIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ErrorIconComp} />);
}
export function EditFullIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={EditFullIconComp} />);
}
export function FindAndReplaceIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={FindAndReplaceIconComp} />);
}
export function HomeIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={HomeIconComp} />);
}
export function InfoIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={InfoIconComp} />);
}
export function InsertIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={InsertIconComp} />);
}
export function InstrumentsIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={InstrumentsIconComp} />);
}
export function InstrumentGroupsIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={InstrumentGroupsIconComp} />);
}
export function IntegrationIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={IntegrationIconComp} />);
}
export function KeyIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={KeyIconComp} />);
}
export function MoreIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={MoreIconComp} />);
}
export function NavCollapseIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={NavCollapseIconComp} />);
}
export function NavUncollapseIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={NavUncollapseIconComp} />);
}
export function BasePolicyIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={BasePolicyIconComp} />);
}
export function OrdersIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={OrdersIconComp} />);
}
export function OptimizationErrorStatusIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={OptimizationErrorStatusIconComp} />);
}
export function OptimizationRecommendedStatusIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={OptimizationRecommendedStatusIconComp} />);
}
export function OptimizationPendingStatusIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={OptimizationPendingStatusIconComp} />);
}
export function OptimizationNotRecommendedStatusIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={OptimizationNotRecommendedStatusIconComp} />);
}
export function PerformanceIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={PerformanceIconComp} />);
}
export function PortfolioMgmtIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={PortfolioMgmtIconComp} />);
}

export function PfMandateAdvisorAdvisoryRealIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateAdvisorAdvisoryRealIconComp} />);
}
export function PfMandateAdvisorAdvisoryVirtualIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateAdvisorAdvisoryVirtualIconComp} />);
}
export function PfMandateAdvisorDiscretionaryRealIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateAdvisorDiscretionaryRealIconComp} />);
}
export function PfMandateAdvisorDiscretionaryVirtualIcon(props: ICustomIconProps) {
    // eslint-disable-next-line max-len
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateAdvisorDiscretionaryVirtualIconComp} />);
}
export function PfMandateRoboAdvisoryRealIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateRoboAdvisoryRealIconComp} />);
}
export function PfMandateRoboAdvisoryVirtualIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateRoboAdvisoryVirtualIconComp} />);
}
export function PfMandateRoboDiscretionaryRealIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateRoboDiscretionaryRealIconComp} />);
}
export function PfMandateRoboDiscretionaryVirtualIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateRoboDiscretionaryVirtualIconComp} />);
}
export function PfMandateSelfRealIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateSelfRealIconComp} />);
}
export function PfMandateSelfVirtualIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} viewBox={TYPE_ICON_VIEWBOX} component={PfMandateSelfVirtualIconComp} />);
}

export function PortfolioReportIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={PortfolioReportIconComp} />);
}
export function PortfolioGroupsIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={PortfoliosGroupsIconComp} />);
}
export function PortfolioActiveStatusIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={PortfolioActiveStatusIconComp} />);
}
export function PortfolioInactiveStatusIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={PortfolioInactiveStatusIconComp} />);
}
export function PortfolioWaitingStatusIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={PortfolioWaitingStatusIconComp} />);
}
export function ReadOnlyIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ReadOnlyIconComp} />);
}
export function RenderIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={RenderIconComp} />);
}
export function ReportIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ReportIconComp} />);
}
export function ScenarioEditorIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ScenarioEditorIconComp} />);
}
export function SettingsIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={SettingsIconComp} />);
}
export function SuccessIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={SuccessIconComp} />);
}
export function StripeIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={StripeIconComp} />);
}
export function TextEditorIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={TextEditorIconComp} />);
}
export function ThematicSearchIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ThematicSearchIconComp} />);
}
export function TrashIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={TrashIconComp} />);
}
export function RemoveIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={RemoveIconComp} />);
}
export function UnlistIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={UnlistIconComp} />);
}
export function UsersIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={UsersIconComp} />);
}
export function ViewIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={ViewIconComp} />);
}
export function WarningIcon(props: ICustomIconProps) {
    return (<SvgIcon {...props} component={WarningIconComp} />);
}
