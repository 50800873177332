import { isRoboPortfolioManagerType } from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import { BankAccountType } from '@console/common/models/banking.models';
import {
    array, number, object, string, boolean,
    bankAccountNumber, bankId, currencySchema,
    ObjectShape, getDynamicObjectSchema,
} from '@console/common/utils/schema';
import {
    PortfolioManagerType,
    PortfolioMoneyType,
} from '@console/core-api/models/portfolioMgmt/portfolio.models';
import { entityId } from '@console/core-api/utils/schema/entityIdSchema';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { IEnhancedPortfolioInstruments } from '@console/bff/models/portfolios/portfolioHoldings.models';
import { policyManagerTagsSchema } from '@console/bff/server/utils/schemas/policy.schemas';
import {
    bankAccountTypeSchema,
    portfolioManagerTypeSchema,
    portfolioMoneyTypeSchema,
} from '@console/bff/server/utils/schemas/portfolio.schemas';
import {
    isPortfolioActiveFieldApplicable,
} from 'utils/entities/portfolioMgmt/portfolioUtils';

const policyObjectSchema = object({
    id: string().required(),
});

const portfolioDetailsSchema = object({
    name: string().required().trim(),
    externalId: string(),

    /* Brokerage Account fields */
    bankAccountNumber: bankAccountNumber()
        // eslint-disable-next-line max-len
        .when('bankAccountType', ([bankAccountType]: BankAccountType[]) => bankAccountNumber(bankAccountType).required()),
    bankAccountType: bankAccountTypeSchema.required(),
    bankId: bankId().required(),
    brokeragePortfolioId: string(),
    brokerageUserId: string(),
    paymentReference: string(),

    managerType: portfolioManagerTypeSchema.required(),
    moneyType: portfolioMoneyTypeSchema.required(),

    /* only for ROBO portfolio's */
    policy: policyObjectSchema.nullable()
        .when('managerType', {
            is: isRoboPortfolioManagerType,
            then: () => policyObjectSchema.required(),
        }),
    tags: policyManagerTagsSchema
        .when('managerType', {
            is: isRoboPortfolioManagerType,
            then: () => array().minItems(0),
        }),
    goalId: string()
        .when('managerType', {
            is: isRoboPortfolioManagerType,
            then: (schema) => schema.required(),
        }),
    horizonId: string()
        .when('managerType', {
            is: isRoboPortfolioManagerType,
            then: (schema) => schema.required(),
        }),
    riskProfileId: string()
        .when('mangerType', {
            is: isRoboPortfolioManagerType,
            then: (schema) => schema.required(),
        }),
    active: boolean()
        .when(['managerType', 'moneyType'], {
            is: (managerType: PortfolioManagerType, moneyType: PortfolioMoneyType) =>
                isPortfolioActiveFieldApplicable({ managerType, moneyType }),
            then: (schema) => schema.required(),
        }),

    owner: object({
        id: string().required(),
    }), /* ownerId maps with 'owned_by_user_id' */

    /* only for PAPER_MONEY portfolio's */
    startAmount: number()
        .when('moneyType', {
            is: PortfolioMoneyType.PAPER_MONEY,
            then: (schema) => schema.required().minVal(0),
        }),
});

export const portfolioDetailsSectionSchema = portfolioDetailsSchema.pick([
    'name', 'externalId', 'active', 'managerType', 'moneyType',
]);
export const portfolioRoboRelatedSchema = portfolioDetailsSchema.pick([
    'goalId', 'horizonId', 'policy', 'managerType', 'tags',
]);
export const portfolioBrokerageAccountSchema = portfolioDetailsSchema.pick([
    'bankAccountNumber', 'bankAccountType', 'bankId', 'brokeragePortfolioId', 'brokerageUserId',
]);
export const portfolioRiskProfileSchema = portfolioDetailsSchema.pick(['riskProfileId', 'managerType']);
export const portfolioOwnerSchema = portfolioDetailsSchema.pick(['owner']);
export const portfolioPolicySchema = portfolioDetailsSchema.pick(['policy', 'managerType']);
export const portfolioAddSchema = portfolioDetailsSchema.pick([
    'name', 'externalId',
    'managerType', 'moneyType',
    'owner',
    'policy', 'goalId', 'horizonId',
    'startAmount',
]);

export const enhancedInstrumentSchema = object({
    name: string().required(),
    pricePer: number().required(),
    units: number().required(),
    amount: number().required(),
    percentageWithinHoldings: number().required(),
    class: string(),
    type: string(),
    deleted: boolean(),
    edited: boolean(),
    added: boolean(),
    missingData: boolean(),
    missingPrice: boolean(),
});

export const enhancedPortfolioHoldingsSchema = object({
    portfolioId: entityId(CoreApiEntityType.portfolios),
    currency: currencySchema,
    cash: object({
        amount: number().required(),
        percentageWithinHoldings: number().required(),
        edited: boolean(),
    }),
    instruments: getDynamicObjectSchema<IEnhancedPortfolioInstruments>({
        isRequired: true,
        mapper: (enhancedInstrumentsInput) => Object.entries(enhancedInstrumentsInput).reduce(
            (accumulator, [key]) => {
                accumulator[key] = enhancedInstrumentSchema.required();
                return accumulator;
            },
            {} as ObjectShape,
        ),
    }),
    instrumentsTotalValue: number().required(),
    instrumentsPercentageWithinHoldings: number().required(),
});

export const portfolioAddHoldingsSchema = enhancedPortfolioHoldingsSchema.pick(['instruments']);
