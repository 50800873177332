import { HEADER_NAME } from '@typsy/rest-client/dist/config/header.config';
import { isTransactionSourceDataAvailable } from '@console/reporting-api/config/reportingApi.config';
import { ConsoleBffPath } from '../../server/consoleBffUrls';
import { get } from '../consoleBffRequestWrapper';
import { TReportInfoBff } from '../../models/mgmtReporting/reportInfoBff.models';
import {
    IFetchRoboAumReportApiInputClient,
    IFetchRoboAumReportApiReply,
} from '../../models/mgmtReporting/roboAumReport.models';
import {
    TFetchSelfAumReportApiInputClient,
    TFetchSelfAumReportApiReply,
} from '../../models/mgmtReporting/selfAumReport.models';
import {
    TFetchRoboClientGrowthReportApiInputClient,
    TFetchRoboClientGrowthReportApiReply,
} from '../../models/mgmtReporting/roboClientGrowthReport.models';
import {
    TFetchSelfClientGrowthReportApiInputClient,
    TFetchSelfClientGrowthReportApiReply,
} from '../../models/mgmtReporting/selfClientGrowthReport.models';
import {
    TFetchRoboClientCashTransferReportApiInputClient,
    TFetchRoboClientCashTransferReportApiReply,
} from '../../models/mgmtReporting/roboClientCashTransferReport.models';
import {
    TFetchSelfClientCashTransferReportApiInputClient,
    TFetchSelfClientCashTransferReportApiReply,
} from '../../models/mgmtReporting/selfClientCashTransferReport.models';
import {
    IFetchMgmtReportBaseApiInput, IFetchMgmtReportBaseApiInputClient,
} from '../../models/mgmtReporting/baseMgmtReporting.models';
import {
    IFetchRoboPortfolioPerformanceReportApiInputClient,
    IFetchRoboPortfolioPerformanceReportApiReplyBff,
} from '../../models/mgmtReporting/roboPortfolioPerformanceReport.models';

export const REPORTING_FEATURE_FLAGS = {
    self_aum: false,
    client_cash_transfer: isTransactionSourceDataAvailable,
};

export function fetchReportInfo() {
    return get<TReportInfoBff>({
        url: ConsoleBffPath.MGMT_REPORTING_INFO,
    });
}

export function fetchRoboAumReport({
    locale,
    aggregateBy,
    ...otherClientInput
}: IFetchRoboAumReportApiInputClient) {
    return get<IFetchRoboAumReportApiReply>({
        url: ConsoleBffPath.MGMT_REPORTING_ROBO_AUM,
        headers: {
            [HEADER_NAME.ACCEPT_LANGUAGE]: locale,
        },
        queryParams: {
            ...mapToFetchMgmtReportBaseApiInput(otherClientInput),
            aggregateBy,
        },
    });
}

export function fetchRoboClientGrowthReport(
    clientInput: TFetchRoboClientCashTransferReportApiInputClient,
) {
    return get<TFetchRoboClientGrowthReportApiReply>({
        url: ConsoleBffPath.MGMT_REPORTING_ROBO_CLIENT_GROWTH,
        queryParams: {
            ...mapToFetchMgmtReportBaseApiInput(clientInput),
        },
    });
}

export function fetchRoboClientCashTransferReport(
    clientInput: TFetchRoboClientGrowthReportApiInputClient,
) {
    return get<TFetchRoboClientCashTransferReportApiReply>({
        url: ConsoleBffPath.MGMT_REPORTING_ROBO_CLIENT_CASH_TRANSFER,
        queryParams: {
            ...mapToFetchMgmtReportBaseApiInput(clientInput),
        },
    });
}

export function fetchRoboPortfolioPerformanceReport({
    locale,
    ...otherClientInput
}: IFetchRoboPortfolioPerformanceReportApiInputClient) {
    return get<IFetchRoboPortfolioPerformanceReportApiReplyBff>({
        url: ConsoleBffPath.MGMT_REPORTING_ROBO_PORTFOLIO_PERFORMANCE,
        headers: {
            [HEADER_NAME.ACCEPT_LANGUAGE]: locale,
        },
        queryParams: {
            ...mapToFetchMgmtReportBaseApiInput(otherClientInput),
        },
    });
}

export function fetchSelfAumReport(
    clientInput: TFetchSelfAumReportApiInputClient,
) {
    return get<TFetchSelfAumReportApiReply>({
        url: ConsoleBffPath.MGMT_REPORTING_SELF_AUM,
        queryParams: {
            ...mapToFetchMgmtReportBaseApiInput(clientInput),
        },
    });
}

export function fetchSelfClientGrowthReport(
    clientInput: TFetchSelfClientCashTransferReportApiInputClient,
) {
    return get<TFetchSelfClientGrowthReportApiReply>({
        url: ConsoleBffPath.MGMT_REPORTING_SELF_CLIENT_GROWTH,
        queryParams: {
            ...mapToFetchMgmtReportBaseApiInput(clientInput),
        },
    });
}

export function fetchSelfClientCashTransferReport(
    clientInput: TFetchSelfClientGrowthReportApiInputClient,
) {
    return get<TFetchSelfClientCashTransferReportApiReply>({
        url: ConsoleBffPath.MGMT_REPORTING_SELF_CLIENT_CASH_TRANSFER,
        queryParams: {
            ...mapToFetchMgmtReportBaseApiInput(clientInput),
        },
    });
}

function mapToFetchMgmtReportBaseApiInput(
    clientInput: IFetchMgmtReportBaseApiInputClient,
): IFetchMgmtReportBaseApiInput {
    const {
        period,
    } = clientInput;

    /**
     * Currently there is no PeriodType.CUSTOM in the Mgmt Reporting screens,
     * but this was already foreseen for if it is.
     */
    /*
    const optionalPeriodDates: Pick<IFetchMgmtReportBaseApiInput, 'customPeriodStartDate' | 'customPeriodEndDate'> = {};
    if (period.type === PeriodType.CUSTOM) {
        if (period.customStartDate) {
            optionalPeriodDates.customPeriodStartDate = formatReportDateForBE(period.customStartDate);
        }
        if (period.customEndDate) {
            optionalPeriodDates.customPeriodEndDate = formatReportDateForBE(period.customEndDate);
        }
    }
    */

    return {
        periodType: period.type,
        // ...optionalPeriodDates,
    };
}
