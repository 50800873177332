import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TI18nLabelOrString } from 'models/general.models';
import { OPACITY } from 'config/styling/colors';
import { SIZES } from 'config/styling/sizes';
import { toI18nLabel } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';
import { ErrorInlineSvg } from 'views/common/icons';
import TextButton from 'views/common/buttons/TextButton';
import ContentTitle from 'views/common/layout/ContentTitle';

export interface IErrorWidgetProps {
    className?: string;
    centerHorizontally?: boolean; /* default true */
    error?: {
        label: TI18nLabelOrString;
    };
    icon?: ReactNode;
    title?: {
        label: TI18nLabelOrString;
    };
    action?: {
        label: TI18nLabelOrString;
        onExecute: () => void;
    };
    positionRelativeToBodyHeader?: boolean; /* default false */
}

const useStyles = makeStyles((theme) => ({
    ErrorWidgetWrapper: {
        ...mixins.heightMax(),

        '&.horizontallyCentered': {
            ...mixins.flexRow({ alignMain: 'center' }),
        },

        '&.positionedRelativeToBodyHeader': {
            ...mixins.widthMax(),
            height: 'inherit',
            marginTop: `-${SIZES.BODY.HEADER_HEIGHT}px`,
        },
    },
    ErrorWidget: {
        ...mixins.flexColCenterCenter(),

        '& .error-icon': {
            opacity: OPACITY.HIGH,
        },
        '& .error-title': {
            marginTop: theme.spacing(4),
        },
        '& p': {
            marginTop: theme.spacing(1),
        },
        '& button': {
            marginTop: theme.spacing(4),
        },
    },
}));

export default function ErrorWidget({
    className,
    centerHorizontally = true,
    error,
    icon,
    title,
    action,
    positionRelativeToBodyHeader = false,
}: IErrorWidgetProps) {
    const classes = useStyles();

    const titleLabel: TI18nLabelOrString = title
        ? title.label
        : 'app_shell.error.widget.title_default';

    return (
        <div
            className={clsx(
                classes.ErrorWidgetWrapper,
                className,
                centerHorizontally && 'horizontallyCentered',
                positionRelativeToBodyHeader && 'positionedRelativeToBodyHeader',
            )}
        >
            <div className={classes.ErrorWidget}>
                {icon && icon}
                {!icon && (
                    /* inline so that the icon is still shown even when there is no internet connection anymore */
                    <ErrorInlineSvg className="error-icon" />
                )}

                <ContentTitle className="error-title" label={titleLabel} />

                {error && (
                    <p>
                        <Translate {...toI18nLabel(error.label)} />
                    </p>
                )}

                {action && (
                    <TextButton
                        id="error-widget-action"
                        onClick={action.onExecute}
                        label={action.label}
                    />
                )}
            </div>
        </div>
    );
}
