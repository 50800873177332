import produce from 'immer';
import isNull from '@snipsonian/core/cjs/is/isNull';
import isSet from '@snipsonian/core/cjs/is/isSet';
import {
    reportBreadcrumb,
    setUserContext, removeUserContext,
} from '@typsy/sentry-react/dist/reporting/reportErrorReact';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import {
    extractUserOperationPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/extractUserOperationPermissions';
import { ISetStateImmutable } from 'models/state.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IAuthenticatedUser,
    IUserAuthorisation,
    TAuthFlowStatus,
    IUserGroupIdMap,
} from 'models/state/auth.models';
import { api } from 'api';
import { createAction, getStore } from 'state';
import { getAuthenticatedUser, getLoginRedirectUrl, getUserPermissions } from 'state/auth/selectors';
import { authService, OidcUser } from 'state/auth/auth.service';
import { fetchAndProcessTenantSettings } from 'state/appConfig/actions';
import { triggerFlashSuccess, triggerFlashError } from 'state/ui/flashMessages.actions';
import { errorMessageFlashDispatcher } from 'state/flashDispatcher';
import { redirectToPath, redirectToHome, redirectToLogin, getRoutePath } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';

authService.setEventHandlers({
    onSilentRenewedUser: (renewedUser) => {
        if (renewedUser) {
            const authenticatedUser = mapToAuthenticatedUser(renewedUser);

            getStore().setState({
                newState: produce(getStore().getState(), (draftState) => {
                    /* keep the previously fetched permissions */
                    authenticatedUser.permissions = getUserPermissions(getStore().getState());

                    draftState.auth.authenticatedUser = authenticatedUser;
                }),
                notificationsToTrigger: [StateChangeNotification.AUTH_USER],
            });
        }
    },
    onAccessTokenExpired: () => {
        /**
         * We don't trigger a flash anymore as this sometimes was triggered even when the session was silently renewed.
         * When browser tab was not active maybe?
         */

        reportBreadcrumb('error.auth.access_token_expired');
        redirectToLogin();
    },
});

export const openLoginModal = () => createAction({
    type: 'OPEN_LOGIN_MODAL',
    payload: {},
    process({ setStateImmutable }) {
        updateFlowStatus({ setStateImmutable, flowStatus: 'login_triggered' });

        authService.login();
    },
});

export const completeLoginOnCodeCallback = ({ urlQueryPart }: { urlQueryPart: string }) => createAction({
    type: 'COMPLETE_LOGIN',
    payload: {},
    async process({ setStateImmutable, getState, dispatch }) {
        try {
            updateFlowStatus({ setStateImmutable, flowStatus: 'login_busy' });

            const oidcUser = await authService.loginCallback(urlQueryPart);

            updateFlowStatus({ setStateImmutable, flowStatus: null });

            if (isNull(oidcUser)) {
                redirectToLogin();
            } else {
                const authenticatedUser = mapToAuthenticatedUser(oidcUser);

                /**
                 * We already store the authenticatedUser (without notifying) so that the accessToken is there
                 * to fetch the user permissions/groups & the tenant settings.
                 */
                setStateImmutable({
                    toState: (draftState) => {
                        draftState.auth.authenticatedUser = authenticatedUser;
                    },
                    notificationsToTrigger: [],
                });

                const [
                    { permissions, groups },
                    tenantSettings,
                ] = await Promise.all([
                    fetchUserAuthorisation(authenticatedUser),
                    fetchAndProcessTenantSettings(),
                ]);

                setStateImmutable({
                    toState: (draftState) => {
                        draftState.auth.authenticatedUser.permissions = permissions;
                        draftState.auth.authenticatedUser.groups = groups;

                        draftState.appConfig.tenant = tenantSettings;
                    },
                    notificationsToTrigger: [
                        StateChangeNotification.AUTH_USER,
                        StateChangeNotification.APP_CONFIG_TENANT,
                    ],
                });

                setUserContext(authenticatedUser);

                const redirectUrl = getLoginRedirectUrl(getState());

                if (isSet(redirectUrl)) {
                    dispatch(setLoginRedirectUrl({ redirectUrl: null }));

                    redirectToPath({
                        path: redirectUrl,
                    });
                } else {
                    redirectToHome();
                }
            }
        } catch (error) {
            updateFlowStatus({ setStateImmutable, flowStatus: null });
            redirectToLogin();
        }
    },
});

export const refreshAuthenticatedUserPermissions = () => createAction({
    type: 'REFRESH_AUTHENTICATED_USER_PERMISSIONS',
    payload: {},
    async process({ setStateImmutable, getState }) {
        try {
            const { permissions, groups } = await fetchUserAuthorisation(
                getAuthenticatedUser(getState()),
            );

            setStateImmutable({
                toState: (draftState) => {
                    draftState.auth.authenticatedUser.permissions = permissions;
                    draftState.auth.authenticatedUser.groups = groups;
                },
                notificationsToTrigger: [StateChangeNotification.AUTH_USER],
            });
        } catch (error) {
            redirectToLogin();
        }
    },
});

async function fetchUserAuthorisation(
    authenticatedUser: IAuthenticatedUser,
): Promise<IUserAuthorisation> {
    try {
        const groupsThatUserBelongsTo =
            await api.users.fetchUserGroupMembership({ userId: authenticatedUser.ulid });

        return {
            permissions: extractUserOperationPermissions(groupsThatUserBelongsTo),
            groups: groupsThatUserBelongsTo.reduce(
                (accumulator, group) => {
                    accumulator[group.id] = { name: group.name };
                    return accumulator;
                },
                {} as IUserGroupIdMap,
            ),
        };
    } catch (error) {
        errorMessageFlashDispatcher('error.auth.user_authorisation_details_could_not_be_fetched')();
        throw error;
    }
}

function mapToAuthenticatedUser(oidcUser: OidcUser): IAuthenticatedUser {
    return {
        ulid: oidcUser.profile.extension_ulid,
        tenant: oidcUser.profile.extension_tenant,
        firstName: oidcUser.profile.given_name,
        /**
         * In case of monosign, we receive just 'name' and not 'given_name' or 'family_name'
         * In case of idcs, we receive just 'user_displayname' and not 'given_name', 'family_name' or 'name'
         */
        lastName: oidcUser.profile.family_name || oidcUser.profile.name || oidcUser.profile.user_displayname,
        email: oidcUser.profile.email,
        accessToken: oidcUser.access_token,
        /* P.S. permissions are added later on */
    };
}

export const triggerResetPasswordFlow = () => createAction({
    type: 'TRIGGER_RESET_PASSWORD',
    payload: {},
    process({ setStateImmutable }) {
        updateFlowStatus({ setStateImmutable, flowStatus: 'reset_pw_triggered' });

        authService.resetPassword();
    },
});

export const completeResetPasswordOnCodeCallback = ({ urlQueryPart }: { urlQueryPart: string }) => createAction({
    type: 'COMPLETE_RESET_PASSWORD',
    payload: {},
    async process({ setStateImmutable, dispatch }) {
        try {
            updateFlowStatus({ setStateImmutable, flowStatus: 'reset_pw_busy' });

            await authService.resetPasswordCallback(urlQueryPart);

            // await simulateWaitTime({ waitTimeInMillis: 5000 });

            updateFlowStatus({ setStateImmutable, flowStatus: null });

            dispatch(triggerFlashSuccess({
                translationKey: 'auth.reset_password.flash_success',
            }));

            // to clear the code query param from the url
            redirectToLogin();
        } catch (error) {
            updateFlowStatus({ setStateImmutable, flowStatus: null });

            dispatch(triggerFlashError({
                translationKey: 'auth.reset_password.flash_success',
            }));

            redirectToLogin();
        }
    },
});

export const logout = () => createAction({
    type: 'LOGOUT',
    payload: {},
    process({ setStateImmutable }) {
        setStateImmutable({
            toState: (draftState) => {
                draftState.auth.authenticatedUser = null;
                draftState.auth.flowStatus = null;

                draftState.appConfig.tenant = null;
            },
            notificationsToTrigger: [
                StateChangeNotification.AUTH_USER,
                StateChangeNotification.AUTH_FLOW_STATUS,
                StateChangeNotification.APP_CONFIG_TENANT,
            ],
        });

        removeUserContext();

        authService.logout();
    },
});

interface IRedirectUrlPayload {
    redirectUrl: string;
}

export function setLoginRedirectUrl({ redirectUrl }: IRedirectUrlPayload) {
    return createAction<IRedirectUrlPayload>({
        type: 'SET_LOGIN_REDIRECT_URL',
        payload: {
            redirectUrl,
        },
        process({ setStateImmutable, getState }) {
            const newRedirectUrl =
                (redirectUrl && (redirectUrl.indexOf(getRoutePath({ routeKey: ROUTE_KEY.R_LOGIN })) === -1))
                    ? redirectUrl
                    : null;

            if (newRedirectUrl !== getLoginRedirectUrl(getState())) {
                setStateImmutable({
                    toState: (draftState) => {
                        draftState.auth.loginRedirectUrl = newRedirectUrl;
                    },
                    notificationsToTrigger: [StateChangeNotification.AUTH_REDIRECT_URL],
                });
            }
        },
    });
}

export const updateUserPermission = ({
    permission,
    enabled,
}: {
    permission: OperationPermissionKey;
    enabled?: boolean; // if not provided, then the permission will be toggled from it's previous boolean value
}) => createAction<unknown>({
    type: 'UPDATE_USER_PERMISSION',
    payload: {
        permission,
    },
    process({ setStateImmutable, getState }) {
        if (getAuthenticatedUser(getState())) {
            setStateImmutable({
                toState: (draftState) => {
                    const newValue = isSet(enabled)
                        ? enabled
                        : !draftState.auth.authenticatedUser.permissions[permission];

                    draftState.auth.authenticatedUser.permissions[permission] = newValue;
                },
                notificationsToTrigger: [StateChangeNotification.AUTH_USER],
            });
        }
    },
});

function updateFlowStatus({
    setStateImmutable,
    flowStatus,
}: {
    setStateImmutable: ISetStateImmutable;
    flowStatus: TAuthFlowStatus;
}) {
    setStateImmutable({
        toState: (draftState) => {
            draftState.auth.flowStatus = flowStatus;
        },
        notificationsToTrigger: [StateChangeNotification.AUTH_FLOW_STATUS],
    });
}
