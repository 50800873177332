import { createSelector } from 'reselect';
import isSet from '@snipsonian/core/cjs/is/isSet';
import getArrayCopy from '@snipsonian/core/cjs/array/getArrayCopy';
import getFirstItemOfArray from '@snipsonian/core/cjs/array/filtering/getFirstItemOfArray';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { multiTranslationsLabel } from '@console/common/utils/schema';
import { IApiMultiTranslationsLabel } from '@console/core-api/models/api.models';
import { TAssetClassKey } from '@console/core-api/models/portfolioMgmt/policy.models';
import {
    containsRoboPortfolioManagerType, containsSelfPortfolioManagerType,
} from '@console/core-api/utils/entities/portfolios/portfolioTypeUtils';
import { UserFeatureFlag } from '@console/core-api/models/userMgmt/user.models';
import { ITenantUserSettings } from '@console/bff/models/settings/tenantSettings.models';
import { IState } from 'models/state.models';
import { getStore } from 'state';

export const getTenantSettings = (state: IState = getStore().getState()) => state.appConfig.tenant;
export const areTenantSettingsSet = (state?: IState) => getTenantSettings(state) !== null;

export const getDefaultTenantCurrency = (state: IState = getStore().getState()) =>
    getTenantSettings(state)?.currencies.default;

export const getClientConfig = (state?: IState) => getTenantSettings(state)?.client;

export const getUserConfig = (state?: IState) => getTenantSettings(state)?.user || {} as ITenantUserSettings;
export const getDefaultUserLocaleConfig = (state?: IState) => getUserConfig(state)?.defaultLocale;
export const isMultipleUserLocales = (state?: IState) => getUserConfig(state)?.locales?.length > 1;
const userLocalesSortedDefaultFirstMemoizedSelector = createSelector(
    getUserConfig,
    (userConfig) => {
        const { locales, defaultLocale } = userConfig;

        return getArrayCopy(locales)
            .sort((localeA, localeB) => {
                if (localeA === defaultLocale) {
                    return -1;
                }
                if (localeB === defaultLocale) {
                    return 1;
                }
                return anyComparerAscending(localeA, localeB);
            });
    },
);
export const getUserLocalesSortedDefaultFirstMemoized = (state: IState = getStore().getState()) =>
    userLocalesSortedDefaultFirstMemoizedSelector(state);
export const getMultiTranslationsLabelInitialValue = (
    defaultValue = '',
    state?: IState,
): IApiMultiTranslationsLabel => {
    const { locales } = getUserConfig(state);

    return locales.reduce(
        (accumulator, locale) => {
            accumulator[locale] = defaultValue;

            return accumulator;
        },
        {} as IApiMultiTranslationsLabel,
    );
};
export const ensureMultiTranslationsLabelHasAllLocales = (
    label: IApiMultiTranslationsLabel,
    state?: IState,
): IApiMultiTranslationsLabel => {
    const { locales } = getUserConfig(state);

    return locales.reduce(
        (accumulator, locale) => {
            accumulator[locale] = (label && label[locale]) || '';

            return accumulator;
        },
        {} as IApiMultiTranslationsLabel,
    );
};
export const getMultiTranslationsLabelSchema = (
    state?: IState,
    markDefaultLocaleAsRequired? : boolean, // default true
) => {
    const { locales, defaultLocale } = getUserConfig(state);

    return multiTranslationsLabel({
        locales,
        defaultLocale,
        markDefaultLocaleAsRequired,
    });
};

export const getRiskProfilesScoreRangeConfig = (state?: IState) => getTenantSettings(state)?.riskProfiles.scoreRange;

export const getPoliciesConfig = (state?: IState) => getTenantSettings(state)?.policies;
export const getPoliciesAlgorithmsConfig = (state?: IState) => getPoliciesConfig(state).algorithms;
export const getDefaultPolicyAlgorithm = (state?: IState) => {
    const availableAlgos = getPoliciesAlgorithmsConfig(state);

    if (!availableAlgos || availableAlgos.length !== 1) {
        return null;
    }

    return availableAlgos[0];
};
export const getPoliciesBenchmarksConfig = (state?: IState) => getPoliciesConfig(state).benchmarks;
export const getPoliciesSettingsBoundariesConfig = (state?: IState) =>
    getPoliciesConfig(state).settingsBoundaries;
export const getPoliciesAssetClassesConfig = (state?: IState) =>
    getPoliciesConfig(state).assetClasses;
const getPoliciesEnabledAssetCLassesMemoizedSelector = createSelector(
    areTenantSettingsSet,
    getPoliciesAssetClassesConfig,
    (areTenantSettings, assetClasses) => {
        if (!areTenantSettings) {
            return {};
        }

        return assetClasses.reduce(
            (accumulator, key) => {
                accumulator[key as unknown as string] = true;
                return accumulator;
            },
            {} as { [key: string]: true },
        );
    },
);
export const isPolicyAssetClassEnabled = (assetClass: TAssetClassKey, state?: IState): boolean =>
    !!getPoliciesEnabledAssetCLassesMemoizedSelector(state)[assetClass];

export const getPortfolioTypesConfig = (state?: IState) => getTenantSettings(state)?.portfolios.portfolioTypes;
export const getDefaultPortfolioTypeConfig = (state?: IState) => getFirstItemOfArray(getPortfolioTypesConfig(state));

export const getStoryTellerConfig = (state?: IState) => getTenantSettings(state)?.storyteller;

export interface IFeaturesEnabledForTenant {
    [userFeatureFlag: string]: boolean;
}

const getFeaturesEnabledForTenantMemoizedSelector = createSelector(
    areTenantSettingsSet,
    getPortfolioTypesConfig,
    getStoryTellerConfig,
    (areTenantSettings, portfolioTypes, storyTellerConfig) => {
        if (!areTenantSettings) {
            return {};
        }

        return {
            [UserFeatureFlag.Console]: true,
            [UserFeatureFlag.RoboAdvisor]: containsRoboPortfolioManagerType(portfolioTypes),
            [UserFeatureFlag.SelfInvestor]: containsSelfPortfolioManagerType(portfolioTypes),
            [UserFeatureFlag.Storyteller]: storyTellerConfig?.manageReports?.isEnabled,
        };
    },
);

export const getFeaturesEnabledForTenantMemoized = (state: IState = getStore().getState()): IFeaturesEnabledForTenant =>
    getFeaturesEnabledForTenantMemoizedSelector(state);

export const isRoboAdvisorEnabled = (state?: IState) =>
    getFeaturesEnabledForTenantMemoized(state)[UserFeatureFlag.RoboAdvisor];

export const isSelfInvestorEnabled = (state?: IState) =>
    getFeaturesEnabledForTenantMemoized(state)[UserFeatureFlag.SelfInvestor];

export const isStorytellerEnabled = (state?: IState) =>
    getFeaturesEnabledForTenantMemoized(state)[UserFeatureFlag.Storyteller];

/**
 * Returns an array of UserFeatureFlag that are
 * - either enabled in the tenant config
 * - either not set at all in the tenant config (assumption that those features is available then)
 */
export function getUserFeatureFlagsListEnabledForTenant(): UserFeatureFlag[] {
    const featuresEnabledForTenant = getFeaturesEnabledForTenantMemoized();

    return Object.values(UserFeatureFlag)
        .filter((featureFlag) =>
            featuresEnabledForTenant[featureFlag]
            || !isSet(featuresEnabledForTenant[featureFlag]));
}

export const isManagementReportingEnabled = (state?: IState) =>
    getTenantSettings(state)?.managementReporting?.isEnabled;

export interface IManagementReportingFeatureFlags {
    robo: boolean;
    self: boolean;
}

export const getManagementReportingFeatureFlags = (state?: IState): IManagementReportingFeatureFlags => ({
    robo: isManagementReportingEnabled(state) && isRoboAdvisorEnabled(state),
    self: isManagementReportingEnabled(state) && isSelfInvestorEnabled(state),
});
