import { CSSProperties } from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';

interface IWidthHeightProps {
    w?: number;
    h?: number;
}

export const widthHeightPixels = ({
    w,
    h,
}: IWidthHeightProps = {}): CSSProperties => ({
    width: w,
    height: h,
});

export const widthHeightPixelsSame = (size: number): CSSProperties => widthHeightPixels({ w: size, h: size });

export const widthHeightPercentage = ({
    w,
    h,
}: IWidthHeightProps = {}): CSSProperties => ({
    width: isSet(w) ? `${w}%` : undefined,
    height: isSet(h) ? `${h}%` : undefined,
});

export const widthHeightMax = () => widthHeightPercentage({ w: 100, h: 100 });
export const widthMax = () => widthHeightPercentage({ w: 100 });
export const heightMax = () => widthHeightPercentage({ h: 100 });
