import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import isNumber from '@snipsonian/core/cjs/is/isNumber';
import { roundFloat } from '@console/common/utils/float/roundFloat';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { TLabel } from 'models/general.models';
import { SIZES } from 'config/styling/sizes';
import { APP_COLORS } from 'config/styling/colors';
import { calculateSpacing } from 'config/styling/theme';
import { formatPercentage } from 'utils/number/percentageUtils';
import { makeStyles, mixins } from 'views/styling';
import { GENERIC_CLASS } from 'views/assets/cssInJs/genericClasses';
import Text from 'views/common/widget/Text';
import InputTextField, { IInputTextFieldProps, IOnChangeTextInputProps } from './InputTextField';

type TNumberInputType = 'number' | 'percentage';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInputNumberFieldProps
    extends Omit<IInputTextFieldProps<number>, 'type' | 'selectProps' | 'children'> {
    nrOfDecimals?: number; // default 0
    shouldAllowNumbersBelow0?: boolean; // default true
    shouldAllowNumbersAbove1?: boolean; // default true
    type?: TNumberInputType; // default 'number'
    noDataLabel?: TLabel;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnChangeNumberInputProps extends IOnChangeTextInputProps<number> {}

interface IStyleProps {
    height?: number;
}

const useStyles = makeStyles(() => ({
    DisabledInputFieldNumber: {
        ...mixins.widthMax(),
        height: ({ height }: IStyleProps) => height,
        ...mixins.flexColCenterCenter(),
        ...mixins.typo({ size: 16 }),

        '& .noDataLabel': {
            ...mixins.typo({ size: 15, color: APP_COLORS.GREY['300'] }),
        },
        '& .spacing': {
            paddingBottom: calculateSpacing(4) - 3,
        },
    },
}));

export default function InputNumberField({
    inputProps,
    nrOfDecimals = 0,
    shouldAllowNumbersBelow0 = true,
    shouldAllowNumbersAbove1 = true,
    markAsWrappedInputField = true,
    disabled = false,
    value,
    noDataLabel,
    type = 'number',
    height = SIZES.INPUT.HEIGHT.M,
    ...otherProps
}: IInputNumberFieldProps & IStyleProps) {
    const classes = useStyles({ height });
    const isInputTypePercentage = type === 'percentage';
    const adjustedValue = isInputTypePercentage
        ? transformPercentageValue({ value, nrOfDecimals })
        : value;
    const numberInputProps = nrOfDecimals > 0
        ? {
            ...(inputProps || {}),
            step: toStepInputProp(nrOfDecimals),
            adornment: getAdornment(),
        }
        : inputProps;

    return (
        <>
            {!disabled && (
                <InputTextField<number>
                    type="number"
                    userInputConverter={toNumber}
                    inputProps={{
                        ...numberInputProps,
                        style: {
                            textAlign: 'right',
                        },
                    }}
                    value={adjustedValue}
                    markAsWrappedInputField={markAsWrappedInputField}
                    {...otherProps}
                />
            )}
            {disabled && renderUneditableField()}
        </>
    );

    function renderUneditableField() {
        const doesInputHaveValue = isSet(value);

        return (
            <div className={clsx(
                markAsWrappedInputField && GENERIC_CLASS.WRAPPED_INPUT_FIELD,
                classes.DisabledInputFieldNumber,
                !doesInputHaveValue && !noDataLabel && 'spacing',
            )}
            >
                {doesInputHaveValue && getReadOnlyValue()}
                {!doesInputHaveValue && noDataLabel && (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label className="noDataLabel">
                        <Text label={noDataLabel} />
                    </label>
                )}
            </div>
        );
    }

    function getReadOnlyValue() {
        if (isSet(inputProps?.adornment)) {
            return formatAmount(adjustedValue, {
                nrOfDecimals,
                stripTrailingDecimalZeros: true,
                currency: inputProps.adornment,
            });
        }
        if (isInputTypePercentage) {
            return formatPercentage(adjustedValue, {
                nrOfDecimals,
                stripTrailingDecimalZeros: true,
            });
        }
        return formatFloat(
            adjustedValue,
            { nrOfDecimals, stripTrailingDecimalZeros: true },
        );
    }

    function getAdornment(): string {
        if (!isSet(inputProps?.adornment)) {
            if (isInputTypePercentage) {
                return '%';
            }
        }
        return inputProps?.adornment;
    }

    function toNumber(userInput: unknown) {
        if (isSetString(userInput)) {
            const userNumberInput = roundFloat(userInput as string, { nrOfDecimals });
            const userNumber = isInputTypePercentage
                ? roundFloat(userNumberInput / 100, { nrOfDecimals: nrOfDecimals + 2 })
                : userNumberInput;
            if (!shouldAllowNumbersBelow0 && userNumber < 0) {
                return value;
            }
            if (!shouldAllowNumbersAbove1 && userNumber > 1) {
                return value;
            }

            return userNumber;
        }

        return undefined;
    }
}

function toStepInputProp(nrOfDecimals: number): string {
    if (nrOfDecimals > 0) {
        const extraZeroes = '0'.repeat(nrOfDecimals - 1);
        return `0.${extraZeroes}1`;
    }

    return undefined;
}

function transformPercentageValue({ value, nrOfDecimals }: Pick<IInputNumberFieldProps, 'value' | 'nrOfDecimals'>) {
    if (isNumber(value)) {
        return roundFloat(value * 100, { nrOfDecimals });
    }
    return value;
}
