import { createSelector } from 'reselect';
import isSet from '@snipsonian/core/cjs/is/isSet';
import mergeObjectPropsDeeply from '@snipsonian/core/cjs/merge/mergeObjectPropsDeeply';
import assert from '@snipsonian/core/cjs/assert';
import {
    IOperationPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/extractUserOperationPermissions';
import {
    doUserPermissionsCoverRequiredPermissions,
    TRequiredPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/doUserPermissionsCoverRequiredPermissions';
import { ITenantSettings } from '@console/bff/models/settings/tenantSettings.models';
import {
    IEnhancedRoute,
    IRoute,
    IRouteBreadcrumbs,
    TParentRouteIndication,
} from 'models/route.models';
import { IPathParams, IRouteLocation } from 'models/routing.models';
import { IState } from 'models/state.models';
import { DEFAULT_ROUTE_APP_SHELL_CONFIG } from 'config/route.config';
import {
    APP_MENU_ITEMS, getAppMenuItemById, getRouteAppMenuItemByRouteKey,
    isMenuItemToContextMenu,
    isMenuItemToRoute,
    isMenuItemWithSubItems,
    TAppMenuStatus,
    TMenuItem,
} from 'config/menu.config';
import {
    getUserPermissions,
    hasUserRequiredPermissions,
} from 'state/auth/selectors';
import { getTenantSettings } from 'state/appConfig/selectors';
import { getStore } from 'state';
import { IVerifyRouteLocationExpected, verifyRouteLocation } from 'utils/routing/verifyRouteLocation';
import { ROUTE_KEY } from 'views/routeKeys';
import { getRoute } from 'views/routes';

export const getAllowedAppMenuItemsMemoized = createSelector(
    getUserPermissions,
    getTenantSettings,
    (userPermissions: IOperationPermissions, tenantSettings: ITenantSettings) =>
        reduceAllowedMenuItemsRecursive({ userPermissions, tenantSettings, menuItems: APP_MENU_ITEMS }),
);

function reduceAllowedMenuItemsRecursive({
    userPermissions,
    tenantSettings,
    menuItems,
}: {
    userPermissions: IOperationPermissions;
    tenantSettings: ITenantSettings;
    menuItems: TMenuItem[];
}): TMenuItem[] {
    return menuItems.reduce(
        (accumulator, menuItem) => {
            if (isMenuItemToRoute(menuItem)) {
                const route = getRoute({ routeKey: menuItem.routeKey });
                if (doUserPermissionsCoverRequiredPermissions({
                    userPermissions,
                    requiredPermissions: route.requiredPermissions,
                }) && isRouteEnabled({ route, tenantSettings })) {
                    accumulator.push(menuItem);
                }
            } else if (isMenuItemWithSubItems(menuItem)) {
                const allowedSubItems = reduceAllowedMenuItemsRecursive({
                    userPermissions,
                    tenantSettings,
                    menuItems: menuItem.subItems,
                });
                if (allowedSubItems.length > 0) {
                    accumulator.push({
                        ...menuItem,
                        subItems: allowedSubItems,
                    });
                }
            } else if (isMenuItemToContextMenu(menuItem)) {
                const allowedContextItems = reduceAllowedMenuItemsRecursive({
                    userPermissions,
                    tenantSettings,
                    menuItems: menuItem.contextItems,
                });
                if (allowedContextItems.length > 0) {
                    accumulator.push({
                        ...menuItem,
                        contextItems: allowedContextItems,
                    });
                }
            }

            return accumulator;
        },
        [],
    );
}

export function isRouteEnabled({
    route,
    tenantSettings,
}: {
    route: IRoute;
    tenantSettings: ITenantSettings;
}): boolean {
    if (isSet(route.isEnabled)) {
        if (!tenantSettings) {
            return false;
        }

        return route.isEnabled({ tenantSettings });
    }

    return true;
}

export const mayUserAccessRoute = (state: IState, routeKey: ROUTE_KEY) => {
    const { requiredPermissions } = getRoute({ routeKey });
    return hasUserRequiredPermissions(state, requiredPermissions);
};

/**
 * Input:
 * - either routeKey : returns true only if user is allowed to navigate to the route
 *                     (based on the 'requiredPermissions' of the route)
 * - or requiredPermissions : return true only if user has these input permissions
 *                            (can be combo of AND / OR relations between the provided permissions)
 * - or both : returns true only if both validations are valid
 */
export const mayUser = ({
    state = getStore().getState(),
    routeKey,
    requiredPermissions,
}: {
    state?: IState;
    routeKey?: ROUTE_KEY;
    requiredPermissions?: TRequiredPermissions;
}) => {
    assert(
        routeKey || requiredPermissions,
        isSet,
        'Provide at least one of "routeKey" or "requiredPermissions" inputs.',
    );

    const mayAccessRoute = isSet(routeKey)
        ? mayUserAccessRoute(state, routeKey)
        : true;

    const hasRequiredPermissions = requiredPermissions
        ? hasUserRequiredPermissions(state, requiredPermissions)
        : true;

    return mayAccessRoute && hasRequiredPermissions;
};

export const getSelectedAppMenuItemId = (state: IState) => state.ui.appMenu.selectedItemId;
export const getSelectedAppMenuItem = (state: IState) => getAppMenuItemById(getSelectedAppMenuItemId(state));
export const getIsUserSelectingInMenu = (state: IState) => state.ui.appMenu.isSelecting;
export const getDoesUserPreferCollapsedMenu = (state: IState) => state.ui.appMenu.prefersCollapsed;
export const getIsAppMenuCollapsed = (state: IState) => {
    if (getIsUserSelectingInMenu(state)) {
        return false;
    }

    return getDoesUserPreferCollapsedMenu(state);
};

export const getSelectedBreadcrumbAppMenuItemIdsMemoized = createSelector(
    getSelectedAppMenuItemId,
    (selectedAppMenuItemId) => {
        const breadcrumbItemIds = [selectedAppMenuItemId];

        let selectedItem = getAppMenuItemById(selectedAppMenuItemId);

        while (selectedItem && selectedItem.parentId) {
            breadcrumbItemIds.push(selectedItem.parentId);

            selectedItem = getAppMenuItemById(selectedItem.parentId);
        }

        return breadcrumbItemIds.reverse();
    },
);

export const getCurrentRoute = (state: IState) => state.ui.currentRoute;

export const getPrevRouteLocation = (state: IState) => {
    const currentRoute = getCurrentRoute(state);
    if (!currentRoute) {
        return null;
    }
    return currentRoute.prevLocation;
};

export const getCurrentRouteLocation = (state: IState) => {
    const currentRoute = getCurrentRoute(state);
    if (!currentRoute) {
        return null;
    }
    return currentRoute.location;
};

export const getDeepestPathParamNameOfCurrentRoute = (state: IState, containsNamePart?: string): string => {
    const currentLocation = getCurrentRouteLocation(state);

    if (!currentLocation) {
        return null;
    }

    const pathParts = currentLocation.path
        .split('/')
        .reverse();

    return pathParts
        .filter((part) => part.startsWith(':'))
        .map((part) => part.replace(':', ''))
        .find((pathParam) => {
            if (isSet(containsNamePart)) {
                return pathParam.toLowerCase().indexOf(containsNamePart.toLowerCase()) > -1;
            }

            // just take the first one (which is the 'deepest' because of the reverse)
            return true;
        });
};

export const isRouteLocation = (state: IState, expected: IVerifyRouteLocationExpected): boolean => verifyRouteLocation({
    currentRouteLocation: getCurrentRouteLocation(state),
    prevRouteLocation: getPrevRouteLocation(state),
    expected,
});

export const getCurrentRouteKey = (state: IState) => {
    const routeLocation = getCurrentRouteLocation(state);

    if (!routeLocation) {
        return null;
    }

    return routeLocation.routeKey;
};

export const getCurrentRouteConfig = (state: IState) => {
    const routeKey = getCurrentRouteKey(state);

    if (!routeKey) {
        return null;
    }

    return getRoute({ routeKey });
};

export const getCurrentRouteParams = (state: IState) => {
    const routeLocation = getCurrentRouteLocation(state);

    if (!routeLocation) {
        return null;
    }

    return routeLocation.params;
};

export const getCurrentRouteParam = <RouteParam = string>(state: IState, paramKey: string): RouteParam => {
    const routeParams = getCurrentRouteParams(state);

    if (!routeParams) {
        return null;
    }

    return routeParams[paramKey] as unknown as RouteParam;
};

export const getCurrentRouteAppShellConfig = (state: IState) => {
    const currentRoute = getCurrentRoute(state);
    if (!currentRoute) {
        return null;
    }
    return currentRoute.appShellConfig;
};

export const getCurrentRouteAppShellConfigEnhanced = (state: IState) => {
    const appShellConfig = getCurrentRouteAppShellConfig(state);
    if (appShellConfig === null || appShellConfig === false) {
        return appShellConfig;
    }

    return mergeObjectPropsDeeply(DEFAULT_ROUTE_APP_SHELL_CONFIG, appShellConfig);
};

export const getCurrentRouteBreadcrumbsMemoized = createSelector(
    getCurrentRouteLocation,
    getPrevRouteLocation,
    (currentRouteLocation, prevRouteLocation): IRouteBreadcrumbs => {
        if (!currentRouteLocation) {
            return null;
        }

        const {
            routesParentLast,
            overrideParentsParams,
        } = getRouteAndItsParentRoutes(currentRouteLocation, prevRouteLocation);

        return {
            routesParentFirst: routesParentLast.slice(0).reverse(),
            routesParentLast,
            pathParams: {
                ...overrideParentsParams,
                ...currentRouteLocation.params,
            },
        };
    },
);

function getRouteAndItsParentRoutes(
    currentRouteLocation: IRouteLocation,
    prevRouteLocation: IRouteLocation,
): { routesParentLast: IRoute<ROUTE_KEY>[]; overrideParentsParams: IPathParams } {
    const route = getRoute({ routeKey: currentRouteLocation.routeKey });

    const routesParentLast = [route];
    let overrideParentsParams: IPathParams = {};

    let parentRouteIndication = getParentRouteIndicationOfRoute(
        route,
        currentRouteLocation,
        prevRouteLocation,
    );

    while (parentRouteIndication.routeKey) {
        const parentRoute = getRoute({ routeKey: parentRouteIndication.routeKey });
        routesParentLast.push(parentRoute);

        overrideParentsParams = {
            ...overrideParentsParams,
            ...parentRouteIndication.params,
        };

        parentRouteIndication = getParentRouteIndicationOfRoute(
            parentRoute,
            currentRouteLocation,
            prevRouteLocation,
        );
    }

    return {
        routesParentLast,
        overrideParentsParams,
    };
}

function getParentRouteIndicationOfRoute(
    route: IEnhancedRoute,
    currentRouteLocation: IRouteLocation,
    prevRouteLocation: IRouteLocation,
): TParentRouteIndication {
    if (route.overrideParentRoute) {
        const potentialParentOverride = route.overrideParentRoute({
            currentRouteLocation,
            prevRouteLocation,
            state: getStore().getState(),
        });
        if (potentialParentOverride) {
            return potentialParentOverride;
        }
    }

    return {
        routeKey: route.parentRouteKey as ROUTE_KEY,
    };
}

export const shouldShowAppShell = (state: IState) => !!getCurrentRouteAppShellConfigEnhanced(state);

export const getActiveAppMenuItemMemoized = createSelector(
    getCurrentRouteBreadcrumbsMemoized,
    (breadcrumbs) => getMenuItemThatMatchesBreadcrumbs(breadcrumbs),
);

export const getActiveBreadcrumbAppMenuItemIdsMemoized = createSelector(
    getActiveAppMenuItemMemoized,
    (activeMenuItem) => {
        if (!activeMenuItem) {
            return [];
        }

        const breadcrumbItemIds = [activeMenuItem.id];

        let tempMenuItem = activeMenuItem;

        while (tempMenuItem && tempMenuItem.parentId) {
            breadcrumbItemIds.push(tempMenuItem.parentId);

            tempMenuItem = getAppMenuItemById(tempMenuItem.parentId);
        }

        return breadcrumbItemIds.reverse();
    },
);

function getMenuItemThatMatchesBreadcrumbs(
    breadcrumbs: IRouteBreadcrumbs,
): TMenuItem {
    let matchingMenuItem = null;

    if (!breadcrumbs) {
        return null;
    }

    /**
     * We traverse the breadcrumbs (starting from the 'lowest' child route)
     * and we search for the first menuItem that points to that route.
     * So either it's a menu-item that points directly to the page/route,
     * or a menu-item that points to a parent page/route.
     */
    breadcrumbs.routesParentLast.some((breadcrumbRoute) => {
        matchingMenuItem = getRouteAppMenuItemByRouteKey(breadcrumbRoute.routeKey);
        if (matchingMenuItem) {
            return true;
        }
        return false;
    });

    return matchingMenuItem;
}

export const getAppMenuStatus = createSelector(
    getIsAppMenuCollapsed,
    getIsUserSelectingInMenu,
    getActiveAppMenuItemMemoized,
    getSelectedAppMenuItem,
    (isCollapsed, isSelecting, activeMenuItem, selectedMenuItem): TAppMenuStatus => {
        if (isCollapsed) {
            return 'collapsed';
        }

        if (!isSelecting) {
            return activeMenuItem
                ? activeMenuItem.level
                : 'top';
        }

        const menuItem = selectedMenuItem || activeMenuItem;

        if (!menuItem) {
            return 'top';
        }

        if (menuItem.level === 'top' && !isMenuItemToContextMenu(menuItem)) {
            return 'top';
        }

        if (menuItem.level === 'context' && isMenuItemWithSubItems(menuItem)) {
            return 'context';
        }

        return 'full';
    },
);
