import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import * as ConsoleBff from '@console/bff/export';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { getRoboMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { getUserConfig } from 'state/appConfig/selectors';
import { api } from 'api';
import {
    enrichReportResponseWithFetchDate,
    isEnrichedReportOutdated,
    TEnrichedReportResponse,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';

// eslint-disable-next-line max-len
export const roboPortfolioPerformanceReportEntity = getEntitiesManager().registerEntity<TEnrichedReportResponse<ConsoleBff.IFetchRoboPortfolioPerformanceReportApiReplyBff>>({
    asyncEntityKey: AsyncEntityKeys.roboPortfolioPerformanceReport,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_ROBO_PORTFOLIO_PERFORMANCE_DATA],
    includeUpdaters: false,
});

export function selectRoboPortfolioPerformanceReport() {
    return roboPortfolioPerformanceReportEntity.select();
}

export function triggerFetchRoboPortfolioPerformanceReport({
    forceRefresh = false,
}: IForceStateRefreshFilter = {}) {
    const {
        period,
    } = getRoboMgmtReportingPageVars();
    const { defaultLocale } = getUserConfig();

    // eslint-disable-next-line max-len
    return roboPortfolioPerformanceReportEntity.async.fetch<ConsoleBff.IFetchRoboPortfolioPerformanceReportApiInputClient, TEnrichedReportResponse<ConsoleBff.IFetchRoboPortfolioPerformanceReportApiReplyBff>, ConsoleBff.IFetchRoboPortfolioPerformanceReportApiReplyBff>({
        api: api.bff.mgmtReporting.fetchRoboPortfolioPerformanceReport,
        apiInputSelector: () => ({
            locale: defaultLocale,
            period,
        }),
        mapApiResponse: ({ response }) => enrichReportResponseWithFetchDate(response),
        refreshMode: () => forceRefresh || isEnrichedReportOutdated(roboPortfolioPerformanceReportEntity.select().data),
        resetDataOnTriggerMode: 'always',
    });
}
