import React from 'react';
import { IPolicySettingsRules } from 'models/ui/policySettings.ui.models';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ContentTitle from 'views/common/layout/ContentTitle';
import TextButton from 'views/common/buttons/TextButton';
import Modal from 'views/common/layout/Modal';
import Text from 'views/common/widget/Text';
import {
    IFetchInstrumentsBasedOnFiltersProps, IIsChangeUniverseModalOpenProps, IPolicySettingsFormValues,
} from './types';
import ChangeInvestmentUniverse from './ChangeInvestmentUniverse';

export function renderPolicyUniverseSectionFormContent(
    context: IExtendedInputFormContext<IPolicySettingsFormValues>, {
        isChangeUniverseModalOpen,
        setIsChangeUniverseModalOpen,
    }: IIsChangeUniverseModalOpenProps,
    triggerFetchInstrumentsBasedOnFilters: (props: IFetchInstrumentsBasedOnFiltersProps) => Promise<unknown>,
    policySettingsRules: IPolicySettingsRules,
) {
    const { fields, labelPrefix, readOnlyForm } = context;

    return (
        <>
            <Modal
                id="change_investment_universe_modal"
                title={{
                    msg: `${labelPrefix}.configuration.settings.sections.universe.modal_title`,
                    placeholders: {
                        policyName: fields.name.value as string,
                    },
                }}
                open={isChangeUniverseModalOpen}
                onClose={closeChangeInvestmentUniverseModal}
                maxWidth="lg"
                emphasizeTitle
            >
                <ChangeInvestmentUniverse
                    context={context}
                    closeModal={closeChangeInvestmentUniverseModal}
                    triggerFetchInstrumentsBasedOnFilters={triggerFetchInstrumentsBasedOnFilters}
                    policySettingsRules={policySettingsRules}
                />
            </Modal>
            <div className="UniverseSectionSpacing">
                <ContentTitle
                    variant="as-field"
                    label={{
                        msg: `${labelPrefix}.fields.investment_universe.selected_number_of_instruments`,
                        placeholders: {
                            nrOfHoldInstruments: ((fields.investmentUniverse.value || []) as string[]).length,
                            nrOfInstrumentsExcludedFromBuying:
                                Object.keys(fields.excludedBuyInstrumentsMap.value || {}).length,
                        },
                    }}
                />
                {!readOnlyForm && (
                    <div className="UniverseChangeSpacing">
                        <TextButton
                            variant="bare"
                            fontWeight="initial"
                            size="M"
                            id="change_investment_universe_button"
                            label={`${labelPrefix}.fields.investment_universe.change`}
                            onClick={openChangeInvestmentUniverseModal}
                        />
                    </div>
                )}
                {!readOnlyForm && fields.investmentUniverse.error && (
                    <div className="__universeFieldError">
                        <Text label={fields.investmentUniverse.error} />
                    </div>
                )}
                {!readOnlyForm && fields.excludedBuyInstrumentsMap.error && (
                    <div className="__universeFieldError">
                        <Text label={fields.excludedBuyInstrumentsMap.error} />
                    </div>
                )}
            </div>
        </>
    );

    function openChangeInvestmentUniverseModal() {
        setIsChangeUniverseModalOpen(true);
    }

    function closeChangeInvestmentUniverseModal() {
        setIsChangeUniverseModalOpen(false);
    }
}
