import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { IUnderlyingRequestApiInput } from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import { SortOrder } from '@console/common/models/sort.models';
import {
    IFetchPortfolioValuationsApiInput,
    TPortfolioValuation,
    TPortfolioValuationsData,
} from '../../models/portfolioMgmt/portfolioValuation.models';
import { TApiEntityId } from '../../models/api.models';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { MAX_PAGE_ITEM_LIMIT } from '../../config/coreApi.config';
import { get } from '../coreApiRequestWrapper';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';

export function fetchPortfolioValuations({
    portfolioIds,
    offset,
    limit = MAX_PAGE_ITEM_LIMIT,
    orderBy,
    underlyingApiRequestConfig,
}: IFetchPortfolioValuationsApiInput) {
    return get<TPortfolioValuationsData>({
        url: CoreApiPath.PORTFOLIO_VALUATIONS,
        queryParams: {
            offset,
            limit,
            ...fetchApiEntityUrlParamBuilder()
                .fieldIn({
                    field: 'portfolio_id',
                    value: portfolioIds,
                })
                .orderBy(orderBy)
                .build(),
        },
        ...underlyingApiRequestConfig,
    });
}

export async function fetchLatestValuationOfSinglePortfolio({
    portfolioId,
    underlyingApiRequestConfig,
}: IUnderlyingRequestApiInput & {
    portfolioId: TApiEntityId;
}): Promise<TPortfolioValuation> {
    const valuations = await fetchPortfolioValuations({
        portfolioIds: [portfolioId],
        limit: 1,
        orderBy: {
            field: 'valuation_date',
            order: SortOrder.Descending,
        },
        underlyingApiRequestConfig,
    });

    if (valuations && isArrayWithValues(valuations.results)) {
        return valuations.results[0];
    }

    return null;
}
