import React from 'react';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import {
    storyManagerDatabasesEntity,
} from 'state/entities/storyTeller/storyManagerDatabases';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import Spinner from 'views/common/loading/Spinner';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import {
    IStoryManagerDatabaseBase,
} from '@console/bff/models/storyteller/storymanager.models';
import DataTable from 'views/common/list/DataTable';
import { IColValues, IDataItem, TDataColumns } from 'models/list.models';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { getStore } from 'state';
import { setStoryManagerPageVars } from 'state/ui/uiPages.actions';
import { makeStyles } from 'views/styling';
import ListPageForFixedItems from 'views/common/list/ListPageForFixedItems';
import { observe } from 'views/observe';
import { formatDate, DATE_FORMAT } from '@console/common/utils/date/formatDate';

const TRANSLATION_PREFIX = 'apps.story_teller.template_databases.list';
const COL_TRANSLATION_PREFIX = `${TRANSLATION_PREFIX}.columns`;

interface IStoryManagerDatabasesCols extends IColValues {
    name: string;
    publishDate: string;
    modifyDate: string;
    status: string;
}

interface IStoryManagerDatabasesExtraItemData {
    version: IStoryManagerDatabaseBase;
}

const COLUMNS: TDataColumns<IStoryManagerDatabasesCols, IStoryManagerDatabasesExtraItemData> = {
    name: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.name`,
        },
        percentWidth: 40,
    },
    publishDate: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.publish_date`,
        },
        percentWidth: 20,
    },
    modifyDate: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.modify_date`,
        },
        percentWidth: 20,
    },
    status: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.status`,
        },
        percentWidth: 20,
    },
};

const useStyles = makeStyles((theme) => ({
    DatabaseList: {},
    LoaderContainer: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    MinorVersionRow: {
        cursor: 'pointer',
        '&:hover': {
            '& > td.version': {
                fill: theme.palette.primary.main,
                color: theme.palette.primary.main,
            },
        },
        '& > td.__tableCell': {
            borderTop: 'none !important',
        },
        '& > td.version:first-child': {
            display: 'flex',
            alignItems: 'center',

            paddingLeft: theme.spacing(5),

            '& > span': {
                paddingTop: 4,
                paddingLeft: theme.spacing(2),
            },
        },
    },
}));

function DatabasesList() {
    const classes = useStyles();

    const versions = storyManagerDatabasesEntity.select().data || [];
    const isLoading = storyManagerDatabasesEntity.select().fetch?.status === AsyncStatus.Busy
        || storyManagerDatabasesEntity.select().create?.status === AsyncStatus.Busy
        || storyManagerDatabasesEntity.select().remove?.status === AsyncStatus.Busy
        || storyManagerDatabasesEntity.select().update?.status === AsyncStatus.Busy;

    return (
        <ListPageForFixedItems
            className={classes.DatabaseList}
            box={{
                title: `${TRANSLATION_PREFIX}.title`,
            }}
            list={{
                items: versions,
                renderData: renderDatabasesList,
            }}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.STORY_MANAGER_UI_VARS}
            uiPageKey={UiPageKey.storyManagerDatabasesList}
        />
    );

    function renderDatabasesList({
        data,
        translator,
        // eslint-disable-next-line max-len
    }: IRenderDataProps<IStoryManagerDatabaseBase[]>) {
        if (isLoading) {
            return (
                <div className={classes.LoaderContainer}>
                    <Spinner />
                </div>
            );
        }
        if (!data) {
            return null;
        }

        const databaseItems: IDataItem<IStoryManagerDatabasesCols, IStoryManagerDatabasesExtraItemData>[] =
            data.map((database) => ({
                id: database.id.toString(),
                colValues: {
                    name: database.name,
                    modifyDate: formatDate({
                        date: database.config_modification_datetime,
                        format: DATE_FORMAT.DAY_MONTH_YEAR_WITH_TIME,
                    }),
                    publishDate: formatDate({
                        date: database.config_publish_datetime,
                        format: DATE_FORMAT.DAY_MONTH_YEAR_WITH_TIME,
                    }),
                    status: getStatusTranslation(database),
                },
                extra: {
                    version: database,
                },
            }));

        return (
            <DataTable
                cols={COLUMNS}
                items={databaseItems}
                onItemRowClicked={(item) => selectDatabase(item.extra?.version?.id)}
            />
        );

        function getStatusTranslation(database: IStoryManagerDatabaseBase) {
            if (database.config_modification_datetime === database.config_publish_datetime) {
                return translator({ msg: `${TRANSLATION_PREFIX}.status.no_changes` });
            }
            return translator({ msg: `${TRANSLATION_PREFIX}.status.has_changes` });
        }

        function selectDatabase(id: number) {
            const { dispatch } = getStore();
            dispatch(setStoryManagerPageVars({
                collapsedOutputKeyIds: [],
            }, [StateChangeNotification.UI_PAGE_STORY_MANAGER_SIDE_NAV_OUTPUT_KEYS]));
            redirectTo({
                routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEYS,
                params: {
                    databaseId: id,
                },
            });
        }
    }
}

export default observe([
    StateChangeNotification.STORY_MANAGER_DATABASES,
], DatabasesList);
