import { Dispatch } from '@snipsonian/observable-state/cjs/actionableStore/types';
import { ITranslatorPlaceholders } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { IBaseApiErrorClientSide } from '@console/api-base/server/error/apiBaseError.models';
import { getApiErrorCode } from '@console/core-api/utils/error/clientSideConsoleApiErrorUtils';
import { setApiWarningFlasher } from 'api/flashMessageNoCircularDependencies';
import { getStore } from 'state';
import {
    triggerFlashError,
    triggerFlashInfo,
    triggerFlashSuccess,
    triggerFlashWarning,
} from 'state/ui/flashMessages.actions';

interface IDispatcherProps {
    dispatch?: Dispatch;
}

interface IOnErrorProps<ApiError extends IBaseApiErrorClientSide = IBaseApiErrorClientSide> extends IDispatcherProps {
    error: ApiError;
}

/* eslint-disable max-len */
export const infoFlashDispatcher = (translationKey: string) => ({ dispatch = getStore().dispatch }: IDispatcherProps = {}) => {
    dispatch(triggerFlashInfo({ translationKey }));
};

export const warningFlashDispatcher = (translationKey: string) => ({ dispatch = getStore().dispatch }: IDispatcherProps = {}) => {
    dispatch(triggerFlashWarning({ translationKey }));
};
setApiWarningFlasher(warningFlashDispatcher);

export const successFlashDispatcher = (translationKey: string) => ({ dispatch = getStore().dispatch }: IDispatcherProps = {}) => {
    dispatch(triggerFlashSuccess({ translationKey }));
};

export const errorMessageFlashDispatcher = (translationKey: string) => ({ dispatch = getStore().dispatch }: IDispatcherProps = {}) => {
    dispatch(triggerFlashError({ translationKey }));
};
/**
 * Only if the (api) error is a known/expected one, a related error message will be shown,
 * otherwise the default error message.
 */
export const errorFlashDispatcher = (
    defaultTranslationKey: string,
    translationPlaceholders?: ITranslatorPlaceholders,
) => ({ error, dispatch = getStore().dispatch }: IOnErrorProps) => {
    const specificErrorCode = getApiErrorCode(error as unknown as IBaseApiErrorClientSide);

    const translationKey = specificErrorCode
        ? `error.code.${specificErrorCode.toLowerCase()}`
        : defaultTranslationKey;

    dispatch(triggerFlashError({ translationKey, translationPlaceholders }));
};
/* eslint-enable max-len */
