import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import * as ConsoleCommon from '@console/common/export';
import {
    DataPointFrequency, IIdToNameMap,
    TFetchReportCommonApiBody,
    TFetchReportWithOptionalReferencePeriodApiBody,
} from '../../../models/reports/commonReport.models';

const ALL_DATAPOINT_FREQUENCIES = Object.values(DataPointFrequency);

export const dataPointFrequencySchema = decorateSchema(
    ConsoleCommon.schema.string()
        .oneOf(ALL_DATAPOINT_FREQUENCIES) as ConsoleCommon.schema.StringSchema<DataPointFrequency>,
    {
        description: 'The desired interval period between data points for the resulting time series.'
            + ' Either D(ay), W(eek), M(onth), Q(uarter) or Y(ear)',
        enumValues: ALL_DATAPOINT_FREQUENCIES,
    },
);

export const acceptLanguageSchema = ConsoleCommon.schema.string()
    .oneOf(Object.values(ConsoleCommon.Locale)) as ConsoleCommon.schema.StringSchema<ConsoleCommon.Locale>;

export const acceptLanguageHeadersSchema = ConsoleCommon.schema.object({
    'accept-language': acceptLanguageSchema.required(),
});

export const fetchReportCommonApiBodySchema = ConsoleCommon.schema.object({
    frequency: dataPointFrequencySchema.required(),
    period: decorateSchema(
        ConsoleCommon.schema.datePeriodSchema,
        // eslint-disable-next-line max-len
        { description: 'The graph period. This is the entire period for which datapoints are needed. Dates should be in the YYYY-MM-DD format.' },
    ).required(),
    strictPeriod: decorateSchema(
        ConsoleCommon.schema.datePeriodSchema,
        // eslint-disable-next-line max-len
        { description: 'Optional. The period that the user selected. Only to be used if different from the graph period.  Dates should be in the YYYY-MM-DD format.' },
    ),
}) as ConsoleCommon.schema.ObjectSchema<TFetchReportCommonApiBody>;

export const fetchReportWithOptionalReferencePeriodApiBodySchema = ConsoleCommon.schema.object({
    referencePeriod: decorateSchema(
        ConsoleCommon.schema.datePeriodSchema,
        // eslint-disable-next-line max-len
        { description: 'Optional period to also receive a data point for another period (to compare with the "main" period).' },
    ),
    includeAverageYtd: decorateSchema(
        ConsoleCommon.schema.boolean(),
        { description: 'Optional flag to include the average for the year-to-date period.' },
    ),
// eslint-disable-next-line max-len
}).concat(fetchReportCommonApiBodySchema) as ConsoleCommon.schema.ObjectSchema<TFetchReportWithOptionalReferencePeriodApiBody>;

export const reportInfoSchema = ConsoleCommon.schema.object({
    firstSnapshotDatetime: ConsoleCommon.schema.datetimeStringSchema.required(),
    lastSnapshotDatetime: ConsoleCommon.schema.datetimeStringSchema.required(),
    timezone: ConsoleCommon.schema.string().required(),
});

export const baseTimeSeriesItemSchema = ConsoleCommon.schema.object({
    datetime: ConsoleCommon.schema.datetimeStringSchema.required(),
});

export const idToNameMapSchema = decorateSchema(
    ConsoleCommon.schema.getDynamicObjectSchemaThatAppliesSameSchemaForEachField({
        isRequired: true,
        schemaToApply: ConsoleCommon.schema.string(),
    }) as ConsoleCommon.schema.ObjectSchema<IIdToNameMap>,
    { description: "Object that maps each id (used in the time series) to it's corresponding name" },
);
