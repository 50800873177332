import React from 'react';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { IColValues, TDataColumns, IDataItem, IToDynamicCellClassProps } from 'models/list.models';
import ListPageForApiEntity, { IListPageForApiEntityProps } from 'views/common/list/ListPageForApiEntity';
import {
    TInstrumentsData,
    TInstrument,
    IFetchInstrumentsApiInput,
    IAdvancedInstrumentFilters,
} from '@console/core-api/models/portfolioMgmt/instruments.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { instrumentsEntity, triggerFetchInstruments } from 'state/entities/portfolioMgmt/instruments';
import { UiPageKey } from 'models/state/ui.models';
import DataTable from 'views/common/list/DataTable';
import { IRenderAdvancedFiltersProps } from 'views/common/list/DataSearch';
import InputGroup from 'views/common/inputs/base/InputGroup';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputTextField from 'views/common/inputs/base/InputTextField';
import EntityFiche from 'views/common/genericApiEntity/EntityFiche';

const DEFAULT_COL_TRANSLATION_PREFIX = 'portfolio_mgmt.instruments.list.columns';

interface IGenericInstrumentsListProps<
    ColValues extends IColValues,
    ExtraInstrumentItemData extends TObjectWithProps = unknown,
>
    // eslint-disable-next-line max-len
    extends Pick<IListPageForApiEntityProps<IFetchInstrumentsApiInput, IAdvancedInstrumentFilters, TInstrumentsData>, 'className' | 'create' | 'actions' | 'box' | 'listActions'>,
    Pick<IFetchInstrumentsApiInput, 'currency' | 'universe_type'> {
    overrideEntity?: Partial<IOverrideEntity>;
    overrideUiVars?: IOverrideUiVars;
    overrideCols?: IOverrideCols<ColValues, ExtraInstrumentItemData>;
    styleCell?: (props: IToDynamicCellClassProps<ColValues, ExtraInstrumentItemData>) => string[];
    shouldDisplayRow?: (item: IDataItem<ColValues, ExtraInstrumentItemData>) => boolean;
    onInstrumentRowClicked?: (instrument: IDataItem<ColValues, ExtraInstrumentItemData>) => void;
    isInstrumentRowClickable?: (instrument: IDataItem<ColValues, ExtraInstrumentItemData>) => boolean;
}

// eslint-disable-next-line max-len
interface IOverrideEntity extends Pick<IListPageForApiEntityProps<IFetchInstrumentsApiInput, IAdvancedInstrumentFilters, TInstrumentsData>, 'asyncListEntity' | 'asyncListEntityFetchTrigger' | 'setStateOnPageNrChange'> {
    dataNotification: StateChangeNotification; // default INSTRUMENTS_DATA
}

// eslint-disable-next-line max-len
interface IOverrideUiVars extends Pick<IListPageForApiEntityProps<IFetchInstrumentsApiInput, IAdvancedInstrumentFilters, TInstrumentsData>, 'uiPageKey'> {
    uiVarsNotification: StateChangeNotification; // default INSTRUMENTS_UI_VARS
}

interface IOverrideCols<ColValues extends IColValues, ExtraInstrumentItemData extends TObjectWithProps> {
    cols: TDataColumns<ColValues, ExtraInstrumentItemData>;
    toInstrumentListItem: TToInstrumentListItem<ColValues, ExtraInstrumentItemData>;
}

type TToInstrumentListItem<ColValues extends IColValues, ExtraInstrumentItemData> =
    (props: IToInstrumentListItemProps) => IDataItem<ColValues, ExtraInstrumentItemData>;

interface IToInstrumentListItemProps {
    instrument: TInstrument;
    defaultInstrumentCols: IDefaultInstrumentCols;
}

export interface IDefaultInstrumentCols extends IColValues {
    name: string;
    price: number;
    type: string;
}

function toDefaultInstrumentListItem({
    instrument,
    defaultInstrumentCols,
}: IToInstrumentListItemProps): IDataItem<IDefaultInstrumentCols> {
    return {
        id: instrument.id,
        colValues: defaultInstrumentCols,
    };
}

interface IDesiredCol {
    colName: keyof IDefaultInstrumentCols;
    percentWidth: number;
}

const INITIAL_ADVANCED_INSTRUMENTS_FILTERS: IAdvancedInstrumentFilters = {
    name: '',
    partialInstrumentId: '',
};

const DEFAULT_DESIRED_INSTRUMENT_COLS: IDesiredCol[] = [
    { colName: 'name', percentWidth: 65 },
    { colName: 'price', percentWidth: 20 },
    { colName: 'type', percentWidth: 15 },
];

export function getDefaultInstrumentCols({
    desiredCols = DEFAULT_DESIRED_INSTRUMENT_COLS,
}: {
    desiredCols?: IDesiredCol[];
} = {}): TDataColumns<Partial<IDefaultInstrumentCols>> {
    return desiredCols.reduce(
        (accumulator, { colName, percentWidth }) => {
            if (percentWidth > 0) {
                switch (colName) {
                    case 'name': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_COL_TRANSLATION_PREFIX}.name`,
                            },
                            data: {
                                render: ({ item }) => (
                                    <EntityFiche
                                        name={item.colValues.name}
                                        id={item.id}
                                        variant="instrument"
                                    />
                                ),
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'type': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_COL_TRANSLATION_PREFIX}.type`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    case 'price': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_COL_TRANSLATION_PREFIX}.price`,
                            },
                            percentWidth,
                        };
                        break;
                    }
                    default: throw new Error(`Unexpected column name '${colName}'`);
                }
            }
            return accumulator;
        },
        {} as TDataColumns<Partial<IDefaultInstrumentCols>>,
    );
}

export default function GenericInstrumentsList<
    ColValues extends IColValues = IDefaultInstrumentCols,
    ExtraInstrumentItemData extends TObjectWithProps = unknown,
>({
    currency,
    universe_type,
    overrideEntity = {},
    overrideUiVars,
    overrideCols,
    styleCell,
    shouldDisplayRow,
    onInstrumentRowClicked,
    isInstrumentRowClickable,
    ...otherListPageProps
}: IGenericInstrumentsListProps<ColValues, ExtraInstrumentItemData>) {
    const entityConfig: IOverrideEntity = {
        dataNotification: StateChangeNotification.INSTRUMENTS_DATA,
        asyncListEntity: instrumentsEntity,
        asyncListEntityFetchTrigger: (apiInput) => triggerFetchInstruments({
            ...apiInput,
            currency,
            universe_type,
        }),
        setStateOnPageNrChange: (pageNr) => ({
            toState: (draftState) => {
                // eslint-disable-next-line no-param-reassign
                draftState.entities.instruments.data.pageNr = pageNr;
            },
            notificationsToTrigger: [StateChangeNotification.INSTRUMENTS_DATA],
        }),
        ...overrideEntity,
    };
    const uiVarsConfig: IOverrideUiVars = overrideUiVars || {
        uiVarsNotification: StateChangeNotification.INSTRUMENTS_UI_VARS,
        uiPageKey: UiPageKey.instrumentsList,
    };
    const colsConfig: IOverrideCols<ColValues, ExtraInstrumentItemData> = overrideCols || {
        cols: getDefaultInstrumentCols() as TDataColumns<ColValues>,
        toInstrumentListItem:
            toDefaultInstrumentListItem as unknown as TToInstrumentListItem<ColValues, ExtraInstrumentItemData>,
    };

    return (
        <ListPageForApiEntity
            notifications={[entityConfig.dataNotification, uiVarsConfig.uiVarsNotification]}

            asyncListEntity={entityConfig.asyncListEntity}
            asyncListEntityFetchTrigger={entityConfig.asyncListEntityFetchTrigger}
            setStateOnPageNrChange={entityConfig.setStateOnPageNrChange}

            uiPageKey={uiVarsConfig.uiPageKey}
            notificationToTriggerOnUiVarChanges={uiVarsConfig.uiVarsNotification}

            list={{
                renderData: renderInstrumentsList,
            }}

            search={{
                simple: {
                    tipTranslationKey: 'portfolio_mgmt.instruments.list.filter.simple.tip',
                    mapSimpleSearchInputToFetchFilter,
                },
                advanced: {
                    initialValues: INITIAL_ADVANCED_INSTRUMENTS_FILTERS,
                    renderFilters: renderAdvancedInstrumentFilters,
                },
            }}

            {...otherListPageProps}
        />
    );

    function renderAdvancedInstrumentFilters({
        filterValues,
        onChangeFilterValue,
    }: IRenderAdvancedFiltersProps<IAdvancedInstrumentFilters>) {
        return (
            <InputGroup>
                <InputWrapper
                    label="portfolio_mgmt.instruments.list.filter.advanced.isin.label"
                >
                    <InputTextField
                        value={filterValues.partialInstrumentId}
                        onChange={({ value }) => changeFilterValue('partialInstrumentId', value)}
                        placeholder="portfolio_mgmt.instruments.list.filter.advanced.isin.tooltip"
                    />
                </InputWrapper>
                <InputWrapper
                    label="portfolio_mgmt.instruments.list.filter.advanced.name.label"
                >
                    <InputTextField
                        value={filterValues.name}
                        onChange={({ value }) => changeFilterValue('name', value)}
                        placeholder="portfolio_mgmt.instruments.list.filter.advanced.name.tooltip"
                    />
                </InputWrapper>
            </InputGroup>
        );

        function changeFilterValue(fieldName: string, value: string | boolean) {
            return onChangeFilterValue({
                [fieldName]: value,
            });
        }
    }

    function renderInstrumentsList({ data }: { data: TInstrument[] }) {
        if (!data) {
            return null;
        }

        const instrumentItems: IDataItem<ColValues, ExtraInstrumentItemData>[] =
            data.map((instrument) => colsConfig.toInstrumentListItem({
                instrument,
                defaultInstrumentCols: {
                    name: instrument.name,
                    price: instrument.price,
                    type: instrument.type,
                },
            }));

        return (
            <DataTable
                cols={colsConfig.cols}
                items={instrumentItems}
                toDynamicCellClass={styleCell}
                shouldDisplayRow={shouldDisplayRow}
                onItemRowClicked={onInstrumentRowClicked}
                isItemClickable={isInstrumentRowClickable}
            />
        );
    }

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            currency,
            universe_type,
            partialInstrumentId: isSetString(simpleInput) ? simpleInput : null,
        };
    }
}
