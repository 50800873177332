import React from 'react';
import clsx from 'clsx';
import { TTrendDirection } from 'utils/entities/mgmtReporting/mgmtReporting.utils';

interface ITrendIndicatorProps {
    className?: string;
    direction: TTrendDirection;
    size?: TTrendSize; /* default L(arge) */
}

export type TTrendSize = 'L' | 'S';

const TREND_COLORS = {
    DANGER: '#F16647',
    SUCCESS: '#73BA18',
};

export function TrendIndicator({
    className,
    direction,
    size = 'L',
}: ITrendIndicatorProps) {
    if (direction === 'remainedSame') {
        return null;
    }

    const width = size === 'L' ? 24 : 16;
    const color = direction === 'up' ? TREND_COLORS.SUCCESS : TREND_COLORS.DANGER;

    return (
        <svg
            width={width}
            height={width}
            className={clsx('TrendIndicator', className)}
            transform={direction === 'down' ? 'rotate(180)' : null}
        >
            <polygon
                points={determineTrianglePath()}
                fill={color}
                stroke={color}
                strokeLinejoin="round"
                strokeWidth={size === 'L' ? 3 : 2}
            />
        </svg>
    );

    /**
     * We draw as if the triangle is for an svg of width L(arge) = 24px
     * --> the triangle itself is then 15px wide and 13px high.
     */
    function determineTrianglePath(): string {
        const pathParts: string[] = [];

        if (size === 'L') {
            pathParts.push('4.5,18.5'); // lower-left
            pathParts.push('19.5,18.5'); // lower-right
            pathParts.push('12,5.5'); // middle-top
        } else {
            pathParts.push('3,12'); // lower-left
            pathParts.push('13,12'); // lower-right
            pathParts.push('8,4'); // middle-top
        }

        return pathParts.join(' ');
    }
}
