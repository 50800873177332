import React, { useState } from 'react';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { getSecondLastItemOfArray } from '@console/common/snipsonian/core/cjs/array/filtering/getSecondLastItemOfArray';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import * as ConsoleBff from '@console/bff/export';
import { getDefaultTenantCurrency } from 'state/appConfig/selectors';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import { reportInfoEntity } from 'state/entities/mgmtReporting/reportInfo';
import { TLabel } from 'models/general.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    calculateTotalAum, determineTrendDirection,
    mapAumDistributionPartsToDonutChartData,
    mapAumTimeSeriesToLineChartData,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';
import { formatPercentage } from 'utils/number/percentageUtils';
import { makeStyles, mixins } from 'views/styling';
import ButtonGroup, { IButtonGroupItem } from 'views/common/buttons/ButtonGroup';
import Text from 'views/common/widget/Text';
import { IEntityWrapperProps, IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { ILineChartData } from 'views/common/charts/types';
import MgmtReportingDonutChart, {
    IMgmtReportingDonutChartProps, TMgmtReportingDonutItem,
} from '../graphs/MgmtReportingDonutChart';
import MgmtReportingStackedLineChart from '../graphs/MgmtReportingStackedLineChart';
import MgmtReportingBox, { TTopCustomConfig } from './MgmtReportingBox';
import { MgmtReportingNoData } from './MgmtReportingNoData';

interface IMgmtReportingAumBoxProps<AggregateAumBy = string>
    extends Pick<IMgmtReportingDonutChartProps, 'chartName'> {
    description: TLabel;
    aggregation: {
        getCurrentAggregateBy: () => AggregateAumBy;
        labelPrefix: string;
        multiple?: {
            values: AggregateAumBy[];
            notifications: StateChangeNotification[];
            onChange: (newValue: AggregateAumBy) => void;
        };
    };
    reportData: {
        notifications: StateChangeNotification[];
        /* the Self Aum Report is the more generic one, so the Robo one will have to be converted to this */
    } & Pick<IEntityWrapperProps<ConsoleBff.TFetchSelfAumReportApiReply>, 'asyncEntitySelector'>;
}

const LABEL_PREFIX = 'mgmt_reporting.aum';

const useStyles = makeStyles((theme) => ({
    MgmtReportingAumBox: {
        ...mixins.widthMax(),

        '& .__aumCategoryChoice': {
            ...mixins.flexRowCenterRight(),
            ...mixins.typoBold(),

            '& .__aumCategoryButtons': {
                paddingLeft: theme.spacing(2),
            },
        },

        '& .__charts': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            ...mixins.widthMax(),

            [theme.breakpoints.down('lg')]: {
                ...mixins.flexCol({ alignMain: 'space-between', alignCross: 'flex-start' }),
            },
        },
    },
}));

export default function MgmtReportingAumBox<AggregateAumBy>({
    chartName,
    description,
    aggregation,
    reportData,
}: IMgmtReportingAumBoxProps<AggregateAumBy>) {
    const classes = useStyles();
    const defaultTenantCurrency = getDefaultTenantCurrency();
    const translator = getTranslator(getStore().getState());
    const { timezone } = reportInfoEntity.select().data;
    let clearChartSelection: TClearChartSelection = null;

    return (
        <MgmtReportingBox<ConsoleBff.TFetchSelfAumReportApiReply>
            className={classes.MgmtReportingAumBox}
            top={{
                title: `${LABEL_PREFIX}.title`,
                description,
                custom: getOptionalAggregateByChoice(),
                tooltip: {
                    name: 'tooltip_portfolio-aum',
                    simpleContent: {
                        info: `${LABEL_PREFIX}.box_tooltip`,
                    },
                },
            }}
            mainMetric={{
                notifications: reportData.notifications,
                asyncEntitySelector: reportData.asyncEntitySelector,
                isDataSufficientValidator: ({ data, isAnyAsyncOperationBusy }) => {
                    if (isAnyAsyncOperationBusy) {
                        return false;
                    }

                    return isArrayWithValues(data.report.time_series);
                },
                calculator: ({ data }) => {
                    const latestAumTotal = calculateLatestAumTotal({
                        data,
                    });
                    const prevAumTotal = calculateTotalAum({
                        aumDistributionParts: getSecondLastItemOfArray(data.report.time_series)?.items || [],
                    });

                    return {
                        value: {
                            text: formatAmount(latestAumTotal, {
                                currency: defaultTenantCurrency,
                                useMagnitudeFlags: true,
                            }),
                            shouldTranslate: false,
                        },
                        trendDirection: determineTrendDirection(
                            prevAumTotal,
                            latestAumTotal,
                        ),
                        reportingPeriodInfo: {
                            msg: `${LABEL_PREFIX}.date_included`,
                            placeholders: {
                                date: formatDate({
                                    date: getLastItemOfArray(data.report.time_series)?.datetime,
                                    format: DATE_FORMAT.DAY_MONTH_YEAR_DOT,
                                    timezone,
                                }),
                            },
                            raw: true,
                        },
                    };
                },
            }}
            sections={[{
                title: () => ({
                    msg: `${LABEL_PREFIX}.sections.distribution`,
                    placeholders: {
                        aggregateBy: translateAumAggregateBy(aggregation.getCurrentAggregateBy()),
                    },
                }),
                notifications: reportData.notifications,
                asyncEntitySelector: reportData.asyncEntitySelector,
                renderContent: renderAumDistributionSectionContent,
            }]}
        />
    );

    function getOptionalAggregateByChoice(): TTopCustomConfig {
        if (!aggregation.multiple || aggregation.multiple.values.length === 1) {
            return null;
        }

        return {
            notifications: aggregation.multiple.notifications,
            renderContent: renderAumAggregateByChoice,
        };
    }

    function renderAumAggregateByChoice() {
        return (
            <div className="__aumCategoryChoice">
                <Text label={`${LABEL_PREFIX}.view_by_category`} />
                <ButtonGroup
                    id="change-aum-distribution-category"
                    className="__aumCategoryButtons"
                    buttons={getAumAggregateByButtonGroupItems()}
                    size="S"
                    fontSize="L"
                    activeKey={aggregation.getCurrentAggregateBy() as unknown as string}
                />
            </div>
        );
    }

    function getAumAggregateByButtonGroupItems(): IButtonGroupItem[] {
        return aggregation.multiple.values.map((aggregateByValue) => ({
            key: aggregateByValue as unknown as string,
            label: getAumAggregateByLabel(aggregateByValue),
            onClick: () => {
                aggregation.multiple.onChange(aggregateByValue);

                if (clearChartSelection) {
                    clearChartSelection();
                }
            },
        }));
    }

    function translateAumAggregateBy(aggregateBy: AggregateAumBy): string {
        return translator(getAumAggregateByLabel(aggregateBy));
    }

    function getAumAggregateByLabel(aggregateBy: AggregateAumBy): string {
        return `${aggregation.labelPrefix}.${(aggregateBy as unknown as string).toLowerCase()}`;
    }

    function calculateLatestAumTotal({ data }: { data: ConsoleBff.TFetchSelfAumReportApiReply }): number {
        return calculateTotalAum({
            aumDistributionParts: getLastItemOfArray(data.report.time_series)?.items || [],
        });
    }

    function renderAumDistributionSectionContent({ data }: IRenderDataProps<ConsoleBff.TFetchSelfAumReportApiReply>) {
        if (!isArrayWithValues(data.report.time_series)) {
            return <MgmtReportingNoData />;
        }

        const donutChartData = mapAumDistributionPartsToDonutChartData({
            aumDistributionParts: getLastItemOfArray(data.report.time_series).items,
        });
        const lineChartData = mapAumTimeSeriesToLineChartData({
            aumTimeSeriesItems: data.report.time_series,
        });

        return (
            <AumChartsThatShareSelection
                baseChartName={chartName}
                donutChartData={donutChartData}
                lineChartData={lineChartData}
                aumTotal={calculateLatestAumTotal({ data })}
                currency={defaultTenantCurrency}
                timezone={timezone}
                currentAggregateBy={aggregation.getCurrentAggregateBy()}
                getClearChartSelectionSetter={getClearChartSelectionSetter}
            />
        );
    }

    /**
     * "hack" to get a function to be able to clear the chart selections
     * even though that those selections are in a child component.
     */
    function getClearChartSelectionSetter(callBackValue: () => void) {
        clearChartSelection = callBackValue;
    }
}

type TClearChartSelection = () => void;

/**
 * Separate child component so that the 'selectedCategoryColor' & 'selectedDate' are not on a higher level.
 * Otherwise, the transitions started again when doing these selections.
 */
function AumChartsThatShareSelection<AggregateAumBy = string>({
    baseChartName,
    donutChartData,
    lineChartData,
    aumTotal,
    currency,
    timezone,
    currentAggregateBy,
    getClearChartSelectionSetter,
}: {
    baseChartName: string;
    donutChartData: TMgmtReportingDonutItem[];
    lineChartData: ILineChartData<Date, number>[];
    aumTotal: number;
    currency: SupportedCurrency;
    timezone?: string;
    currentAggregateBy: AggregateAumBy;
    getClearChartSelectionSetter: (callBackValue: TClearChartSelection) => void;
}) {
    const [selectedCategoryColor, setSelectedCategoryColor] = useState<string>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(null);

    getClearChartSelectionSetter(() => {
        setSelectedCategoryColor(null);
        setSelectedDate(null);
    });

    return (
        <div className="__charts">
            <MgmtReportingDonutChart
                chartName={`${baseChartName}_${currentAggregateBy}`}
                data={donutChartData}
                mainText={{
                    title: `${LABEL_PREFIX}.title`,
                    amount: aumTotal,
                    currency,
                }}
                selectedSliceColor={selectedCategoryColor}
                onSelectSlice={setSelectedCategoryColor}
                chartLegend={{
                    variant: 'vertical',
                    valueFormatter: ({ value }) => formatPercentage(value * 100),
                }}
            />

            <MgmtReportingStackedLineChart
                chartName={baseChartName}
                currency={currency}
                timezone={timezone}
                data={lineChartData}
                selectedAreaColor={selectedCategoryColor}
                onSelectArea={setSelectedCategoryColor}
                selectedDate={selectedDate}
                onSelectDate={setSelectedDate}
            />
        </div>
    );
}
