import React, { useState } from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { SortOrder } from '@console/common/models/sort.models';
import { roundFloat } from '@console/common/utils/float/roundFloat';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import {
    IEnhancedPortfolioHoldings,
} from '@console/bff/models/portfolios/portfolioHoldings.models';
import {
    calculateEnhancedPortfolioHoldingsPercentages,
} from '@console/bff/utils/portfolios/enhancedPortfolioHoldingsUtils';
import {
    IColValues,
    IDataItem,
    IToDynamicCellClassProps,
    TDataColumns,
    IRenderPreRowProps,
} from 'models/list.models';
import { INITIAL_INSTRUMENT_SHARE_NR_OF_UNITS } from 'config/portfolioMgmt/portfolioHoldings.config';
import { formatPercentage } from 'utils/number/percentageUtils';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import IconButton from 'views/common/buttons/IconButton';
import { TrashIcon, RenderIcon, InfoIcon, WarningIcon } from 'views/common/icons';
import InputNumberField from 'views/common/inputs/base/InputNumberField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import DataTable from 'views/common/list/DataTable';
import AmountDisplay from 'views/common/widget/AmountDisplay';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import TextButton from 'views/common/buttons/TextButton';
import EntityFiche from 'views/common/genericApiEntity/EntityFiche';
import { IPortfolioHoldingsFormValues } from '../portfolioFormContents/types';

const LABEL_PREFIX = 'portfolio_mgmt.portfolios.detail.holdings';
const COL_LABEL_PREFIX = `${LABEL_PREFIX}.columns`;
const DEFAULT_AMOUNT_DECIMAL_POINTS = 2;
const DEFAULT_UNITS_DECIMAL_POINTS = 4;
const DATA_ITEM_ID = {
    CASH: 'CASH',
    INSTRUMENTS: 'INSTRUMENTS',
};
const INSTRUMENTS_SORTING_OVERRIDE =
    (itemA: IDataItem<IHoldingsCols, IHoldingsExtra>, itemB: IDataItem<IHoldingsCols, IHoldingsExtra>) => {
        if (!itemA.extra.missingData && itemB.extra.missingData) {
            return -1;
        }
        return 0;
    };

interface IPublicProps
    extends Pick<IExtendedInputFormContext<IPortfolioHoldingsFormValues>, 'fields' | 'setFieldValue'> {
    initialHoldings: IEnhancedPortfolioHoldings;
    currentHoldings: IEnhancedPortfolioHoldings;
    translator: TTranslator;
    isEditActive: boolean;
}

enum HoldingsAction {
    Delete = 'delete',
    Edit = 'edit',
    Restore = 'restore',
    RecalculateCash = 'recalculateCash',
}

interface IOnEditHoldingsProps {
    action: HoldingsAction;
    isin?: string;
    amount?: number;
    units?: number;
}

interface IChangeHoldingsProps {
    isEditActive: boolean;
    onEditHoldings: (props: IOnEditHoldingsProps) => void;
}

interface IHoldingsCols extends IColValues {
    name: string;
    units: number;
    price: number;
    amount: number;
    percentage: string;
}

interface IHoldingsExtra {
    pricePer?: string;
    deleted?: boolean;
    edited?: boolean;
    added?: boolean;
    percentageSortValue?: number;
    priceSortValue?: number;
    missingData?: boolean;
    missingPrice?: boolean;
}

function renderEditHoldingsIconButton(
    id: string,
    icon: React.ReactNode,
    onClick: () => void,
) {
    return (
        <IconButton
            id={id}
            icon={icon}
            size="XS"
            svgSize={25}
            variant="bare"
            color="text"
            onClick={onClick}
        />
    );
}

function renderEditHoldingsInput(
    item: IDataItem<IHoldingsCols, IHoldingsExtra>,
    cellValue: number,
    onChange: (value: number) => void,
) {
    return (
        <InputWrapper className="AdjustedHeight">
            <InputNumberField
                value={cellValue}
                shouldAllowNumbersBelow0={false}
                onChange={({ value }) => {
                    if (value !== cellValue) {
                        return onChange(value);
                    }
                    return null;
                }}
                disabled={item.extra.missingData || item.extra.missingPrice}
                crudStylingType={
                    (item.extra.added) ? 'added' : (item.extra.edited) ? 'edited' : null
                }
            />
        </InputWrapper>
    );
}

function getCols({
    currency,
    isEditActive,
    onEditHoldings,
}: { currency: string } & IChangeHoldingsProps) {
    const cols: TDataColumns<IHoldingsCols, IHoldingsExtra> = {
        name: {
            label: {
                msg: `${COL_LABEL_PREFIX}.name`,
            },
            data: {
                render: ({ item }) => {
                    if (item.id === DATA_ITEM_ID.CASH) {
                        return (
                            <div className={clsx(UtilityClass.table.cellBold, 'CashNameDisplay')}>
                                {item.colValues.name}
                            </div>
                        );
                    }

                    return (
                        <InstrumentNameCell dataItem={item} />
                    );
                },
            },
            percentWidth: 50,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: {
                    alwaysOnTopFilter,
                },
            },
        },
        units: {
            label: {
                msg: `${COL_LABEL_PREFIX}.units`,
            },
            data: {
                type: 'number',
                render: ({ item, cellValue }) => {
                    if (item.id !== DATA_ITEM_ID.CASH && item.id !== DATA_ITEM_ID.INSTRUMENTS) {
                        if (isEditActive) {
                            return renderEditHoldingsInput(item, cellValue as number, (value) => onEditHoldings({
                                action: HoldingsAction.Edit,
                                isin: item.id,
                                units: value,
                            }));
                        }
                        return (
                            <AmountDisplay
                                value={cellValue as number}
                                nrOfDecimals={DEFAULT_UNITS_DECIMAL_POINTS}
                                notSetValue="-"
                            />
                        );
                    }
                    return null;
                },
            },
            align: 'right',
            percentWidth: 15,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: {
                    alwaysOnTopFilter,
                },
            },
        },
        price: {
            label: {
                msg: `${COL_LABEL_PREFIX}.price`,
            },
            align: 'right',
            percentWidth: 5,
            data: {
                type: 'number',
                render: ({ item, cellValue }) => {
                    if (item.id !== DATA_ITEM_ID.CASH && item.id !== DATA_ITEM_ID.INSTRUMENTS) {
                        return (
                            <AmountDisplay
                                value={cellValue as number}
                                nrOfDecimals={DEFAULT_AMOUNT_DECIMAL_POINTS}
                                notSetValue="-"
                            />
                        );
                    }
                    return null;
                },
            },
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: {
                    alwaysOnTopFilter,
                    customSort: (a, b) =>
                        anyComparerAscending(a.extra.priceSortValue, b.extra.priceSortValue),
                },
            },
        },
        amount: {
            label: {
                msg: `${COL_LABEL_PREFIX}.amount`,
                placeholders: {
                    currency,
                },
            },
            data: {
                type: 'number',
                render: ({ item, cellValue }) => {
                    if (item.id !== DATA_ITEM_ID.INSTRUMENTS && isEditActive) {
                        if (item.id === DATA_ITEM_ID.CASH) {
                            return renderEditHoldingsInput(item, cellValue as number, (value) => onEditHoldings({
                                action: HoldingsAction.Edit,
                                amount: value,
                            }));
                        }

                        return renderEditHoldingsInput(item, cellValue as number, (value) => onEditHoldings({
                            action: HoldingsAction.Edit,
                            isin: item.id,
                            amount: value,
                        }));
                    }
                    return (
                        <AmountDisplay
                            value={cellValue as number}
                            nrOfDecimals={DEFAULT_AMOUNT_DECIMAL_POINTS}
                            notSetValue="-"
                        />
                    );
                },
            },
            align: 'right',
            percentWidth: 15,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: {
                    alwaysOnTopFilter,
                },
            },
        },
        percentage: {
            label: {
                msg: `${COL_LABEL_PREFIX}.percentage`,
            },
            align: 'right',
            percentWidth: 10,
            data: {
                type: 'number',
            },
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: {
                    alwaysOnTopFilter,
                    customSort: (a, b) =>
                        anyComparerAscending(a.extra.percentageSortValue, b.extra.percentageSortValue),
                },
            },
        },
    };

    if (isEditActive) {
        cols.delete = {
            label: {
                msg: null,
            },
            data: {
                render: ({ item }) => {
                    if (item.id !== DATA_ITEM_ID.INSTRUMENTS) {
                        if (item.extra.edited) {
                            return renderEditHoldingsIconButton(
                                'reset-changes-instrument',
                                <RenderIcon />,
                                () => onEditHoldings({
                                    action: HoldingsAction.Restore,
                                    isin: (item.id === DATA_ITEM_ID.CASH) ? null : item.id,
                                }),
                            );
                        }
                        if (item.id !== DATA_ITEM_ID.CASH && !item.extra.edited) {
                            return renderEditHoldingsIconButton(
                                'delete-instrument',
                                <TrashIcon />,
                                () => onEditHoldings({
                                    action: HoldingsAction.Delete,
                                    isin: item.id,
                                }),
                            );
                        }
                    }

                    return null;
                },
            },
            percentWidth: 10,
        };
    }

    return cols;
}

export default function PortfolioHoldingsList({
    initialHoldings,
    currentHoldings,
    fields,
    setFieldValue,
    translator,
    isEditActive,
}: IPublicProps) {
    const [cashUpdatedAmount, setCashUpdatedAmount] = useState<number>(0);
    const [cashUpdateAmount, setCashUpdateAmount] = useState<number>(0);
    let isCashUpdateNeeded = false;

    // Check if a cash recalculation is necessary
    if (roundFloat(cashUpdateAmount, { nrOfDecimals: DEFAULT_AMOUNT_DECIMAL_POINTS })
        !== roundFloat(cashUpdatedAmount, { nrOfDecimals: DEFAULT_AMOUNT_DECIMAL_POINTS })) {
        isCashUpdateNeeded = true;
    }

    const { currency, cash, instruments, instrumentsPercentageWithinHoldings, instrumentsTotalValue }
        = currentHoldings;

    const holdingsItems: IDataItem<IHoldingsCols, IHoldingsExtra>[] = [{
        id: DATA_ITEM_ID.CASH,
        colValues: {
            name: translator(`${LABEL_PREFIX}.rows.cash`),
            units: null,
            price: null,
            /* no currency as it is already shown in the column header */
            amount: setFloatDecimals(cash.amount, DEFAULT_AMOUNT_DECIMAL_POINTS),
            percentage: formatHoldingsPercentage(cash.percentageWithinHoldings),
        },
        extra: {
            edited: cash.edited,
        },
    }, {
        id: DATA_ITEM_ID.INSTRUMENTS,
        colValues: {
            name: translator(`${LABEL_PREFIX}.rows.instruments`),
            units: null,
            price: null,
            amount: setFloatDecimals(instrumentsTotalValue, DEFAULT_AMOUNT_DECIMAL_POINTS),
            percentage: formatHoldingsPercentage(instrumentsPercentageWithinHoldings),
        },
        extra: {
            edited: false,
            missingData: false,
            missingPrice: false,
        },
    }].concat(Object.entries(instruments).map(([isin, body]) => ({
        id: isin,
        colValues: {
            name: body.name || isin,
            units: (!body.deleted) ? setFloatDecimals(body.units, DEFAULT_UNITS_DECIMAL_POINTS) : 0,
            price: (!body.deleted) ? setFloatDecimals(body.pricePer, DEFAULT_AMOUNT_DECIMAL_POINTS) : 0,
            amount: (!body.deleted) ? setFloatDecimals(body.amount, DEFAULT_AMOUNT_DECIMAL_POINTS) : 0,
            percentage: (!body.deleted) ? formatHoldingsPercentage(body.percentageWithinHoldings) : '-',
        },
        extra: {
            deleted: body.deleted,
            edited: body.edited,
            added: body.added,
            percentageSortValue: body.percentageWithinHoldings,
            priceSortValue: body.pricePer,
            missingData: body.missingData,
            missingPrice: body.missingPrice,
        },
    })));

    return (
        <DataTable
            cols={getCols({ currency, onEditHoldings, isEditActive })}
            items={holdingsItems}
            toDynamicCellClass={styleTableData}
            renderPreRow={renderPreRow}
            clientSideSortingOverride={INSTRUMENTS_SORTING_OVERRIDE}
        />
    );

    function renderPreRow({
        prevDataItem,
        dataItem,
        RowComponent,
        CellComponent,
    }: IRenderPreRowProps<IHoldingsCols, IHoldingsExtra>) {
        if (dataItem.id === DATA_ITEM_ID.INSTRUMENTS && isCashUpdateNeeded) {
            return renderRecalculateCashBaner();
        }

        if (dataItem.id !== DATA_ITEM_ID.CASH && dataItem.id !== DATA_ITEM_ID.INSTRUMENTS
            && !prevDataItem.extra.missingData && isEditActive && dataItem.extra.missingData) {
            return (
                <RowComponent className="RecalculateRowBackground">
                    <CellComponent colSpan={6}>
                        <div className="MissingInstrumentData">
                            <Translate msg={`${LABEL_PREFIX}.missing_instruments_data_warning`} />
                        </div>
                    </CellComponent>
                </RowComponent>
            );
        }

        return null;

        function renderRecalculateCashBaner() {
            const isRecalculationPossible: boolean = currentHoldings.cash.amount > cashUpdateAmount;

            return (
                <RowComponent className="RecalculateRowBackground">
                    <CellComponent colSpan={6}>
                        <div className="RecalculateCashBaner">
                            {isRecalculationPossible && (
                                <div className="IconTextAlign">
                                    <InfoIcon />
                                    <Translate msg={`${LABEL_PREFIX}.recalculate_cash_message`} />
                                </div>
                            )}
                            {!isRecalculationPossible && (
                                <div className={clsx('RecalculationWarning', 'IconTextAlign')}>
                                    <WarningIcon />
                                    <Translate
                                        msg={`${LABEL_PREFIX}.recalculate_cash_warning`}
                                        placeholders={{
                                            currency,
                                            cashAmount: formatFloat(
                                                Math.abs(currentHoldings.cash.amount - cashUpdateAmount),
                                                { nrOfDecimals: DEFAULT_AMOUNT_DECIMAL_POINTS },
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                            <div className="RecalculateButtons">
                                <TextButton
                                    id="ignore-recalculate-cash"
                                    variant="bare"
                                    size="M"
                                    label={{
                                        msg: `${LABEL_PREFIX}.buttons.ignore`,
                                    }}
                                    onClick={() => setCashUpdateAmount(cashUpdatedAmount)}
                                />
                                <TextButton
                                    id="recalculate-cash"
                                    variant="filled"
                                    size="M"
                                    disabled={!isRecalculationPossible}
                                    label={{
                                        msg: `${LABEL_PREFIX}.buttons.recalculate_cash`,
                                    }}
                                    onClick={() => onEditHoldings({
                                        action: HoldingsAction.RecalculateCash,
                                    })}
                                />
                            </div>
                        </div>
                    </CellComponent>
                </RowComponent>
            );
        }
    }

    function onEditHoldings({
        isin,
        action,
        units,
        amount,
    }: IOnEditHoldingsProps) {
        /* eslint-disable no-param-reassign */
        if (isSet(isin)) {
            // Update the instruments
            if (action === HoldingsAction.Delete) {
                delete currentHoldings.instruments[isin];
            } else if (action === HoldingsAction.Edit) {
                if (isSet(units)) {
                    currentHoldings.instruments[isin].units = units;
                    currentHoldings.instruments[isin].amount =
                        units * currentHoldings.instruments[isin].pricePer;
                }
                if (isSet(amount)) {
                    currentHoldings.instruments[isin].amount = amount;
                    currentHoldings.instruments[isin].units =
                        amount / currentHoldings.instruments[isin].pricePer;
                }
                currentHoldings.instruments[isin].edited = true;
            } else if (action === HoldingsAction.Restore) {
                currentHoldings.instruments[isin].edited = false;
                currentHoldings.instruments[isin] = {
                    ...currentHoldings.instruments[isin],
                    amount: initialHoldings.instruments[isin]?.amount
                        || INITIAL_INSTRUMENT_SHARE_NR_OF_UNITS * currentHoldings.instruments[isin].pricePer,
                    units: initialHoldings.instruments[isin]?.units || INITIAL_INSTRUMENT_SHARE_NR_OF_UNITS,
                };
            }
            // Update the instrument fields with recalculated percentages
            currentHoldings = calculateEnhancedPortfolioHoldingsPercentages(currentHoldings);
            updateInstrumentFields();
        } else {
            // Update the cash
            if (action === HoldingsAction.Edit) {
                currentHoldings.cash.amount = amount;
                currentHoldings.cash.edited = true;
            } else if (action === HoldingsAction.Restore) {
                currentHoldings.cash.edited = false;
                currentHoldings.cash.amount = initialHoldings.cash.amount;
                setCashUpdatedAmount(0);
            } else if (action === HoldingsAction.RecalculateCash) {
                currentHoldings.cash.amount -= cashUpdateAmount;
                currentHoldings.cash.edited = true;
                setCashUpdatedAmount(cashUpdateAmount);
            }
            // Update the cash fields with recalculated percentages
            currentHoldings = calculateEnhancedPortfolioHoldingsPercentages(currentHoldings);
            updateCashFields();
        }

        // Update the difference in cash between initial and current instruments
        setCashUpdateAmount(currentHoldings.instrumentsTotalValue - initialHoldings.instrumentsTotalValue);
        /* eslint-enable no-param-reassign */
    }

    function updateCashFields() {
        setFieldValue({
            fieldName: fields.cash.fieldName,
            value: currentHoldings.cash,
        }, {
            fieldName: fields.instrumentsPercentageWithinHoldings.fieldName,
            value: currentHoldings.instrumentsPercentageWithinHoldings,
        });
    }

    function updateInstrumentFields() {
        setFieldValue({
            fieldName: fields.instruments.fieldName,
            value: currentHoldings.instruments,
        }, {
            fieldName: fields.instrumentsTotalValue.fieldName,
            value: currentHoldings.instrumentsTotalValue,
        }, {
            fieldName: fields.instrumentsPercentageWithinHoldings.fieldName,
            value: currentHoldings.instrumentsPercentageWithinHoldings,
        });
    }
}

function setFloatDecimals(value: number, nrOfDecimals: number): number {
    if (value > 0) {
        return Number(value.toFixed(nrOfDecimals));
    }

    return value;
}

function formatHoldingsPercentage(percentageTo1: number) {
    if (!isSet(percentageTo1) || percentageTo1 === 0) {
        return '-';
    }

    return formatPercentage(percentageTo1 * 100);
}

function styleTableData({
    colKey,
    dataItem,
}: IToDynamicCellClassProps<IHoldingsCols, IHoldingsExtra>) {
    if ((colKey !== 'name') && (dataItem.id === DATA_ITEM_ID.CASH || dataItem.id === DATA_ITEM_ID.INSTRUMENTS)) {
        return [UtilityClass.table.cellBold];
    }
    if (dataItem.id !== DATA_ITEM_ID.INSTRUMENTS) {
        if (colKey === 'name') {
            if (dataItem.extra.added) {
                return [UtilityClass.table.cellNewItem];
            }
            if (dataItem.extra.edited) {
                return [UtilityClass.table.cellEditedItem];
            }
        }
    }
    return null;
}

function InstrumentNameCell({
    dataItem,
}: {
    dataItem: IDataItem<IHoldingsCols, IHoldingsExtra>;
}) {
    if (dataItem.id === DATA_ITEM_ID.INSTRUMENTS) {
        return (
            <div className={UtilityClass.table.cellBold}>
                {dataItem.colValues.name}
            </div>
        );
    }

    return (
        <EntityFiche
            id={dataItem.id}
            name={dataItem.colValues.name}
            variant="instrument"
        />
    );
}

function alwaysOnTopFilter(dataItem: IDataItem<IHoldingsCols, IHoldingsExtra>) {
    return dataItem.id === DATA_ITEM_ID.INSTRUMENTS || dataItem.id === DATA_ITEM_ID.CASH;
}
