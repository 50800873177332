import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import * as ConsoleCommon from '@console/common/export';
import {
    TBaseClientGrowthReport,
    IBaseClientGrowthReportDataPoint,
    IBaseClientGrowthReportTimeSeriesItem,
} from '../../../models/reports/commonClientReport.models';
import {
    IRoboClientCashTransferReport,
    IRoboClientCashTransferReportDataPoint, IRoboClientCashTransferReportTimeSeriesItem,
} from '../../../models/reports/robo/roboClientCashTransferReport.models';
import { baseTimeSeriesItemSchema } from './commonReporting.schemas';

export const baseClientGrowthReportDataPointSchema: ConsoleCommon.schema.ObjectSchema<IBaseClientGrowthReportDataPoint>
    = ConsoleCommon.schema.object({
        inflow: ConsoleCommon.schema.number().required(),
        outflow: ConsoleCommon.schema.number().required(),
        retention_rate: ConsoleCommon.schema.number().required(),
    });

// eslint-disable-next-line max-len
export const roboClientCashTransferReportDataPointSchema: ConsoleCommon.schema.ObjectSchema<IRoboClientCashTransferReportDataPoint>
    = ConsoleCommon.schema.object({
        making_initial_deposits: ConsoleCommon.schema.number().required(),
        making_additional_deposits: ConsoleCommon.schema.number().required(),
        making_withdrawals: ConsoleCommon.schema.number().required(),
    });

// eslint-disable-next-line max-len
export const baseClientGrowthReportTimeSeriesItemSchema: ConsoleCommon.schema.ObjectSchema<IBaseClientGrowthReportTimeSeriesItem>
    = ConsoleCommon.schema.object({
        total: ConsoleCommon.schema.number().required(),
    })
        .concat(baseTimeSeriesItemSchema)
        .concat(baseClientGrowthReportDataPointSchema);

// eslint-disable-next-line max-len
export const roboClientCashTransferReportTimeSeriesItemSchema: ConsoleCommon.schema.ObjectSchema<IRoboClientCashTransferReportTimeSeriesItem>
    = baseTimeSeriesItemSchema.concat(roboClientCashTransferReportDataPointSchema);

export const baseClientGrowthReportSchema = ConsoleCommon.schema.object({
    time_series: decorateSchema(
        ConsoleCommon.schema.array().of(baseClientGrowthReportTimeSeriesItemSchema).required(),
        { description: 'Array of ascending data points, each containing a datetime and specific client growth data.' },
    ),
    reference_period: decorateSchema(
        baseClientGrowthReportDataPointSchema,
        { description: 'Optional single datapoint of the reference period to compare the "main" requested period.' },
    ),
    average_ytd: decorateSchema(
        baseClientGrowthReportDataPointSchema,
        { description: 'Optional single datapoint containing the average of the year-to-date period.' },
    ),
}) as ConsoleCommon.schema.ObjectSchema<TBaseClientGrowthReport>;

export const roboClientCashTransferReportSchema = ConsoleCommon.schema.object({
    time_series: decorateSchema(
        ConsoleCommon.schema.array().of(roboClientCashTransferReportTimeSeriesItemSchema).required(),
        // eslint-disable-next-line max-len
        { description: 'Array of ascending data points, each containing a datetime and specific client cash-transfer data.' },
    ),
    reference_period: decorateSchema(
        roboClientCashTransferReportDataPointSchema,
        { description: 'Optional single datapoint of the reference period to compare the "main" requested period.' },
    ),
    average_ytd: decorateSchema(
        roboClientCashTransferReportDataPointSchema,
        { description: 'Optional single datapoint containing the average of the year-to-date period.' },
    ),
}) as ConsoleCommon.schema.ObjectSchema<IRoboClientCashTransferReport>;
