import React, { useState } from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import {
    TEnhancedPortfoliosData,
    TEnhancedPortfolioInList,
} from '@console/bff/models/portfolios/enhancedPortfolios.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IDataItem, IRenderPreRowProps, TDataColumns, TDataItemId } from 'models/list.models';
import { DEFAULT_PORTFOLIO_TAB_KEY, DEFAULT_USER_TAB_KEY } from 'config/tabs.config';
import { APP_COLORS } from 'config/styling/colors';
import {
    portfolioGroupPortfoliosEntity,
} from 'state/entities/portfolioMgmt/portfolioGroupPortfolios';
import { canUserCreatePortfolioReport, canUserModifyPortfolioGroup } from 'state/auth/apiEntityAuthorization.selectors';
import { getStore } from 'state';
import { openCreatePortfolioReportAppModal } from 'state/ui/appModals.actions';
import {
    portfolioGroupDetailsEntity,
    triggerPatchPortfolioGroupDetails,
} from 'state/entities/portfolioMgmt/portfolioGroupDetails';
import { extractEmbeddedEntity } from 'utils/entities/entityEmbedUtils';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { makeStyles } from 'views/styling';
import DataTable from 'views/common/list/DataTable';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { initEntityWrapper, IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { mixins } from 'views/assets/cssInJs/mixins';
import RouteLink from 'views/common/routing/RouteLink';
import ListActionsHeader from 'views/common/list/ListActionsHeader';
import Modal from 'views/common/layout/Modal';
import ExtendedInputForm, { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import ActionButtons from 'views/common/buttons/ActionButtons';
import {
    getDefaultPortfolioColumns,
    IDefaultPortfolioCols,
} from 'views/portfolioMgmt/Portfolios/GenericPortfoliosList';
import { TPortfolioGroupPortfoliosFormValues } from '../shared/types';
import { addPortfolioToPortfolioGroupSchema } from './portfolioGroupDetailsSchema';
import { AddIcon, ViewIcon, UnlistIcon, ReportIcon } from '../../../common/icons';
import PortfolioGroupPortfoliosFormContent from '../shared/PortfolioGroupPortfoliosFormContent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPortfolioGroupPortfolioCols extends Omit<IDefaultPortfolioCols, 'owner'> { }

interface IExtraPortfolioData {
    userId: string;
}

const COLS = getDefaultPortfolioColumns({
    desiredCols: [
        { colName: 'type', percentWidth: 10 },
        { colName: 'name', percentWidth: 30 },
        { colName: 'externalId', percentWidth: 20 },
        { colName: 'updated', percentWidth: 15 },
        { colName: 'status', percentWidth: 10 },
        { colName: 'actions', percentWidth: 15 },
    ],
    hideActions: { createReport: true },
    enableSort: false,
}) as TDataColumns<IPortfolioGroupPortfolioCols, IExtraPortfolioData>;

const useStyles = makeStyles((theme) => ({
    PortfolioGroupPortfolios: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(4),
    },
    PortfoliosList: {
        '& .__table .__tableBody .__tableRow.userRow': {
            '&:not(:first-child) .__tableCell': {
                borderTop: 'none',
            },
            '& .__tableCell': {
                ...mixins.typo({ weight: 'bold', size: 18 }),
                '&.viewUserDetails a': {
                    ...mixins.typo({ weight: 'normal', size: 16, color: APP_COLORS.PRIMARY['500'] }),
                },
            },
            '& + .__tableRow': {
                '& .__tableCell': {
                    borderTop: 'none',
                },
            },
            '&:hover': {
                color: 'inherit',
            },
        },
    },
}));

export default function PortfolioGroupPortfolios() {
    const classes = useStyles();
    const [isAddPortfoliosOpen, setIsAddPortfoliosOpen] = useState(false);

    const EntityWrapper = initEntityWrapper({
        notifications: [StateChangeNotification.PORTFOLIO_GROUP_PORTFOLIOS],
    });

    const portfolioGroupData = portfolioGroupDetailsEntity.select().data;
    const doesUserHaveModifyPermissions = canUserModifyPortfolioGroup(portfolioGroupData);
    const canUserCreateReports = canUserCreatePortfolioReport();

    return (
        <div className={classes.PortfolioGroupPortfolios}>
            <ListActionsHeader
                actions={[{
                    id: 'create_portfolio_report_button',
                    icon: <ReportIcon />,
                    disabled: !canUserCreateReports,
                    tooltip: !canUserCreateReports && 'common.action.insufficient_permissions',
                    tooltipPlacement: 'left',
                    label: 'portfolio_mgmt.portfolio_reports.create.portfolio_group_button_label',
                    onClick: () => getStore().dispatch(openCreatePortfolioReportAppModal({
                        extraData: {
                            entityType: CoreApiEntityType.portfolioGroups,
                            reportsInputData: [{
                                entityCreationDatetime: portfolioGroupData.creation_datetime,
                                entityId: portfolioGroupData.id,
                                entityName: portfolioGroupData.name,
                                portfolioIds: portfolioGroupData.portfolio_ids,
                            }],
                        },
                    })),
                }, {
                    id: 'add_portfolio_to_portfolio_group',
                    icon: <AddIcon />,
                    label: 'portfolio_mgmt.portfolio_groups.detail.portfolios.list.actions.add_portfolios',
                    onClick: () => setIsAddPortfoliosOpen(true),
                    disabled: !doesUserHaveModifyPermissions,
                    tooltip: 'common.action.edit_disabled',
                }]}
            />
            <EntityWrapper
                asyncEntitySelector={portfolioGroupPortfoliosEntity.select}
                renderData={renderPortfolios}
                entityDataTransformer={({ entityData }) => entityData.results}
            />
            <Modal
                id="add-portfolios-modal"
                title="portfolio_mgmt.portfolio_groups.detail.portfolios.list.add_portfolio_modal.title"
                open={isAddPortfoliosOpen}
                onClose={() => setIsAddPortfoliosOpen(false)}
                maxWidth="lg"
            >
                <ExtendedInputForm<TPortfolioGroupPortfoliosFormValues>
                    name="add-portfolios-to-portfolio-group-form"
                    submit={{
                        actionLabel: 'portfolio_mgmt.portfolio_groups.detail.portfolios.list.actions.add_portfolios',
                        onSubmit,
                    }}
                    schema={addPortfolioToPortfolioGroupSchema}
                    initialValues={{ portfolioIds: [] }}
                    renderFormFields={(formContext) => (
                        <PortfolioGroupPortfoliosFormContent
                            formContext={formContext}
                            unselectablePortfolioIds={portfolioGroupData.portfolio_ids}
                        />
                    )}
                />
            </Modal>
        </div>
    );

    function onSubmit({ values }: IOnSubmitProps<TPortfolioGroupPortfoliosFormValues>) {
        triggerPatchPortfolioGroupDetails((currentPortfolioGroup) => {
            /* eslint-disable no-param-reassign */
            currentPortfolioGroup.portfolio_ids = [...currentPortfolioGroup.portfolio_ids, ...values.portfolioIds];
            /* eslint-enable no-param-reassign */
        }, {
            onPreSuccess: () => setIsAddPortfoliosOpen(false),
        });

        return Promise.resolve();
    }

    function renderPortfolios({
        data,
        entityData,
    }: IRenderDataProps<TEnhancedPortfolioInList[], unknown, TEnhancedPortfoliosData>) {
        if (!data) {
            return null;
        }

        COLS.actions.data.render = renderActions;

        const portfolioItems: IDataItem<IPortfolioGroupPortfolioCols, IExtraPortfolioData>[] =
            data
                .map((portfolio) => ({
                    id: portfolio.id,
                    colValues: {
                        type: {
                            managerType: portfolio.config.manager,
                            moneyType: portfolio.money_type,
                        },
                        name: portfolio.name,
                        status: portfolio.enhancedStatus,
                        externalId: portfolio.external_id,
                        updated: formatDateRelativeToNow({ date: portfolio.version_datetime }),
                        actions: null,
                    },
                    extra: {
                        userId: portfolio.owned_by_user_id,
                    },
                }))
                .sort((a, b) => {
                    const aUserName = getUserFullName(extractEmbeddedEntity<IUserEntityData>({
                        data: entityData,
                        id: a.extra.userId,
                    }));
                    const bUserName = getUserFullName(extractEmbeddedEntity<IUserEntityData>({
                        data: entityData,
                        id: b.extra.userId,
                    }));

                    return aUserName < bUserName ? -1 : aUserName > bUserName ? 1 : 0;
                });

        return (
            <div className={classes.PortfoliosList}>
                <DataTable
                    cols={COLS}
                    items={portfolioItems}
                    renderPreRow={renderUserNamePreRow}
                />
            </div>
        );

        function renderActions({ item }: { item: IDataItem<IPortfolioGroupPortfolioCols, IExtraPortfolioData> }) {
            return (
                <ActionButtons
                    actions={[{
                        onExecute: () => openPortfolioDetail(item.id),
                        id: 'view-detail',
                        label: { msg: 'portfolio_mgmt.portfolio_groups.list.columns.action.view_detail' },
                        variant: 'extra',
                        icon: <ViewIcon />,
                    }, doesUserHaveModifyPermissions && {
                        onExecute: () => removePortfolioFromList(item.id),
                        id: 'unlist-portfolio',
                        label: { msg: 'portfolio_mgmt.portfolio_groups.list.columns.action.remove_from_list' },
                        variant: 'extra',
                        icon: <UnlistIcon />,
                    }]}
                />
            );
        }

        function renderUserNamePreRow({
            prevDataItem,
            dataItem,
            RowComponent,
            CellComponent,
        }: IRenderPreRowProps<IPortfolioGroupPortfolioCols, IExtraPortfolioData>) {
            if (!prevDataItem) {
                return <UserNameRow />;
            }

            if (dataItem.extra.userId !== prevDataItem.extra.userId) {
                return <UserNameRow />;
            }

            return null;

            // TODO
            // eslint-disable-next-line react/no-unstable-nested-components
            function UserNameRow() {
                return (
                    <RowComponent className="userRow">
                        <CellComponent colSpan={5}>
                            <Translate
                                msg="portfolio_mgmt.portfolio_groups.detail.portfolios.list.portfolios_owner.name"
                                placeholders={{
                                    name: getUserFullName(extractEmbeddedEntity<IUserEntityData>({
                                        data: entityData,
                                        id: dataItem.extra.userId,
                                    })),
                                }}
                            />
                        </CellComponent>
                        <CellComponent colSpan={1} className="viewUserDetails">
                            <RouteLink
                                to={ROUTE_KEY.R_USER_DETAIL}
                                params={{
                                    userId: dataItem.extra.userId,
                                    userTab: DEFAULT_USER_TAB_KEY,
                                }}
                            >
                                <Translate // eslint-disable-next-line max-len
                                    msg="portfolio_mgmt.portfolio_groups.detail.portfolios.list.portfolios_owner.view_detail"
                                />
                            </RouteLink>
                        </CellComponent>
                    </RowComponent>
                );
            }
        }
    }

    function removePortfolioFromList(portfolioId: TDataItemId) {
        triggerPatchPortfolioGroupDetails((currentPortfolioGroup) => {
            const indexOfPortfolioId = currentPortfolioGroup.portfolio_ids.indexOf(portfolioId);
            if (indexOfPortfolioId !== -1) {
                currentPortfolioGroup.portfolio_ids.splice(indexOfPortfolioId, 1);
            }
        });
    }

    function openPortfolioDetail(portfolioId: TDataItemId) {
        redirectTo({
            routeKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
            params: {
                portfolioId,
                portfolioTab: DEFAULT_PORTFOLIO_TAB_KEY,
            },
        });
    }
}
