import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { isMenuItemToContextMenu, isMenuItemToRoute, isMenuItemWithSubItems, TMenuItem } from 'config/menu.config';
import { MENU_COLORS } from 'config/styling/colors';
import { SIZES } from 'config/styling/sizes';
import { getStore } from 'state';
import { setSelectedMenuItem } from 'state/ui/actions';
import { cleanupToUseAsElementId } from 'utils/dom/selectorUtils';
import { getRoute } from 'views/routes';
import RouteLink from 'views/common/routing/RouteLink';
import { makeStyles, mixins } from 'views/styling';
import Tooltip from 'views/common/widget/Tooltip';
import BaseButton from 'views/common/buttons/BaseButton';

const ACCORDION_SPACING_LEFT_MULTIPLIER = 7;

const useStyles = makeStyles((theme) => ({
    MenuListItem: {
        padding: theme.spacing(0, 3, 0, 0),
        minWidth: Math.min(SIZES.MENU.TOP_WIDTH, SIZES.MENU.CONTEXT_WIDTH) - 1,
    },
    MenuItem: {
        margin: theme.spacing(2, 2, 2, 4),
        // transition: theme.transitions.create(['margin'], {
        //     easing: theme.transitions.easing.easeOut,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
    },
    MenuSubItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

        '& .MenuItemContentOverride:not(.MuiButton-root).active': {
            // 32 = 2x8 on the left & 2x8 on the right
            width: SIZES.MENU.TOP_WIDTH - 32 - (8 * ACCORDION_SPACING_LEFT_MULTIPLIER),
        },

        '& .selected.MuiButton-text.MenuItemContentOverride': {
            width: SIZES.MENU.TOP_WIDTH - 16 - (8 * ACCORDION_SPACING_LEFT_MULTIPLIER),
        },
    },
    MenuItemCollapsed: {
        marginLeft: theme.spacing(2),

        '& .MenuItemContentOverride:not(.MuiButton-root).active,.MenuItemContentOverride.activeMenuItem': {
            // 16 = 1x8 on the left and 1x8 on the right
            width: SIZES.MENU.COLLAPSED_WIDTH - 16,
            minWidth: 'unset',
            margin: theme.spacing(-1, -1),
            padding: theme.spacing(1, 1),
        },
    },
    MenuItemContent: {
        ...mixins.flexRow({ alignCross: 'center', wrap: 'nowrap' }),

        color: MENU_COLORS.TOP.TEXT, // TODO other color TOP vs CONTEXT?
        textTransform: 'none',
        whiteSpace: 'nowrap',

        '&:hover': {
            // TODO other color TOP vs CONTEXT?
            ...mixins.typoBold({ color: MENU_COLORS.TOP.TEXT_HOVER }),
        },

        '&:not(.MuiButton-root).active': {
            ...mixins.typoBold({ color: MENU_COLORS.ACTIVE_ITEM.TEXT }),
            backgroundColor: MENU_COLORS.ACTIVE_ITEM.BACKGROUND,
            margin: theme.spacing(-1, -2),
            padding: theme.spacing(1, 2),
            width: SIZES.MENU.TOP_WIDTH - 32, // 32 = 2x8 on the left & 2x8 on the right
            borderRadius: 12,
        },
        '&.activeMenuItem': {
            ...mixins.typoBold({ color: MENU_COLORS.ACTIVE_ITEM.TEXT }),
            backgroundColor: MENU_COLORS.ACTIVE_ITEM.BACKGROUND,
            margin: theme.spacing(-1, -2),
            padding: theme.spacing(1, 2),
            width: SIZES.MENU.TOP_WIDTH - 32, // 32 = 2x8 on the left & 2x8 on the right
            borderRadius: 12,
        },

        '& svg,.fontIcon': {
            ...mixins.flex({ alignMain: 'center' }),
            width: 24,
            height: 24,
            marginRight: theme.spacing(2),
        },
        '& .fontIcon': {
            ...mixins.flexCenter(),
        },
        '& .TooltipContentWrapper': {
            height: 24,
        },

        '&.selected.MuiButton-text': {
            ...mixins.typoBold({ color: MENU_COLORS.SELECTED_ITEM.TEXT }),
            backgroundColor: MENU_COLORS.CONTEXT.BACKGROUND,
            margin: theme.spacing(-1, -2),
            padding: theme.spacing(1, 2),
            width: SIZES.MENU.TOP_WIDTH - 16,
            borderRadius: '12px 0 0 12px', // border-top-left and border-bottom-left
        },
        '&.selected.MuiButton-text.contextItem': {
            backgroundColor: MENU_COLORS.CONTEXT.BACKGROUND_HOVER,
        },

        '&.MuiButtonBase-root': {
            padding: 0,
        },
        '& .MuiButton-label': {
            justifyContent: 'initial',
        },
    },
    accordionRoot: {
        ...mixins.widthMax(),
        color: MENU_COLORS.TOP.TEXT,
        backgroundColor: 'inherit',
        boxShadow: 'none',

        '& .MuiAccordionSummary-root': {
            padding: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 56,
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiAccordionDetails-root': {
            padding: theme.spacing(0, 0, 0, ACCORDION_SPACING_LEFT_MULTIPLIER),

            '& .MuiList-padding': {
                padding: 0,
            },
        },
        '& .MuiButton-text': {
            padding: 'inherit',
        },
        '& .MuiButton-label': {
            justifyContent: 'initial',
        },
        '& .MuiIconButton-root': {
            color: 'inherit',
        },
    },
}));

interface IPublicProps {
    menuItem: TMenuItem;
    isCollapsed: boolean;
    activeBreadcrumbMenuItemIds: number[];
    selectedBreadcrumbMenuItemIds: number[];
}

export default function MenuListItem({
    menuItem,
    isCollapsed,
    activeBreadcrumbMenuItemIds,
    selectedBreadcrumbMenuItemIds,
}: IPublicProps) {
    const classes = useStyles();
    const isPartOfActiveBreadcrumbItems = activeBreadcrumbMenuItemIds
        && activeBreadcrumbMenuItemIds.includes(menuItem.id);
    const isActiveMenuItem = isPartOfActiveBreadcrumbItems
        // is last item within the breadcrumb
        && activeBreadcrumbMenuItemIds[activeBreadcrumbMenuItemIds.length - 1] === menuItem.id;
    const isPartOfSelectedBreadcrumbItems = !isPartOfActiveBreadcrumbItems
        && selectedBreadcrumbMenuItemIds
        && selectedBreadcrumbMenuItemIds.includes(menuItem.id);
    const [expanded, setExpanded] = React.useState(
        isMenuItemWithSubItems(menuItem) && (isPartOfActiveBreadcrumbItems || isPartOfSelectedBreadcrumbItems),
    );

    const isSelected = isPartOfSelectedBreadcrumbItems
        && !isCollapsed
        && !isMenuItemWithSubItems(menuItem);

    return (
        <ListItem
            className={classes.MenuListItem}
        >
            { isMenuItemToRoute(menuItem) && (
                <MenuItem
                    menuItem={menuItem}
                    isCollapsed={isCollapsed}
                    isSelected={isSelected}
                    isPartOfActiveBreadcrumbItems={isPartOfActiveBreadcrumbItems}
                    isActiveMenuItem={isActiveMenuItem}
                />
            )}
            { isMenuItemWithSubItems(menuItem) && isCollapsed && (
                <MenuItem
                    menuItem={menuItem}
                    isCollapsed={isCollapsed}
                    isSelected={isSelected}
                    onSelect={() => onExpandedChange(null, true)}
                    isPartOfActiveBreadcrumbItems={isPartOfActiveBreadcrumbItems}
                    isActiveMenuItem={isActiveMenuItem}
                />
            )}
            { isMenuItemWithSubItems(menuItem) && !isCollapsed && (
                <Accordion
                    square
                    classes={{
                        root: classes.accordionRoot,
                    }}
                    expanded={expanded}
                    onChange={onExpandedChange}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <MenuItem
                            menuItem={menuItem}
                            isCollapsed={isCollapsed}
                            isSelected={isSelected}
                            onSelect={() => onExpandedChange(null, !expanded)}
                            isPartOfActiveBreadcrumbItems={isPartOfActiveBreadcrumbItems}
                            isActiveMenuItem={isActiveMenuItem}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <List component="nav" aria-label="sub menu">
                            {menuItem.subItems.map((subItem) => (
                                <MenuListItem
                                    key={`sub-menu-item_${subItem.id}`}
                                    menuItem={subItem}
                                    isCollapsed={isCollapsed}
                                    activeBreadcrumbMenuItemIds={activeBreadcrumbMenuItemIds}
                                    selectedBreadcrumbMenuItemIds={selectedBreadcrumbMenuItemIds}
                                />
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            )}
            { isMenuItemToContextMenu(menuItem) && (
                <MenuItem
                    menuItem={menuItem}
                    isCollapsed={isCollapsed}
                    isSelected={isSelected}
                    isPartOfActiveBreadcrumbItems={isPartOfActiveBreadcrumbItems}
                    isActiveMenuItem={isActiveMenuItem}
                />
            )}
        </ListItem>
    );

    function onExpandedChange(event: ChangeEvent<unknown>, newExpanded: boolean) {
        setExpanded(newExpanded);
    }
}

function MenuItem({
    menuItem,
    isCollapsed,
    isSelected,
    isPartOfActiveBreadcrumbItems,
    isActiveMenuItem,
    onSelect,
}: {
    menuItem: TMenuItem;
    isCollapsed: boolean;
    isSelected: boolean;
    isPartOfActiveBreadcrumbItems: boolean;
    isActiveMenuItem: boolean;
    onSelect?: () => void;
}) {
    const classes = useStyles();

    return (
        <div
            className={clsx(
                classes.MenuItem,
                isCollapsed && classes.MenuItemCollapsed,
                menuItem.isSubItem && classes.MenuSubItem,
            )}
        >
            <MenuItemContent
                menuItem={menuItem}
                isCollapsed={isCollapsed}
                isSelected={isSelected}
                isPartOfActiveBreadcrumbItems={isPartOfActiveBreadcrumbItems}
                isActiveMenuItem={isActiveMenuItem}
                onSelect={onSelect}
            />
        </div>
    );
}

function MenuItemContent({
    menuItem,
    isCollapsed,
    isSelected,
    isPartOfActiveBreadcrumbItems,
    isActiveMenuItem,
    onSelect,
}: {
    menuItem: TMenuItem;
    isCollapsed: boolean;
    isSelected: boolean;
    isPartOfActiveBreadcrumbItems: boolean;
    isActiveMenuItem: boolean;
    onSelect?: () => void;
}) {
    const classes = useStyles();
    const { translationKey } = menuItem;
    const isContextItem = menuItem.level === 'context';
    const menuId = cleanupToUseAsElementId(`menu-item_${translationKey}`);

    const className = clsx(
        classes.MenuItemContent,
        'MenuItemContentOverride',
        isSelected && 'selected',
        isContextItem && 'contextItem',
        ((isCollapsed && isPartOfActiveBreadcrumbItems) || isActiveMenuItem) && 'activeMenuItem',
    );

    const content = (
        <TooltipIfCollapsed
            isCollapsed={isCollapsed}
            translationKey={translationKey}
        >
            <>
                { !menuItem.isSubItem && menuItem.icon && <menuItem.icon /> }
                { !menuItem.isSubItem && menuItem.iconFont && (
                    <div className="fontIcon">
                        <i className={addFontAwesomeClass(menuItem.iconFont)} />
                    </div>
                ) }
                { !isCollapsed && <Translate msg={translationKey} /> }
            </>
        </TooltipIfCollapsed>
    );

    if (isMenuItemToRoute(menuItem)) {
        const { routeKey, params } = menuItem;
        const { exact } = getRoute({ routeKey });

        return (
            <RouteLink
                className={className}
                id={menuId}
                to={routeKey}
                params={params}
                exact={exact}
                onClick={onSelectMenuItem}
            >
                { content }
            </RouteLink>
        );
    }

    return (
        <BaseButton
            id={menuId}
            classNames={[className]}
            onClick={onSelectMenuItem}
            disabled={false}
            variant="bare"
            color="primary"
            addBoxShadowOnHover={false}
            setSvgFillColor={false}
            showLoaderWhenAsync={false}
        >
            { content }
        </BaseButton>
    );

    function onSelectMenuItem() {
        const { dispatch } = getStore();

        dispatch(setSelectedMenuItem({
            selectedItem: menuItem,
            isSelecting: !isMenuItemToRoute(menuItem),
        }));

        if (onSelect) {
            onSelect();
        }
    }
}

function TooltipIfCollapsed({
    isCollapsed,
    translationKey,
    children,
}: {
    isCollapsed: boolean;
    translationKey: string;
    children: React.ReactElement;
}) {
    if (!isCollapsed) {
        return children;
    }

    return (
        <Tooltip
            label={translationKey}
            placement="right"
        >
            {children}
        </Tooltip>
    );
}

/**
 * Prepend 'fas' (= font-awesome solid) if the input does not start with
 * 'far' (= font-awesome regular).
 * p.s. 'far' is not available for each icon.
 */
function addFontAwesomeClass(iconFont: string) {
    if (iconFont.indexOf('far ') === 0) {
        return iconFont;
    }

    return `fas ${iconFont}`;
}
