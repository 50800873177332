import React from 'react';
import clsx from 'clsx';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { TLabel } from 'models/general.models';
import { CHART_STYLING } from 'config/styling/chart';
import { formatPercentage } from 'utils/number/percentageUtils';
import { determineDonutChartCenter } from 'utils/chart/donutChartUtils';
import { makeStyles } from 'views/styling';
import Pie, { IPieProps } from 'views/common/charts/Pie';
import Text from 'views/common/widget/Text';

export interface IGenericDonutChartProps extends IPieProps {
    id: string;
    className?: string;
    mainText?: IGenericDonutChartMainText;
}

export interface IGenericDonutChartMainText {
    title: TLabel;
    amount: number;
    currency?: SupportedCurrency;
    evolution?: 'up' | 'down';
    totalPercentage?: number; /* default 100 */
}

const useStyles = makeStyles((/* theme */) => ({
    GenericDonutChart: {
        width: '100%',
        position: 'relative',
    },
}));

export default function GenericDonutChart({
    id,
    className,
    data,
    options,
    mainText,
    ...otherPieProps
}: IGenericDonutChartProps) {
    const classes = useStyles();

    // TODO do something with the (optional) evolution in the mainText
    //   (via drawing triangle + adding some spaces to the amount text?)

    return (
        <div className={clsx(classes.GenericDonutChart, className)}>
            <svg
                id={id}
                style={{
                    maxWidth: options.dimensions.maxWidth,
                    maxHeight: options.dimensions.maxHeight,
                    minWidth: options.dimensions.minWidth,
                }}
                viewBox={`0 0 ${options.dimensions.maxWidth} ${options.dimensions.maxHeight}`}
                preserveAspectRatio="xMidYMid meet"
            >
                <Pie
                    id={`${id}_pie`}
                    data={data}
                    options={options}
                    {...otherPieProps}
                />

                {mainText && renderMainTextInCenterOfPie()}
            </svg>
        </div>
    );

    function renderMainTextInCenterOfPie() {
        const textCenterX = determineDonutChartCenter(options).centerX;

        return (
            <>
                <text
                    x={textCenterX}
                    y="44%"
                    textAnchor="middle"
                    fontWeight="500"
                    fontSize="16px"
                    fill={CHART_STYLING.colors.neutral['900']}
                >
                    {formatPercentage(mainText.totalPercentage || 100, {
                        stripTrailingDecimalZeros: true,
                    })}
                </text>
                <text
                    x={textCenterX}
                    y="52%"
                    textAnchor="middle"
                    fontWeight="700"
                    fontSize="20px"
                    fill={CHART_STYLING.colors.neutral['900']}
                >
                    {formatAmount(mainText.amount, {
                        currency: mainText.currency,
                        useMagnitudeFlags: true,
                    })}
                </text>
                <text
                    x={textCenterX}
                    y="59%"
                    textAnchor="middle"
                    fontWeight="400"
                    fontSize="12px"
                    fill={CHART_STYLING.colors.neutral['900']}
                >
                    <Text label={mainText.title} />
                </text>
            </>
        );
    }
}
