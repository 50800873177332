import React from 'react';
import clsx from 'clsx';
import { makeStyles, mixins } from 'views/styling';
import getArrayCopy from '@snipsonian/core/cjs/array/getArrayCopy';
import { anyComparerDescending } from '@snipsonian/core/cjs/array/sorting/comparers';
import {
    getMgmtReportingChartColor,
    MGMT_REPORTING_DONUT_CHART_CONFIG,
} from 'config/mgmtReporting/mgmtReportingCharts.config';
import GenericDonutChart, {
    IGenericDonutChartMainText,
    IGenericDonutChartProps,
} from 'views/common/charts/GenericDonutChart';
import { IPieDataItem } from 'views/common/charts/types';
import { ChartLegend, IChartLegendItem, TChartLegendVariant } from 'views/common/charts/ChartLegend';

export interface IMgmtReportingDonutChartProps
    extends Pick<IGenericDonutChartProps, 'mainText' | 'selectedSliceColor' | 'onSelectSlice'> {
    chartName: string;
    data: TMgmtReportingDonutItem[];
    chartLegend?: {
        variant: TChartLegendVariant;
        valueFormatter: (props: { value: number }) => string;
    };
}

export type TMgmtReportingDonutItem = Omit<IPieDataItem, 'color'> & {
    amount: number;
};

const useStyles = makeStyles((/* theme */) => ({
    MgmtReportingDonutChart: {
        ...mixins.widthMax(),

        '&.--legend-vertical': {
            /* the 'vertical' legend will be shown on the right of the donut chart */
            ...mixins.flexRowCenterLeft(),
        },
    },
}));

export default function MgmtReportingDonutChart({
    chartName,
    data,
    chartLegend,
    mainText,
    ...other
}: IMgmtReportingDonutChartProps) {
    const classes = useStyles();

    /**
     * The pieGenerator of d3 sorts descending by default, but we here sort ourselves
     * first so that we are sure that the random chart colors are assigned in the order we want.
     */
    const sortedColoredItems: IPieDataItem[] = getArrayCopy(data)
        .sort((itemA, itemB) => anyComparerDescending(itemA.value, itemB.value))
        .map((item, index) => ({
            ...item,
            color: getMgmtReportingChartColor(index),
        }));

    const itemThatMatchesSelection = other.selectedSliceColor
        ? sortedColoredItems.find(({ color }) => color === other.selectedSliceColor)
        : null;

    const mainTextDependingOnSelection: IGenericDonutChartMainText = itemThatMatchesSelection
        ? {
            title: itemThatMatchesSelection.label,
            amount: (itemThatMatchesSelection as unknown as TMgmtReportingDonutItem).amount,
            currency: mainText.currency,
            totalPercentage: itemThatMatchesSelection.value * 100,
        }
        : mainText;

    return (
        <div
            className={clsx(
                classes.MgmtReportingDonutChart,
                chartLegend && `--legend-${chartLegend.variant}`,
            )}
        >
            <GenericDonutChart
                id={`mgmt-reporting_donut-chart_${chartName}`}
                data={sortedColoredItems}
                options={{
                    dimensions: {
                        maxWidth: MGMT_REPORTING_DONUT_CHART_CONFIG.svg.maxWidth,
                        maxHeight: MGMT_REPORTING_DONUT_CHART_CONFIG.svg.maxHeight,
                        minWidth: MGMT_REPORTING_DONUT_CHART_CONFIG.svg.minWidth,
                    },
                    pie: {
                        outerRadius: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.outerRadius,
                        innerRadius: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.innerRadius,
                        centerX: MGMT_REPORTING_DONUT_CHART_CONFIG.svg.maxWidth / 2,
                        centerY: MGMT_REPORTING_DONUT_CHART_CONFIG.svg.maxHeight / 2,
                    },
                    slices: {
                        padAngleInRadians: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.slices.padAngleInRadians,
                        cornerRadius: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.slices.cornerRadius,
                    },
                    // percentageLines: {
                    //     outerRadius: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.percentageLines.outerRadius,
                    //     innerRadius: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.percentageLines.innerRadius,
                    //     strokeWidth: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.percentageLines.strokeWidth,
                    //     strokeDasharray: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.percentageLines.strokeDasharray,
                    // eslint-disable-next-line max-len
                    //     strokeLinecap: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.percentageLines.strokeLinecap as TStrokeLinecap,
                    // },
                    transitionDurationInMillis: MGMT_REPORTING_DONUT_CHART_CONFIG.pie.transitionDurationInMillis,
                }}
                mainText={mainTextDependingOnSelection}
                {...other}
            />

            {chartLegend && (
                <ChartLegend
                    variant={chartLegend.variant}
                    items={getChartLegendItems()}
                />
            )}
        </div>
    );

    function getChartLegendItems(): IChartLegendItem[] {
        return sortedColoredItems.map((donutItem) => ({
            color: donutItem.color,
            label: donutItem.label,
            highlightedText: {
                text: chartLegend.valueFormatter({ value: donutItem.value }),
                shouldTranslate: false,
            },
        }));
    }
}
