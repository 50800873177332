import React from 'react';
import { Switch } from 'react-router-dom';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { IAppShellConfig } from 'models/state/ui.models';
import { APP_COLORS } from 'config/styling/colors';
import { SIZES } from 'config/styling/sizes';
import { makeStyles, mixins } from 'views/styling';
import { getRoute, getIndependantRouteKeys } from 'views/routes';
import AppRoute from 'views/common/routing/AppRoute';
import AppBodyTop from './AppBodyTop';

const useStyles = makeStyles((theme) => ({
    appBody: {
        ...mixins.flexCol({ wrap: 'nowrap' }),
        ...mixins.widthMax(),
        color: APP_COLORS.TEXT['500'],
        backgroundColor: APP_COLORS.SYSTEM.BACKGROUND,
    },
    appBodyBox: {
        ...mixins.widthHeightMax(),
    },
    appBodyBoxShell: {
        position: 'relative',
        maxWidth: SIZES.BODY.MAX_WIDTH,
        margin: '0px auto',
        padding: theme.spacing(2),
    },
}));

export default function AppBody() {
    const classes = useStyles();

    return (
        <Switch>
            {getIndependantRouteKeys().map((routeKey) => {
                const {
                    component,
                    template,
                    ...rest
                } = getRoute({ routeKey });

                const RouteComponent = component;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const TemplateComponent = template as React.ComponentType<any>;

                const showAppShell = rest.appShell !== false;
                const backgroundColor = showAppShell
                    ? (rest.appShell as IAppShellConfig)?.backgroundColor
                    : null;

                return (
                    <AppRoute
                        key={routeKey}
                        {...rest}
                    >
                        <div
                            className={classes.appBody}
                            style={backgroundColor ? { background: backgroundColor } : null}
                        >
                            <AppBodyTop />

                            <Box
                                className={clsx(classes.appBodyBox, showAppShell && classes.appBodyBoxShell)}
                            >
                                {template ? (
                                    <TemplateComponent>
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/* @ts-ignore */}
                                        <RouteComponent />
                                    </TemplateComponent>
                                ) : (
                                    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                    /* @ts-ignore */
                                    <RouteComponent />
                                )}
                            </Box>
                        </div>
                    </AppRoute>
                );
            })}
        </Switch>
    );
}
