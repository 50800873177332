import React, { useRef, useEffect } from 'react';
import d3, { AxisDomain, CurveFactory } from 'utils/libs/d3';
import { IAreaDataItem, TXScale, TYScale } from './types';

interface IAreaBetweenTwoLinesProps<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number> {
    id: string;
    xScale: TXScale<XDomain>;
    yScale: TYScale<YDomain>;
    areaData: IAreaDataItem<XDomain, YDomain>[];
    fillColor: string;
    opacity?: number;
    curveFactory?: CurveFactory; // default curveLinear, see https://github.com/d3/d3-shape/blob/master/README.md#curves
    onAreaClicked?: () => void;
    transitionDurationInMillis?: number;
}

export default function AreaBetweenTwoLines<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number>({
    id,
    xScale,
    yScale,
    areaData,
    fillColor,
    opacity = 1,
    curveFactory,
    onAreaClicked,
    transitionDurationInMillis = 0,
}: IAreaBetweenTwoLinesProps<XDomain, YDomain>) {
    const ref = useRef();

    /* via useRef so that the value is persisted across re-renders */
    /* see https://www.smashingmagazine.com/2020/11/react-useref-hook/ */
    const hasTransitionEnded = useRef(false);

    useEffect(
        () => {
            const areaGenerator = d3.area<IAreaDataItem<XDomain, YDomain>>()
                .x((d) => xScale(d.x))
                .y0((d) => yScale(d.y0))
                .y1((d) => yScale(d.y1));

            if (curveFactory) {
                areaGenerator.curve(curveFactory);
            }

            const area = d3.select<SVGPathElement, unknown>(ref.current)
                .datum(areaData)
                .attr('fill', fillColor)
                .attr('stroke', 'none');

            if (transitionDurationInMillis > 0) {
                const flatAreaGenerator = d3.area<IAreaDataItem<XDomain, YDomain>>()
                    .x((d) => xScale(d.x))
                    .y0(() => yScale.range()[1] as number)
                    .y1(() => yScale.range()[1] as number);

                area.attr('d', flatAreaGenerator)
                    .transition()
                    .duration(transitionDurationInMillis)
                    .ease(d3.easeLinear)
                    .on('end', () => {
                        hasTransitionEnded.current = true;
                    })
                    .attr('d', areaGenerator);
            } else {
                area.attr('d', areaGenerator);

                hasTransitionEnded.current = true;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id], /* the transition will only be done again when the id changes --> should be unique across all areas */
    );

    /* The style "pointerEvents: 'none'" is needed so that this path - which sets on top of other svg
       elements - does not block the capture of the mouse movement */
    return (
        <path
            id={id}
            ref={ref}
            style={{
                cursor: onAreaClicked ? 'pointer' : 'default',
                opacity,
            }}
            onMouseDown={onClickArea}
        />
    );

    function onClickArea() {
        if (hasTransitionEnded.current && onAreaClicked) {
            onAreaClicked();
        }
    }
}
