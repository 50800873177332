import { IParseDateOptions, parseInputDate, TDateToFormat, toDateWithinTimezone, TTimezone } from './dateUtils';
import {
    DATE_FORMAT,
    CALENDAR_RELATIVE_DATE_FORMATS, CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME,
    putDayAfterMonthIfAppropriate,
} from '../../config/dateFormats.config';

export { DATE_FORMAT, TIME_FORMAT } from '../../config/dateFormats.config';

export const DEFAULT_DAY_POSITION_RELATIVE_TO_MONTH = 'before';

let currentDayPositionRelativeToMonth = DEFAULT_DAY_POSITION_RELATIVE_TO_MONTH;
let dateFormatToDayMonthSwitchedMap: Record<string, string> = null;
let calendarRelativeDateFormats = CALENDAR_RELATIVE_DATE_FORMATS;
let calendarRelativeDateFormatsAlwaysTime = CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME;

interface IFormatDateOptions extends IParseDateOptions {
    tweakFormatBasedOnDisplaySettings?: boolean; /* default true */
}

export function formatDate({
    date,
    format = DATE_FORMAT.DAY_MONTH_YEAR,
    options = {},
    timezone,
}: {
    date: TDateToFormat;
    format?: string;
    options?: IFormatDateOptions;
    timezone?: TTimezone, /* default LOCAL time zone */
}) {
    return (timezone
        ? toDateWithinTimezone({ date, timezone })
        : parseInputDate(date, options))
        .format(tweakFormatBasedOnDisplaySettings(format, options));
}

interface IFormatDateRelativeOptions {
    alwaysIncludeTime?: boolean; // default false
}

export function formatDateRelativeToNow({
    date,
    options = {},
}: {
    date: TDateToFormat;
    options?: IParseDateOptions & IFormatDateRelativeOptions;
}) {
    return formatDateRelativeTo({
        date,
        referenceDate: null, // = NOW
        options,
    });
}

export function formatDateRelativeTo({
    date,
    referenceDate,
    options = {},
}: {
    date: TDateToFormat;
    referenceDate: TDateToFormat;
    options?: IParseDateOptions & IFormatDateRelativeOptions;
}) {
    if (!date) {
        return null;
    }

    const {
        alwaysIncludeTime = false,
        ...parseOptions
    } = options;

    return parseInputDate(date, parseOptions)
        /* see https://day.js.org/docs/en/plugin/calendar */
        .calendar(
            referenceDate,
            alwaysIncludeTime ? calendarRelativeDateFormatsAlwaysTime : calendarRelativeDateFormats,
        );
}

/**
 * Returns a date in the format of YYYY-MM-DDTHH:mm:ss.sssZ
 * (where the timezone is always zero UTC offset)
 */
export function formatDateForBE(date: Date) {
    if (!date) {
        return null;
    }

    return new Date(date).toISOString();
}

/**
 * Returns a date in the format of YYYY-MM-DDTHH:mm:ss.SSS000+00.00
 * To be used if you really need a data in the exact format as python uses, e.g. to get a datetime value
 * that will be stored in the database.
 *
 * TODO As this is the format returned by the BE, do we still need the similar formatDateForBE function
 * TODO     which is used to format e.g. a date filter?
 */
export function formatDateAsBackendDatetime(date: TDateToFormat) {
    if (!date) {
        return null;
    }

    return parseInputDate(date)
        .utc() /* converts the date to the utc timezone as all dates are stored in the BE as UTC */
        .format(DATE_FORMAT.BACK_END_DATETIME);
}

export function setDatesDisplayCustomizationConfig({
    dayPositionRelativeToMonth,
}: {
    dayPositionRelativeToMonth: string;
}) {
    if (dayPositionRelativeToMonth === currentDayPositionRelativeToMonth) {
        return;
    }

    currentDayPositionRelativeToMonth = dayPositionRelativeToMonth;

    if (dayPositionRelativeToMonth === 'after') {
        dateFormatToDayMonthSwitchedMap = Object.values(DATE_FORMAT).reduce(
            (accumulator, origFormat) => {
                accumulator[origFormat] = putDayAfterMonthIfAppropriate(origFormat);
                return accumulator;
            },
            {} as Record<string, string>,
        );
    }

    calendarRelativeDateFormats = dayPositionRelativeToMonth === 'after'
        ? Object.entries(CALENDAR_RELATIVE_DATE_FORMATS)
            .reduce(
                (accumulator, [key, val]) => {
                    accumulator[key] = putDayAfterMonthIfAppropriate(val);
                    return accumulator;
                },
                {} as Record<string, string>,
            ) as typeof CALENDAR_RELATIVE_DATE_FORMATS
        : CALENDAR_RELATIVE_DATE_FORMATS;

    calendarRelativeDateFormatsAlwaysTime = dayPositionRelativeToMonth === 'after'
        ? Object.entries(CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME)
            .reduce(
                (accumulator, [key, val]) => {
                    accumulator[key] = putDayAfterMonthIfAppropriate(val);
                    return accumulator;
                },
                {} as Record<string, string>,
            ) as typeof CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME
        : CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME;
}

function tweakFormatBasedOnDisplaySettings(
    inputFormat: string,
    {
        tweakFormatBasedOnDisplaySettings: shouldTweakFormat = true,
    }: IFormatDateOptions = {},
): string {
    if (shouldTweakFormat && currentDayPositionRelativeToMonth === 'after') {
        if (dateFormatToDayMonthSwitchedMap[inputFormat]) {
            return dateFormatToDayMonthSwitchedMap[inputFormat];
        }
    }

    return inputFormat;
}
