import React from 'react';
import clsx from 'clsx';
import { makeStyles } from 'views/styling';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import {
    TStoryManagerStatus,
} from '@console/bff/models/storyteller/storymanager.models';
import InfoIconTooltip from '../../../common/widget/InfoIconTooltip';
import { IInputSelectItem } from '../../../common/inputs/base/InputSelectField';
import ExtendedInputSelect from '../../../common/inputs/extended/ExtendedInputSelect';
import { IExtendedFormField } from '../../../common/inputs/extended/ExtendedInputForm';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.status';

interface IPublicProps {
    className?: string;
    status: TStoryManagerStatus;
    small?: boolean;
    infoIconTooltip?: {
        name: string;
        label: string;
    };
    form?: {
        // even when using formField the status prop should still be set for the styling
        formField: IExtendedFormField;
        readOnly: boolean;
    };
}

interface IStyleProps {
    status?: TStoryManagerStatus;
}

interface IColorVariantConfig {
    textColor: string;
    backgroundColor: string;
}

const STATUS_COLOR_CONFIG: { [color: string]: IColorVariantConfig; } = {
    // TODO merge the below colors with the upper ones
    TO_DO: getStatusVariantConfig('#F16647', '#FCE0DA'),
    DONE: getStatusVariantConfig('#73BA18', '#E3F1D1'),
    IN_PROGRESS: getStatusVariantConfig('#16B5E5', '#D0F0FA'),
    IN_REVIEW: getStatusVariantConfig('#FFA10A', '#FFECCE'),
};

const useStyles = makeStyles((theme) => ({
    StoryManagerStatusDot: {
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',

        backgroundColor: ({ status }: IStyleProps) => STATUS_COLOR_CONFIG[status].textColor,
    },
    StoryManagerStatusFullWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    StoryManagerStatusFull: {
        display: 'inline-block',
        position: 'relative',
        padding: theme.spacing(0.25, 0.75),

        '& > .label': {
            position: 'relative',
            zIndex: 1,
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightBold,

            '& .status-select-wrapper': {
                padding: 0,

                '& .status-select': {
                    background: 'none',
                    border: 'none',

                    '& .MuiInputBase-root': {
                        minHeight: 'auto',
                        margin: 0,

                        '& .MuiSelect-root': {
                            fontSize: theme.typography.pxToRem(14),
                            fontWeight: theme.typography.fontWeightBold,
                            paddingTop: 2,
                            paddingBottom: 1,

                            '&:focus': {
                                background: 'none',
                            },
                        },
                    },
                },
            },
        },

        '& > .background': {
            content: ' ',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',

            padding: theme.spacing(1),
            borderRadius: 8,
        },

        '& .background': {
            backgroundColor: ({ status }: IStyleProps) => STATUS_COLOR_CONFIG[status].backgroundColor,
        },
        '& .label': {
            color: ({ status }: IStyleProps) => STATUS_COLOR_CONFIG[status].textColor,
            '& .MuiSelect-root': {
                color: ({ status }: IStyleProps) => STATUS_COLOR_CONFIG[status].textColor,
            },
            '& svg': {
                fill: ({ status }: IStyleProps) => STATUS_COLOR_CONFIG[status].textColor,
            },
        },
    },
}));

const STATUS_ITEMS: IInputSelectItem<TStoryManagerStatus>[] = [{
    label: 'fields.status.to_do',
    value: 'TO_DO',
}, {
    label: 'fields.status.in_progress',
    value: 'IN_PROGRESS',
}, {
    label: 'fields.status.in_review',
    value: 'IN_REVIEW',
}, {
    label: 'fields.status.done',
    value: 'DONE',
}];

export default function StoryManagerStatus({
    status,
    className,
    small,
    infoIconTooltip,
    form,
}: IPublicProps) {
    const classes = useStyles({ status });

    if (small) {
        return (
            <div className={clsx(classes.StoryManagerStatusDot, className)} />
        );
    }

    return (
        <div className={clsx(classes.StoryManagerStatusFullWrapper, className)}>
            <div className={classes.StoryManagerStatusFull}>
                <span className="background" />
                <span className="label">
                    {(form && !form.readOnly) ? (
                        <ExtendedInputSelect
                            className="status-select"
                            wrapper={{
                                className: 'status-select-wrapper',
                            }}
                            formField={form.formField}
                            items={STATUS_ITEMS}
                            itemLabelsAreTranslationKeys
                        />
                    ) : (
                        <Translate msg={`${TRANSLATION_PREFIX}.${status?.toLowerCase()}`} />
                    )}
                </span>
            </div>
            {infoIconTooltip && (
                <InfoIconTooltip
                    name={infoIconTooltip.name}
                    showUntilDismissed
                    simpleContent={{
                        info: {
                            msg: infoIconTooltip.label,
                        },
                    }}
                />
            )}
        </div>
    );
}

function getStatusVariantConfig(baseColor: string, bgColor?: string): IColorVariantConfig {
    return {
        textColor: baseColor,
        backgroundColor: bgColor || baseColor,
    };
}
