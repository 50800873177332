import { IExecuteOnRoute, IRoute } from 'models/route.models';
import { ISingleRiskProfileApiInput } from '@console/core-api/models/portfolioMgmt/riskProfiles.models';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import { ISingleGoalApiInput } from '@console/core-api/models/portfolioMgmt/goal.models';
import { ISingleHorizonApiInput } from '@console/core-api/models/portfolioMgmt/horizon.models';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { ISinglePolicyApiInput } from '@console/core-api/models/portfolioMgmt/policy.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { PORTFOLIO_GROUP_DETAILS_TAB_KEY, PORTFOLIO_TAB_KEY } from 'config/tabs.config';
import { APP_COLORS } from 'config/styling/colors';
import { getCurrentRouteParam } from 'state/ui/selectors';
import { triggerFetchRiskProfiles } from 'state/entities/portfolioMgmt/riskProfiles';
import { getRiskProfileTitle, triggerFetchRiskProfileDetails } from 'state/entities/portfolioMgmt/riskProfileDetails';
import { triggerFetchPolicies } from 'state/entities/portfolioMgmt/policies';
import { triggerFetchPortfolios } from 'state/entities/portfolioMgmt/portfolios';
import { getPortfolioTitle, triggerFetchPortfolioDetails } from 'state/entities/portfolioMgmt/portfolioDetails';
import { triggerFetchPortfolioAllocation } from 'state/entities/portfolioMgmt/portfolioAllocation';
import { triggerFetchPortfolioHoldings } from 'state/entities/portfolioMgmt/portfolioHoldings';
import { triggerFetchPortfolioOptimizations } from 'state/entities/portfolioMgmt/portfolioOptimizations';
import { triggerFetchPortfolioTransactions } from 'state/entities/portfolioMgmt/portfolioTransactions';
import { triggerFetchGoals } from 'state/entities/portfolioMgmt/goals';
import { getGoalTitle, triggerFetchGoalDetails } from 'state/entities/portfolioMgmt/goalDetails';
import { triggerFetchHorizons } from 'state/entities/portfolioMgmt/horizons';
import { triggerFetchPortfolioGroups } from 'state/entities/portfolioMgmt/portfolioGroups';
import { getHorizonTitle, triggerFetchHorizonDetails } from 'state/entities/portfolioMgmt/horizonDetails';
import {
    portfolioGroupDetailsEntity,
    triggerFetchPortfolioGroupDetails,
} from 'state/entities/portfolioMgmt/portfolioGroupDetails';
import {
    triggerFetchPortfolioGroupPortfolioReports,
    triggerFetchPortfolioReports,
} from 'state/entities/portfolioMgmt/portfolioReports';
import { getPolicyTitle, triggerFetchPolicyDetails } from 'state/entities/portfolioMgmt/policyDetails';
import { triggerFetchPortfolioGroupPortfolios } from 'state/entities/portfolioMgmt/portfolioGroupPortfolios';
import {
    triggerFetchPortfolioOptimizationComparison,
} from 'state/entities/portfolioMgmt/portfolioOptimizationComparison';
import getApiEntityVersionsChildRoute from 'views/common/genericApiEntity/versions/ApiEntityVersions';
import { ROUTE_KEY } from '../routeKeys';
import PortfoliosList from './Portfolios/PortfoliosList';
import PortfolioAdd from './Portfolios/PortfolioAdd';
import PortfolioDetail from './Portfolios/PortfolioDetail';
import PortfolioOptimizationComparison
    from './Portfolios/PortfolioDetail/PortfolioOptimizations/PortfolioOptimizationComparison';
import PoliciesList from './Policies/PoliciesList';
import PolicyAdd from './Policies/PolicyAdd';
import PolicyDetail from './Policies/PolicyDetail';
import PortfolioGroupsList from './PortfolioGroups/PortfolioGroupsList';
import PortfolioGroupDetail from './PortfolioGroups/PortfolioGroupDetail';
import GoalsList from './Goals/GoalsList';
import GoalAdd from './Goals/GoalAdd';
import GoalDetail from './Goals/GoalDetail';
import HorizonsList from './Horizons/HorizonsList';
import HorizonAdd from './Horizons/HorizonAdd';
import HorizonDetail from './Horizons/HorizonDetail';
import RiskProfilesList from './RiskProfiles/RiskProfilesList';
import RiskProfileAdd from './RiskProfiles/RiskProfileAdd';
import RiskProfileDetail from './RiskProfiles/RiskProfileDetail';
import PortfolioGroupAdd from './PortfolioGroups/PortfolioGroupAdd';

export const PORTFOLIO_MGMT_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_PORTFOLIOS_LIST,
    path: '/portfolios',
    breadcrumb: {
        translationKey: 'portfolio_mgmt.portfolios.breadcrumb',
    },
    exact: true,
    component: PortfoliosList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.portfolios?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchPortfolios,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_PORTFOLIO_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.portfolios.add.breadcrumb',
            },
            exact: true,
            component: PortfolioAdd,
            requiredPermissions: [OperationPermissionKey.PORTFOLIOS_CREATE],
        },
        {
            routeKey: ROUTE_KEY.R_PORTFOLIO_OPTIMIZATION_DETAIL,
            path: '/:portfolioId/optimizations/:optimizationId',
            breadcrumb: null,
            exact: true,
            component: PortfolioOptimizationComparison,
            appShell: {
                showBreadcrumbs: false,
                padWithHeader: false,
                backgroundColor: APP_COLORS.SYSTEM.WHITE,
            },
            executeOnRoute: [{
                execute: triggerFetchPortfolioOptimizationComparison,
            }],
        },
        getPortfolioDetailTabRoute({
            routeKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
            versionsRouteKey: ROUTE_KEY.R_PORTFOLIO_VERSIONS,
        }),
    ],
}, {
    routeKey: ROUTE_KEY.R_POLICIES_LIST,
    path: '/policies',
    breadcrumb: {
        translationKey: 'portfolio_mgmt.policies.breadcrumb',
    },
    exact: true,
    component: PoliciesList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.policies?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchPolicies,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_POLICY_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.policies.add.breadcrumb',
            },
            exact: true,
            component: PolicyAdd,
            requiredPermissions: {
                childPolicy: [OperationPermissionKey.POLICIES_CREATE],
                basePolicy: [OperationPermissionKey.BASE_POLICIES_CREATE],
            },
        }, {
            routeKey: ROUTE_KEY.R_POLICY_DETAIL,
            path: '/:policyId/:policyTab',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.policies.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'portfolio_mgmt.policies.detail.breadcrumb_if_current_route',
                translationPlaceholdersSelector: ({ pathParams, state, translator, translatorIfNeeded }) => {
                    const policyName = getPolicyTitle()
                        || getCurrentRouteParam(state, 'policyId')
                        || (pathParams && pathParams.policyId) as string;
                    const tabKey = (pathParams && pathParams.policyTab)
                        || getCurrentRouteParam(state, 'policyTab');

                    return {
                        policyName: translatorIfNeeded(policyName),
                        policyTab: translator(`portfolio_mgmt.policies.detail.tabs.${tabKey}.breadcrumb_part`),
                    };
                },
                extraNotifications: [StateChangeNotification.POLICY_DETAILS_DATA],
            },
            component: PolicyDetail,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            executeOnRoute: [<IExecuteOnRoute<ISinglePolicyApiInput>>{
                execute: triggerFetchPolicyDetails,
                executeInputSelector: ({ routeLocation }) => ({
                    policyId: routeLocation.params.policyId,
                }),
            }],
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: CoreApiEntityType.policies,
                    entityIdRouteParamName: 'policyId',
                    routeKey: ROUTE_KEY.R_POLICY_VERSIONS,
                }),
            ],
        },
    ],
}, {
    routeKey: ROUTE_KEY.R_GOALS_LIST,
    path: '/goals',
    breadcrumb: {
        translationKey: 'portfolio_mgmt.goals.breadcrumb',
    },
    exact: true,
    component: GoalsList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.goals?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchGoals,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_GOAL_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.goals.add.breadcrumb',
            },
            exact: true,
            component: GoalAdd,
            requiredPermissions: [OperationPermissionKey.GOALS_CREATE],
        }, {
            routeKey: ROUTE_KEY.R_GOAL_DETAIL,
            path: '/:goalId/:goalTab',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.goals.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'portfolio_mgmt.goals.detail.breadcrumb_if_current_route',
                translationPlaceholdersSelector: ({ pathParams, state, translator, translatorIfNeeded }) => {
                    const goalTitle = getGoalTitle();
                    const tabKey = (pathParams && pathParams.goalTab)
                        || getCurrentRouteParam(state, 'goalTab');

                    return {
                        goalName: translatorIfNeeded(goalTitle),
                        goalTab: translator(`portfolio_mgmt.goals.detail.tabs.${tabKey}.breadcrumb_part`),
                    };
                },
                extraNotifications: [StateChangeNotification.GOAL_DETAILS_DATA],
            },
            component: GoalDetail,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            executeOnRoute: [<IExecuteOnRoute<ISingleGoalApiInput>>{
                execute: triggerFetchGoalDetails,
                executeInputSelector: ({ routeLocation }) => ({
                    goalId: routeLocation.params.goalId,
                }),
            }],
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: CoreApiEntityType.goals,
                    entityIdRouteParamName: 'goalId',
                    routeKey: ROUTE_KEY.R_GOAL_VERSIONS,
                }),
            ],
        },
    ],
}, {
    routeKey: ROUTE_KEY.R_HORIZONS_LIST,
    path: '/horizons',
    breadcrumb: {
        translationKey: 'portfolio_mgmt.horizons.breadcrumb',
    },
    exact: true,
    component: HorizonsList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.horizons?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchHorizons,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_HORIZON_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.horizons.add.breadcrumb',
            },
            exact: true,
            component: HorizonAdd,
            requiredPermissions: [OperationPermissionKey.HORIZONS_CREATE],
        }, {
            routeKey: ROUTE_KEY.R_HORIZON_DETAIL,
            path: '/:horizonId/:horizonTab',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.horizons.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'portfolio_mgmt.horizons.detail.breadcrumb_if_current_route',
                translationPlaceholdersSelector: ({ pathParams, state, translator, translatorIfNeeded }) => {
                    const horizonName = getHorizonTitle();
                    const tabKey = (pathParams && pathParams.horizonTab)
                        || getCurrentRouteParam(state, 'horizonTab');

                    return {
                        horizonName: translatorIfNeeded(horizonName),
                        horizonTab: translator(`portfolio_mgmt.horizons.detail.tabs.${tabKey}.breadcrumb_part`),
                    };
                },
                extraNotifications: [StateChangeNotification.HORIZON_DETAILS_DATA],
            },
            component: HorizonDetail,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            executeOnRoute: [<IExecuteOnRoute<ISingleHorizonApiInput>>{
                execute: triggerFetchHorizonDetails,
                executeInputSelector: ({ routeLocation }) => ({
                    horizonId: routeLocation.params.horizonId,
                }),
            }],
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: CoreApiEntityType.horizons,
                    entityIdRouteParamName: 'horizonId',
                    routeKey: ROUTE_KEY.R_HORIZON_VERSIONS,
                }),
            ],
        },
    ],
}, {
    routeKey: ROUTE_KEY.R_RISK_PROFILES_LIST,
    path: '/risk-profiles',
    breadcrumb: {
        translationKey: 'portfolio_mgmt.risk_profiles.breadcrumb',
    },
    exact: true,
    component: RiskProfilesList,
    isEnabled: ({ tenantSettings }) => tenantSettings?.riskProfiles?.isEnabled,
    executeOnRoute: [{
        execute: triggerFetchRiskProfiles,
    }],
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_RISK_PROFILE_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.risk_profiles.add.breadcrumb',
            },
            exact: true,
            component: RiskProfileAdd,
            requiredPermissions: [OperationPermissionKey.RISKPROFILES_CREATE],
        }, {
            routeKey: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
            path: '/:riskProfileId/:riskProfileTab',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.risk_profiles.detail.breadcrumb',
                translationKeyIfCurrentRoute: 'portfolio_mgmt.risk_profiles.detail.breadcrumb_if_current_route',
                translationPlaceholdersSelector: ({ pathParams, state, translator, translatorIfNeeded }) => {
                    const riskProfileName = getRiskProfileTitle();
                    const tabKey = (pathParams && pathParams.riskProfileTab)
                        || getCurrentRouteParam(state, 'riskProfileTab');

                    return {
                        riskProfileName: translatorIfNeeded(riskProfileName),
                        riskProfileTab:
                            translator(`portfolio_mgmt.risk_profiles.detail.tabs.${tabKey}.breadcrumb_part`),
                    };
                },
                extraNotifications: [StateChangeNotification.RISK_PROFILES_DATA],
            },
            component: RiskProfileDetail,
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            executeOnRoute: [<IExecuteOnRoute<ISingleRiskProfileApiInput>>{
                execute: triggerFetchRiskProfileDetails,
                executeInputSelector: ({ routeLocation }) => ({
                    riskProfileId: routeLocation.params.riskProfileId,
                }),
            }],
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: CoreApiEntityType.riskProfiles,
                    entityIdRouteParamName: 'riskProfileId',
                    routeKey: ROUTE_KEY.R_RISK_PROFILE_VERSIONS,
                }),
            ],
        },
    ],
}, {
    routeKey: ROUTE_KEY.R_PORTFOLIO_GROUPS_LIST,
    path: '/portfolio_groups',
    breadcrumb: {
        translationKey: 'portfolio_mgmt.portfolio_groups.breadcrumb',
    },
    exact: true,
    component: PortfolioGroupsList,
    executeOnRoute: [{
        execute: triggerFetchPortfolioGroups,
    }],
    // requiredPermissions: [OperationPermissionKey.PORTFOLIO_GROUPS_READ], // TODO
    isEnabled: ({ tenantSettings }) => tenantSettings?.storyteller?.manageReports?.isEnabled,
    childRoutes: [
        {
            routeKey: ROUTE_KEY.R_PORTFOLIO_GROUP_ADD,
            path: '/add',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.portfolio_groups.add.breadcrumb',
            },
            exact: true,
            component: PortfolioGroupAdd,
            requiredPermissions: [OperationPermissionKey.PORTFOLIO_GROUPS_CREATE],
        },
        {
            routeKey: ROUTE_KEY.R_PORTFOLIO_GROUP_DETAIL,
            path: '/:portfolioGroupId/:portfolioGroupTab',
            breadcrumb: {
                translationKey: 'portfolio_mgmt.portfolio_groups.detail.breadcrumb',
                translationPlaceholdersSelector: ({ pathParams, state, translator }) => {
                    const tabKey = (pathParams && pathParams.portfolioGroupTab)
                        || getCurrentRouteParam(state, 'portfolioGroupTab');
                    return {
                        portfolioGroupTab:
                            translator(`portfolio_mgmt.portfolio_groups.detail.tabs.${tabKey}.breadcrumb_part`),
                    };
                },
            },
            exact: true,
            component: PortfolioGroupDetail,
            executeOnRoute: [{
                execute: triggerFetchPortfolioGroupDetails,
                executeInputSelector: ({ routeLocation }) => ({
                    portfolioGroupId: routeLocation.params.portfolioGroupId,
                }),
            }, {
                execute: triggerFetchPortfolioGroupPortfolios,
                executeInputSelector: () => ({
                    ids: portfolioGroupDetailsEntity.select().data.portfolio_ids,
                }),
                shouldExecute: ({ routeLocation }) => {
                    // On initial load the portfolios are fetched in 'triggerFetchPortfolioGroupDetails()'
                    // because we need the portfolio_ids field of the detail data.
                    if (!portfolioGroupDetailsEntity.select().data) {
                        return false;
                    }
                    return routeLocation.params.portfolioGroupTab === PORTFOLIO_GROUP_DETAILS_TAB_KEY.PORTFOLIOS;
                },
            }, {
                execute: triggerFetchPortfolioGroupPortfolioReports,
                executeInputSelector: ({ routeLocation }) => ({
                    portfolioId: routeLocation.params.portfolioGroupId,
                }),
                shouldExecute: ({ routeLocation }) =>
                    routeLocation.params.portfolioGroupTab === PORTFOLIO_GROUP_DETAILS_TAB_KEY.GROUP_REPORTS,
            }],
            childRoutes: [
                getApiEntityVersionsChildRoute({
                    entityType: CoreApiEntityType.portfolioGroups,
                    entityIdRouteParamName: 'portfolioGroupId',
                    routeKey: ROUTE_KEY.R_PORTFOLIO_GROUP_VERSIONS,
                }),
            ],
        },
    ],
}];

export function getPortfolioDetailTabRoute({
    routeKey,
    versionsRouteKey,
}: {
    routeKey: ROUTE_KEY;
    versionsRouteKey: ROUTE_KEY;
}): IRoute<ROUTE_KEY> {
    return {
        routeKey,
        path: '/:portfolioId/:portfolioTab',
        breadcrumb: {
            translationKey: 'portfolio_mgmt.portfolios.detail.breadcrumb',
            translationKeyIfCurrentRoute: 'portfolio_mgmt.portfolios.detail.breadcrumb_if_current_route',
            translationPlaceholdersSelector: ({ pathParams, state, translator }) => {
                const portfolioName = getPortfolioTitle({
                    includeUserName: false,
                });
                const tabKey = (pathParams && pathParams.portfolioTab) || getCurrentRouteParam(state, 'portfolioTab');

                return {
                    portfolioName,
                    portfolioTab: translator(`portfolio_mgmt.portfolios.detail.tabs.${tabKey}.breadcrumb_part`),
                };
            },
        },
        component: PortfolioDetail,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        executeOnRoute: [<IExecuteOnRoute<IFetchTabSpecificPortfolioDetailsInput>>{
            execute: triggerFetchTabSpecificPortfolioDetails,
            executeInputSelector: ({ routeLocation }) => ({
                portfolioId: routeLocation.params.portfolioId,
                tab: routeLocation.params.portfolioTab,
            }),
        }],
        childRoutes: [
            getApiEntityVersionsChildRoute({
                entityType: CoreApiEntityType.portfolios,
                entityIdRouteParamName: 'portfolioId',
                routeKey: versionsRouteKey,
            }),
        ],
    };
}

interface IFetchTabSpecificPortfolioDetailsInput {
    portfolioId: string;
    tab: string;
}

async function triggerFetchTabSpecificPortfolioDetails({
    portfolioId,
    tab,
}: IFetchTabSpecificPortfolioDetailsInput) {
    await triggerFetchPortfolioDetails();

    switch (tab) {
        case PORTFOLIO_TAB_KEY.ALLOCATION:
            return triggerFetchPortfolioAllocation({ portfolioId });
        case PORTFOLIO_TAB_KEY.HOLDINGS:
            return triggerFetchPortfolioHoldings({ portfolioId });
        case PORTFOLIO_TAB_KEY.OPTIMIZATIONS:
            return triggerFetchPortfolioOptimizations({ portfolioId });
        case PORTFOLIO_TAB_KEY.TRANSACTIONS:
            return triggerFetchPortfolioTransactions({ portfolioId });
        case PORTFOLIO_TAB_KEY.REPORTS:
            return triggerFetchPortfolioReports({ portfolioId });
        default:
            return Promise.resolve();
    }
}
