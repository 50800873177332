import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { makeStyles } from 'views/styling';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { appendValueToArrayLikeObject, reshiftArrayLikeObject } from '@console/common/utils/object/objectUtils';
import {
    IOutputStatuses,
    IVariations,
    IVariationTranslations,
    TStoryManagerStatus,
} from '@console/bff/models/storyteller/storymanager.models';
import {
    getStoryManagerFilters,
} from 'state/ui/storyManager.selector';
import { getStore } from 'state';
import { getStoryManagerLocales } from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import ActionButtons, { IActionItem } from 'views/common/buttons/ActionButtons';
import { AddIcon, TrashIcon } from 'views/common/icons';
import { typoSmallCaps } from 'views/assets/cssInJs/mixins/typo';
import TextButton from 'views/common/buttons/TextButton';
import {
    conditionallyDisableStoryManagerAddAction,
} from 'views/apps/StoryTeller/StoryManager/storyManagerActions';
import { IScenarioDetailsFormValues } from './shared.models';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.scenario_detail';

const useStyles = makeStyles((theme) => ({
    OutputEditor: {
        width: '100%',
    },
    StatusHeader: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'flex-end',

        '& .languageStatus': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: theme.spacing(1),
        },
    },
    VariationsWrapper: {
        marginTop: theme.spacing(3),

        '& .variation': {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(2),
            },

            '& .variationHeader': {
                ...typoSmallCaps(),
                display: 'grid',
                gridTemplateColumns: '100px 1fr 50px',
                gap: theme.spacing(1),
                alignItems: 'center',
                marginBottom: theme.spacing(1),
            },
            '& .variationRow': {
                display: 'grid',
                gridTemplateColumns: '100px 1fr',
                gap: theme.spacing(1),
                alignItems: 'center',
            },
        },
    },
    EmptyVariations: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    VariationsActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',

        padding: theme.spacing(2, 0),
    },
}));

export function OutputEditor({
    fields,
    setFieldValue,
    canUserModifyTextOutputs,
}: IExtendedInputFormContext<IScenarioDetailsFormValues> & {
    canUserModifyTextOutputs: boolean;
}) {
    const classes = useStyles();

    const { getState } = getStore();
    const filters = getStoryManagerFilters(getState());

    const variations = (fields.variations.value || {}) as IVariations;
    const statuses = (fields.statuses.value || {}) as IOutputStatuses;

    const localeFilter = filters?.locale || [];

    const emptyVariations = Object.keys(variations).length === 0;

    return (
        <div className={classes.OutputEditor}>
            <div className={classes.StatusHeader}>
                {Object.keys(statuses)
                    .filter((locale) => localeFilter.includes(locale))
                    .map((locale) => (
                        <div key={locale} className="languageStatus">
                            <span>{`${locale}:`}</span>
                            <StoryManagerStatus
                                status={fields[`statuses.${locale}`].value as TStoryManagerStatus}
                                form={{
                                    formField: fields[`statuses.${locale}`],
                                    readOnly: !canUserModifyTextOutputs,
                                }}
                            />
                        </div>
                    ))}
            </div>
            <div className={classes.VariationsWrapper}>
                {emptyVariations && (
                    <div className={classes.EmptyVariations}>
                        <Translate msg={`${TRANSLATION_PREFIX}.fields.copy.variation.empty`} />
                        <TextButton
                            id="condition-editor-empty-add-new"
                            label={`${TRANSLATION_PREFIX}.fields.copy.variation.add`}
                            onClick={addVariation}
                            size="S"
                            disabled={!canUserModifyTextOutputs}
                            tooltip={!canUserModifyTextOutputs && 'common.action.insufficient_permissions'}
                        />
                    </div>
                )}
                {Object.keys(variations).map((key, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="variation">
                        <div className="variationHeader">
                            <span />
                            <span>
                                <Translate
                                    msg={`${TRANSLATION_PREFIX}.fields.copy.variation.label`}
                                    placeholders={{ number: index + 1 }}
                                />
                            </span>
                            <ActionButtons
                                actions={getVariationHeaderActions(index)}
                            />
                        </div>
                        {Object.keys(statuses)
                            .filter((locale) => localeFilter.includes(locale))
                            .map((locale) => {
                                const itemFormFieldName = `variations.${index}.${locale}`;

                                return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <div key={index + locale} className="variationRow">
                                        <span>{`${locale}:`}</span>
                                        <ExtendedInputText
                                            formField={fields[itemFormFieldName]}
                                            wrapper={{}}
                                            multilineRows={2}
                                            disabled={!canUserModifyTextOutputs}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                ))}
            </div>
            {!emptyVariations && (
                <div className={classes.VariationsActions}>
                    <ActionButtons
                        actions={[getAddVariationAction()]}
                    />
                </div>
            )}
        </div>
    );

    function getVariationHeaderActions(index: number): IActionItem[] {
        return [{
            id: `${index}-delete-variation`,
            label: canUserModifyTextOutputs
                ? `${TRANSLATION_PREFIX}.fields.copy.variation.delete`
                : 'common.action.insufficient_permissions',
            disabled: !canUserModifyTextOutputs,
            onExecute: () => deleteVariation(index),
            icon: <TrashIcon />,
            variant: 'main-icon-bare',
        }];
    }

    function getAddVariationAction() {
        return conditionallyDisableStoryManagerAddAction({
            canUserModify: canUserModifyTextOutputs,
            action: {
                id: 'output-editor-add-variation',
                label: `${TRANSLATION_PREFIX}.fields.copy.variation.add`,
                icon: <AddIcon />,
                onExecute: addVariation,
                variant: 'main-icon-bare',
            },
        });
    }

    function addVariation() {
        const emptyTranslations: IVariationTranslations = {};

        getStoryManagerLocales().forEach((locale) => {
            emptyTranslations[locale] = '';
        });

        const newVariations = appendValueToArrayLikeObject(
            variations,
            emptyTranslations,
        );

        setFieldValue({
            fieldName: 'variations',
            value: newVariations,
            resetChildFields: true,
        });
    }

    function deleteVariation(index: number) {
        const newVariations = cloneDeep(variations);
        delete newVariations[index];

        setFieldValue({
            fieldName: 'variations',
            value: reshiftArrayLikeObject(newVariations),
            resetChildFields: true,
        });
    }
}
