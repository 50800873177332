import React, { useEffect } from 'react';
import clsx from 'clsx';
import { gsap } from 'gsap';
import List from '@mui/material/List';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { TMenuItem } from 'config/menu.config';
import { MENU_COLORS } from 'config/styling/colors';
import { SIZES } from 'config/styling/sizes';
import { DURATION_IN_SECS } from 'config/styling/animation';
import { makeStyles, mixins } from 'views/styling';
import { toIdSelector } from 'utils/dom/selectorUtils';
import MenuListItem from './MenuListItem';

const TOP_MENU_ID = 'top-menu';
const TOP_MENU_ITEMS_ID = 'top-menu-items';
let prevShowTopMenu: boolean;
let prevIsCollapsed: boolean;

const useStyles = makeStyles((/* theme */) => ({
    TopMenu: {
        ...mixins.flexCol({ alignMain: 'space-between' }),
        width: `${SIZES.MENU.TOP_WIDTH}px`,
        backgroundColor: MENU_COLORS.TOP.BACKGROUND,
        overflow: 'hidden',

        '&.notShown': {
            width: 0,
        },
        '&.collapsed': {
            width: `${SIZES.MENU.COLLAPSED_WIDTH}px`,
        },
    },
    MenuList: {
        padding: 0,
    },
}));

interface IPublicProps {
    allowedMenuItems: TMenuItem[];
    showTopMenu: boolean;
    isCollapsed?: boolean;
    activeBreadcrumbMenuItemIds: number[];
    selectedBreadcrumbMenuItemIds: number[];
}

export function TopMenu({
    allowedMenuItems,
    showTopMenu,
    isCollapsed = false,
    activeBreadcrumbMenuItemIds,
    selectedBreadcrumbMenuItemIds,
}: IPublicProps) {
    const classes = useStyles();

    useEffect(
        () => {
            if (!isSet(prevShowTopMenu)) {
                prevShowTopMenu = showTopMenu;
                prevIsCollapsed = isCollapsed;
            }

            animateTopMenuShift();

            prevShowTopMenu = showTopMenu;
            prevIsCollapsed = isCollapsed;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showTopMenu, isCollapsed],
    );

    return (
        <div
            id={TOP_MENU_ID}
            className={clsx(classes.TopMenu, !showTopMenu && 'notShown', showTopMenu && isCollapsed && 'collapsed')}
        >
            <List
                id={TOP_MENU_ITEMS_ID}
                className={classes.MenuList}
                component="nav"
                aria-label="top menu"
            >
                {allowedMenuItems.map((topMenuItem) => (
                    <MenuListItem
                        key={`top-menu-item_${topMenuItem.id}`}
                        menuItem={topMenuItem}
                        isCollapsed={isCollapsed}
                        activeBreadcrumbMenuItemIds={activeBreadcrumbMenuItemIds}
                        selectedBreadcrumbMenuItemIds={selectedBreadcrumbMenuItemIds}
                    />
                ))}
            </List>
        </div>
    );

    function animateTopMenuShift() {
        const fromWidth = determineTopMenuWidth(prevShowTopMenu, prevIsCollapsed);
        const toWidth = determineTopMenuWidth(showTopMenu, isCollapsed);

        if (fromWidth !== toWidth) {
            gsap.to(toIdSelector(TOP_MENU_ITEMS_ID), {
                display: 'none',
                duration: 0,
            });
            gsap.to(toIdSelector(TOP_MENU_ITEMS_ID), {
                display: 'initial',
                duration: 0.1,
                delay: DURATION_IN_SECS.MENU_SHIFT - 0.1,
            });
        }

        gsap.fromTo(toIdSelector(TOP_MENU_ID), { width: fromWidth }, {
            width: toWidth,
            duration: DURATION_IN_SECS.MENU_SHIFT,
        });
    }
}

function determineTopMenuWidth(showTopMenu: boolean, isCollapsed: boolean) {
    if (!showTopMenu) {
        return 0;
    }
    if (isCollapsed) {
        return SIZES.MENU.COLLAPSED_WIDTH;
    }
    return SIZES.MENU.TOP_WIDTH;
}
