import { ITraceableApiErrorBase } from '@snipsonian/core/cjs/typings/apiErrors';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { IBaseServerErrorBody } from '@typsy/errors/dist/models/serverError.models';
import { BaseErrorCategory } from '@typsy/errors/dist/models/errorCategory';

/** should at least contain the values of BaseErrorCode (see @typsy/errors) */
export enum BaseApiErrorCode {
    /* === Authentication / Authorisation === */
    API_KEY_HEADER_UNKNOWN_OR_INVALID = 'API_KEY_HEADER_UNKNOWN_OR_INVALID',
    API_KEY_INVALID = 'API_KEY_INVALID',
    AUTH_HEADER_UNKNOWN_OR_INVALID = 'AUTH_HEADER_UNKNOWN_OR_INVALID',
    AUTH_TOKEN_INVALID = 'AUTH_TOKEN_INVALID',
    AUTH_NOT_ENOUGH_PERMISSIONS = 'AUTH_NOT_ENOUGH_PERMISSIONS',
    DISABLED_FEATURE = 'DISABLED_FEATURE',

    /* === Generic === */
    NOT_FOUND = 'NOT_FOUND',
    RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED',
    UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    PRECONDITION_ERROR = 'PRECONDITION_ERROR',
    INPUT_MISMATCH = 'INPUT_MISMATCH',
    DATABASE_ERROR = 'DATABASE_ERROR',
    CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
    EXTERNAL_ERROR = 'EXTERNAL_ERROR',

    /* === Specific codes === */
    NO_OPTIMIZATION_ORDERS_AVAILABLE = 'NO_OPTIMIZATION_ORDERS_AVAILABLE',
    JSON_PATCH_OPERATION_NOT_SUPPORTED = 'JSON_PATCH_OPERATION_NOT_SUPPORTED',
    PUBLIC_INSTRUMENT_ISIN_UNKNOWN = 'PUBLIC_INSTRUMENT_ISIN_UNKNOWN',
    USER_NEEDS_UNIQUE_EMAIL = 'USER_NEEDS_UNIQUE_EMAIL',
    USER_NEEDS_UNIQUE_PHONE = 'USER_NEEDS_UNIQUE_PHONE',
    ENTITY_STILL_USED = 'ENTITY_STILL_USED',
    ENTITY_NEEDS_UNIQUE_EXTERNAL_ID = 'ENTITY_NEEDS_UNIQUE_EXTERNAL_ID',
    ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
    RESOURCES_NOT_READY = 'RESOURCES_NOT_READY',
    OPTIMIZATION_MISSING = 'OPTIMIZATION_MISSING',
}

/** This is the (base) error received on the client side !!! */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseApiErrorClientSide<ErrorCode extends string = BaseApiErrorCode, ErrorData = TAnyObject>
    extends ITraceableApiErrorBase<IBaseApiErrorBody<ErrorCode, ErrorData>> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBaseApiErrorBody<ErrorCode extends string = BaseApiErrorCode, ErrorData = TAnyObject>
    extends IBaseServerErrorBody<ErrorCode, ErrorData, BaseErrorCategory, ApiErrorOrigin> {}

export enum ApiErrorOrigin {
    CoreApi = 'ConsoleApi', // TODO rename var
    ConsoleBff = 'ConsoleBff',
    CmsApi = 'CmsApi',
    ReportingApi = 'ReportingApi',
}
