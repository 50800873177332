import {
    ConsoleApiSpecificErrorCode,
    TConsoleApiErrorClientSide,
} from '@typsy/console-api-client/dist/models/consoleApiError.models';
import { doesApiErrorContainData } from '../../utils/error/clientSideConsoleApiErrorUtils';
import {
    IApiEntityListResponse,
    IBaseSingleEntityApiInput,
} from '../../models/api.models';
import {
    IFetchPublicInstrumentsApiInput,
    IPublicInstrumentEntityData,
    ISinglePublicInstrumentApiInput,
    TPublicInstrument,
    TPublicInstrumentsData,
    TTPublicInstrumentPatch,
    TTPublicInstrumentCreate,
} from '../../models/thematicSearch/publicInstrument.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchPublicInstruments({
    offset,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    name,
    externalId,
    count,
}: IFetchPublicInstrumentsApiInput = {}) {
    return get<TPublicInstrumentsData, IApiEntityListResponse<IPublicInstrumentEntityData>>({
        url: CoreApiPath.PUBLIC_INSTRUMENTS,
        queryParams: {
            offset,
            limit,
            ...fetchApiEntityUrlParamBuilder()
                .contains({ field: 'external_id', value: externalId })
                .contains({ field: 'name', value: name })
                .build(),
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function patchPublicInstrument({
    id,
    ...patchFields
}: TTPublicInstrumentPatch) {
    return patch<TPublicInstrument>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_DETAILS,
        pathParams: {
            instrumentId: id,
        },
        body: patchFields,
        enhanceError: enhancePublicInstrumentApiError,
    });
}

export function createPublicInstrument(publicInstrumentToCreate: TTPublicInstrumentCreate) {
    return post<TPublicInstrument>({
        url: CoreApiPath.PUBLIC_INSTRUMENTS,
        body: publicInstrumentToCreate,
        enhanceError: enhancePublicInstrumentApiError,
    });
}

function enhancePublicInstrumentApiError(error: TConsoleApiErrorClientSide): TConsoleApiErrorClientSide {
    if (doesApiErrorContainData(error, {
        locEndsWith: 'external_id',
        msgContains: 'ISIN value is invalid',
    })) {
        // eslint-disable-next-line no-param-reassign
        error.response.code = ConsoleApiSpecificErrorCode.PUBLIC_INSTRUMENT_ISIN_UNKNOWN;
    }

    return error;
}

export function deletePublicInstrument({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_DETAILS,
        pathParams: {
            instrumentId: id,
        },
    });
}

export function fetchPublicInstrumentDetails({ publicInstrumentId }: ISinglePublicInstrumentApiInput) {
    return get<TPublicInstrument>({
        url: CoreApiPath.PUBLIC_INSTRUMENT_DETAILS,
        pathParams: {
            instrumentId: publicInstrumentId,
        },
    });
}
