import React from 'react';
import { IColValues, IDataItem, TDataColumns, TDataItemId } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IAdvancedPublicInstrumentGroupsFilters,
    TPublicInstrumentGroup,
} from '@console/core-api/models/thematicSearch/publicInstrumentGroup.models';
import { UiPageKey } from 'models/state/ui.models';
import {
    publicInstrumentGroupsEntity,
    triggerFetchPublicInstrumentGroups,
} from 'state/entities/thematicSearch/publicInstrumentGroups';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import DataTable from 'views/common/list/DataTable';
import ListPageForApiEntity, { TSetStateOnPageNrChange } from 'views/common/list/ListPageForApiEntity';
import { IRenderAdvancedFiltersProps } from 'views/common/list/DataSearch';
import InputTextField, { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import InputGroup from 'views/common/inputs/base/InputGroup';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { DEFAULT_PUBLIC_INSTRUMENT_GROUP_TAB_KEY } from 'config/tabs.config';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';

const LIST_TRANSLATION_PREFIX = 'thematic_search.public_instrument_groups.list';
const COL_TRANSLATION_PREFIX = `${LIST_TRANSLATION_PREFIX}.columns`;
const FILTERS_TRANSLATION_PREFIX = `${LIST_TRANSLATION_PREFIX}.filters`;

interface IPublicInstrumentGroupCols extends IColValues {
    name: string;
    category: string;
    externalId: string;
    updated: string;
}

const COLS: TDataColumns<IPublicInstrumentGroupCols> = {
    name: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.name`,
        },
        data: {
            className: UtilityClass.table.cellBold,
        },
        percentWidth: 30,
    },
    category: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.category`,
        },
        percentWidth: 30,
    },
    externalId: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.external_id`,
        },
        percentWidth: 25,
    },
    updated: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.updated`,
        },
        percentWidth: 15,
    },
};

const INITIAL_ADVANCED_PUBLIC_INSTRUMENT_GROUPS_FILTERS: IAdvancedPublicInstrumentGroupsFilters = {
    name: '',
    externalId: '',
    category: '',
};

export function PublicInstrumentGroupsList() {
    const setStateOnPageNrChange: TSetStateOnPageNrChange = (pageNr) => ({
        toState: (draftState) => {
            // eslint-disable-next-line no-param-reassign
            draftState.entities.publicInstrumentGroups.data.pageNr = pageNr;
        },
        notificationsToTrigger: [StateChangeNotification.PUBLIC_INSTRUMENT_GROUPS_DATA],
    });

    return (
        <ListPageForApiEntity
            notifications={[
                StateChangeNotification.PUBLIC_INSTRUMENT_GROUPS_DATA,
                StateChangeNotification.PUBLIC_INSTRUMENT_GROUPS_UI_VARS,
            ]}
            asyncListEntity={publicInstrumentGroupsEntity}
            asyncListEntityFetchTrigger={triggerFetchPublicInstrumentGroups}
            setStateOnPageNrChange={setStateOnPageNrChange}

            uiPageKey={UiPageKey.publicInstrumentGroupsList}
            notificationToTriggerOnUiVarChanges={StateChangeNotification.PUBLIC_INSTRUMENT_GROUPS_UI_VARS}
            box={{
                title: 'thematic_search.public_instrument_groups.title',
            }}
            list={{
                renderData: renderPublicInstrumentGroupsList,
            }}
            search={{
                simple: {
                    tipTranslationKey: `${FILTERS_TRANSLATION_PREFIX}.simple.tip`,
                    mapSimpleSearchInputToFetchFilter,
                },
                advanced: {
                    initialValues: INITIAL_ADVANCED_PUBLIC_INSTRUMENT_GROUPS_FILTERS,
                    renderFilters: renderAdvancedPublicInstrumentGroupsFilters,
                },
            }}
            create={{
                toRoute: {
                    routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_ADD,
                },
                tooltip: `${LIST_TRANSLATION_PREFIX}.actions.create`,
            }}
        />
    );

    function renderAdvancedPublicInstrumentGroupsFilters({
        filterValues,
        onChangeFilterValue,
    }: IRenderAdvancedFiltersProps<IAdvancedPublicInstrumentGroupsFilters>) {
        return (
            <InputGroup>
                <InputWrapper
                    label={`${FILTERS_TRANSLATION_PREFIX}.advanced.name.label`}
                >
                    <InputTextField
                        value={filterValues.name}
                        onChange={changeInputHandler('name')}
                        placeholder={`${FILTERS_TRANSLATION_PREFIX}.advanced.name.tip`}
                    />
                </InputWrapper>
                <InputWrapper
                    label={`${FILTERS_TRANSLATION_PREFIX}.advanced.external_id.label`}
                >
                    <InputTextField
                        value={filterValues.externalId}
                        onChange={changeInputHandler('externalId')}
                        placeholder={`${FILTERS_TRANSLATION_PREFIX}.advanced.external_id.tip`}
                    />
                </InputWrapper>
                <InputWrapper
                    label={`${FILTERS_TRANSLATION_PREFIX}.advanced.category.label`}
                >
                    <InputTextField
                        value={filterValues.category}
                        onChange={changeInputHandler('category')}
                        placeholder={`${FILTERS_TRANSLATION_PREFIX}.advanced.category.tip`}
                    />
                </InputWrapper>
            </InputGroup>
        );
        function changeInputHandler(fieldName: string) {
            return ({ value }: IOnChangeTextInputProps) => onChangeFilterValue({
                [fieldName]: value,
            });
        }
    }

    function mapSimpleSearchInputToFetchFilter(simpleInput: string) {
        return {
            name: simpleInput,
        };
    }

    function renderPublicInstrumentGroupsList({ data }: { data: TPublicInstrumentGroup[] }) {
        if (!data) {
            return null;
        }

        const publicInstrumentGroupItems: IDataItem<IPublicInstrumentGroupCols>[] =
            data.map((publicInstrumentGroup) => ({
                id: publicInstrumentGroup.id,
                colValues: {
                    name: getDefaultTranslationFromApiLabel(publicInstrumentGroup.name),
                    category: getDefaultTranslationFromApiLabel(publicInstrumentGroup.category),
                    externalId: publicInstrumentGroup.external_id,
                    updated: formatDateRelativeToNow({ date: publicInstrumentGroup.version_datetime }),
                },
            }));

        return (
            <DataTable
                cols={COLS}
                items={publicInstrumentGroupItems}
                onItemRowClicked={(publicInstrument) => openPublicInstrumentGroupDetail(publicInstrument.id)}
            />
        );

        function openPublicInstrumentGroupDetail(publicInstrumentGroupId: TDataItemId) {
            redirectTo({
                routeKey: ROUTE_KEY.R_PUBLIC_INSTRUMENT_GROUP_DETAIL,
                params: {
                    publicInstrumentGroupId,
                    publicInstrumentGroupTab: DEFAULT_PUBLIC_INSTRUMENT_GROUP_TAB_KEY,
                },
            });
        }
    }
}
