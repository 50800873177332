import React from 'react';
import { SchemaErrorType } from '@console/common/utils/schema';
import { PolicyAllocationCategory } from '@console/core-api/models/portfolioMgmt/policy.models';
import { II18nLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { allocationCategoriesList } from 'config/portfolioMgmt/policySettings.config';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import {
    getInfeasiblePolicyAllocationCategoriesIfAny,
    isOverallValidationErrorForAllocationBoundariesSection,
} from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { makeStyles } from 'views/styling';
import Text from 'views/common/widget/Text';
import { IOverallValidationError } from 'views/common/inputs/extended/ExtendedInputForm';
import {
    IPolicyAllocationBoundariesFormValues,
} from 'views/portfolioMgmt/Policies/PolicyDetail/policyFormContents/types';

interface IPublicProps {
    overallValidationError: IOverallValidationError;
}

const useStyles = makeStyles((theme) => ({
    InfeasiblePolicyBoundariesWarning: {
        color: APP_COLORS.FEEDBACK.ERROR,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
}));

export default function InfeasibleAllocationCategoryBoundariesWarning({
    overallValidationError,
}: IPublicProps) {
    const classes = useStyles();

    if (!isOverallValidationErrorForAllocationBoundariesSection(overallValidationError)) {
        return null;
    }

    const translator = getTranslator(getStore().getState());
    const { msg, placeholders } = overallValidationError.error as II18nLabel;
    const {
        categoriesWhereLowerBoundariesAddUpToMoreThan100Percent,
        categoriesWhereUpperBoundariesAddUpToLessThan100Percent,
    } = getInfeasiblePolicyAllocationCategoriesIfAny(
        placeholders.originalValue as unknown as IPolicyAllocationBoundariesFormValues,
    );
    const readableInfeasibleCategories = convertInfeasibleCategoryBoundariesToReadableFormat(
        overallValidationError.errorType === SchemaErrorType.PolicyAllocationInfeasibleLowerBoundaries
            ? categoriesWhereLowerBoundariesAddUpToMoreThan100Percent
            : categoriesWhereUpperBoundariesAddUpToLessThan100Percent,
    );

    return (
        <div className={classes.InfeasiblePolicyBoundariesWarning}>
            <Text
                label={{
                    msg,
                    placeholders: {
                        allocationCategories: readableInfeasibleCategories,
                    },
                }}
            />
        </div>
    );

    function convertInfeasibleCategoryBoundariesToReadableFormat(
        infeasibleCategories: PolicyAllocationCategory[],
    ) {
        return infeasibleCategories
            .map((category) => getTranslatedAllocationCategoryLabel(category))
            .join(', ');
    }

    function getTranslatedAllocationCategoryLabel(allocationCategory: PolicyAllocationCategory) {
        return translator(allocationCategoriesList.find(({ value }) => value === allocationCategory).label);
    }
}
