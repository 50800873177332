import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import * as ConsoleCommon from '@console/common/export';
import {
    AggregateRoboAumBy,
    IRoboAumReport,
    IRoboAumReportTimeSeriesItem,
} from '../../../models/reports/robo/roboAumReport.models';
import { baseTimeSeriesItemSchema, fetchReportCommonApiBodySchema, idToNameMapSchema } from './commonReporting.schemas';

const ALL_AGGREGATE_ROBO_AUM_BYS = Object.values(AggregateRoboAumBy);

export const aggregateRoboAumBySchema = decorateSchema(
    ConsoleCommon.schema.string()
        .oneOf(ALL_AGGREGATE_ROBO_AUM_BYS) as ConsoleCommon.schema.StringSchema<AggregateRoboAumBy>,
    {
        description: 'On which entity the AUM has to be aggregated on.',
        enumValues: ALL_AGGREGATE_ROBO_AUM_BYS,
    },
);

export const fetchRoboAumReportBodySchema = ConsoleCommon.schema.object({
    aggregateBy: aggregateRoboAumBySchema.required(),
}).concat(fetchReportCommonApiBodySchema);

const roboAumReportTimeSeriesItemSchema = ConsoleCommon.schema.object({
    items: ConsoleCommon.schema.array().of(
        decorateSchema(
            ConsoleCommon.schema.object({
                id: ConsoleCommon.schema.string().required(),
                amount: ConsoleCommon.schema.number().required(),
            }).required(),
            // eslint-disable-next-line max-len
            { description: 'Captures the amount=AUM that corresponds to the id (which can be a goal/horizon/policy) on the given datetime.' },
        ),
    ).required(),
}).concat(baseTimeSeriesItemSchema) as ConsoleCommon.schema.ObjectSchema<IRoboAumReportTimeSeriesItem>;

export const roboAumReportSchema = ConsoleCommon.schema.object({
    time_series: decorateSchema(
        ConsoleCommon.schema.array().of(roboAumReportTimeSeriesItemSchema).required(),
        { description: 'Array of ascending data points, each containing a datetime and a list of id-amount items.' },
    ),
    id_to_name_map: idToNameMapSchema,
}) as ConsoleCommon.schema.ObjectSchema<IRoboAumReport>;
