import React from 'react';
import { APP_COLORS } from 'config/styling/colors';
import { AxisDomain } from 'utils/libs/d3';
import { IVerticalMarkerData, TXScale, TYScale } from './types';

interface IVerticalMarkerProps<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number> {
    id?: string;
    xScale: TXScale<XDomain>;
    yScale: TYScale<YDomain>;
    markerData: IVerticalMarkerData<XDomain, YDomain>;
    markerLineColor?: string;
    markerPointBackgroundColor?: string; /* default white */
    markerSize?: 'S' | 'M'; /* default M (= medium) */
    chartHeight: number;
    showLine?: boolean;
    classNamePrefix?: string;
}

export const CLASSES = {
    BASE: 'VerticalMarker',
};

export default function VerticalMarker<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number>({
    id,
    xScale,
    yScale,
    markerData,
    markerLineColor,
    markerPointBackgroundColor = APP_COLORS.SYSTEM.WHITE,
    markerSize = 'M',
    chartHeight,
    showLine = true,
    classNamePrefix,
}: IVerticalMarkerProps<XDomain, YDomain>) {
    const xCoordinate = xScale(markerData.x);

    const markerStrokeWidth = markerSize === 'M'
        ? 4
        : 3;
    const markerRadius = markerSize === 'M'
        ? 6
        : 4;

    return (
        <g
            id={id}
            className={`${classNamePrefix || ''}${CLASSES.BASE}`}
        >
            {showLine && (
                <line
                    x1={xCoordinate}
                    y1={0}
                    x2={xCoordinate}
                    // y2={yScale(yScale.domain()[0])}
                    y2={chartHeight}
                    stroke={markerLineColor}
                    strokeWidth={2}
                />
            )}

            {markerData.y.map((yMarker, index) => {
                const key = `vertical-marker-point_${index}`;

                return (
                    <circle
                        key={key}
                        className={`${classNamePrefix || ''}${yMarker.className}`}
                        cx={xCoordinate}
                        cy={yScale(yMarker.val)}
                        strokeWidth={markerStrokeWidth}
                        stroke={yMarker.color}
                        fill={markerPointBackgroundColor}
                        r={markerRadius}
                    />
                );
            })}
        </g>
    );
}
