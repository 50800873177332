import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { joinParts } from '@console/common/utils/string/joinParts';
import {
    doUserPermissionsCoverRequiredPermissions,
    TRequiredPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/doUserPermissionsCoverRequiredPermissions';
import { IState } from 'models/state.models';
import { getStore } from 'state';
import { authService } from 'state/auth/auth.service';
import { getFirstLetterIfSet } from 'utils/string/getFirstLetterIfSet';

// TODO: trigger this periodically to verify that the session is not expired?
//       or not needed as the back-end will also do the check and return with an error if expired?
//       maybe combine this with a session warning check?
export const isAuthenticatedOidc = (): Promise<boolean> => authService.isAuthenticated().then(({ isLoggedIn }) => isLoggedIn);

export const getAuthenticatedUser = (state: IState = getStore().getState()) => state.auth.authenticatedUser;

export const isAuthenticatedUser = (state: IState): boolean => isSet(state.auth.authenticatedUser);

export const getUserId = (state: IState) => {
    const user = getAuthenticatedUser(state);

    if (!user) {
        return null;
    }

    return user.ulid;
};

export const getUserTenant = (state: IState) => {
    const user = getAuthenticatedUser(state);

    if (!user) {
        return null;
    }

    return user.tenant;
};

export const getUserPermissions = (state?: IState) => {
    const user = getAuthenticatedUser(state);

    if (!user || !user.permissions) {
        return {};
    }

    return user.permissions;
};

export const getUserDisplayName = (state: IState) => {
    const user = getAuthenticatedUser(state);

    if (!user) {
        return null;
    }

    return joinParts([user.firstName, user.lastName]);
};

export const getUserInitials = (state: IState) => {
    const user = getAuthenticatedUser(state);

    if (!user) {
        return null;
    }

    const firstLetters = [];

    if (isSetString(user.firstName)) {
        firstLetters.push(
            getFirstLetterIfSet(user.firstName),
            getFirstLetterIfSet(user.lastName),
        );
    } else if (isSetString(user.lastName)) {
        const lastNamePartsMax2 = user.lastName.split(' ', 2);

        firstLetters.push(
            ...lastNamePartsMax2.map((part) => getFirstLetterIfSet(part)),
        );
    }

    return joinParts(firstLetters, {
        joinSeparator: '',
    }).toUpperCase();
};

export const getUserAccessToken = (state: IState) => {
    const user = getAuthenticatedUser(state);

    if (!user) {
        return null;
    }

    return user.accessToken;
};

export const hasUserRequiredPermissions = (
    state: IState,
    requiredPermissions: TRequiredPermissions,
): boolean => {
    const userPermissions = getUserPermissions(state);

    return doUserPermissionsCoverRequiredPermissions({
        userPermissions,
        requiredPermissions,
    });
};

export const getLoginRedirectUrl = (state: IState) => state.auth.loginRedirectUrl;

export const getAuthFlowStatus = (state: IState) => state.auth.flowStatus;
