import { IncomingHttpHeaders } from 'http';
import * as ConsoleCommon from '@console/common/export';

export type TReportCategory = 'robo' | 'self';
export type TReportLevel = 'total' | 'per_advisor';

export type TFetchReportCommonApiInputClient = TFetchReportCommonApiBody<string>;

export type TFetchReportCommonApiBody<DateType = string> = {
    frequency: DataPointFrequency;
    period: ConsoleCommon.IDatePeriod<DateType>;
    strictPeriod?: ConsoleCommon.IDatePeriod<DateType>;
};

export type TFetchReportWithOptionalReferencePeriodApiInputClient
    = TFetchReportWithOptionalReferencePeriodApiBody<string>;

export type TFetchReportWithOptionalReferencePeriodApiBody<DateType = string> = TFetchReportCommonApiBody<DateType> & {
    referencePeriod?: ConsoleCommon.IDatePeriod<DateType>;
    includeAverageYtd?: boolean;
};

export enum DataPointFrequency {
    Day = 'D',
    Week = 'W',
    Month = 'M',
    Quarter = 'Q',
    Year = 'Y',
}

export interface ITimeSeriesItemBase {
    datetime: string;
}

export interface IIdToNameMap {
    [id: string]: string;
}

export interface IAumDistributionPart {
    name: string; /* the name/code of the AUM part, which can be a goal name, or an asset type, etc. */
    amount: number;
}

export interface ITimeSeriesReportBase<TimeSeriesItem extends ITimeSeriesItemBase> {
    time_series: TimeSeriesItem[];
}

export interface ITimeSeriesReportWithRefPeriodAndAverageYtd<TimeSeriesItem extends ITimeSeriesItemBase, DataPoint>
    extends ITimeSeriesReportBase<TimeSeriesItem> {
    reference_period?: DataPoint;
    average_ytd?: DataPoint;
}

export type TAcceptLanguageReportApiHeaders = Pick<IncomingHttpHeaders, 'accept-language'>;
