import isNumber from '@snipsonian/core/cjs/is/isNumber';
import {
    object, array, string, integer, percentageMax1, NumberSchema, ObjectSchema,
} from '@console/common/utils/schema';
import { getRiskProfilesScoreRangeConfig, getMultiTranslationsLabelSchema } from 'state/appConfig/selectors';
import { IState } from 'models/state.models';

const fraction = () => percentageMax1().required();

export function getScoreRangeMinMax() {
    if (!getRiskProfilesScoreRangeConfig()) {
        throw new Error('A required setting is not available.');
    }

    return {
        MIN: getRiskProfilesScoreRangeConfig().min,
        MAX: getRiskProfilesScoreRangeConfig().max,
    };
}

export const getExpectedAssetClassSchema = (stateForTestPurposes?: IState) =>
    object({
        name: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
        allocation: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
        // .format(/^[0-9]{1,3}(-[0-9]{1,3})*%$/, '00% | 00-00%'),
    });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let riskProfileDetailsSchema: ObjectSchema<any> = null;

export function getRiskProfileDetailsSchema(stateForTestPurposes?: IState) {
    if (!riskProfileDetailsSchema) {
        const SCORE_RANGE_EXTREMES = getScoreRangeMinMax();

        const scoreRange = () => integer().required()
            .minVal(SCORE_RANGE_EXTREMES.MIN)
            .maxVal(SCORE_RANGE_EXTREMES.MAX);

        riskProfileDetailsSchema = object({
            name: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
            description: getMultiTranslationsLabelSchema(stateForTestPurposes).required(),
            scoreRangeMin: scoreRange(),
            scoreRangeMax: scoreRange()
                .when('scoreRangeMin', ([scoreRangeMin]: number[], schema: NumberSchema) => {
                    if (isNumber(scoreRangeMin)) {
                        return schema.minVal(Math.min(scoreRangeMin, SCORE_RANGE_EXTREMES.MAX));
                    }
                    return schema;
                }),
            expectedRiskFraction: fraction(),
            expectedReturnFraction: fraction(),
            expectedAssetClasses: array().of(getExpectedAssetClassSchema())
                .unique({ uniqueLabelForDisplay: 'asset class name', subFieldToCheck: 'name' })
                .minItems(1), // TODO is there indeed a 1 limit ?
            externalId: string(),
            imageUrl: string().url(),
        }).required();
    }

    return riskProfileDetailsSchema;
}

export function getRiskProfilePropertiesDetailsSchema() {
    return getRiskProfileDetailsSchema().pick([
        'name', 'description', 'externalId', 'imageUrl',
        'scoreRangeMax', 'scoreRangeMin', 'expectedRiskFraction', 'expectedReturnFraction',
    ]);
}

export function getRiskProfileAssetClassesSchema() {
    return getRiskProfileDetailsSchema().pick(['expectedAssetClasses']);
}
