import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { IBaseApiEntity, TApiEntityId } from '@console/core-api/models/api.models';
import {
    CORE_API_ENTITY_ID_PREFIX_TO_TYPE, CORE_API_ENTITY_TYPE_TO_MODIFY_PERMISSION_MAP,
    CoreApiEntityType,
    ENTITY_ID_REGEX,
} from '@console/core-api/config/coreEntities.config';
import { CORE_API_ENTITY_TYPE_TO_SINGULAR_ENTITY_NAME_TRANSLATION_MAP } from 'config/coreEntities.ui.config';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import { flashErrorInvalidEntityId } from 'state/entities/entitiesFlashDispatcher';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';

export function getEntityTypeBasedOnId(entityId: TApiEntityId): CoreApiEntityType {
    if (!isSetString(entityId)) {
        return null;
    }

    return CORE_API_ENTITY_ID_PREFIX_TO_TYPE[entityId.substr(0, 1)];
}

export function getEntityTypeSingularNameBasedOnId(entityId: TApiEntityId): string {
    if (!isSetString(entityId)) {
        return null;
    }
    const translator = getTranslator(getStore().getState());

    return translator(CORE_API_ENTITY_TYPE_TO_SINGULAR_ENTITY_NAME_TRANSLATION_MAP[getEntityTypeBasedOnId(entityId)]);
}

export function isUserEntityId(entityId: TApiEntityId): boolean {
    return getEntityTypeBasedOnId(entityId) === CoreApiEntityType.users;
}

export function isEntityIdValid(entityId: string): boolean {
    return entityId.search(ENTITY_ID_REGEX) > -1;
}

export function isEntityIdValidForEntityType({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: CoreApiEntityType;
}): boolean {
    if (!isEntityIdValid(entityId)) {
        return false;
    }

    return getEntityTypeBasedOnId(entityId) === entityType;
}

export function validateEntityIdBeforeFetch({
    entityId,
    entityType,
}: {
    entityId: string;
    entityType: CoreApiEntityType;
}): boolean {
    const isIdValid = isEntityIdValidForEntityType({ entityId, entityType });

    if (!isIdValid) {
        flashErrorInvalidEntityId(entityId);
    }

    return isIdValid;
}

export function getDefaultModifyPermissionOfEntity(
    apiEntity: IBaseApiEntity,
): OperationPermissionKey {
    if (apiEntity) {
        const entityType = getEntityTypeBasedOnId(apiEntity.id);

        return CORE_API_ENTITY_TYPE_TO_MODIFY_PERMISSION_MAP[entityType];
    }

    return null;
}
