import { ONE_MINUTE_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { RESPONSE_TYPES } from '@snipsonian/axios/cjs/request/types';
import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import {
    ConsoleApiSpecificErrorCode,
    TConsoleApiErrorClientSide,
} from '@typsy/console-api-client/dist/models/consoleApiError.models';
import { doesApiErrorContainData } from '../../utils/error/clientSideConsoleApiErrorUtils';
import {
    IFetchUsersApiInput,
    IUserEntityData,
    TUsersData,
    TUser,
    ISingleUserApiInput,
    IGroupThatUserBelongsTo,
    TTUserPatch,
    TTUserCreate,
} from '../../models/userMgmt/user.models';
import {
    IApiEntityListResponse,
    IBaseSingleEntityApiInput,
    IDownloadableDocument,
} from '../../models/api.models';
import { DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR } from '../../config/coreApi.config';
import { getDownloadFileResponseMapper } from '../../utils/file/fileDownloadUtils';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import { CLIENT_FEATURE_FLAGS } from '../../config/user.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { toExactMatch } from '../../utils/fetch/customQueryParamValueFormatter';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchUsers({
    virtualIban,
    email,
    externalId,
    phoneNumber,
    brokerageUserId,
    lastName,
    offset,
    isClientSearch = false,
    userIds = null,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    featureFlags = null,
    orderBy,
    count,
}: IFetchUsersApiInput = {}) {
    return get<TUsersData, IApiEntityListResponse<IUserEntityData>>({
        url: CoreApiPath.USERS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                // .contains({ field: 'email', value: email })
                .exactMatch({ field: 'email', value: email })
                .exactMatch({ field: 'external_id', value: externalId })
                .exactMatch({ field: 'brokerage_user_id', value: brokerageUserId })
                // .contains({ field: 'phone', value: phoneNumber })
                .exactMatch({ field: 'phone', value: phoneNumber })
                // .contains({ field: 'last_name', value: lastName })
                .exactMatch({ field: 'last_name', value: lastName })
                .fieldIn({ field: 'id', value: userIds })
                .fieldIn({ field: 'feature_flags', value: isClientSearch ? CLIENT_FEATURE_FLAGS : null })
                .arrayContains({ field: 'feature_flags', value: isArrayWithValues(featureFlags) ? featureFlags : null })
                .orderBy(orderBy)
                .build(),
            iban: toExactMatch(virtualIban),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
    });
}

export function fetchUserDetails({
    userId,
    underlyingApiRequestConfig,
}: ISingleUserApiInput & IUnderlyingRequestApiInput) {
    return get<TUser>({
        url: CoreApiPath.USER_DETAILS,
        pathParams: {
            userId,
        },
        ...underlyingApiRequestConfig,
    });
}

export function fetchUserGroupMembership({
    userId,
    underlyingApiRequestConfig,
}: ISingleUserApiInput & IUnderlyingRequestApiInput) {
    return get<IGroupThatUserBelongsTo[]>({
        url: CoreApiPath.USER_GROUP_MEMBERSHIP,
        pathParams: {
            userId,
        },
        ...underlyingApiRequestConfig,
    });
}

/**
 * TODO: Would be better to make this call asynchronous (incl. notification) instead of a large timeout
 * so that the user does not have to wait.
 * Also "exportPortfolios".
 */
export function exportUsers() {
    return get<IDownloadableDocument, Blob>({
        url: CoreApiPath.USERS_EXPORT,
        headers: {
            Accept: 'text/csv',
        },
        responseType: RESPONSE_TYPES.blob,
        timeoutInMillis: 5 * ONE_MINUTE_IN_MILLIS,
        mapResponse: getDownloadFileResponseMapper('users.csv'),
    });
}

export function patchUser({
    id,
    ...patchFields
}: TTUserPatch) {
    return patch<TUser>({
        url: CoreApiPath.USER_DETAILS,
        pathParams: {
            userId: id,
        },
        body: patchFields,
        enhanceError: enhanceUserApiError,
    });
}

export function createUser(userToCreate: TTUserCreate) {
    return post<TUser>({
        url: CoreApiPath.USERS,
        body: userToCreate,
        enhanceError: enhanceUserApiError,
    });
}

export function deleteUser({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.USER_DETAILS,
        pathParams: {
            userId: id,
        },
    });
}

function enhanceUserApiError(error: TConsoleApiErrorClientSide): TConsoleApiErrorClientSide {
    if (doesApiErrorContainData(error, {
        locEndsWith: 'phone',
        msgContains: 'must be unique',
    })) {
        // eslint-disable-next-line no-param-reassign
        error.response.code = ConsoleApiSpecificErrorCode.USER_NEEDS_UNIQUE_PHONE;
    } else if (doesApiErrorContainData(error, {
        locEndsWith: 'email',
        msgContains: 'must be unique',
    })) {
        // eslint-disable-next-line no-param-reassign
        error.response.code = ConsoleApiSpecificErrorCode.USER_NEEDS_UNIQUE_EMAIL;
    }

    return error;
}
