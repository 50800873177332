import React from 'react';
import clsx from 'clsx';
import Checkbox from '@mui/material/Checkbox';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TLabel } from 'models/general.models';
import { FOCUS_OUTLINE, FOCUS_OUTLINE_OFFSET } from 'config/styling/elevation';
import { APP_COLORS } from 'config/styling/colors';
import { hexToRgba } from 'utils/styling/colorUtils';
import { makeStyles, mixins } from 'views/styling';
import { CheckboxOffIcon, CheckboxOnIcon, ReadOnlyIcon, SuccessIcon } from 'views/common/icons';
import Text from 'views/common/widget/Text';

export interface IInputCheckboxFieldProps<Name extends string = string> {
    id?: string;
    className?: string;
    name: Name;
    checked?: boolean;
    onChange: (onChangeProps: IOnChangeCheckboxProps<Name>) => void;
    error?: TLabel;
    emphasizeError?: boolean; // default true - if true, then the error will be shown in red
    disabled?: boolean;
    description?: TLabel;
    // this will override the description and display 'Yes' , 'No', default false
    shouldIncludeTextValueDescription?: boolean;
    showValueIndicatorsIfDisabled?: boolean; // default false
}

export interface IOnChangeCheckboxProps<Name extends string = string> {
    name: Name;
    checked: boolean;
}

const useStyles = makeStyles((theme) => ({
    InputCheckboxWrapper: {
        ...mixins.flexColTopLeft(),

        '& .__errorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.TEXT['300'] }),
            lineHeight: 1.2,
            paddingTop: theme.spacing(1),
        },
        '&.__has-error .__errorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.FEEDBACK.ERROR }),
        },
    },
    InputCheckboxField: {
        ...mixins.flexRow({ alignMain: 'flex-start', alignCross: 'center' }),

        '& .__checkboxLabel': {
            ...mixins.typoText({ size: 15 }),
            cursor: 'pointer',
            margin: 0,
        },
        '& .__checkboxLabel.__checkboxLabel-disabled': {
            cursor: 'not-allowed',
        },
        '& .__checkboxLabel.__checkboxLabel-unchecked.__checkboxLabel-disabled': {
            ...mixins.typo({ size: 15, color: APP_COLORS.GREY['300'] }),
        },
        '& input': {
            /* was to overrule the "width: auto" causing the onChange event not to being fired (because the input
               was not behind the checkbox-svg), but not needed anymore following mui upgrade to v5 */
            // width: 'inherit',
        },
        '& svg': {
            fill: APP_COLORS.GREY['300'],
        },
        '&.__noValueIndicators svg': {
            width: 20,
            height: 20,
            stroke: hexToRgba(APP_COLORS.GREY['500'], 0.56),
            fill: APP_COLORS.TEXT['500'],
        },
        '&.__noValueIndicators:hover svg': {
            stroke: APP_COLORS.PRIMARY['500'], // for the 'off' icon
            fill: APP_COLORS.PRIMARY['500'], // for the 'on' icon
        },
        '&:focus-within:not(:hover) svg': {
            outline: FOCUS_OUTLINE,
            outlineOffset: FOCUS_OUTLINE_OFFSET,
        },
        '&.__noValueIndicators .Mui-disabled svg': {
            stroke: APP_COLORS.GREY['100'],
            fill: APP_COLORS.GREY['100'],
        },
        '& .MuiCheckbox-root': {
            backgroundColor: 'unset',
        },
        '& .MuiCheckbox-root.Mui-checked': {
            backgroundColor: 'unset',
        },
    },
}));

export default function InputCheckboxField<Name extends string = string>({
    id,
    className,
    name,
    checked = false,
    onChange,
    error,
    emphasizeError = true,
    disabled = false,
    description,
    shouldIncludeTextValueDescription = false,
    showValueIndicatorsIfDisabled = false,
}: IInputCheckboxFieldProps<Name>) {
    const classes = useStyles();
    const shouldDisplayValueIndicators = disabled && showValueIndicatorsIfDisabled;
    const checkboxId = id || name;

    return (
        <div
            className={clsx(
                classes.InputCheckboxWrapper,
                error && emphasizeError && '__has-error',
                className,
            )}
        >
            <div
                className={clsx(
                    classes.InputCheckboxField,
                    !shouldDisplayValueIndicators && '__noValueIndicators',
                )}
            >
                <Checkbox
                    id={checkboxId}
                    name={name}
                    checked={checked}
                    onChange={onChangeCheckbox}
                    // StopPropagation to prevent background elements onClick handlers
                    // being fired (f.e. list row onClick)
                    onClick={(e) => e.stopPropagation()}
                    disabled={disabled}
                    icon={shouldDisplayValueIndicators ? <ReadOnlyIcon /> : <CheckboxOffIcon />}
                    checkedIcon={shouldDisplayValueIndicators ? <SuccessIcon /> : <CheckboxOnIcon />}
                    disableRipple
                />

                {(shouldIncludeTextValueDescription || description) && (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                    <label
                        htmlFor={checkboxId}
                        className={clsx(
                            '__checkboxLabel',
                            disabled && '__checkboxLabel-disabled',
                            !checked && '__checkboxLabel-unchecked',
                        )}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {shouldIncludeTextValueDescription && (
                            <Translate
                                msg={
                                    disabled
                                        ? checked
                                            ? 'common.fields.yes'
                                            : 'common.fields.no'
                                        : 'common.fields.yes'
                                }
                            />
                        )}
                        {!shouldIncludeTextValueDescription && description && (
                            <Text label={description} />
                        )}
                    </label>
                )}
            </div>

            {error && (
                <div
                    className={clsx(
                        '__errorText',
                    )}
                >
                    <Text label={error} />
                </div>
            )}
        </div>
    );

    function onChangeCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
        onChange({
            name: event.target.name as Name,
            checked: event.target.checked,
        });
    }
}
