import React, { CSSProperties } from 'react';
import getFirstItemOfArray from '@snipsonian/core/cjs/array/filtering/getFirstItemOfArray';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { findClosestDate } from '@console/common/utils/date/dateUtils';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { formatPercentage } from 'utils/number/percentageUtils';
import { makeStyles, mixins } from 'views/styling';
import { ILineChartOptions } from 'models/lineChart.models';
import d3 from 'utils/libs/d3';
import {
    determineAxisAmountTicks,
    determineAxisTimeTicks,
    determineXTimeScale,
    determineYLinearScale,
} from 'utils/chart/chartConfig.utils';
import { determineLineChartSizeWithoutAxis } from 'utils/chart/lineChartUtils';
import { determineXValueThatCorrespondsWithClick } from 'utils/chart/chartClickUtils';
import { getElementDimensionsById } from 'utils/dom/selectorUtils';
import { CHART_STYLING, COLOR_OPACTITY_WHEN_UN_SELECTED } from 'config/styling/chart';
import { APP_COLORS } from 'config/styling/colors';
import { BOX_SHADOW } from 'config/styling/elevation';
import { ILineChartAreasData, ILineChartData, ILineDataItem, IVerticalMarkerData, IVerticalYMarker } from './types';
import Text from '../widget/Text';
import YAxis from './YAxis';
import XAxis from './XAxis';
import { YAxisLabel } from './YAxisLabel';
import PathLine from './PathLine';
import AreaBetweenTwoLines from './AreaBetweenTwoLines';
import VerticalMarker from './VerticalMarker';

export interface IGenericLineChartProps {
    id: string;
    options: ILineChartOptions;
    data: ILineChartData<Date, number>[];
    selectedAreaColor?: string;
    onSelectArea?: (color: string | null) => void; /* will be null when an area gets deselected */
    selectedDate?: Date;
    onSelectDate?: (date: Date | null) => void; /* will be null when a date gets deselected */
}

const DEFAULT_LINE_COLOR = APP_COLORS.SYSTEM.WHITE;
const DEFAULT_AREA_COLOR = APP_COLORS.GREY[300];
const ID_SUFFIX = {
    clickable_x_axis: '_clickable_x-axis',
    clickable_chart_rectangle: '_clickable_chart_rectangle',
    vertical_marker: '_vertical_marker',
};
const PADDING_BETWEEN_MARKER_AND_INFO_BOX = 10;

const useStyles = makeStyles((theme) => ({
    GenericLineChart: {
        ...mixins.flexRow({ alignMain: 'center', alignCross: 'center' }),
        width: 'inherit',
        position: 'relative',
    },
    MarkedDate: {
        position: 'absolute',
        backgroundColor: APP_COLORS.SYSTEM.WHITE,
        // opacity: 0.5,
        ...mixins.flexColTopLeft(),
        padding: theme.spacing(0.5),
        borderRadius: 4,
        boxShadow: BOX_SHADOW.DEFAULT,

        '& .__selectedDate': {
            ...mixins.typoBold({ size: 10 }),
        },
        '& table td': {
            ...mixins.typo({ size: 12 }),
        },
        '& .__label': {
            paddingRight: theme.spacing(1),
        },
    },
}));

export default function GenericLineChart({
    id,
    options,
    data: rawData,
    selectedAreaColor,
    onSelectArea,
    selectedDate,
    onSelectDate,
}: IGenericLineChartProps) {
    const classes = useStyles();
    const data = options.shouldStackLines ? getStackedData() : rawData;
    const {
        chartWidth,
        chartHeight,
    } = determineLineChartSizeWithoutAxis(options);

    const yValues = getAllYValues();
    const xValues = getAllXValues();
    const minMaxXValues = d3.extent(xValues);
    let minMaxYValues = d3.extent(yValues);

    const xAxisTicks = determineAxisTimeTicks({
        dates: xValues,
        timezone: options.axis.x.timezone,
    });
    const yAxisTicks = determineAxisAmountTicks({
        minAmount: minMaxYValues[0],
        maxAmount: minMaxYValues[1],
        tickLinesLength: chartWidth,
        autoTickValues: false,
        alwaysIncludeZeroTick: true,
        widerTickValues: true,
    });

    if (yAxisTicks.tickValues) {
        minMaxYValues = d3.extent(yAxisTicks.tickValues);
    }

    const yScale = determineYLinearScale({
        globalMinMaxAmount: minMaxYValues,
        chartHeight,
    }).nice();
    const xScale = determineXTimeScale({
        xDates: xValues,
        chartWidth,
    });

    return (
        <div
            className={classes.GenericLineChart}
            style={{
                /* we set the max-height & -width on both the svg as the surrounding div, so that, together with the
                 * width: 'inherit', both the div and the svg take as most of the available space as they can/need
                 * plus so that there is no surrounding space around the svg within the div (needed for the placement
                 * of the vertical marker box) */
                maxWidth: options.dimensions.maxWidth,
                maxHeight: options.dimensions.maxHeight,
                minWidth: options.dimensions.minWidth,
            }}
        >
            <svg
                id={id}
                style={{
                    maxWidth: options.dimensions.maxWidth,
                    maxHeight: options.dimensions.maxHeight,
                    minWidth: options.dimensions.minWidth,
                }}
                viewBox={`0 0 ${options.dimensions.maxWidth} ${options.dimensions.maxHeight}`}
                preserveAspectRatio="xMidYMid meet"
            >
                {options.axis.y.text && (
                    <g transform={`translate(0, ${options.axis.y.marginTop})`}>
                        <YAxisLabel
                            label={options.axis.y.text.label}
                            chartHeight={chartHeight}
                            paddingLeft={options.axis.y.text.paddingLeft}
                        />
                    </g>
                )}
                <g transform={`translate(${options.axis.y.width}, ${options.axis.y.marginTop})`}>
                    {onSelectDate && (
                        /* Rectangle without visible purpose but just to capture the mouse movement.
                         * When areas are shown, they will overlap this rectangle (you will only be able to click outside of this
                         * rectangle <> clicking on the areas will do something else) */
                        <rect
                            id={getClickableChartRectangleId()}
                            x={0}
                            y={0}
                            width={chartWidth}
                            height={chartHeight}
                            strokeWidth={0}
                            fill={APP_COLORS.SYSTEM.WHITE}
                            style={{
                                cursor: 'pointer',
                            }}
                            onMouseDown={onMouseClickChartRectangle}
                        />
                    )}

                    <YAxis
                        yScale={yScale}
                        {...yAxisTicks}
                        showTickLines
                        tickLineColor={CHART_STYLING.colors.neutral['400']}
                        tickLineStrokeDasharray="0.25 6"
                        tickLineStrokeLinecap="round"
                        textColor={CHART_STYLING.colors.neutral['900']}
                        textSize={10}
                        specialTickLines={[{
                            yValue: 0,
                            strokeColor: CHART_STYLING.colors.neutral['300'],
                            strokeLinecap: 'round',
                        }]}
                    />

                    <g transform={`translate(0, ${chartHeight})`}>
                        <XAxis
                            xScale={xScale}
                            textColor={CHART_STYLING.colors.neutral['900']}
                            textSize={10}
                            tickFormatter={xAxisTicks.tickFormatter}
                            tickValues={minMaxXValues}
                            clickable={onSelectDate ? {
                                id: `${id}${ID_SUFFIX.clickable_x_axis}`,
                                height: options.axis.x.height,
                                width: chartWidth,
                                onValueClicked: onClickXDate,
                            } : null}
                        />
                    </g>

                    {/* eslint-disable-next-line max-len */}
                    {options.shouldIncludeAreas && getAreasData().map(({ dataPoints, areaColor, label, key }, areaIndex) => {
                        const lastAreaDataPoint = getLastItemOfArray(dataPoints);
                        const opacity = determineOpacityBasedOnSelection(areaColor);
                        /** To avoid all the labels overlapping in case of small areas
                         * we will by default only show the label if it's relative weight
                         * is higher than a certain threshold
                         * OR if the area was selected */
                        // eslint-disable-next-line max-len
                        const relativeYHeightVsMax = Math.abs((lastAreaDataPoint.y1 - lastAreaDataPoint.y0) / minMaxYValues[0]);

                        return (
                            <g key={`line_chart_area_${key}`}>
                                <AreaBetweenTwoLines
                                    id={`${id}_area_${areaIndex}`}
                                    xScale={xScale}
                                    yScale={yScale}
                                    areaData={dataPoints}
                                    fillColor={areaColor}
                                    opacity={opacity}
                                    onAreaClicked={onSelectArea ? () => onClickArea(areaColor) : null}
                                    transitionDurationInMillis={options.transitionDurationInMillis}
                                />
                                {((areaColor === selectedAreaColor)
                                    || (!selectedAreaColor && (relativeYHeightVsMax > 0.05)))
                                && (lastAreaDataPoint.y0 !== lastAreaDataPoint.y1) && (
                                    <text
                                        transform={`translate(${options.labels.paddingLeft}, 0)`}
                                        x={xScale(lastAreaDataPoint.x)}
                                        y={yScale((lastAreaDataPoint.y0 + lastAreaDataPoint.y1) / 2)}
                                        dominantBaseline="middle"
                                        fontWeight="700"
                                        fontSize="12px"
                                        fill={areaColor}
                                        opacity={opacity}
                                    >
                                        <Text label={label} />
                                    </text>
                                )}
                            </g>
                        );
                    })}

                    {data.map(({ dataPoints, lineColor, key }, lineIndex) => (
                        <PathLine
                            id={`${id}_line_${lineIndex}`}
                            key={`line_chart_line_${key}`}
                            xScale={xScale}
                            yScale={yScale}
                            lineData={dataPoints}
                            strokeColor={lineColor || DEFAULT_LINE_COLOR}
                            transitionDurationInMillis={options.transitionDurationInMillis}
                        />
                    ))}

                    {selectedDate && (
                        <VerticalMarker<Date, number>
                            id={getVerticalMarkerId()}
                            xScale={xScale}
                            yScale={yScale}
                            markerData={getSelectedMarkerData()}
                            markerLineColor={options?.verticalMarker?.lineColor || CHART_STYLING.colors.neutral['50']}
                            chartHeight={chartHeight}
                            markerSize="S"
                        />
                    )}
                </g>
            </svg>

            {selectedDate && (
                <div
                    className={classes.MarkedDate}
                    style={{
                        ...calculateRelativePositionAndStylingOfMarkedDateVsSvg(),
                        // TODO depend content on both selected date & selected area
                    }}
                >
                    <div className="__selectedDate">
                        {formatDate({
                            date: selectedDate,
                            format: DATE_FORMAT.DAY_MONTH_YEAR,
                            timezone: options.axis.x.timezone,
                        })}
                    </div>
                    <table>
                        <tbody>
                            {getSelectedDateInfo().map(({ label, color, val }, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <tr key={`${id}_selected_line-${index}`}>
                                    <td style={{ color }}>
                                        <div className="__label"><Text label={label} /></div>
                                    </td>
                                    <td align="right" style={{ color }}>
                                        {options.axis.y.domain === 'amount' && formatAmount(val, { useMagnitudeFlags: true })}
                                        {options.axis.y.domain === 'percentage' && formatPercentage(val)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );

    function getStackedData(): ILineChartData<Date, number>[] {
        let prevStackedLineDataPoints: ILineDataItem<Date, number>[] = [];

        return rawData.map((lineData, lineIndex) => {
            const currentLineStackedDataPoints = lineData.dataPoints.map((lineDataPoint, dataPointIndex) => ({
                ...lineDataPoint,
                y: lineDataPoint.y + (lineIndex !== 0 ? prevStackedLineDataPoints[dataPointIndex].y : 0),
            }));

            prevStackedLineDataPoints = currentLineStackedDataPoints;

            return {
                ...lineData,
                dataPoints: currentLineStackedDataPoints,
            };
        });
    }

    function getAllYValues() {
        return data.reduce(
            (allYValues, { dataPoints }) => allYValues.concat(dataPoints.map(({ y }) => y)),
            [],
        );
    }

    function getAllXValues() {
        return data[0].dataPoints.map(({ x }) => x);
    }

    function getAreasData(): ILineChartAreasData<Date, number>[] {
        return data.map(({ areaColor, ...otherData }, lineIndex) => ({
            ...otherData,
            areaColor: areaColor || DEFAULT_AREA_COLOR,
            dataPoints: data[lineIndex].dataPoints.map((lineDataPoint, dataPointIndex) => ({
                x: lineDataPoint.x,
                y0: lineIndex !== 0 ? data[lineIndex - 1].dataPoints[dataPointIndex].y : 0,
                y1: lineDataPoint.y,
            })),
        }));
    }

    function determineOpacityBasedOnSelection(areaColor: string): number {
        if (!selectedAreaColor) {
            /* no area selected */
            return null;
        }

        if (selectedAreaColor === areaColor) {
            /* this area selected --> leave color untouched */
            return null;
        }

        /* other area selected --> blur this one */
        return COLOR_OPACTITY_WHEN_UN_SELECTED;
    }

    function onClickArea(newAreaColor: string) {
        if (onSelectArea) {
            if (selectedAreaColor === newAreaColor) {
                /* re-click on already selected area --> de-select */
                onSelectArea(null);
            } else {
                onSelectArea(newAreaColor);
            }
        }
    }

    function onMouseClickChartRectangle(event: React.MouseEvent) {
        const xDateEquivalent = determineXValueThatCorrespondsWithClick<Date>({
            event,
            xScale,
            clickableArea: {
                id: getClickableChartRectangleId(),
                width: chartWidth,
            },
        });

        onClickXDate(xDateEquivalent);
    }

    function onClickXDate(newDate: Date) {
        if (onSelectDate) {
            const closestDate = findClosestDate({
                candidates: getAllXValues(),
                date: newDate,
            });

            if (selectedDate && (selectedDate.getTime() === closestDate.getTime())) {
                /* re-click on already selected date --> de-select */
                onSelectDate(null);
            } else {
                onSelectDate(closestDate);
            }
        }
    }

    function getSelectedMarkerData(): IVerticalMarkerData<Date, number> {
        let yOfPreviousLine = 0;

        return {
            x: selectedDate,
            y: data.reduce(
                (accumulator, { dataPoints, areaColor, lineColor }, index) => {
                    const yOfMatchingDatapoint = dataPoints
                        .find((lineDataPoint) => lineDataPoint.x.getTime() === selectedDate.getTime()).y;

                    if (yOfMatchingDatapoint !== yOfPreviousLine) {
                        accumulator.push({
                            val: yOfMatchingDatapoint,
                            color: options.shouldIncludeAreas ? areaColor : lineColor,
                            className: `marker_${index}`,
                        });

                        yOfPreviousLine = yOfMatchingDatapoint;
                    }

                    return accumulator;
                },
                [] as IVerticalYMarker<number>[],
            ),
        };
    }

    function calculateRelativePositionAndStylingOfMarkedDateVsSvg(): CSSProperties {
        const svgDimensions = getElementDimensionsById(id);

        /* as we can't get the dimensions of the vertical marker by its id (because it is not drawn/available yet),
         * we get its location via the xScale */
        const chartResizeRatio = svgDimensions.width / options.dimensions.maxWidth;
        const xVerticalMarker = xScale(selectedDate);
        const xLeft = xVerticalMarker - xScale(getFirstItemOfArray(xValues));
        const xRight = xScale(getLastItemOfArray(xValues)) - xVerticalMarker;

        const paddingBottom = options.axis.x.height * chartResizeRatio;

        if (xLeft > xRight) {
            /* marker is more to the right --> place the MarkedDate on the left of the marker (right-aligned) */
            return {
                alignItems: 'flex-end',
                bottom: paddingBottom,
                right: (xRight + getWidthBetweenXYAreaAndSvgRight()) * chartResizeRatio
                    + PADDING_BETWEEN_MARKER_AND_INFO_BOX,
            };
        }

        /* marker is more to the left --> place the MarkedDate on the right of the marker (left-aligned) */
        return {
            alignItems: 'flex-start',
            bottom: paddingBottom,
            left: (options.axis.y.width + xLeft) * chartResizeRatio
                + PADDING_BETWEEN_MARKER_AND_INFO_BOX,
        };
    }

    function getSelectedDateInfo() {
        const selectedDateInfo = rawData
            .map((lineData) => {
                const matchingLineDataPoint = lineData.dataPoints
                    .find((lineDataPoint) => lineDataPoint.x.getTime() === selectedDate.getTime());

                return {
                    label: lineData.label,
                    color: options.shouldIncludeAreas ? lineData.areaColor : lineData.lineColor,
                    val: matchingLineDataPoint?.y || 0,
                };
            })
            .filter(({ val, color }) => {
                if (color === selectedAreaColor) {
                    /* when an area was selected, we always show its value (even if zero) */
                    return true;
                }
                /* other values are only shown when no area was selected AND if they are not zero */
                return !selectedAreaColor && (options.shouldShowSelectedValuesEvenWhenZero || val !== 0);
            });

        return options.shouldStackLines
            ? selectedDateInfo.reverse() /* so that the largest value is at the bottom */
            : selectedDateInfo;
    }

    function getVerticalMarkerId() {
        return `${id}${ID_SUFFIX.vertical_marker}`;
    }

    function getClickableChartRectangleId() {
        return `${id}${ID_SUFFIX.clickable_chart_rectangle}`;
    }

    function getWidthBetweenXYAreaAndSvgRight() {
        return options.dimensions.maxWidth - options.axis.y.width - chartWidth;
    }
}
