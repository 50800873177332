import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { APP_COLORS } from 'config/styling/colors';
import { TLabel } from 'models/general.models';
import { makeStyles, mixins } from 'views/styling';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';
import { IInfoIconTooltipProps } from 'views/common/widget/InfoIconTooltip.models';
import Text from 'views/common/widget/Text';

export interface IContentTitleProps {
    label: TLabel;
    className?: string;
    /* the 'section' one will be larger and in blue */
    variant?: 'regular' | 'section' | 'section-small' | 'as-field' | 'title' |
    'text' | 'field-group' | 'list-section' | 'list-section-open'; // default 'regular';
    infoTooltip?: IInfoIconTooltipProps;
    customRenderer?: (label: TLabel) => React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
    ContentTitle: {
        ...mixins.flexRowCenterLeft(),

        '& .__titleLabel': {
            marginRight: theme.spacing(1),
        },
    },
    SectionTitle: {
        color: APP_COLORS.PRIMARY['500'],
        marginTop: theme.spacing(2),
    },
    SectionTitleSmall: {
        marginTop: theme.spacing(2),
        ...mixins.typo({ size: 15, color: APP_COLORS.PRIMARY['500'] }),
    },
    AsTitle: {
        ...mixins.typo({ size: 30, weight: 'bold' }),
        paddingTop: theme.spacing(2),
    },
    AsFieldTitle: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        ...mixins.typo({ size: 15 }),
    },
    AsText: {
        ...mixins.typo({ size: 15, weight: 'normal' }),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    FieldGroupTitle: {
        ...mixins.typo({ size: 15, weight: 'bold' }),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(2),
    },
    ListSectionTitle: {
        ...mixins.typo({ size: 16, weight: 'bold' }),
    },
    ListSectionOpenTitle: {
        ...mixins.typo({ size: 16, weight: 'bold', color: APP_COLORS.PRIMARY[500] }),
    },
}));

export default function ContentTitle({
    label,
    className,
    variant = 'regular',
    infoTooltip,
    customRenderer,
}: IContentTitleProps) {
    const classes = useStyles();

    const typoVariant = variant === 'section' ? 'h5' : 'h6';

    return (
        <Typography
            className={clsx(
                classes.ContentTitle,
                'ContentTitle',
                className,
                (variant === 'section') && classes.SectionTitle,
                (variant === 'section-small') && classes.SectionTitleSmall,
                (variant === 'as-field') && classes.AsFieldTitle,
                (variant === 'text') && classes.AsText,
                (variant === 'field-group') && classes.FieldGroupTitle,
                (variant === 'list-section') && classes.ListSectionTitle,
                (variant === 'list-section-open') && classes.ListSectionOpenTitle,
                (variant === 'title') && classes.AsTitle,
            )}
            variant={typoVariant}
        >
            <div className="__titleLabel">
                {customRenderer ? customRenderer(label) : <Text label={label} />}
            </div>

            {infoTooltip && (
                <InfoIconTooltip
                    {...infoTooltip}
                />
            )}
        </Typography>
    );
}
