import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { IRoute } from 'models/route.models';
import { triggerFetchReports } from 'state/entities/portfolioMgmt/portfolioReports';
import {
    triggerFetchStoryManagerDatabases,
} from 'state/entities/storyTeller/storyManagerDatabases';
import {
    getSelectedStoryManagerDatabaseDetail,
    getSelectedStoryManagerDatabaseOutputKey,
    triggerFetchStoryManagerDatabaseDetail,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import UnderConstruction from 'views/appShell/routing/UnderConstruction';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getOutputKeyNameCompact } from 'utils/entities/storyManager/storyManagerUtils';
import { ROUTE_KEY } from '../routeKeys';
import ReportsList from './StoryTeller/ReportsList';
import TemplateDataBasesList from './StoryTeller/StoryManager/DatabasesList';
import StoryManagerTemplate from './StoryTeller/StoryManager/StoryManagerTemplate';
import OutputKeysList from './StoryTeller/StoryManager/OutputKeysList';
import OutputKeyDetail from './StoryTeller/StoryManager/OutputKeyDetails';
import ScenarioDetail from './StoryTeller/StoryManager/ScenarioDetails';
import FindAndReplace from './StoryTeller/StoryManager/FindAndReplace';

export const APP_ROUTES: IRoute<ROUTE_KEY>[] = [{
    routeKey: ROUTE_KEY.R_ROBOADVISOR_DASHBOARD,
    path: '/robo-advisor',
    exact: true,
    component: UnderConstruction,
    breadcrumb: { translationKey: 'to do' },
}, {
    routeKey: ROUTE_KEY.R_ROBOADVISOR_ANALYTICS_DASHBOARD,
    path: '/robo-advisor/analytics',
    exact: true,
    component: UnderConstruction,
    breadcrumb: { translationKey: 'to do' },
}, {
    routeKey: ROUTE_KEY.R_STORYTELLER_DASHBOARD,
    path: '/story-teller',
    exact: true,
    breadcrumb: {
        translationKey: 'apps.story_teller.breadcrumb',
    },
    childRoutes: [{
        path: '/reports',
        breadcrumb: {
            translationKey: 'apps.story_teller.reports.breadcrumb',
        },
        executeOnRoute: [{
            execute: triggerFetchReports,
        }],
        routeKey: ROUTE_KEY.R_STORYTELLER_REPORTS,
        isEnabled: ({ tenantSettings }) => tenantSettings?.storyteller?.manageReports?.isEnabled,
        component: ReportsList,
    }, {
        path: '/databases',
        exact: true,
        breadcrumb: {
            translationKey: 'apps.story_teller.template_databases.breadcrumb',
        },
        executeOnRoute: [{
            execute: triggerFetchStoryManagerDatabases,
        }],
        routeKey: ROUTE_KEY.R_STORY_MANAGER_VERSIONS,
        isEnabled: ({ tenantSettings }) => tenantSettings?.storyteller?.storyManager?.isEnabled,
        /**
         * Normally we don't put _READ permissions on list pages (because you also have to be able to see those
         * entities where you are in their readably_by array even when you don't have the _READ all permission).
         * But the StoryManager databases are no entities --> the STORYMANAGER_READ permission really determines
         * if you can see the databases or not.
         */
        requiredPermissions: [OperationPermissionKey.STORYMANAGER_READ],
        component: TemplateDataBasesList,
        childRoutes: [{
            path: '/:databaseId/output-keys',
            breadcrumb: {
                translationKey: () => 'apps.story_teller.output_keys.breadcrumb',
                translationPlaceholdersSelector: () => ({
                    selectedDatabase: getSelectedStoryManagerDatabaseDetail()?.name,
                }),
                extraNotifications: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
            },
            executeOnRoute: [{
                execute: triggerFetchStoryManagerDatabaseDetail,
                executeInputSelector: ({ routeLocation }) => ({
                    databaseId: routeLocation.params.databaseId,
                }),
            }],
            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEYS,
            component: OutputKeysList,
            template: StoryManagerTemplate,
            childRoutes: [{
                path: '/:outputKeyId/details',
                breadcrumb: {
                    translationKey: () => 'apps.story_teller.output_keys.detail.breadcrumb',
                    translationPlaceholdersSelector: ({ pathParams }) => {
                        // eslint-disable-next-line max-len
                        const selectedOutputKey = getSelectedStoryManagerDatabaseOutputKey(pathParams.outputKeyId as string);
                        return {
                            selectedOutputKey: getOutputKeyNameCompact(selectedOutputKey),
                        };
                    },
                    extraNotifications: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
                },
                executeOnRoute: [{
                    execute: triggerFetchStoryManagerDatabaseDetail,
                    executeInputSelector: ({ routeLocation }) => ({
                        databaseId: routeLocation.params.databaseId,
                    }),
                }],
                routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
                component: OutputKeyDetail,
                template: StoryManagerTemplate,
                childRoutes: [{
                    path: '/:scenarioIndex',
                    breadcrumb: {
                        translationKey: () => 'apps.story_teller.output_keys.scenario_detail.breadcrumb',
                        translationPlaceholdersSelector: ({ pathParams }) => ({
                            number: (Number(pathParams?.scenarioIndex) + 1).toString(),
                        }),
                        extraNotifications: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
                    },
                    executeOnRoute: [{
                        execute: triggerFetchStoryManagerDatabaseDetail,
                        executeInputSelector: ({ routeLocation }) => ({
                            databaseId: routeLocation.params.databaseId,
                        }),
                    }],
                    routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_SCENARIO_DETAIL,
                    component: ScenarioDetail,
                    template: StoryManagerTemplate,
                }],
            }],
        }, {
            path: '/:databaseId/find-and-replace/:outputKeyId/:scenarioIndex',
            breadcrumb: {
                translationKey: () => 'apps.story_teller.output_keys.find_and_replace.breadcrumb',
                translationPlaceholdersSelector: () => ({
                    selectedDatabase: getSelectedStoryManagerDatabaseDetail()?.name,
                }),
                extraNotifications: [StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL],
            },
            executeOnRoute: [{
                execute: triggerFetchStoryManagerDatabaseDetail,
                executeInputSelector: ({ routeLocation }) => ({
                    databaseId: routeLocation.params.databaseId,
                }),
            }],
            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_FIND_AND_REPLACE,
            isEnabled: ({ tenantSettings }) => tenantSettings?.storyteller?.storyManager?.isEnabled,
            component: FindAndReplace,
        }],
    }],
}];
