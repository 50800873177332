import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import {
    StringSchema,
    string,
    object,
    validateAgainstSchema,
    ISchemaValidationResult, SchemaErrorType,
} from '@console/common/utils/schema';
import {
    IListPageVars,
    TListSearchMode,
    IListSimpleSearch,
    IListAdvancedSearch,
    ISimpleFilterToggles,
} from 'models/state/ui.models';
import { TI18nLabelOrString } from 'models/general.models';
import { APP_COLORS, hexToRgba, OPACITY } from 'config/styling/colors';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { mapSchemaValidationErrorToI18Label } from 'utils/i18n/mapSchemaValidationErrorToI18Label';
import { makeStyles, mixins } from 'views/styling';
import CustomI18nContext from 'views/appShell/providers/CustomI18nContext';
import TextButton from 'views/common/buttons/TextButton';
import InputForm from 'views/common/inputs/base/InputForm';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IExtendedInputFormProps,
    IFormValues, IOnAtLeastOneFieldValueChangedProps, IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputTextField, { IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import IconButton, { IIconButtonProps } from 'views/common/buttons/IconButton';
import IconButtonSubmit from 'views/common/buttons/IconButtonSubmit';
import { GENERIC_CLASS } from 'views/assets/cssInJs/genericClasses';

const NO_SIMPLE_SEARCH_VALUE = '';
const DEFAULT_MIN_NR_OF_SIMPLE_SEARCH_CHARS = 3;

export interface IDataSearchProps<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,max-len
    AdvancedFilters = any, ExtraSimpleFilterToggles extends ISimpleFilterToggles = any, AdvancedFormFilters extends IFormValues = any> {
    listPageVars: IListPageVars<AdvancedFilters, ExtraSimpleFilterToggles>;
    onSetSearchMode: (newSearchMode: TListSearchMode) => void;
    simple?: ISimpleFilterProps<ExtraSimpleFilterToggles>;
    advanced?: IAdvancedFilterProps<AdvancedFilters>;
    advancedWithSchema?: IAdvancedWithSchemaFilterProps<AdvancedFilters, AdvancedFormFilters>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISimpleFilterProps<ExtraSimpleFilterToggles extends ISimpleFilterToggles = any> {
    tipTranslationKey: string;
    onSetSearchValue: (newSearchValue: string) => void;
    onSearch: TOnSimpleSearch<ExtraSimpleFilterToggles>;
    isClientSideSearch?: boolean; // default false
    minInputChars?: number; // default 3 - only used when server-side-search
    extraFilters?: ISimpleExtraFilters<ExtraSimpleFilterToggles>;
    schema?: StringSchema;
}

type TOnSimpleSearch<ExtraSimpleFilterToggles> =
    (filterValue: string, extraFilterToggles?: ExtraSimpleFilterToggles) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISimpleExtraFilters<ExtraSimpleFilterToggles extends ISimpleFilterToggles = any> {
    initialExtraToggles: ExtraSimpleFilterToggles;
    renderExtraFilters: (renderProps: IRenderExtraSimpleFiltersProps<ExtraSimpleFilterToggles>) => ReactNode;
    onSetFilterToggles: (newToggles: ExtraSimpleFilterToggles) => void;
}

export interface IRenderExtraSimpleFiltersProps<ExtraSimpleFilterToggles> {
    extraFilterToggles: ExtraSimpleFilterToggles;
    onChangeFilterToggle: (changes: Partial<ExtraSimpleFilterToggles>) => void;
    translator: TTranslator;
}

export interface IAdvancedFilterProps<AdvancedFilters> {
    initialValues: AdvancedFilters;
    renderFilters: (renderProps: IRenderAdvancedFiltersProps<AdvancedFilters>) => ReactNode;
    onSetFilterValues: (newFilterValues: AdvancedFilters) => void;
    onSearch: (filterValues: AdvancedFilters) => void;
    onReset?: () => AdvancedFilters; // if not provided, the initialValues will be taken
}

export interface IRenderAdvancedFiltersProps<AdvancedFilters> {
    filterValues: AdvancedFilters;
    onChangeFilterValue: (changes: Partial<AdvancedFilters>) => void;
    translator: TTranslator;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IAdvancedWithSchemaFilterProps<AdvancedFilters, AdvancedFormFilters extends IFormValues = any>
    extends Pick<IAdvancedFilterProps<AdvancedFormFilters>, 'initialValues' | 'onSetFilterValues' | 'onSearch'>,
    Pick<IExtendedInputFormProps<AdvancedFormFilters>, 'schema' | 'preferTheseInitialValuesOverCurrentValues'> {
    renderFilters: (renderProps: IRenderAdvancedWithSchemaFiltersProps<AdvancedFormFilters>) => ReactNode;
    /** Ben: typing dummy very-ugly-workaround because I needed the 'AdvancedFilters' but don't actually use it */
    dummyNotUsed?: AdvancedFilters;
}

export interface IRenderAdvancedWithSchemaFiltersProps<AdvancedFormFilters extends IFormValues>
    extends Pick<IExtendedInputFormContext<AdvancedFormFilters>, 'fields'> {
    translator: TTranslator;
}

const useStyles = makeStyles((theme) => ({
    DataSearch: {
        ...mixins.widthMax(),
        padding: theme.spacing(0, 4, 3, 4),

        '& .SimpleSearchWrapper': {
            ...mixins.flexColCenterCenter(),
        },
        '& .SimpleSearch': {
            '& .wrapped-input-field': {
                ...mixins.flexRowCenterLeft(),
                ...mixins.widthMax(),
                position: 'relative',
            },

            '& .filter-icon': {
                margin: theme.spacing(0, 1, 0, 0),
                color: APP_COLORS.TEXT['500'],
            },
            '& .filter-icon--start': {
                marginLeft: theme.spacing(1),
            },
            '& .disabled-icon': {
                color: hexToRgba(APP_COLORS.TEXT['500'], OPACITY.MEDIUM),
            },
            '& .simple-search-button': {
                height: 28,
                padding: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        },

        '& .AdvancedSearchWrapper': {
            ...mixins.widthMax(),
            ...mixins.flexCol({ alignMain: 'flex-start' }),
            marginTop: theme.spacing(3),
            padding: theme.spacing(0, 1, 1, 1),

            '& .filter-inputs': {},

            '& .filter-actions': {
                ...mixins.flexRow({ alignMain: 'flex-end', alignCross: 'center' }),
                marginTop: theme.spacing(2),
            },
            '& .filter-actions button:last-child': {
                marginLeft: theme.spacing(3),
                marginRight: 0,
            },
        },
    },
}));

export default function DataSearch<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,max-len
    AdvancedFilters = any, ExtraSimpleFilterToggles extends ISimpleFilterToggles = any, AdvancedFormFilters extends IFormValues = any>({
    listPageVars,
    onSetSearchMode,
    simple,
    advanced,
    advancedWithSchema,
}: IDataSearchProps<AdvancedFilters, ExtraSimpleFilterToggles, AdvancedFormFilters>) {
    const classes = useStyles();

    const searchMode = listPageVars.search && listPageVars.search.mode
        ? listPageVars.search.mode
        : isSet(simple)
            ? 'simple'
            : 'advanced';

    useExecuteOnMount({
        execute: () => {
            if (!isSet(listPageVars?.search?.advanced?.filterValues)) {
                /*
                    In case the advanced filter values are not defined we save the initial values to the state.
                    This is done to allow setting the filter values from other places e.g. (Entity Still Used Modal)
                    when the advanced filters haven't been opened and changed yet
                */
                if (isSet(advanced)) {
                    advanced.onSetFilterValues(advanced.initialValues);
                } else if (isSet(advancedWithSchema)) {
                    advancedWithSchema.onSetFilterValues(advancedWithSchema.initialValues);
                }
            }

            if (!listPageVars.search || !listPageVars.search.mode) {
                onSetSearchMode(searchMode);
            }
        },
    });

    /* define filterValues: either the initial ones, or the previous ones from the list page vars */
    const simpleSearch: IListSimpleSearch<ExtraSimpleFilterToggles> = {
        filterValue: NO_SIMPLE_SEARCH_VALUE,
        extraFilterToggles: (simple && simple.extraFilters)
            ? {
                ...simple.extraFilters.initialExtraToggles,
            }
            : {},
        ...(listPageVars.search && listPageVars.search.simple),
    };
    const advancedSearch: IListAdvancedSearch<AdvancedFilters> = {
        filterValues: advanced || advancedWithSchema
            ? {
                ...(advanced || advancedWithSchema).initialValues,
            }
            : {},
        ...(listPageVars.search && listPageVars.search.advanced),
    };

    return (
        <div className={clsx(classes.DataSearch, 'DataSearch')}>
            {simple && (
                <SimpleSearch<ExtraSimpleFilterToggles>
                    {...simple}
                    onSetSearchMode={onSetSearchMode}
                    searchMode={searchMode}
                    searchValue={simpleSearch.filterValue}
                    extraFilterToggles={simpleSearch.extraFilterToggles}
                    isAdvancedSearchAvailable={isSet(advanced) || isSet(advancedWithSchema)}
                />
            )}

            {(advanced && searchMode === 'advanced') && (
                <AdvancedSearch
                    {...advanced}
                    filterValues={advancedSearch.filterValues}
                />
            )}

            {(advancedWithSchema && searchMode === 'advanced') && (
                <AdvancedSearchWithSchema<AdvancedFilters, AdvancedFormFilters>
                    {...advancedWithSchema}
                    currentValues={advancedSearch.filterValues as unknown as AdvancedFormFilters}
                />
            )}
        </div>
    );
}

interface IExtraSimpleFilterProps<ExtraSimpleFilterToggles extends ISimpleFilterToggles> {
    onSetSearchMode: (newSearchMode: TListSearchMode) => void;
    searchMode: TListSearchMode;
    searchValue: string;
    extraFilterToggles: ExtraSimpleFilterToggles;
    isAdvancedSearchAvailable: boolean;
}

interface ISimpleSearchState {
    searchInput: string;
    isValid: boolean;
    validationErrorLabel: TI18nLabelOrString;
}

function SimpleSearch<ExtraSimpleFilterToggles extends ISimpleFilterToggles>({
    tipTranslationKey,
    onSetSearchValue,
    onSearch,
    isClientSideSearch = false,
    minInputChars = DEFAULT_MIN_NR_OF_SIMPLE_SEARCH_CHARS,
    onSetSearchMode,
    searchMode,
    searchValue: currentSearchValue = NO_SIMPLE_SEARCH_VALUE,
    extraFilterToggles,
    extraFilters,
    isAdvancedSearchAvailable,
    schema,
}: ISimpleFilterProps & IExtraSimpleFilterProps<ExtraSimpleFilterToggles>) {
    /**
     * For the simple search we store the search value in 2 different 'states' because
     * we need a difference between the actual user input (so that we can show validation errors)
     * and the validated input that is used on page refresh.
     *
     * 2 states:
     * - once in the browser storage (see also 'updateListSimpleSearch')
     *    > used on page refresh
     *    > passed as input via the 'searchValue' param
     *    > the user input is only save to this state when the input is valid
     * - once in this simpleSearchState
     *    > every user input is saved (so also when invalid)
     *
     * p.s. In the 'AdvancedSearchWithSchema' there is a similar mechanism but slightly different: instead
     * of a custom state (like simpleSearchState), there the ExtendedInputForm has a state.
     */
    const [simpleSearchState, setSimpleSearchState] = useState<ISimpleSearchState>(getInitialSimpleSearchState);

    const isAdvancedSearchMode = searchMode === 'advanced';

    return (
        <InputForm
            name="list-simple-search-form"
            className="SimpleSearchWrapper"
            onSubmit={submitSimpleSearchForm}
        >
            {extraFilters && (
                <CustomI18nContext.Consumer>
                    {({ translator }) => (
                        <div className="ExtraSimpleFilters">
                            {extraFilters.renderExtraFilters({
                                extraFilterToggles,
                                onChangeFilterToggle,
                                translator,
                            })}
                        </div>
                    )}
                </CustomI18nContext.Consumer>
            )}

            <InputWrapper
                className="SimpleSearch"
                disabled={isAdvancedSearchMode}
                noPadding
            >
                <div className={GENERIC_CLASS.WRAPPED_INPUT_FIELD}>
                    <SearchIcon
                        className={clsx('filter-icon', 'filter-icon--start', isAdvancedSearchMode && 'disabled-icon')}
                    />

                    <InputTextField
                        id="simple-search-input"
                        value={simpleSearchState.searchInput}
                        onChange={changeSearchInput}
                        placeholder={tipTranslationKey}
                        disabled={isAdvancedSearchMode}
                        noInnerMargin
                        markAsWrappedInputField={false}
                        positionHelperTextAsAbsolute
                        error={simpleSearchState.validationErrorLabel}
                    />

                    {!isClientSideSearch && !isAdvancedSearchMode && (
                        <IconButtonSubmit
                            id="trigger-simple-search"
                            className="simple-search-button"
                            color="primary"
                            isSubmit={false}
                            onClick={executeServerSideSimpleSearch}
                            disabled={isAdvancedSearchMode || !isInputValid()}
                            tooltip="common.filter.actions.search"
                        />
                    )}

                    <ActionableIcon
                        id="simple-search-clear"
                        onClick={clearInput}
                        icon={<ClearIcon />}
                        disabled={!simpleSearchState.searchInput || isAdvancedSearchMode}
                        tooltip="common.filter.tooltip.clear_simple"
                    />

                    {isAdvancedSearchAvailable && !isAdvancedSearchMode && (
                        <ActionableIcon
                            id="to-advanced-search"
                            onClick={toggleSearchMode}
                            icon={<ExpandMoreIcon />}
                            tooltip="common.filter.tooltip.open_advanced"
                            tooltipPlacement="top-end"
                        />
                    )}
                    {isAdvancedSearchAvailable && isAdvancedSearchMode && (
                        <ActionableIcon
                            id="to-simple-search"
                            onClick={toggleSearchMode}
                            icon={<ExpandLessIcon />}
                            tooltip="common.filter.tooltip.close_advanced"
                            tooltipPlacement="top-end"
                        />
                    )}
                </div>
            </InputWrapper>
        </InputForm>
    );

    function getInitialSimpleSearchState(): ISimpleSearchState {
        return mapSearchInputToSimpleSearchState(currentSearchValue);
    }

    function mapSearchInputToSimpleSearchState(searchInput: string): ISimpleSearchState {
        const { isValid, errors } = validateSearchInput(searchInput);
        const validationErrorLabel = isValid
            ? null
            : mapSchemaValidationErrorToI18Label(errors?.simpleSearchValue);

        return {
            searchInput,
            isValid,
            validationErrorLabel,
        };
    }

    function validateSearchInput(searchInput: string): ISchemaValidationResult {
        if (schema || !isClientSideSearch) {
            const validationResult = validateAgainstSchema({
                input: {
                    simpleSearchValue: searchInput,
                },
                schema: object({
                    simpleSearchValue: schema || string(), /* if no schema provided, we take the base string schema */
                }),
            });

            if (validationResult.isValid && !isClientSideSearch) {
                /* Only show a 'min length' error validation message when there is at least some input */
                if (isSetString(searchInput) && searchInput.length < minInputChars) {
                    return {
                        isValid: false,
                        errors: {
                            simpleSearchValue: {
                                fieldName: null,
                                type: SchemaErrorType.Min,
                                message: null,
                                value: searchInput,
                                params: {
                                    min: minInputChars,
                                },
                            },
                        },
                    };
                }
            }

            return validationResult;
        }

        return {
            isValid: true,
            errors: {},
        };
    }

    function changeSearchInput({ value }: IOnChangeTextInputProps) {
        const newSimpleSearchState = mapSearchInputToSimpleSearchState(value);

        setSimpleSearchState(newSimpleSearchState);

        if (newSimpleSearchState.isValid) {
            /**
             * We only save the changed input to browser storage in case the value is valid,
             * so that on page-refresh that invalid filter value will not be there (otherwise the invalid
             * simple search value was actually used in the back-end-query when refreshing the page)
             */
            onSetSearchValue(value);

            if (isClientSideSearch) {
                onSearch(value, extraFilterToggles);
            }
        }
    }

    function executeServerSideSimpleSearch() {
        if (isInputValid()) {
            onSearch(simpleSearchState.searchInput, extraFilterToggles);
        }
    }

    function submitSimpleSearchForm() {
        if (isClientSideSearch) {
            /* do nothing as already taken action on each key press in changeSearchInput() */
            return;
        }

        /* allow empty so that the user can clear the input and press enter */
        if (isInputValid(true)) {
            onSearch(simpleSearchState.searchInput, extraFilterToggles);
        }
    }

    function clearInput() {
        if (!isAdvancedSearchMode && simpleSearchState.searchInput) {
            setSimpleSearchState(mapSearchInputToSimpleSearchState(NO_SIMPLE_SEARCH_VALUE));

            onSetSearchValue(NO_SIMPLE_SEARCH_VALUE);

            onSearch(NO_SIMPLE_SEARCH_VALUE, extraFilters && extraFilters.initialExtraToggles);
        }
    }

    function isInputValid(allowEmpty?: boolean) {
        if (!simpleSearchState.isValid) {
            return false;
        }

        if (!simpleSearchState.searchInput) {
            if (allowEmpty) {
                return true;
            }
            return false;
        }

        /* as there is no validation error when the input is empty --> make sure there is actual input */
        return isClientSideSearch || simpleSearchState.searchInput.length >= minInputChars;
    }

    function toggleSearchMode() {
        const newSearchMode = searchMode === 'simple' ? 'advanced' : 'simple';
        onSetSearchMode(newSearchMode);
    }

    function onChangeFilterToggle(partiallyChangedToggles: Partial<ExtraSimpleFilterToggles>) {
        const newExtraFilterToggles = {
            ...extraFilterToggles,
            ...partiallyChangedToggles,
        };

        extraFilters.onSetFilterToggles(newExtraFilterToggles);

        onSearch(simpleSearchState.searchInput, newExtraFilterToggles);
    }
}

function ActionableIcon(
    props: Pick<IIconButtonProps, 'id' | 'onClick' | 'icon' | 'disabled' | 'tooltip' | 'tooltipPlacement'>,
) {
    return (
        <IconButton
            className="filter-icon"
            variant="bare"
            size="XS"
            color="grey"
            {...props}
        />
    );
}

interface IExtraAdvancedFilterProps<AdvancedFilters> {
    filterValues: AdvancedFilters;
}

function AdvancedSearch<AdvancedFilters>({
    initialValues,
    renderFilters,
    onSetFilterValues,
    onSearch,
    onReset,
    filterValues,
}: IAdvancedFilterProps<AdvancedFilters> & IExtraAdvancedFilterProps<AdvancedFilters>) {
    return (
        <InputForm
            name="list-advanced-search-form"
            className="AdvancedSearchWrapper"
            onSubmit={submitAdvancedSearchForm}
        >
            <CustomI18nContext.Consumer>
                {({ translator }) => (
                    <div className="filter-inputs">
                        {renderFilters({ filterValues, onChangeFilterValue, translator })}
                    </div>
                )}
            </CustomI18nContext.Consumer>

            <div className="filter-actions">
                <TextButton
                    id="reset-advanced-filters"
                    variant="bare"
                    color="secondary"
                    onClick={resetFilterValues}
                    label="common.filter.actions.reset"
                />

                <TextButton
                    id="trigger-advanced-search"
                    isSubmit
                    variant="filled"
                    disabled={!isInputValid()}
                    label="common.filter.actions.search"
                />
            </div>
        </InputForm>
    );

    function onChangeFilterValue(partiallyChangedFilters: Partial<AdvancedFilters>) {
        onSetFilterValues({
            ...filterValues,
            ...partiallyChangedFilters,
        });
    }

    function resetFilterValues() {
        const resettedFilterValues = onReset
            ? onReset()
            : {
                ...initialValues,
            };

        onSetFilterValues(resettedFilterValues);

        onSearch(resettedFilterValues);
    }

    function executeServerSideAdvancedSearch() {
        if (isInputValid()) {
            onSearch(filterValues);
        }
    }

    function submitAdvancedSearchForm() {
        executeServerSideAdvancedSearch();
    }

    function isInputValid() {
        // TODO via a validator input function OR a schema?
        return true;
    }
}

function AdvancedSearchWithSchema<AdvancedFilters, AdvancedFormFilters extends IFormValues>({
    initialValues,
    renderFilters,
    onSetFilterValues,
    onSearch,
    schema,
    currentValues,
    preferTheseInitialValuesOverCurrentValues,
}: IAdvancedWithSchemaFilterProps<AdvancedFilters, AdvancedFormFilters> & { currentValues: AdvancedFormFilters }) {
    return (
        <ExtendedInputForm
            name="list-advanced-search-form"
            className="AdvancedSearchWrapper"
            initialValues={initialValues}
            currentValues={currentValues}
            preferTheseInitialValuesOverCurrentValues={preferTheseInitialValuesOverCurrentValues}
            schema={schema}
            reset={{
                actionLabel: 'common.filter.actions.reset',
                buttonId: 'reset-advanced-filters',
                onReset: onResetAdvancedFilterValues,
                useCurrentValuesIfProvided: false,
            }}
            submit={{
                actionLabel: 'common.filter.actions.search',
                buttonId: 'trigger-advanced-search',
                onSubmit: submitAdvancedWithSchemaSearchForm,
                shouldBeAllowedWithNoChanges: true,
            }}
            renderFormFields={renderFormFields}
            onlyShowErrorsWhenFormDirty={false}
            checkUnsavedChangesOnRouteChange={false}
            onAtLeastOneFieldValueChanged={onAtLeastOneFieldValueChanged}
        />
    );

    function renderFormFields(context: IExtendedInputFormContext<AdvancedFormFilters>) {
        return (
            <CustomI18nContext.Consumer>
                {({ translator }) => (
                    <div className="filter-inputs">
                        {renderFilters({
                            fields: context.fields,
                            translator,
                        })}
                    </div>
                )}
            </CustomI18nContext.Consumer>
        );
    }

    function onAtLeastOneFieldValueChanged({
        values,
        isValid,
    }: IOnAtLeastOneFieldValueChangedProps<AdvancedFormFilters>) {
        if (isValid) {
            /**
             * We only save the filter values to browser storage in case the form values are valid,
             * so that on page-refresh those invalid filter values will not be there (otherwise those invalid
             * advanced search values were actually used in the back-end-query when refreshing the page)
             */
            onSetFilterValues(values);
        }
    }

    function onResetAdvancedFilterValues() {
        const resettedFilterValues = {
            ...initialValues,
        };
        // onSetFilterValues(resettedFilterValues);
        onSearch(resettedFilterValues);
    }

    function submitAdvancedWithSchemaSearchForm({ values }: IOnSubmitProps<AdvancedFormFilters>) {
        onSearch(values); /* to execute the server side search */
        return Promise.resolve();
    }
}
