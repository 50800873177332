export enum CoreApiPath {
    /* eslint-disable max-len */

    INSTRUMENTS = '/instruments/',
    INSTRUMENT_DETAILS = '/instruments/{instrumentId}/',

    PUBLIC_INSTRUMENTS = '/public/instruments/',
    PUBLIC_INSTRUMENT_DETAILS = '/public/instruments/{instrumentId}/',
    PUBLIC_INSTRUMENT_VERSIONS = '/public/instruments/{instrumentId}/versions/',

    PUBLIC_INSTRUMENT_GROUPS = '/public/instrument_groups/',
    PUBLIC_INSTRUMENT_GROUP_DETAILS = '/public/instrument_groups/{instrumentGroupId}/',
    PUBLIC_INSTRUMENT_GROUP_VERSIONS = '/public/instrument_groups/{instrumentGroupId}/versions/',

    TIMESERIES_PAST = '/timeseries/past/',

    OPTIMIZATION_DETAILS = '/optimizations/{optimizationId}/',

    POLICIES = '/policies/',
    POLICY_DETAILS = '/policies/{policyId}/',
    POLICY_VERSIONS = '/policies/{policyId}/versions/',
    POLICY_OPTIMIZATION = '/policies/{policyId}/optimization/',
    POLICY_PERFORMANCE_FUTURE = '/policies/{policyId}/future/',

    PORTFOLIOS = '/portfolios/',
    PORTFOLIOS_EXPORT = '/portfolios/export/',
    PORTFOLIO_DETAILS = '/portfolios/{portfolioId}/',
    PORTFOLIO_OPTIMIZATIONS = '/portfolios/{portfolioId}/optimizations/',
    PORTFOLIO_OPTIMIZATION_LATEST = '/portfolios/{portfolioId}/optimizations/latest/',
    PORTFOLIO_CASH = '/portfolios/{portfolioId}/cash/',
    PORTFOLIO_TRANSACTIONS = '/portfolios/{portfolioId}/transactions/',
    PORTFOLIO_PERFORMANCE_PAST = '/portfolios/{portfolioId}/past/',
    PORTFOLIO_PERFORMANCE_FUTURE = '/portfolios/{portfolioId}/future/',
    PORTFOLIO_LOOK_THROUGH = '/portfolios/{portfolioId}/look_through/',
    PORTFOLIO_VERSIONS = '/portfolios/{portfolioId}/versions/',

    PORTFOLIO_VALUATIONS = '/portfolio-valuations/',

    PORTFOLIO_REPORTS = '/portfolio-reports/',
    PORTFOLIO_REPORT_DETAIL = '/portfolio-reports/{portfolioReportId}/',
    PORTFOLIO_REPORT_GENERATE = '/portfolio-reports/generate/',
    PORTFOLIO_REPORT_FILE_URL = '/portfolio-reports/{portfolioReportId}/report-url/{format}/',

    PORTFOLIO_GROUPS = '/portfolio_groups/',
    PORTFOLIO_GROUP_DETAILS = '/portfolio_groups/{portfolioGroupId}/',
    PORTFOLIO_GROUP_VERSIONS = '/portfolio_groups/{portfolioGroupId}/versions/',

    RISK_PROFILES = '/risk_profiles/',
    RISK_PROFILE_DETAILS = '/risk_profiles/{riskProfileId}/',
    RISK_PROFILE_VERSIONS = '/risk_profiles/{riskProfileId}/versions/',

    GOALS = '/goals/',
    GOAL_DETAILS = '/goals/{goalId}/',
    GOAL_VERSIONS = '/goals/{goalId}/versions/',

    USERS = '/users/',
    USERS_EXPORT = '/users/export/',
    USER_DETAILS = '/users/{userId}/',
    USER_GROUP_MEMBERSHIP = '/users/{userId}/groups/',
    USER_VERSIONS = '/users/{userId}/versions/',

    USERGROUPS = '/groups/',
    USERGROUP_DETAILS = '/groups/{userGroupId}/',
    USERGROUP_VERSIONS = '/groups/{userGroupId}/versions/',

    HORIZONS = '/horizons/',
    HORIZON_DETAILS = '/horizons/{horizonId}/',
    HORIZON_VERSIONS = '/horizons/{horizonId}/versions/',

    /* this suffix will be appended after the *_VERSIONS url's from above */
    VERSIONS_CHANGES_SUFFIX = '{toVersion}/changes/{fromVersion}/',

    ALGORITHMS_IVAR_OPTIMIZE = '/algorithms/ivar/optimize/',

    /* eslint-enable max-len */
}
