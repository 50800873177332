import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'views/styling';
import { TI18nLabelOrString } from 'models/general.models';
import { INavigateToRoute } from 'models/routing.models';
import { toI18nLabel } from 'utils/i18n/i18nUtils';
import { mixins } from 'views/assets/cssInJs/mixins';
import ShowIfAllowed from 'views/auth/ShowIfAllowed';
import { redirectTo } from 'views/routes';
import TextButton from '../buttons/TextButton';

interface IPublicProps {
    illustration: string;
    title: TI18nLabelOrString;
    text: TI18nLabelOrString;
    create?: {
        id: string;
        label: TI18nLabelOrString;
        toRoute: INavigateToRoute;
    };
}

const useStyles = makeStyles((theme) => ({
    NoResults: {
        ...mixins.flexCenter(),
        padding: theme.spacing(4),
        '& .__illustration': {
            flexBasis: '50%',
            textAlign: 'center',
            paddingRight: theme.spacing(2),
        },
        '& .__content': {
            flexBasis: '50%',
            paddingLeft: theme.spacing(2),
        },
        '& .__title': {
            marginTop: 0,
            marginBottom: theme.spacing(2),
        },
        '& .__text': {
            margin: 0,
        },
        '& .__create': {
            marginTop: theme.spacing(2),
            maxWidth: 300,
        },
    },
}));

export default function NoResults({
    title,
    illustration,
    text,
    create,
}: IPublicProps) {
    const classes = useStyles();

    return (
        <div className={classes.NoResults}>
            <div className="__illustration">
                <img src={illustration} alt="no-results" />
            </div>
            <div className="__content">
                <Typography variant="h5" className="__title">
                    <Translate {...toI18nLabel(title)} />
                </Typography>
                <Typography className="__text">
                    <Translate {...toI18nLabel(text)} />
                </Typography>
                {create && (
                    <div className="__create">
                        <ShowIfAllowed
                            routeKey={create.toRoute.routeKey}
                            disableInsteadOfHide={{
                                tooltip: 'common.action.add_not_allowed',
                            }}
                        >
                            <TextButton
                                id={create.id}
                                label={create.label}
                                onClick={navigateToCreateRoute}
                            />
                        </ShowIfAllowed>
                    </div>
                )}
            </div>
        </div>
    );

    function navigateToCreateRoute() {
        redirectTo(create.toRoute);
    }
}
