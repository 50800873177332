import React from 'react';
import clsx from 'clsx';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TI18nLabelOrString } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { toI18nLabel } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';
import ContentBox from 'views/common/layout/ContentBox';

export interface ITabsProps {
    className?: string;
    items: ITabItem[];
    activeTabKey: string;
    onTabChange: (props: IOnTabChangeProps) => void;
    variant?: 'inner' | 'outer'; // default 'outer' - pass 'inner' if e.g. tabs within ContentBox;
    WrapperComponent?: React.ElementType<IWrapperComponentProps>;
    showSingleTab?: boolean;
}

export interface IWrapperComponentProps {
    renderWrapped: () => React.ReactNode;
}

export interface IOnTabChangeProps {
    tabKey: string;
}

export interface ITabItem {
    key: string;
    label: TI18nLabelOrString;
    component: React.ElementType;
    hidden?: boolean; // default false - if true: the tab will not be shown
    disabled?: boolean; // default false - if true (& hidden false): the tab will not be clickable
    wrapWithContentBox?: boolean; // default true
    noHorizontalPadding?: boolean; // default false
}

const SPACING = 4;

const useStyles = makeStyles((theme) => ({
    Tabs: {
        ...mixins.widthMax(),

        '& .MuiTabs-root': {
            ...mixins.borderBottomGrey(),
            marginBottom: theme.spacing(SPACING),
        },

        '& .MuiTab-root': {
            ...mixins.typo({ size: 18, weight: 'bold' }),
            color: APP_COLORS.TEXT['500'],
            textTransform: 'none',
            padding: 0,
            marginRight: theme.spacing(SPACING),
            minWidth: 'unset',
            height: theme.spacing(7),
        },
        '&.__innerTabs .MuiTab-root:first-child': {
            marginLeft: theme.spacing(SPACING),
        },

        '& .MuiTab-root.Mui-selected': {
            color: APP_COLORS.PRIMARY['500'],
        },

        '& .MuiTab-wrapper': {
            ...mixins.flexRowCenterLeft(),
        },

        '&.__innerTabs .activeTabContent': {
            margin: theme.spacing(0, SPACING),
        },
    },
}));

export default function Tabs({
    className,
    items,
    activeTabKey,
    onTabChange,
    variant = 'outer',
    WrapperComponent,
    showSingleTab = false,
}: ITabsProps) {
    const classes = useStyles();
    const visibleTabItems = items.filter((tabItem) => !tabItem.hidden);

    const activeTab = visibleTabItems.find((tabItem) => tabItem.key === activeTabKey);
    const {
        wrapWithContentBox = true,
        noHorizontalPadding = false,
    } = activeTab || {};

    return (
        <div className={clsx(classes.Tabs, (variant === 'inner') && '__innerTabs', className)}>
            {((visibleTabItems.length > 1) || showSingleTab) && (
                <MuiTabs
                    value={activeTabKey}
                    onChange={changeTab}
                    indicatorColor="primary"
                    selectionFollowsFocus={false}
                >
                    {visibleTabItems.map((tabItem) => (
                        <MuiTab
                            key={`tab-item-${tabItem.key}`}
                            label={<Translate {...toI18nLabel(tabItem.label)} />}
                            value={tabItem.key}
                            disabled={tabItem.disabled}
                            disableFocusRipple
                            disableRipple
                        />
                    ))}
                </MuiTabs>
            )}

            {wrapWithContentBox && (
                <ContentBox
                    className="activeTabContent"
                    noWrapperPadding
                    noHorizontalPadding={noHorizontalPadding}
                >
                    {renderTabComponentConditionallyWrapped()}
                </ContentBox>
            )}
            {!wrapWithContentBox && (
                <div className="activeTabContent">
                    {renderTabComponentConditionallyWrapped()}
                </div>
            )}
        </div>
    );

    function renderTabComponentConditionallyWrapped() {
        if (WrapperComponent) {
            return (
                <WrapperComponent
                    renderWrapped={renderActiveTabComponent}
                />
            );
        }

        return renderActiveTabComponent();
    }

    function renderActiveTabComponent() {
        if (!activeTab) {
            return <Translate msg="common.tabs.tab_key_invalid" />;
        }

        return (
            <activeTab.component />
        );
    }

    function changeTab(event: React.ChangeEvent<unknown>, tabKey: string) {
        onTabChange({ tabKey });
    }
}
