import React from 'react';
import clsx from 'clsx';
import { TLabel } from 'models/general.models';
import { TTrendDirection } from 'utils/entities/mgmtReporting/mgmtReporting.utils';
import { makeStyles, mixins } from 'views/styling';
import ContentTitle from 'views/common/layout/ContentTitle';
import Text from 'views/common/widget/Text';
import ContentBox from 'views/common/layout/ContentBox';
import { TrendIndicator } from 'views/common/charts/TrendIndicator';
import { MgmtReportingBoxSection } from 'views/mgmtReporting/blocks/MgmtReportingBoxSection';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IEntityWrapperProps, initEntityWrapper, IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { initNotifiable, INotifiableConfig } from 'views/common/widget/Notifiable';
import { IInfoIconTooltipProps } from 'views/common/widget/InfoIconTooltip.models';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';

interface IMgmtReportingBoxProps<MainMetricReport> {
    className?: string;
    top: {
        title: TLabel;
        description?: TLabel;
        custom?: TTopCustomConfig;
        tooltip?: Pick<IInfoIconTooltipProps, 'name' | 'simpleContent'>;
    },
    mainMetric?: IMainMetricConfig<MainMetricReport>,
    sections: IMgmtReportingBoxSectionConfig[];
}

export type TTopCustomConfig = INotifiableConfig;

// eslint-disable-next-line max-len
interface IMainMetricConfig<MainMetricReport> extends Pick<IEntityWrapperProps<MainMetricReport>, 'asyncEntitySelector'> {
    notifications: StateChangeNotification[];
    // eslint-disable-next-line max-len
    isDataSufficientValidator?: (props: Pick<IRenderDataProps<MainMetricReport>, 'data' | 'isAnyAsyncOperationBusy'>) => boolean;
    // eslint-disable-next-line max-len
    calculator: (props: Pick<IRenderDataProps<MainMetricReport>, 'data' | 'isAnyAsyncOperationBusy' | 'translator'>) => {
        value: TLabel;
        trendDirection?: TTrendDirection;
        reportingPeriodInfo?: TLabel;
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IMgmtReportingBoxSectionConfig<EntityData = any>
    extends Pick<IEntityWrapperProps, 'asyncEntitySelector'> {
    title: () => TLabel;
    notifications: StateChangeNotification[];
    renderContent: (renderProps: IRenderDataProps<EntityData>) => React.ReactNode;
    hidden?: boolean;
}

const useStyles = makeStyles((theme) => ({
    MgmtReportingBox: {
        ...mixins.flexColTopLeft(),
        ...mixins.widthMax(),
        paddingLeft: theme.spacing(1),

        '& .__topRow': {
            ...mixins.widthMax(),
            ...mixins.flexRow({ alignMain: 'space-between' }),

            '& .__blockTitle': {
                paddingBottom: theme.spacing(1),
            },

            '& .__topCustom': {
                marginTop: theme.spacing(2),
            },
        },

        '& .__subRow': {
            ...mixins.widthMax(),
            ...mixins.flexRow({ alignMain: 'flex-start' }),

            '& .__descriptionTooltip': {
                marginLeft: theme.spacing(0.5),
            },
        },

        '& .__mainMetric': {
            ...mixins.flexRowCenterLeft(),

            '& .ContentTitle .__titleLabel': {
                marginRight: 4,
            },

            '& .__reportingPeriodInfo': {
                ...mixins.typoText({ size: 12 }),
                paddingTop: theme.spacing(2),
                marginLeft: 4,
            },

            '& .__titleTrendIndicator': {
                marginTop: 14,
            },
        },

        '& .__boxSectionWrapper': {
            ...mixins.widthMax(),
            minHeight: 100, /* so that the initial loaders of different sections are not too close to each other */
        },
    },
}));

export default function MgmtReportingBox<MainMetricReport>({
    className,
    top,
    mainMetric,
    sections,
}: IMgmtReportingBoxProps<MainMetricReport>) {
    const classes = useStyles();
    const sectionsToShow = sections.filter((section) => !section.hidden);

    return (
        <ContentBox>
            <div className={clsx(classes.MgmtReportingBox, className)}>
                <div className="__topRow">
                    <ContentTitle
                        className="__blockTitle"
                        variant="section"
                        label={top.title}
                    />

                    {top.custom && <TopCustom />}
                </div>

                {(top.description || top.tooltip) && (
                    <div className="__subRow">
                        {top.description && <Text label={top.description} />}
                        {top.tooltip && (
                            <InfoIconTooltip
                                name={top.tooltip.name}
                                className="__descriptionTooltip"
                                simpleContent={top.tooltip.simpleContent}
                                infoIconColor="primary"
                            />
                        )}
                    </div>
                )}

                {mainMetric && <MainMetric />}

                {sectionsToShow.map((section, index) => {
                    const key = `MgmtReportingBox-section-${index}`;
                    const isLastItemOfArray = index === sectionsToShow.length - 1;

                    return (
                        <BoxSectionWrapper
                            key={key}
                            section={section}
                            isLastSection={isLastItemOfArray}
                        />
                    );
                })}
            </div>
        </ContentBox>
    );

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function TopCustom() {
        const TopCustomNotifiable = initNotifiable({
            notifications: top.custom.notifications,
        });

        return (
            <TopCustomNotifiable
                className={clsx('__topCustom', top.custom.className)}
                renderContent={top.custom.renderContent}
            />
        );
    }

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function MainMetric() {
        const MainMetricEntityWrapper = initEntityWrapper({
            notifications: mainMetric.notifications,
        });

        return (
            <MainMetricEntityWrapper
                asyncEntitySelector={mainMetric.asyncEntitySelector}
                showLoader={false}
                renderData={renderMainMetric}
            />
        );

        function renderMainMetric({
            data,
            isAnyAsyncOperationBusy,
            translator,
        }: IRenderDataProps<MainMetricReport>) {
            if (mainMetric.isDataSufficientValidator) {
                if (!mainMetric.isDataSufficientValidator({ data, isAnyAsyncOperationBusy })) {
                    return null;
                }
            }

            const {
                value,
                trendDirection,
                reportingPeriodInfo,
            } = mainMetric.calculator({
                data,
                isAnyAsyncOperationBusy,
                translator,
            });

            return (
                <div className="__mainMetric">
                    <ContentTitle
                        variant="title"
                        label={value}
                    />
                    {trendDirection && (
                        <TrendIndicator
                            className="__titleTrendIndicator"
                            direction={trendDirection}
                            size="L"
                        />
                    )}
                    {reportingPeriodInfo && (
                        <div className="__reportingPeriodInfo">
                            <Text label={reportingPeriodInfo} />
                        </div>
                    )}
                </div>
            );
        }
    }

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function BoxSectionWrapper({
        section,
        isLastSection,
    }: {
        section: IMgmtReportingBoxSectionConfig;
        isLastSection: boolean;
    }) {
        const SectionEntityWrapper = initEntityWrapper({
            notifications: section.notifications,
        });

        return (
            <SectionEntityWrapper
                className="__boxSectionWrapper"
                asyncEntitySelector={section.asyncEntitySelector}
                renderData={renderBoxSectionWithItsCustomContent}
                markAsPositionRelative
                loaderPosition="absolute"
            />
        );

        function renderBoxSectionWithItsCustomContent(renderProps: IRenderDataProps<unknown>) {
            return (
                <MgmtReportingBoxSection
                    title={section.title}
                    showBottomBorder={!isLastSection}
                >
                    {section.renderContent(renderProps)}
                </MgmtReportingBoxSection>
            );
        }
    }
}
