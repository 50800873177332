import getRandomNumber from '@snipsonian/core/cjs/number/getRandomNumber';
import { APP_COLORS } from 'config/styling/colors';

export const MGMT_REPORTING_LINE_CHART_CONFIG = {
    svg: {
        maxWidth: 680,
        maxHeight: 400,
        minWidth: 350,
    },
    axis: {
        x: {
            height: 32,
            marginRight: 20,
        },
        y: {
            width: 57,
            marginTop: 16,
            label: {
                paddingLeft: 16,
            },
        },
    },
    labels: {
        width: 100,
        paddingLeft: 10,
    },
    lineColor: APP_COLORS.SYSTEM.WHITE,
    lines: {
        transitionDurationInMillis: 1200,
    },
};

export const MGMT_REPORTING_DONUT_CHART_CONFIG = {
    svg: {
        maxWidth: 230,
        maxHeight: 230,
        minWidth: 150,
    },
    pie: {
        outerRadius: 115, /* entire pie is same as the svg as we don't show labels (separate legend) */
        innerRadius: 92,
        transitionDurationInMillis: 1200,
        slices: {
            padAngleInRadians: 0.015,
            cornerRadius: 0,
        },
        percentageLines: {
            outerRadius: 115 + 5 + 15, /* where 15 = the actual length of the percentage Lines */
            innerRadius: 115 + 5, /* some padding so that these lines do not touch the pie slices */
            strokeWidth: 2,
            strokeDasharray: '0.25 6',
            strokeLinecap: 'round',
        },
    },
};

export const MGMT_REPORTING_BAR_CHART_CONFIG = {
    svg: {
        maxWidth: 560,
        maxHeight: 290,
        minWidth: 300,
    },
    axis: {
        x: {
            height: 32,
            marginRight: 16,
        },
        y: {
            width: 57,
            marginTop: 24,
            label: {
                paddingLeft: 16,
            },
        },
    },
    bars: {
        /**
         * The actual paddings will be calculated based on the absolute bar width (and on the
         * padding ratio, the number of bars and the graph width)
         */
        absolute: {
            barWidth: 32,
            /* a ratio of 2 --> the inner space between bars will be twice as wide as the outer space
               left of the first bar */
            innerVsOuterPaddingRatio: 2,
            groupInnerVsOuterPaddingRatio: 0.5,
        },
        /** the actual barWidth will be calculated based on the relative padding sizes */
        relative: {
            paddingInner: 0.2,
            paddingOuter: 0.1,
            groupsPaddingInner: 0.05,
        },
        barSeparatorLine: {
            color: 'white',
        },
        endCornerRadius: 4,
        transitionDurationInMillis: 1200,
    },
};

export const MGMT_REPORTING_CHART_COLORS = [
    /* from design: */
    '#5F508E',
    '#DE8C74',
    '#81C12F',
    '#FFD084',
    '#8ADAF2',
    '#C2B0FF',
    '#A3AFBB',
    /* custom added to have some extra: */
    '#538be6',
    '#8d63d4',
    '#d463d2',
    '#bdd463',
];

export function getMgmtReportingChartColor(index: number) {
    if (index >= MGMT_REPORTING_CHART_COLORS.length) {
        console.log(`===== No chart color defined for index ${index} =====`);
        return `#${getRandomNumber({ min: 111111, max: 999999 })}`;
    }
    return MGMT_REPORTING_CHART_COLORS[index];
}

export const MGMT_REPORTING_BAR_CHART_COLORS = {
    positive: [
        '#67A716',
        '#B9DD8B',
    ],
    negative: [
        '#C4725A',
        '#D59785',
    ],
};

export function getMgmtReportingBarChartColor(index: number, isPositive: boolean) {
    const colors = isPositive
        ? MGMT_REPORTING_BAR_CHART_COLORS.positive
        : MGMT_REPORTING_BAR_CHART_COLORS.negative;

    if (index >= colors.length) {
        console.log(`===== No bar chart color defined for index ${index} =====`);
        return `#${getRandomNumber({ min: 111111, max: 999999 })}`;
    }
    return colors[index];
}
