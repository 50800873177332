import { ONE_MINUTE_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';

const DEFAULT_IDLE_THRESHOLD_IN_MINUTES = 5;

export interface IUserActivityMonitor {
    wasUserRecentlyActive: (props?: { idleThresholdInMinutes?: number }) => boolean;
    stopMonitoring: () => void;
}

/**
 * Mechanism:
 * - A timer will increase this counter value every minute.
 * - However, on certain user activities (e.g. something clicked) the counter is being reset to 0.
 * - So When checking if the user is still active, we know via this counter how many minutes the user has been idle.
 */
export function initUserActivityMonitor(): IUserActivityMonitor {
    let userIdleMinutesCounter = 0;

    const intervalThatIncreasesCounterEveryMinute = window.setInterval(
        () => {
            userIdleMinutesCounter += 1;
        },
        ONE_MINUTE_IN_MILLIS,
    );

    window.addEventListener('keydown', resetUserIdleCounter);
    window.addEventListener('mousedown', resetUserIdleCounter);

    return {
        wasUserRecentlyActive({
            idleThresholdInMinutes = DEFAULT_IDLE_THRESHOLD_IN_MINUTES,
        }: {
            idleThresholdInMinutes?: number;
        } = {}) {
            return userIdleMinutesCounter < idleThresholdInMinutes;
        },
        stopMonitoring() {
            window.clearInterval(intervalThatIncreasesCounterEveryMinute);

            window.removeEventListener('keydown', resetUserIdleCounter);
            window.removeEventListener('mousedown', resetUserIdleCounter);
        },
    };

    function resetUserIdleCounter() {
        userIdleMinutesCounter = 0;
    }
}
