import packageJson from '../../../package.json';

/**
 * - when on server --> returns the tag (extracted from the release set within index.html)
 * - when on localhost --> returns the version within the package.json of the console-web package.
 *     This will just be a semver format like 'major.minor.patch'.
 */
export function getAppVersion(): string {
    const release = getSentryRelease();

    if (release) {
        const [version] = release.split('-');
        return version;
    }

    return `#${packageJson.version}`;
}

export function getSentryRelease(): string {
    return getBuildInfo().release;
}

function getBuildInfo(): IConsoleConfigBuildInfo {
    return window.CONSOLE_CONFIG.buildInfo;
}
