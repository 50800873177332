import React from 'react';
import { makeStyles, mixins } from 'views/styling';
import ExtendedInputForm, {
    IExtendedInputFormContext,
    IFormValues,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';
import { ExtendedInputFormName } from 'views/common/inputs/extended/extendedInputFormManager';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import Modal from 'views/common/layout/Modal';
import { triggerAddOutputKey } from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { getStoryManagerOutputKeyNamesList } from 'state/ui/storyManager.selector';
import { getOutputKeyDetailsSchema } from './outputKeyDetailsSchema';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.add_output_key_modal';

interface IPublicProps {
    open: boolean;
    onClose: () => void;
    addOptions?: {
        insertBeforeOutputKeyId?: string;
    },
}

interface IAddOutputKeyFormValues extends IFormValues {
    name: string;
    description: string;
}

const useStyles = makeStyles(() => ({
    AddOutputKeyModal: {
        ...mixins.flexColTopLeft(),
        ...mixins.widthMax(),

        '& .__actions': {
            ...mixins.flexRowCenterRight(),
            ...mixins.widthMax(),
        },
    },
}));

export default function AddOutputKeyModal({
    onClose,
    open,
    addOptions,
}: IPublicProps) {
    const classes = useStyles();

    return (
        <Modal
            id="add-output-key-modal"
            title={{
                msg: `${TRANSLATION_PREFIX}.${addOptions?.insertBeforeOutputKeyId ? 'title_insert' : 'title'}`,
            }}
            onClose={onClose}
            open={open}
            emphasizeTitle
        >
            <div className={classes.AddOutputKeyModal}>
                <ExtendedInputForm<IAddOutputKeyFormValues>
                    className="form"
                    name={ExtendedInputFormName.storyManagerAddOutputKey}
                    labelPrefix={TRANSLATION_PREFIX}
                    submit={{
                        onSubmit: onAddOutputKey,
                        // eslint-disable-next-line max-len
                        actionLabel: `${TRANSLATION_PREFIX}.actions.${addOptions?.insertBeforeOutputKeyId ? 'insert' : 'add'}`,
                    }}
                    initialValues={{
                        description: '',
                        name: '',
                    }}
                    renderFormFields={renderFormFields}
                    schema={getOutputKeyDetailsSchema({
                        allOtherOutputKeyNames: getStoryManagerOutputKeyNamesList(),
                    })}
                />
            </div>
        </Modal>
    );

    async function onAddOutputKey(submitProps: IOnSubmitProps<IAddOutputKeyFormValues>) {
        triggerAddOutputKey({
            description: submitProps.values.description,
            name: submitProps.values.name,
        }, {
            insertBeforeOutputKeyId: addOptions?.insertBeforeOutputKeyId,
        });
    }

    function renderFormFields(context: IExtendedInputFormContext<IAddOutputKeyFormValues>) {
        return (
            <>
                <ExtendedInputText
                    formField={context.fields.name}
                    wrapper={{
                        label: 'fields.name',
                    }}
                />
                <ExtendedInputText
                    formField={context.fields.description}
                    wrapper={{
                        label: 'fields.description',
                    }}
                />
            </>
        );
    }
}
