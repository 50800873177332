import splitEvery from 'ramda/src/splitEvery';
import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import {
    IApiBaseListResponse,
    IApiEntityListResponseWithPageNr,
} from '../../models/api.models';
import {
    IFetchInstrumentsApiInput,
    IFetchSpecificInstrumentsApiInput,
    IInstrumentEntityData,
    TInstrumentsData,
} from '../../models/portfolioMgmt/instruments.models';
import {
    MAX_PAGE_ITEM_LIMIT, DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR, LIST_RESPONSE_NO_COUNT,
} from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { get } from '../coreApiRequestWrapper';

/**
 * Fetches the instrument details for all the given instruments,
 * if needed (more than 100 id's) in chunks of 100 id's which are called in parallel.
 */
export async function fetchAllSpecificInstruments({
    instrumentIds,
    universe_type,
    currency,
    failOnApiError = true,
    underlyingApiRequestConfig,
// eslint-disable-next-line max-len
}: Pick<IFetchSpecificInstrumentsApiInput, 'instrumentIds' | 'universe_type' | 'currency'> & IUnderlyingRequestApiInput & {
    failOnApiError?: boolean;
}): Promise<IApiBaseListResponse<IInstrumentEntityData>> {
    const instrumentIdArrayChunks = splitEvery(MAX_PAGE_ITEM_LIMIT, instrumentIds);

    const chunkResults = await Promise.all(
        instrumentIdArrayChunks.map((max100InstrumentIds) => fetchInstruments({
            limit: MAX_PAGE_ITEM_LIMIT,
            instrumentIds: max100InstrumentIds,
            universe_type,
            currency,
            underlyingApiRequestConfig,
        })
            .catch((error) => {
                if (failOnApiError) {
                    return Promise.reject(error);
                }

                return Promise.resolve({
                    next: null,
                    offset: null,
                    count: LIST_RESPONSE_NO_COUNT,
                    results: [],
                });
            })),
    );

    return chunkResults.reduce(
        (accumulator, chunkResult) => {
            accumulator.results = accumulator.results.concat(chunkResult.results);

            return accumulator;
        },
        {
            next: null,
            offset: null,
            results: [],
        } as IApiBaseListResponse<IInstrumentEntityData>,
    );
}

export function fetchInstruments({
    name,
    offset,
    instrumentIds,
    partialInstrumentId,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    universe_type,
    currency,
    underlyingApiRequestConfig,
}: IFetchInstrumentsApiInput & IUnderlyingRequestApiInput) {
    return get<TInstrumentsData, IApiEntityListResponseWithPageNr<IInstrumentEntityData>>({
        url: CoreApiPath.INSTRUMENTS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .contains({ field: 'name', value: name })
                .fieldIn({ field: 'id', value: instrumentIds })
                .contains({ field: 'id', value: partialInstrumentId })
                .build(),
            universe_type,
            currency,
            offset,
            limit,
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}
