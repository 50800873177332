import React from 'react';
import clsx from 'clsx';
import Switch from '@mui/material/Switch';
import { APP_COLORS } from 'config/styling/colors';
import { FOCUS_OUTLINE, FOCUS_OUTLINE_OFFSET } from 'config/styling/elevation';
import { makeStyles, mixins } from 'views/styling';
import { toCssBorder } from 'views/assets/cssInJs/mixins/box';
import { IInputCheckboxFieldProps } from './InputCheckboxField';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IInputToggleFieldProps<Name extends string = string>
    extends IInputCheckboxFieldProps<Name>, Partial<IStyleProps> {}

interface IStyleProps {
    size: 'M' | 'S';
}

const useStyles = makeStyles((theme) => ({
    InputToggleField: {
        '&.MuiSwitch-root': {
            width: ({ size }: IStyleProps) => theme.spacing(size === 'S' ? 3 : 5),
            height: ({ size }: IStyleProps) => theme.spacing(size === 'S' ? 2 : 3),
            padding: 0,
            display: 'flex',
        },
        '& .MuiSwitch-switchBase': {
            ...mixins.widthMax(),
            padding: ({ size }: IStyleProps) => (size === 'S' ? 3 : 4),
            color: APP_COLORS.GREY['300'],
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(0px)',
            color: APP_COLORS.SYSTEM.WHITE,
        },
        '& .MuiSwitch-track': {
            border: toCssBorder({ width: 2, color: APP_COLORS.GREY['300'] }),
            borderRadius: 13,
            opacity: 1,
            backgroundColor: APP_COLORS.SYSTEM.WHITE,
        },
        /* div + p : Selects the first <p> element that are placed immediately after <div> elements */
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: APP_COLORS.PRIMARY['500'],
            borderColor: APP_COLORS.PRIMARY['500'],
        },
        '& input': {
            /* some overrules because otherwise the onChange event is not being fired (because the input
               was not behind the toggle) */
            width: 'inherit',
            left: 0,
        },
        '& .MuiSwitch-thumb': {
            width: ({ size }: IStyleProps) => (size === 'S' ? 10 : 16),
            height: ({ size }: IStyleProps) => (size === 'S' ? 10 : 16),
            boxShadow: 'none',
            marginLeft: ({ size }: IStyleProps) => (size === 'S' ? -6 : -14),
        },
        '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
            transform: ({ size }: IStyleProps) => `translateX(${size === 'S' ? 8 : 16}px)`,
        },

        '&:hover .MuiSwitch-switchBase:not(.Mui-disabled) + .MuiSwitch-track': {
            backgroundColor: APP_COLORS.GREY['100'],
        },
        '&:hover .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: APP_COLORS.PRIMARY['700'],
            borderColor: APP_COLORS.PRIMARY['700'],
        },
        '&:focus-within:not(:hover)': {
            outline: FOCUS_OUTLINE,
            outlineOffset: FOCUS_OUTLINE_OFFSET,
        },

        '& .MuiSwitch-switchBase.Mui-disabled': {
            '&.Mui-checked': {
                color: APP_COLORS.SYSTEM.WHITE,
            },
            '&.Mui-checked + .MuiSwitch-track': {
                borderColor: APP_COLORS.PRIMARY['100'],
                backgroundColor: APP_COLORS.PRIMARY['100'],
            },
        },
    },
}));

export default function InputToggleField<Name extends string = string>({
    id,
    className,
    name,
    checked = false,
    onChange,
    disabled = false,
    size = 'M',
}: IInputToggleFieldProps<Name>) {
    const classes = useStyles({ size });

    return (
        <Switch
            id={id}
            className={clsx(classes.InputToggleField, className)}
            name={name}
            checked={checked}
            onChange={onChangeSwitch}
            disabled={disabled}
            disableRipple
        />
    );

    function onChangeSwitch(event: React.ChangeEvent<HTMLInputElement>) {
        onChange({
            name: event.target.name as Name,
            checked: event.target.checked,
        });
    }
}
