import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'views/styling';
import { mapArrayLikeObjectToArray } from '@console/common/utils/object/objectUtils';
import {
    IOutputKey,
    IScenario,
    TStoryManagerStatus,
} from '@console/bff/models/storyteller/storymanager.models';
import { IColValues, IDataItem, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getStoryManagerScenarioStatus } from 'state/ui/storyManager.selector';
import { triggerAddScenario, triggerDeleteScenarios } from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import DataTable from 'views/common/list/DataTable';
import { observe, IObserveProps } from 'views/observe';
import { AddIcon, DuplicateIcon, TrashIcon } from 'views/common/icons';
import { IActionItem } from 'views/common/buttons/ActionButtons';
import ListPageForFixedItems from 'views/common/list/ListPageForFixedItems';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { ROUTE_KEY } from 'views/routeKeys';
import { redirectTo } from 'views/routes';
import { UiPageKey } from 'models/state/ui.models';
import {
    conditionallyDisableStoryManagerActions,
    conditionallyDisableStoryManagerAddAction,
} from 'views/apps/StoryTeller/StoryManager/storyManagerActions';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.detail.scenario_list';
const COL_TRANSLATION_PREFIX = `${TRANSLATION_PREFIX}.columns`;

interface IScenariosCols extends IColValues {
    name: string;
    status: TStoryManagerStatus;
}
const COLUMNS: TDataColumns<IScenariosCols> = {
    name: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.name`,
        },
        percentWidth: 70,
    },
    status: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.status`,
        },
        percentWidth: 30,
        data: {
            render: ({ cellValue }) => (
                <StoryManagerStatus
                    status={cellValue as TStoryManagerStatus}
                />
            ),
        },
    },
};

const useStyles = makeStyles((theme) => ({
    ScenariosList: {
        marginTop: theme.spacing(1),
        width: '100%',

        '& .data-table': {
            marginTop: theme.spacing(1),
        },

        '& .actions': {
            top: 0,
        },
    },
}));

interface IPublicProps {
    outputKeyId: string;
    outputKey: IOutputKey;
    className?: string;
    isScenarioEditorMode: boolean;
    canUserModifyScenarios: boolean;
}

function ScenariosList({
    outputKeyId,
    outputKey,
    className,
    isScenarioEditorMode,
    canUserModifyScenarios,
    state,
}: IPublicProps & IObserveProps) {
    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const classes = useStyles();
    const { databaseId } = useRouteParams();

    return (
        <div className={clsx(classes.ScenariosList, className)}>
            <ListPageForFixedItems
                list={{
                    items: mapArrayLikeObjectToArray(outputKey.scenarios, { addKeyAsId: true }),
                    renderData: renderScenarioTable,
                }}
                // eslint-disable-next-line max-len
                notificationToTriggerOnUiVarChanges={StateChangeNotification.STORY_MANAGER_OUTPUT_KEYS_SCENARIO_LIST_UI_VARS}
                uiPageKey={UiPageKey.storyManagerOutputKeysScenarioList}
                selectConfig={isScenarioEditorMode && canUserModifyScenarios && {
                    selectedItemIds,
                    onChange: setSelectedItemIds,
                    withSelectAll: true,
                    selectActions: getListSelectActions(),
                }}
                actions={getListTopActions()}
            />
        </div>
    );

    function renderScenarioTable({
        data,
        extraData,
        translator,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }: IRenderDataProps<(IScenario & { id: string; })[]>) {
        const scenarioItems: IDataItem<IScenariosCols>[] =
            data.map((scenario) => ({
                id: scenario.id,
                colValues: {
                    name: translator({
                        msg: `${COL_TRANSLATION_PREFIX}.name_value`,
                        placeholders: { number: Number(scenario.id) + 1 },
                    }),
                    status: getStoryManagerScenarioStatus(state, scenario),
                },
            }));

        return (
            <DataTable
                className="data-table"
                cols={COLUMNS}
                items={scenarioItems}
                getActions={getScenarioItemActions}
                selectData={extraData?.selectData}
                onItemRowClicked={navigateToScenarioDetail}
            />
        );
    }

    function navigateToScenarioDetail(item: IDataItem<IScenariosCols>) {
        redirectTo({
            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_SCENARIO_DETAIL,
            params: {
                databaseId,
                outputKeyId,
                scenarioIndex: item.id,
            },
        });
    }

    function getScenarioItemActions(item: IDataItem<IScenariosCols>): IActionItem[] {
        if (!isScenarioEditorMode) {
            return [];
        }

        return conditionallyDisableStoryManagerActions({
            canUserModify: canUserModifyScenarios,
            actions: [{
                id: `${item.id}-output-key-detail-scenario-actions-duplicate`,
                label: `${COL_TRANSLATION_PREFIX}.actions.duplicate`,
                onExecute: async () => triggerAddScenario(outputKeyId, outputKey.scenarios[item.id]),
                icon: <DuplicateIcon />,
                variant: 'extra',
            }, {
                id: `${item.id}-output-key-detail-scenario-actions-delete`,
                label: `${COL_TRANSLATION_PREFIX}.actions.delete`,
                onExecute: async () => triggerDeleteScenarios(outputKeyId, [item.id]),
                icon: <TrashIcon />,
                variant: 'extra',
            }],
        });
    }

    function getListSelectActions(): IActionItem[] {
        return [{
            id: 'output-key-detail-scenario-delete-multiple',
            label: `${COL_TRANSLATION_PREFIX}.actions.delete`,
            onExecute: async () => triggerDeleteScenarios(outputKeyId, selectedItemIds),
            icon: <TrashIcon />,
            variant: 'main-icon-bare',
        }];
    }

    function getListTopActions(): IActionItem[] {
        if (!isScenarioEditorMode) {
            return [];
        }

        return [
            conditionallyDisableStoryManagerAddAction({
                canUserModify: canUserModifyScenarios,
                action: {
                    id: 'output-key-detail-scenario-add',
                    label: `${TRANSLATION_PREFIX}.actions.add`,
                    onExecute: async () => triggerAddScenario(outputKeyId),
                    icon: <AddIcon />,
                    variant: 'main-icon-grey',
                },
            }),
        ];
    }
}

export default observe(
    [StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE],
    ScenariosList,
);
