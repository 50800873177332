import React from 'react';
import clsx from 'clsx';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import getArrayCopy from '@snipsonian/core/cjs/array/getArrayCopy';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { anyComparerDescending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { TLabel } from 'models/general.models';
import { TLineChartAxisYDomain } from 'models/lineChart.models';
import {
    getMgmtReportingChartColor,
    MGMT_REPORTING_LINE_CHART_CONFIG,
} from 'config/mgmtReporting/mgmtReportingCharts.config';
import { CHART_STYLING } from 'config/styling/chart';
import GenericLineChart, { IGenericLineChartProps } from 'views/common/charts/GenericLineChart';
import { ChartLegend, IChartLegendItem, TChartLegendVariant } from 'views/common/charts/ChartLegend';
import { makeStyles, mixins } from 'views/styling';

export interface IMgmtReportingOverlappingLineChartProps extends Omit<IGenericLineChartProps, 'id' | 'options'> {
    chartName: string;
    yLabel: TLabel;
    yDomain?: TLineChartAxisYDomain; /* default 'amount' */
    timezone?: string;
    chartLegend?: {
        variant: TChartLegendVariant;
    };
}

const useStyles = makeStyles((/* theme */) => ({
    MgmtReportingOverlappingLineChart: {
        ...mixins.widthMax(),

        '&.--legend-horizontal': {
            /* the 'horizontal' legend will be shown on the bottom of the donut chart */
            ...mixins.flexColTopCenter(),
        },

        '&.--legend-vertical': {
            /* the 'vertical' legend will be shown on the right of the donut chart */
            ...mixins.flexRowCenterLeft(),
        },
    },
}));

export default function MgmtReportingOverlappingLineChart({
    chartName,
    yLabel,
    yDomain = 'amount',
    timezone,
    chartLegend,
    data,
    ...otherProps
}: IMgmtReportingOverlappingLineChartProps) {
    const classes = useStyles();

    if (!isArrayWithValues(data) || !isArrayWithValues(data[0].dataPoints) || data[0].dataPoints.length < 2) {
        console.log('Not enough dataPoints to display the MgmtReportingOverlappingLineChart.');
        return null;
    }

    const sortedColoredData = getArrayCopy(data)
        .sort((lineA, lineB) => anyComparerDescending(
            getLastItemOfArray(lineA.dataPoints).y,
            getLastItemOfArray(lineB.dataPoints).y,
        ))
        .map((item, index) => ({
            ...item,
            lineColor: getMgmtReportingChartColor(index),
        }));

    return (
        <div
            className={clsx(
                classes.MgmtReportingOverlappingLineChart,
                chartLegend && `--legend-${chartLegend.variant}`,
            )}
        >
            <GenericLineChart
                id={`mgmt_reporting_overlapping_line_chart_${chartName}`}
                options={{
                    dimensions: {
                        maxWidth: MGMT_REPORTING_LINE_CHART_CONFIG.svg.maxWidth,
                        maxHeight: MGMT_REPORTING_LINE_CHART_CONFIG.svg.maxHeight,
                        minWidth: MGMT_REPORTING_LINE_CHART_CONFIG.svg.minWidth,
                    },
                    axis: {
                        x: {
                            height: MGMT_REPORTING_LINE_CHART_CONFIG.axis.x.height,
                            marginRight: MGMT_REPORTING_LINE_CHART_CONFIG.axis.x.marginRight,
                            timezone,
                        },
                        y: {
                            width: MGMT_REPORTING_LINE_CHART_CONFIG.axis.y.width,
                            marginTop: MGMT_REPORTING_LINE_CHART_CONFIG.axis.y.marginTop,
                            text: {
                                label: yLabel,
                                paddingLeft: MGMT_REPORTING_LINE_CHART_CONFIG.axis.y.label.paddingLeft,
                            },
                            domain: yDomain,
                        },
                    },
                    labels: {
                        width: MGMT_REPORTING_LINE_CHART_CONFIG.labels.width,
                        paddingLeft: MGMT_REPORTING_LINE_CHART_CONFIG.labels.paddingLeft,
                    },
                    shouldStackLines: false,
                    shouldIncludeAreas: false,
                    shouldShowSelectedValuesEvenWhenZero: true,
                    transitionDurationInMillis: MGMT_REPORTING_LINE_CHART_CONFIG.lines.transitionDurationInMillis,
                    verticalMarker: {
                        lineColor: CHART_STYLING.colors.neutral['300'],
                    },
                }}
                data={sortedColoredData}
                {...otherProps}
            />

            {chartLegend && (
                <ChartLegend
                    variant={chartLegend.variant}
                    items={getChartLegendItems()}
                />
            )}
        </div>
    );

    function getChartLegendItems(): IChartLegendItem[] {
        return sortedColoredData.map((dataItem) => ({
            color: dataItem.lineColor,
            label: dataItem.label,
        }));
    }
}
