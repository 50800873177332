import { CSSProperties } from 'react';
import {
    AlignItemsProperty, JustifyContentProperty,
    FlexDirectionProperty, FlexWrapProperty,
} from 'csstype';

interface IFlexContainerProps {
    direction?: 'row' | 'col'; // default 'row'
    reverse?: boolean; // for the direction
    wrap?: FlexWrapProperty;
    alignMain?: JustifyContentProperty; // alignment along main axis
    alignCross?: AlignItemsProperty; // alignment along cross axis
    inline?: boolean;
}

export const flex = ({
    direction = 'row',
    reverse = false,
    wrap,
    alignMain,
    alignCross,
    inline,
}: IFlexContainerProps = {}): CSSProperties => ({
    display: inline ? 'inline-flex' : 'flex',
    flexDirection: direction === 'row'
        ? conditionallyAddRevereDirection('row', reverse)
        : conditionallyAddRevereDirection('column', reverse),
    flexWrap: wrap,
    justifyContent: alignMain,
    alignItems: alignCross,
});

export const flexCenter = (props: Omit<IFlexContainerProps, 'alignMain' | 'alignCross'> = {}) => flex({
    ...props,
    alignMain: 'center',
    alignCross: 'center',
});

export const flexRow = (props: Omit<IFlexContainerProps, 'direction'> = {}) => flex({ ...props, direction: 'row' });
export const flexCol = (props: Omit<IFlexContainerProps, 'direction'> = {}) => flex({ ...props, direction: 'col' });

/* syntax: flex<Direction><Vertical><Horizontal> */
export const flexRowCenterLeft = () => flexRow({ alignMain: 'flex-start', alignCross: 'center' });
export const flexRowCenterRight = () => flexRow({ alignMain: 'flex-end', alignCross: 'center' });
export const flexColTopLeft = () => flexCol({ alignMain: 'flex-start', alignCross: 'flex-start' });
export const flexColTopCenter = () => flexCol({ alignMain: 'flex-start', alignCross: 'center' });
export const flexColCenterLeft = () => flexCol({ alignMain: 'center', alignCross: 'flex-start' });
export const flexColCenterCenter = () => flexCol({ alignMain: 'center', alignCross: 'center' });

function conditionallyAddRevereDirection(direction: FlexDirectionProperty, reverse: boolean): FlexDirectionProperty {
    if (reverse) {
        return `${direction}-reverse` as FlexDirectionProperty;
    }
    return direction;
}
