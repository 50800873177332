import React from 'react';
import { Route } from 'react-router-dom';
import { IRoute } from 'models/route.models';
import { getStore } from 'state';
import { hasUserRequiredPermissions, isAuthenticatedUser } from 'state/auth/selectors';
import { ROUTE_KEY } from 'views/routeKeys';
import { getRoute } from 'views/routes';
import ErrorBoundary from 'views/common/error/ErrorBoundary';
import NotAllowed from 'views/common/error/NotAllowed';
import { isRouteEnabled } from 'state/ui/selectors';
import { getTenantSettings } from 'state/appConfig/selectors';

export default function AppRoute({
    routeKey,
    allowAnonymousAccess = false,
    children,
    ...rest
}: Omit<IRoute<ROUTE_KEY>, 'component'>) {
    const { getState } = getStore();

    return (
        <Route
            {...rest}
            render={() => {
                if (!allowAnonymousAccess && !isAuthenticatedUser(getState())) {
                    /* see onRouteConfig where this is checked PRIOR to rendering the route */
                    return '';
                }

                const route = getRoute({ routeKey });

                if (!hasUserRequiredPermissions(getState(), route.requiredPermissions)) {
                    return (
                        <NotAllowed />
                    );
                }

                if (!isRouteEnabled({ route, tenantSettings: getTenantSettings(getState()) })) {
                    return (
                        <NotAllowed />
                    );
                }

                return (
                    <ErrorBoundary positionRelativeToBodyHeader>
                        {children as React.ReactNode}
                    </ErrorBoundary>
                );
            }}
        />
    );
}
