import React from 'react';
import clsx from 'clsx';
import { TLabel } from 'models/general.models';
import { APP_COLORS, hexToRgba, OPACITY } from 'config/styling/colors';
import { SIZES } from 'config/styling/sizes';
import { getI18nLabelMsg } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';
import { TShowUntilDismissed } from 'views/common/widget/InfoIconTooltip.models';
import Text from 'views/common/widget/Text';
import InputToggleField from 'views/common/inputs/base/InputToggleField';
import { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';

export interface IInputWrapperProps extends IStyleProps {
    className?: string;
    label?: TLabel;
    tooltip?: TLabel; /* (for now) only shown when there is a label */
    showTooltipUntilDismissed?: TShowUntilDismissed; /* default false */
    disabled?: boolean; /* default false */
    readOnly?: boolean; /* default false */
    noPadding?: boolean; /* default false */
    maxWidth?: boolean; /* default true */
    hasError?: boolean; /* default false */
    children: React.ReactNode;
    shouldStyleInputWrapped?: boolean; /* default true */
    shouldShowOutlineInReadOnly?: boolean; /* default false */
    /* Only use this when you want a toggle (next to the label) to be able to show/hide the input field (= children) */
    inputHideable?: {
        isShown: boolean;
        onChange: (isInputShown: boolean) => void;
    };
}

interface IStyleProps {
    minWidth?: number;
    minHeight?: number; // default M
    shouldShowLabelInBold?: boolean; // default true
}

const useStyles = makeStyles((theme) => ({
    InputWrapper: ({ shouldShowLabelInBold }: IStyleProps) => ({
        position: 'relative',
        color: APP_COLORS.TEXT['500'],
        padding: theme.spacing(1, 0, 1, 0),
        minWidth: ({ minWidth }: IStyleProps) => minWidth,

        '& .input-wrapped': {
            ...mixins.flexRowCenterLeft(),
        },
        '& .input-wrapped .MuiInputBase-root': {
            minHeight: ({ minHeight }: IStyleProps) => minHeight,
        },
        '& .input-wrapped .wrapped-input-field': {
            ...mixins.border({ color: APP_COLORS.SYSTEM.BACKGROUND }),
            backgroundColor: APP_COLORS.SYSTEM.BACKGROUND,
            borderRadius: 8,
        },
        '& .input-wrapped:focus-within .wrapped-input-field': {
            ...mixins.border({ color: APP_COLORS.TEXT['500'] }),
            color: APP_COLORS.TEXT['500'],
            backgroundColor: APP_COLORS.SYSTEM.WHITE,
        },
        '&.__has-error .input-wrapped .wrapped-input-field': {
            ...mixins.border({ color: APP_COLORS.FEEDBACK.ERROR }),
        },
        '&.__disabled .input-wrapped .wrapped-input-field': {
            // ...mixins.border({ color: '#9AA8B6' }),
            ...mixins.border({ color: hexToRgba(APP_COLORS.TEXT['500'], OPACITY.VERY_HIGH) }),
            color: hexToRgba(APP_COLORS.TEXT['500'], OPACITY.MEDIUM),
            backgroundColor: APP_COLORS.SYSTEM.WHITE,
        },
        '&.__readOnly': {},
        '& .input-wrapped .readOnly': {
            paddingTop: 5,
        },
        '&.__readOnly .input-wrapped': {
            height: 'unset',
        },
        '&.__readOnly .input-wrapped .wrapped-input-field': {
            backgroundColor: 'unset',
            border: 'unset',
        },
        '&.__readOnly.__show-outline .input-wrapped .wrapped-input-field': {
            ...mixins.border({ color: APP_COLORS.SYSTEM.BACKGROUND }),
            backgroundColor: APP_COLORS.SYSTEM.WHITE,
        },
        '&.__no-padding': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '&.__max-width': {
            ...mixins.widthMax(),
        },
        '& .input-label': {
            ...mixins.flexRowCenterLeft(),
            ...mixins.typo({ size: 15, weight: shouldShowLabelInBold ? 700 : 400 }),
            padding: theme.spacing(0.5, 0),
            position: 'relative',
            width: 'fit-content',
        },
        '& .input-label__tooltip': {
            position: 'absolute',
            right: theme.spacing(-4),
        },
        '& .toggle-to-hide-input': {
            marginRight: theme.spacing(1),
            marginBottom: 2,
        },
    }),
}));

export default function InputWrapper({
    className,
    label,
    tooltip,
    showTooltipUntilDismissed,
    disabled = false,
    readOnly = false,
    noPadding = false,
    maxWidth = true,
    minWidth,
    minHeight = SIZES.INPUT.HEIGHT.M,
    hasError = false,
    shouldStyleInputWrapped = true,
    shouldShowOutlineInReadOnly = false,
    shouldShowLabelInBold = true,
    children,
    inputHideable,
}: IInputWrapperProps) {
    const classes = useStyles({ minWidth, minHeight, shouldShowLabelInBold });

    return (
        <div
            className={clsx(
                classes.InputWrapper,
                'InputWrapper',
                disabled && '__disabled',
                readOnly && '__readOnly',
                noPadding && '__no-padding',
                maxWidth && '__max-width',
                hasError && '__has-error',
                shouldShowOutlineInReadOnly && '__show-outline',
                className,
            )}
        >
            {label && (
                <div className="input-label">
                    {inputHideable && (
                        <InputToggleField
                            className="toggle-to-hide-input"
                            name={`hide-input-toggle_${label}`}
                            checked={inputHideable.isShown}
                            onChange={onChangeInputShown}
                            disabled={readOnly}
                            size="S"
                        />
                    )}

                    <Text label={label} />

                    {tooltip && (
                        <InfoIconTooltip
                            name={`input-tooltip_${getI18nLabelMsg(label)}`}
                            simpleContent={{
                                // title: label, // no need to show the label again as title in the tooltip
                                info: tooltip,
                            }}
                            className="input-label__tooltip"
                            showUntilDismissed={showTooltipUntilDismissed}
                        />
                    )}
                </div>
            )}
            {(!inputHideable || inputHideable.isShown) && (
                <div className={clsx(shouldStyleInputWrapped && 'input-wrapped')}>
                    {children}
                </div>
            )}
        </div>
    );

    function onChangeInputShown({ checked }: IOnChangeCheckboxProps) {
        if (inputHideable) {
            inputHideable.onChange(checked);
        }
    }
}
