import React, { useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import RouteLink from 'views/common/routing/RouteLink';
import { ROUTE_KEY } from 'views/routeKeys';
import TextButton from 'views/common/buttons/TextButton';
import { getStore } from 'state';
import {
    triggerFlashError, triggerFlashInfo, triggerFlashSuccess, triggerFlashWarning,
} from 'state/ui/flashMessages.actions';
import Typography from '@mui/material/Typography';
import ContentBox from 'views/common/layout/ContentBox';

export default function StyleGuide() {
    const [counter, setCounter] = useState(0);
    const { dispatch } = getStore();

    if (counter === 1) {
        // Simulate a JS error
        throw new Error('Simulated error');
    }

    return (
        <ContentBox titleLabel="main.style_guide.title">
            <div className="StyleGuide">
                <RouteLink
                    to={ROUTE_KEY.R_HOME}
                    query={{
                        test: 'q w e',
                        bool: true,
                        nr: 7096,
                    }}
                >
                    Link with query param
                </RouteLink>
                <RouterNavLink
                    to="/non-existing-page"
                >
                    Link to non-existing page
                </RouterNavLink>

                <Typography variant="h4">H4 Buttons</Typography>
                <TextButton
                    id="test-success-flash"
                    size="XL"
                    onClick={() => dispatch(triggerFlashSuccess({
                        translationKey: 'auth.reset_password.flash_success',
                    }))}
                    label="test success flash"
                />
                <TextButton
                    id="test-warning-flash"
                    onClick={() => dispatch(triggerFlashWarning({
                        translationKey: 'auth.reset_password.flash_success',
                    }))}
                    label="test warning flash"
                />
                <TextButton
                    id="test-error-flash-with-route"
                    color="secondary"
                    size="M"
                    onClick={() => dispatch(triggerFlashError({
                        translationKey: 'auth.reset_password.flash_success',
                        navigateToRoute: {
                            routeKey: ROUTE_KEY.R_HORIZONS_LIST,
                        },
                    }))}
                    label="test error flash with route"
                />
                <TextButton
                    id="test-diabled-button"
                    size="S"
                    disabled
                    onClick={() => console.log('This disabled button should not have been clickable!')}
                    label="disabled button"
                />
                <TextButton
                    id="test-simulate-error"
                    size="XS"
                    variant="outlined"
                    onClick={() => {
                        setCounter(counter + 1);
                    }}
                    label="simulate error"
                />

                <Typography variant="h4">H4 Action links</Typography>
                <TextButton
                    id="test-info-flash"
                    size="M"
                    variant="bare"
                    color="primary"
                    onClick={() => dispatch(triggerFlashInfo({
                        translationKey: 'auth.reset_password.flash_success',
                    }))}
                    label="test info flash"
                />
                <TextButton
                    id="test-info-flash-2"
                    size="M"
                    variant="bare"
                    color="secondary"
                    onClick={() => dispatch(triggerFlashInfo({
                        translationKey: 'auth.reset_password.flash_success',
                    }))}
                    label="test info flash"
                />

                <Typography variant="h5">H5 Layout</Typography>
                <p>
                    LoRem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                </p>
                <p>
                    LoRem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                    in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
        </ContentBox>
    );
}
