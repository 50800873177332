import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import * as ConsoleBff from '@console/bff/export';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { getRoboMgmtReportingPageVars } from 'state/ui/uiPages.selectors';
import { getUserConfig } from 'state/appConfig/selectors';
import { api } from 'api';
import {
    enrichReportResponseWithFetchDate,
    isEnrichedReportOutdated,
    TEnrichedReportResponse,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';

// eslint-disable-next-line max-len
export const roboAumReportEntity = getEntitiesManager().registerEntity<TEnrichedReportResponse<ConsoleBff.IFetchRoboAumReportApiReply>>({
    asyncEntityKey: AsyncEntityKeys.roboAumReport,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.MGMT_REPORTING_ROBO_AUM_DATA],
    includeUpdaters: false,
});

export function selectRoboAumReportAsCommonReport() {
    const { data, ...other } = roboAumReportEntity.select();

    return {
        ...other,
        data: data
            ? {
                ...data,
                report: convertToCommonReport(data.report),
            }
            : null,
    };

    function convertToCommonReport(roboReport: ConsoleBff.TRoboAumReport): ConsoleBff.TSelfAumReport {
        const { time_series, id_to_name_map } = roboReport;

        return {
            time_series: time_series.map(({ datetime, items }) => ({
                datetime,
                items: items.map((item) => ({
                    name: id_to_name_map[item.id] || item.id,
                    amount: item.amount,
                })),
            })),
        };
    }
}

export function triggerFetchRoboAumReport({
    forceRefresh = false,
}: IForceStateRefreshFilter = {}) {
    const {
        period,
        aumAggregateBy,
    } = getRoboMgmtReportingPageVars();
    const { defaultLocale } = getUserConfig();

    // eslint-disable-next-line max-len
    return roboAumReportEntity.async.fetch<ConsoleBff.IFetchRoboAumReportApiInputClient, TEnrichedReportResponse<ConsoleBff.IFetchRoboAumReportApiReply>, ConsoleBff.IFetchRoboAumReportApiReply>({
        api: api.bff.mgmtReporting.fetchRoboAumReport,
        apiInputSelector: () => ({
            locale: defaultLocale,
            aggregateBy: aumAggregateBy,
            period,
        }),
        mapApiResponse: ({ response }) => enrichReportResponseWithFetchDate(response),
        refreshMode: () => forceRefresh || isEnrichedReportOutdated(roboAumReportEntity.select().data),
        resetDataOnTriggerMode: 'always',
    });
}
