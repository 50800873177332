import React, { useState } from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { isUpdateBusy } from '@snipsonian/observable-state/cjs/actionableStore/entities/utils';
import {
    PolicyAllocationCategory,
} from '@console/core-api/models/portfolioMgmt/policy.models';
import { PolicyAlgorithmType } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { isPolicyOfCompositionBasedType } from '@console/bff/utils/policies/policyTypeUtils';
import { IPolicySettingsRules } from 'models/ui/policySettings.ui.models';
import {
    mapPolicyToInitialValuesForSettingsPatch,
    updatePolicyObjectWithPolicySettingsValues,
} from 'utils/entities/portfolioMgmt/policyFormMapper';
import { getStore } from 'state';
import { getUniverseInstrumentsPageVars } from 'state/ui/uiPages.selectors';
import { canUserModifyPolicy } from 'state/auth/apiEntityAuthorization.selectors';
import {
    getPolicyDetailsMetaInfo,
    policyDetailsEntity,
    triggerPatchPolicyDetails,
} from 'state/entities/portfolioMgmt/policyDetails';
import { triggerFetchInstrumentsForUniverse } from 'state/entities/portfolioMgmt/instruments';
import { findOrFetchSpecificPolicy } from 'state/entities/portfolioMgmt/policies';
import {
    getPolicySettingsChangeConfirmationModalConfig,
} from 'utils/entities/portfolioMgmt/policyUtils';
import useAsyncFetch from 'utils/react/hooks/useAsyncFetch';
import { ObjectSchema } from '@console/common/utils/schema';
import { UniverseType } from '@console/core-api/models/portfolioMgmt/instruments.models';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import {
    doUserPermissionsCoverRequiredPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/doUserPermissionsCoverRequiredPermissions';
import DetailFieldsListWithSections, {
    IDetailSection,
    IOnChangeIsEditActiveProps,
} from 'views/common/detail/DetailFieldsListWithSections';
import { IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import {
    ExtendedInputFormName,
} from 'views/common/inputs/extended/extendedInputFormManager';
import Spinner from 'views/common/loading/Spinner';
import {
    areThereErrorsInPolicyCompositionDetailSection,
    areThereErrorsInPolicySettingsAllocationBoundariesSection,
    areThereErrorsInPolicySettingsBenchmarkSection,
    areThereErrorsInPolicySettingsDetailSection,
    areThereErrorsInPolicySettingsExecutionConstraintsSection,
    areThereErrorsInPolicySettingsPortfolioConstraintsSection,
    areThereErrorsInPolicySettingsPortfolioUpdateConstraintsSection,
    isLessItemsAllowedRule,
} from 'utils/entities/portfolioMgmt/policySettingsUtils';
import { getUserPermissions } from 'state/auth/selectors';
import { getPolicyBaseCurrency } from './PolicySimulationExpectedPerformance/InstrumentPerformancePast';
import { renderPolicyDetailsSectionFormContent } from '../policyFormContents/policyDetailSectionFormContent';
import {
    renderPolicyPortfolioConstraintsFormContent,
} from '../policyFormContents/policyPortfolioConstraintsSectionFormContent';
import {
    IFetchInstrumentsBasedOnFiltersProps,
    IIsChangeUniverseModalOpenProps,
    IOpenAllocationCategoryProps,
    IOpenBenchmarkAllocationCategoryProps,
    IPolicySettingsFormValues,
} from '../policyFormContents/types';
import {
    renderPolicyAllocationBoundariesSectionFormContent,
} from '../policyFormContents/policyAllocationBoundariesSectionFormContent';
import {
    renderPolicyExecutionConstraintsFormContent,
} from '../policyFormContents/policyExecutionConstraintsSectionFormContent';
import {
    renderPolicyPortfolioUpdateConstraintsSectionFormContent,
} from '../policyFormContents/policyPortfolioUpdateConstraintsSectionFormContent';
import { renderPolicyCompositionSectionFormContent } from '../policyFormContents/policyCompositionSectionFormContent';
import {
    renderPolicyUniverseSectionFormContent,
} from '../policyFormContents/policyUniverseSectionFormContent';
import { renderPolicyBenchmarkSectionFormContent } from '../policyFormContents/policyBenchmarkSectionFormContent';
import ParentPolicySection from './ParentPolicySection';

const LABEL_PREFIX = 'portfolio_mgmt.policies.detail.configuration.settings';
const FIELDS_LABEL_PREFIX = 'portfolio_mgmt.policies.detail';
const SECTIONS_LABEL_PREFIX = `${LABEL_PREFIX}.sections`;
const INITIAL_ALLOCATION_CATEGORY = PolicyAllocationCategory.assetClasses;

interface IPolicyDetailSettingsSectionsProps {
    algorithm: PolicyAlgorithmType;
    openAllocationCategoryProps: IOpenAllocationCategoryProps;
    openBenchmarkAllocationCategoryProps: IOpenBenchmarkAllocationCategoryProps;
    isChangeUniverseModalOpenProps: IIsChangeUniverseModalOpenProps;
    initialValues: IPolicySettingsFormValues;
    triggerFetchInstrumentsBasedOnFilters: (props: IFetchInstrumentsBasedOnFiltersProps) => Promise<unknown>;
    policySettingsRules: IPolicySettingsRules;
}

function getPolicyDetailSettingsSections({
    algorithm,
    openAllocationCategoryProps,
    openBenchmarkAllocationCategoryProps,
    initialValues,
    isChangeUniverseModalOpenProps,
    triggerFetchInstrumentsBasedOnFilters,
    policySettingsRules,
}: IPolicyDetailSettingsSectionsProps): IDetailSection<IPolicySettingsFormValues>[] {
    const isCompositionBasedPolicy = isPolicyOfCompositionBasedType(algorithm);

    return [{
        title: {
            msg: `${SECTIONS_LABEL_PREFIX}.details.title`,
        },
        renderFields: renderPolicyDetailsSectionFormContent,
        areThereErrorsWithinSection: areThereErrorsInPolicySettingsDetailSection,
    }, {
        title: {
            msg: isCompositionBasedPolicy
                ? `${SECTIONS_LABEL_PREFIX}.composition.title`
                : `${SECTIONS_LABEL_PREFIX}.universe.title`,
        },
        renderFields: (context) => {
            if (isCompositionBasedPolicy) {
                return renderPolicyCompositionSectionFormContent(
                    context,
                    algorithm,
                );
            }

            return renderPolicyUniverseSectionFormContent(
                context,
                isChangeUniverseModalOpenProps,
                triggerFetchInstrumentsBasedOnFilters,
                policySettingsRules,
            );
        },
        areThereErrorsWithinSection: areThereErrorsInPolicyCompositionDetailSection,
    }, {
        title: {
            msg: `${SECTIONS_LABEL_PREFIX}.execution_settings.title`,
        },
        renderFields: (props) => renderPolicyExecutionConstraintsFormContent(
            props,
            policySettingsRules,
        ),
        areThereErrorsWithinSection: areThereErrorsInPolicySettingsExecutionConstraintsSection,
    }, {
        title: {
            msg: `${SECTIONS_LABEL_PREFIX}.portfolio_constraints.title`,
        },
        renderFields: (props) => renderPolicyPortfolioConstraintsFormContent(
            props,
            initialValues,
            policySettingsRules,
        ),
        areThereErrorsWithinSection: areThereErrorsInPolicySettingsPortfolioConstraintsSection,
    }, {
        title: {
            msg: `${SECTIONS_LABEL_PREFIX}.portfolio_update_constraints.title`,
        },
        renderFields: (props) => renderPolicyPortfolioUpdateConstraintsSectionFormContent(
            props,
            initialValues,
            policySettingsRules,
        ),
        areThereErrorsWithinSection: areThereErrorsInPolicySettingsPortfolioUpdateConstraintsSection,
    }, {
        title: {
            msg: `${SECTIONS_LABEL_PREFIX}.benchmark.title`,
        },
        renderFields: (props) => renderPolicyBenchmarkSectionFormContent(
            props,
            initialValues,
            openBenchmarkAllocationCategoryProps,
            policySettingsRules,
        ),
        areThereErrorsWithinSection: areThereErrorsInPolicySettingsBenchmarkSection,
    }, {
        title: {
            msg: `${SECTIONS_LABEL_PREFIX}.allocation_boundaries.title`,
        },
        renderFields: (props) => renderPolicyAllocationBoundariesSectionFormContent(
            props,
            openAllocationCategoryProps,
            initialValues,
            policySettingsRules,
        ),
        areThereErrorsWithinSection: areThereErrorsInPolicySettingsAllocationBoundariesSection,
    }];
}

export default function PolicySettings() {
    const policyDetailsData = policyDetailsEntity.select().data;
    const parentPolicyId = policyDetailsData.parent_policy_id;

    const [openAllocationCategory, setOpenAllocationCategory] =
        useState<PolicyAllocationCategory>(INITIAL_ALLOCATION_CATEGORY);
    const [openBenchmarkAllocationCategory, setOpenBenchmarkAllocationCategory] =
        useState<PolicyAllocationCategory>(INITIAL_ALLOCATION_CATEGORY);
    const [isChangeUniverseModalOpen, setIsChangeUniverseModalOpen] = useState<boolean>(false);
    const [isSettingsEditActive, setIsSettingsEditActive] = useState<boolean>(false);

    const [asyncParentPolicy] = useAsyncFetch({
        fetcher: () => findOrFetchSpecificPolicy({ policyId: parentPolicyId }),
        shouldFetch: () => isSet(parentPolicyId),
        deps: [parentPolicyId],
    });

    if (parentPolicyId
        && (asyncParentPolicy.status === AsyncStatus.Initial || asyncParentPolicy.status === AsyncStatus.Busy)) {
        return (
            <Spinner />
        );
    }

    if (!isSettingsEditActive && isUpdateBusy(policyDetailsEntity.select())) {
        /**
         * This policy-editing, when the edit is not active, is done because the parent policy is changed or unlinked
         * --> while this update is busy we don't want to render the inner form of the "DetailFieldsListWithSections"
         *     so that afterwards the form is re-initialized with the changed schema
         *     (otherwise the areThereErrorsWithinSection kept receiving the previous errors, also see PLAT-914)
         */
        return null;
    }

    const {
        rules: policySettingsRules,
        schema: policyDynamicSchema,
    } = !parentPolicyId
        ? getPolicyDetailsMetaInfo({ parentPolicy: null })
        : asyncParentPolicy.data
            ? getPolicyDetailsMetaInfo({ parentPolicy: asyncParentPolicy.data })
            : null;

    const isReadOnly = determineIsReadOnly();
    const initialValues = mapPolicyToInitialValuesForSettingsPatch(policyDetailsData);

    return (
        <>
            <ParentPolicySection
                parentPolicy={asyncParentPolicy.data}
                isReadOnly={isReadOnly}
                isSettingsEditActive={isSettingsEditActive}
                policySettingsRules={policySettingsRules}
            />

            <DetailFieldsListWithSections<IPolicySettingsFormValues>
                formName={ExtendedInputFormName.policySettings}
                title={{
                    msg: `${LABEL_PREFIX}.title`,
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                schema={policyDynamicSchema as unknown as ObjectSchema<any>}
                sections={getPolicyDetailSettingsSections({
                    algorithm: policyDetailsData.config.algorithm,
                    initialValues,
                    triggerFetchInstrumentsBasedOnFilters,
                    openBenchmarkAllocationCategoryProps: {
                        openBenchmarkAllocationCategory,
                        setOpenBenchmarkAllocationCategory,
                    },
                    openAllocationCategoryProps: {
                        openAllocationCategory,
                        setOpenAllocationCategory,
                    },
                    isChangeUniverseModalOpenProps: {
                        isChangeUniverseModalOpen,
                        setIsChangeUniverseModalOpen,
                    },
                    policySettingsRules,
                })}
                readOnly={isReadOnly}
                submit={{
                    confirmationModal: getPolicySettingsChangeConfirmationModalConfig(),
                    onSubmit: updatePolicyDetails,
                }}
                initialValues={initialValues}
                fieldsLabelPrefix={FIELDS_LABEL_PREFIX}
                keepTheManagedFormStateInSync
                onChangeIsEditActive={onChangeIsEditActive}
            />
        </>
    );

    function determineIsReadOnly(): boolean {
        if (parentPolicyId && !asyncParentPolicy.data) {
            /* not editable while the parent policy is not fetched yet */
            return true;
        }

        if (!isSet(parentPolicyId)) {
            /*
                We do not check the modifiable_by here, because if a user does not have
                the BASE_POLICY_MODIFY permission he should never be able to edit
            */
            return !doUserPermissionsCoverRequiredPermissions({
                userPermissions: getUserPermissions(),
                requiredPermissions: [OperationPermissionKey.BASE_POLICIES_MODIFY],
            });
        }

        return !canUserModifyPolicy(policyDetailsData);
    }

    function onChangeIsEditActive({ isEditActive }: IOnChangeIsEditActiveProps) {
        setIsSettingsEditActive(isEditActive);
    }

    function triggerFetchInstrumentsBasedOnFilters({
        selectedInstruments,
        apiInput = { currency: getPolicyBaseCurrency() },
        forceRefresh = true,
        // eslint-disable-next-line max-len
        shouldFetchOnlySelectedInstruments = getUniverseInstrumentsPageVars(getStore().getState()).showOnlySelectedInstruments,
    }: IFetchInstrumentsBasedOnFiltersProps) {
        const instrumentIds = shouldFetchOnlySelectedInstruments
            ? selectedInstruments
            : isLessItemsAllowedRule(policySettingsRules.universe.instruments.rule)
                ? policySettingsRules.universe.instruments.boundaryValues
                : null;

        return triggerFetchInstrumentsForUniverse({
            ...apiInput,
            universe_type: UniverseType.Robo,
            forceRefresh,
            instrumentIds,
        });
    }

    function updatePolicyDetails({ values }: IOnSubmitProps<IPolicySettingsFormValues>) {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        return triggerPatchPolicyDetails((currentPolicy) =>
            updatePolicyObjectWithPolicySettingsValues({
                currentPolicy,
                values,
            }));
    }
}
