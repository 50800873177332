import React from 'react';
import clsx from 'clsx';
import Radio from '@mui/material/Radio';
import { TLabel } from 'models/general.models';
import { FOCUS_OUTLINE, FOCUS_OUTLINE_OFFSET } from 'config/styling/elevation';
import { APP_COLORS } from 'config/styling/colors';
import { makeStyles, mixins } from 'views/styling';
import { ReadOnlyIcon, SuccessIcon } from 'views/common/icons';
import Text from 'views/common/widget/Text';

export interface IInputRadioFieldProps<Name extends string = string> {
    id?: string;
    className?: string;
    name: Name; // give all radio inputs, within the same radio group, the same name
    value: string;
    selected?: boolean;
    onSelected: (onSelectedProps: IOnSelectedRadioProps<Name>) => void;
    disabled?: boolean;
    description?: TLabel;
    extraDescription?: TLabel;
    showValueIndicatorsIfDisabled?: boolean; // default false
    error?: TLabel;
    emphasizeError?: boolean; // default true - if true, then the error will be shown in red
}

export interface IOnSelectedRadioProps<Name extends string = string> {
    name: Name;
    value: string;
}

const useStyles = makeStyles((theme) => ({
    InputRadioWrapper: {
        ...mixins.flexColTopLeft(),

        '& .__extraDescription': {
            ...mixins.typo({ size: 14, color: APP_COLORS.GREY['300'] }),
            paddingLeft: theme.spacing(5),
            marginTop: theme.spacing(-1),
            marginBottom: theme.spacing(0.5),
        },

        '& .__errorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.TEXT['300'] }),
            lineHeight: 1.2,
            paddingTop: theme.spacing(1),
        },
        '&.__has-error .__errorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.FEEDBACK.ERROR }),
        },
    },
    InputRadioField: {
        ...mixins.flexRow({ alignMain: 'flex-start', alignCross: 'center' }),

        '& .__radioLabel': {
            ...mixins.typo({ size: 15, color: APP_COLORS.TEXT['500'] }),
            cursor: 'pointer',
            margin: 0,
        },
        '& .__radioLabel.__radioLabel-disabled': {
            cursor: 'not-allowed',
        },
        '& .__radioLabel.__radioLabel-unchecked.__radioLabel-disabled': {
            ...mixins.typo({ size: 15, color: APP_COLORS.GREY['300'] }),
        },
        '& .indicatorSpacing': {
            paddingTop: theme.spacing(1) + 3,
            padding: theme.spacing(1),
        },

        '& .MuiRadio-root': {
            backgroundColor: 'unset',
        },
        '& .MuiRadio-root.Mui-checked': {
            backgroundColor: 'unset',
        },

        '& .MuiSvgIcon-root': {
            fill: APP_COLORS.TEXT['500'],
        },
        '&:hover .MuiSvgIcon-root': {
            fill: APP_COLORS.PRIMARY['500'],
        },
        '& .Mui-disabled .MuiSvgIcon-root': {
            fill: APP_COLORS.GREY['300'],
        },
        '& .__disabled .MuiSvgIcon-root': {
            fill: APP_COLORS.GREY['300'],
        },

        '&:focus-within:not(:hover) svg': {
            outline: FOCUS_OUTLINE,
            outlineOffset: FOCUS_OUTLINE_OFFSET,
        },
    },
}));

export default function InputRadioField<Name extends string = string>({
    id,
    className,
    name,
    value,
    selected = false,
    onSelected,
    disabled = false,
    description,
    extraDescription,
    showValueIndicatorsIfDisabled = false,
    error,
    emphasizeError = true,
}: IInputRadioFieldProps<Name>) {
    const classes = useStyles();
    const radioId = id || `${name}_${value}`;

    return (
        <div
            className={clsx(
                classes.InputRadioWrapper,
                error && emphasizeError && '__has-error',
                className,
            )}
        >
            <div
                className={clsx(
                    classes.InputRadioField,
                )}
            >
                {renderRadioButton()}

                {description && (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control
                    <label
                        htmlFor={radioId}
                        className={clsx(
                            '__radioLabel',
                            disabled && '__radioLabel-disabled',
                            !selected && '__radioLabel-unchecked',
                        )}
                    >
                        <Text label={description} />
                    </label>
                )}
            </div>

            {extraDescription && (
                <div className="__extraDescription">
                    <Text label={extraDescription} />
                </div>
            )}

            {error && (
                <div
                    className={clsx(
                        '__errorText',
                    )}
                >
                    <Text label={error} />
                </div>
            )}
        </div>
    );

    function renderRadioButton() {
        if (showValueIndicatorsIfDisabled && disabled) {
            return (
                <div className={clsx('indicatorSpacing', '__disabled')}>
                    {selected ? <SuccessIcon /> : <ReadOnlyIcon />}
                </div>
            );
        }

        return (
            <Radio
                id={radioId}
                name={name}
                value={value}
                checked={selected}
                onChange={onChangeRadio}
                disabled={disabled}
                disableRipple
            />
        );
    }

    function onChangeRadio(event: React.ChangeEvent<HTMLInputElement>) {
        onSelected({
            name,
            value: event.target.value,
        });
    }
}
