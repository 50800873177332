import { decorateSchema } from '@typsy/rest-api/dist/server/swagger/decorateSchema';
import * as ConsoleCommon from '@console/common/export';
import {
    IRoboPortfolioPerformanceReport,
    IRoboPortfolioPerformanceReportTimeSeriesItem,
} from '../../../models/reports/robo/roboPortfolioPerformanceReport.models';
import { baseTimeSeriesItemSchema, idToNameMapSchema } from './commonReporting.schemas';

const roboPortfolioPerformanceReportTimeSeriesItemSchema = ConsoleCommon.schema.object({
    items: ConsoleCommon.schema.array().of(
        decorateSchema(
            ConsoleCommon.schema.object({
                id: ConsoleCommon.schema.string().required(),
                twr: ConsoleCommon.schema.number().required(),
            }).required(),
            // eslint-disable-next-line max-len
            { description: 'Captures the combined twr of all portfolios that are linked to the same policy (id) on the given datetime.' },
        ),
    ).required(),
}).concat(baseTimeSeriesItemSchema) as ConsoleCommon.schema.ObjectSchema<IRoboPortfolioPerformanceReportTimeSeriesItem>;

export const roboPortfolioPerformanceReportSchema = ConsoleCommon.schema.object({
    time_series: decorateSchema(
        ConsoleCommon.schema.array().of(roboPortfolioPerformanceReportTimeSeriesItemSchema).required(),
        { description: 'Array of ascending data points, each containing a datetime and a list of id-twr items,'
                + ' that represent the time-weighted return evolution of all portfolios - grouped per policy - over time.' },
    ),
    id_to_name_map: idToNameMapSchema,
}) as ConsoleCommon.schema.ObjectSchema<IRoboPortfolioPerformanceReport>;
