/**
 * All the below formats have to use the DD/MM/YYYY sequence where appropriate!! (so not for 'backend' dates
 * that follow the YYY-MM-DD sequence)
 * However, based on the tenant config, this sequence will get reversed automatically.
 */

export const DATE_FORMAT = {
    MONTH_YEAR_SHORT: 'MM/YY',
    DAY_MONTH_YEAR: 'DD/MM/YYYY',
    DAY_MONTH_YEAR_SHORT: 'DD/MM/YY',
    MONTH_LONG_DAY: 'MMM DD',
    DAY_MONTH: 'DD/MM',
    BACK_END: 'YYYY-MM-DD',
    DAY_MONTH_YEAR_DOT: 'DD.MM.YYYY',
    DAY_MONTH_YEAR_DOT_WITH_TIME: 'DD.MM.YYYY H:mm',
    DAY_MONTH_YEAR_DOT_WITH_TIME_AND_ZONE: 'DD.MM.YYYY H:mm Z',
    DAY_MONTH_YEAR_WITH_TIME: 'DD/MM/YYYY H:mm',
    WEEKDAY_DAY_MONTH_LONG_WITH_TIME: 'ddd D MMM H:mm',
    TIMESTAMP: 'YYYYMMDD-HHmmss',
    /**
     * E.g. 2022-05-12T14:54:10.987590+00:00
     *
     * Some explanation:
     *   .SSS[000]
     *     In the core api the datetimes have the microseconds after the dot, which is a 6-digit number,
     *     but as dayjs only supports 3-digit-milliseconds (SSS) and not microseconds, we just append 3 zero's
     *   Z
     *     The timezone = offset from UTC. e.g. +02:00
     */
    BACK_END_DATETIME: 'YYYY-MM-DD[T]HH:mm:ss.SSS[000]Z',
    WEEK_NUMBER: '[W]W', /* W = 'ISO Week of year' - see https://day.js.org/docs/en/plugin/advanced-format */
    QUARTER_OF_YEAR: '[Q]Q[ - ]YYYY', /* Q = 'Quarter' - see https://day.js.org/docs/en/plugin/advanced-format */
};

export const TIME_FORMAT = {
    SHORT_HR_WITH_MINS: 'H:mm',
    LONG_HR_WITH_MINS: 'HH:mm',
};

export const CALENDAR_RELATIVE_DATE_FORMATS = {
    sameDay: `[Today at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The same day ( Today at 2:30 )
    nextDay: `[Tomorrow at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The next day ( Tomorrow at 14:30 )
    nextWeek: `[Next] dddd [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The next week ( Next Sunday at 2:30 )
    lastDay: `[Yesterday at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // The day before ( Yesterday at 2:30 )
    // lastWeek: `[Last] dddd [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // Last week ( Last Monday at 2:30 )
    lastWeek: DATE_FORMAT.DAY_MONTH_YEAR, // see 'sameElse'
    sameElse: DATE_FORMAT.DAY_MONTH_YEAR, // Everything else ( 17/10/2011 )
};

export const CALENDAR_RELATIVE_DATE_FORMATS_ALWAYS_TIME = {
    ...CALENDAR_RELATIVE_DATE_FORMATS,
    lastWeek: `${DATE_FORMAT.DAY_MONTH_YEAR} [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // see 'sameElse'
    sameElse: `${DATE_FORMAT.DAY_MONTH_YEAR} [at] ${TIME_FORMAT.SHORT_HR_WITH_MINS}`, // Everything else ( 17/10/2011 )
};

export function putDayAfterMonthIfAppropriate(inputFormat: string): string {
    return inputFormat
        .replace('DD/MM', 'MM/DD')
        .replace('DD.MM', 'MM.DD');
}
