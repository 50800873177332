import React from 'react';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isNumber from '@snipsonian/core/cjs/is/isNumber';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { getNrOfDaysBetweenDates } from '@console/common/utils/date/dateUtils';
import { getItemOfArrayFromEnd } from '@console/common/snipsonian/core/cjs/array/filtering/getItemOfArrayFromEnd';
import {
    IFetchMgmtReportBaseResponse,
    ITimeSeriesItemBase,
} from '@console/bff/models/mgmtReporting/baseMgmtReporting.models';
import { ConsoleBff } from '@console/bff/index';
import { TLabel } from 'models/general.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { reportInfoEntity } from 'state/entities/mgmtReporting/reportInfo';
import {
    getAverageYtdLabel,
    INCOMPLETE_PERIOD_TYPES,
    YEAR_PERIOD_TYPES,
    TTrendDirection,
} from 'utils/entities/mgmtReporting/mgmtReporting.utils';
import { IEntityWrapperProps, IRenderDataProps } from 'views/common/widget/EntityWrapper';
import { makeStyles, mixins } from 'views/styling';
import MgmtReportingBarChartByTime, { IMgmtReportingBarChartByTimeProps } from '../graphs/MgmtReportingBarChartByTime';
import MgmtReportingBox from './MgmtReportingBox';
import {
    IColKeys,
    IGenericColumn,
    IMgmtReportingGenericTableProps,
    MgmtReportingGenericTable,
} from './MgmtReportingGenericTable';
import { MgmtReportingNoData } from './MgmtReportingNoData';

const LABEL_PREFIX = 'mgmt_reporting';
const LABEL_PREFIX_CLIENTS = `${LABEL_PREFIX}.clients`;
const LABEL_PREFIX_GENERIC = `${LABEL_PREFIX}.generic`;

type TClientReport =
    IFetchMgmtReportBaseResponse<ConsoleBff.ITimeSeriesReportWithRefPeriodAndAverageYtd<ITimeSeriesItemBase, unknown>>;

interface IMgmtReportingClientsBoxProps<
    ClientGrowthReport extends TClientReport,
    ClientCashTransferReport extends TClientReport,
> {
    description: TLabel;
    reportData: {
        growth: IClientReportDataSectionConfig<ClientGrowthReport> & {
            mainMetricDataSelector: (props: Pick<IRenderDataProps<ClientGrowthReport>, 'data'>) => {
                clientTotal: number;
                trendDirection?: TTrendDirection;
            };
        };
        cashTransfer: IClientReportDataSectionConfig<ClientCashTransferReport> & {
            hidden?: boolean; /* default false */
        };
    };
}

interface IClientReportDataSectionConfig<ClientReport extends TClientReport>
    // eslint-disable-next-line max-len
    extends Pick<IEntityWrapperProps<ClientReport>, 'asyncEntitySelector'>, Pick<IClientPeriodComparisonTableProps<ClientReport>, 'periodComparisonTableMapper'> {
    notifications: StateChangeNotification[];
    // eslint-disable-next-line max-len
    barChartMapper: (props: Pick<IRenderDataProps<ClientReport>, 'data'>) => Pick<IMgmtReportingBarChartByTimeProps, 'categories' | 'data' | 'valueLineAggregator'>;
}

const useStyles = makeStyles((theme) => ({
    MgmtReportingClientsBox: {
        '& .__sectionContent': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            ...mixins.widthMax(),

            '& table': {
                marginRight: theme.spacing(4),
            },

            [theme.breakpoints.down('lg')]: {
                ...mixins.flexCol({ alignMain: 'space-between', alignCross: 'center' }),
            },
        },
    },
}));

export default function MgmtReportingClientsBox<
    ClientGrowthReport extends TClientReport,
    ClientCashTransferReport extends TClientReport,
>({
    description,
    reportData,
}: IMgmtReportingClientsBoxProps<ClientGrowthReport, ClientCashTransferReport>) {
    const classes = useStyles();
    const { timezone } = reportInfoEntity.select().data;

    return (
        <MgmtReportingBox<ClientGrowthReport>
            className={classes.MgmtReportingClientsBox}
            top={{
                title: `${LABEL_PREFIX_CLIENTS}.title`,
                description,
            }}
            mainMetric={{
                notifications: reportData.growth.notifications,
                asyncEntitySelector: reportData.growth.asyncEntitySelector,
                isDataSufficientValidator: ({ data, isAnyAsyncOperationBusy }) => {
                    if (isAnyAsyncOperationBusy) {
                        return false;
                    }

                    return isArrayWithValues(data.report.time_series);
                },
                calculator: ({ data }) => {
                    const {
                        clientTotal,
                        trendDirection,
                    } = reportData.growth.mainMetricDataSelector({ data });

                    return {
                        value: {
                            text: formatAmount(clientTotal, {
                                useMagnitudeFlags: true,
                            }),
                            shouldTranslate: false,
                        },
                        trendDirection,
                        reportingPeriodInfo: {
                            msg: `${LABEL_PREFIX_CLIENTS}.date_included`,
                            placeholders: {
                                date: formatDate({
                                    date: getLastItemOfArray(data.report.time_series)?.datetime,
                                    format: DATE_FORMAT.DAY_MONTH_YEAR_DOT,
                                    timezone,
                                }),
                            },
                            raw: true,
                        },
                    };
                },
            }}
            sections={[{
                title: () => `${LABEL_PREFIX_CLIENTS}.sections.growth.title`,
                notifications: reportData.growth.notifications,
                asyncEntitySelector: reportData.growth.asyncEntitySelector,
                renderContent: renderClientsGrowthSectionContent,
            }, {
                title: () => `${LABEL_PREFIX_CLIENTS}.sections.cash_transfer.title`,
                notifications: reportData.cashTransfer.notifications,
                asyncEntitySelector: reportData.cashTransfer.asyncEntitySelector,
                renderContent: renderClientsCashTransferSectionContent,
                hidden: reportData.cashTransfer.hidden,
            }]}
        />
    );

    function renderClientsGrowthSectionContent({ data }: IRenderDataProps<ClientGrowthReport>) {
        if (!isArrayWithValues(data.report.time_series)) {
            return <MgmtReportingNoData />;
        }

        const barChartProps = reportData.growth.barChartMapper({ data });

        return (
            <ClientSectionContent
                data={data}
                periodComparisonTableMapper={reportData.growth.periodComparisonTableMapper}
                barChart={{
                    name: 'mgmt-reporting_client-growth_bar-chart',
                    variant: 'absoluteBarSize',
                    yAxisLabel: `${LABEL_PREFIX_CLIENTS}.sections.growth.chart.y_axis`,
                    ...barChartProps,
                }}
                timezone={timezone}
            />
        );
    }

    function renderClientsCashTransferSectionContent({ data }: IRenderDataProps<ClientCashTransferReport>) {
        if (!isArrayWithValues(data.report.time_series)) {
            return <MgmtReportingNoData />;
        }

        const barChartProps = reportData.cashTransfer.barChartMapper({ data });

        return (
            <ClientSectionContent
                data={data}
                periodComparisonTableMapper={reportData.cashTransfer.periodComparisonTableMapper}
                barChart={{
                    name: 'mgmt-reporting_client-cash-transfer_bar-chart',
                    variant: 'relativeBarSize',
                    yAxisLabel: `${LABEL_PREFIX_CLIENTS}.sections.cash_transfer.chart.y_axis`,
                    ...barChartProps,
                }}
                timezone={timezone}
            />
        );
    }
}

function ClientSectionContent<ClientReport extends TClientReport>({
    barChart,
    ...periodComparisonTable
}: Omit<IClientPeriodComparisonTableProps<ClientReport>, 'headerDateFormat'> & {
    barChart: IMgmtReportingBarChartByTimeProps;
}) {
    const dateFormat = determineDateFormat();

    return (
        <div className="__sectionContent">
            <ClientPeriodComparisonTable
                headerDateFormat={dateFormat.long}
                {...periodComparisonTable}
            />

            <MgmtReportingBarChartByTime
                xAxisDateFormat={dateFormat.short}
                timezone={periodComparisonTable.timezone}
                {...barChart}
            />
        </div>
    );

    /**
     * See https://day.js.org/docs/en/display/format
     */
    function determineDateFormat(): { long: string; short: string } {
        switch (periodComparisonTable.data.meta.frequency) {
            case ConsoleBff.DataPointFrequency.Day:
                return {
                    long: 'dd MMM D[th]', /* e.g. "Mo Mar 27th" */
                    short: null, /* the default will be taken */
                };
            case ConsoleBff.DataPointFrequency.Week:
                return {
                    long: DATE_FORMAT.WEEK_NUMBER, /* e.g. "W18" */
                    short: DATE_FORMAT.WEEK_NUMBER,
                };
            case ConsoleBff.DataPointFrequency.Month:
                return {
                    long: 'MMMM YYYY', /* e.g. "March 2023" */
                    short: 'MMM YY',
                };
            case ConsoleBff.DataPointFrequency.Quarter:
                return {
                    long: DATE_FORMAT.QUARTER_OF_YEAR, /* e.g. "Q1 - 2023" */
                    short: DATE_FORMAT.QUARTER_OF_YEAR,
                };
            case ConsoleBff.DataPointFrequency.Year:
                return {
                    long: 'YYYY', /* e.g. "2023" */
                    short: 'YYYY',
                };
            default:
                return {
                    long: 'dd MMM D',
                    short: null,
                };
        }
    }
}

interface IClientPeriodComparisonColKeys extends IColKeys {
    prevPeriod3?: number;
    prevPeriod2?: number;
    prevPeriod: number;
    currentPeriod: number;
    averageYTD?: number; /* YTD = year to date */
    comparisonPeriod?: number;
}

interface IClientPeriodComparisonTableProps<ClientReport extends TClientReport> {
    data: ClientReport;
    // eslint-disable-next-line max-len
    periodComparisonTableMapper: (props: Pick<IRenderDataProps<ClientReport>, 'data'>) => Pick<IMgmtReportingGenericTableProps<IClientPeriodComparisonColKeys>, 'rows'>;
    headerDateFormat: string;
    timezone: string;
}

function ClientPeriodComparisonTable<ClientReport extends TClientReport>({
    data,
    periodComparisonTableMapper,
    headerDateFormat,
    timezone,
}: IClientPeriodComparisonTableProps<ClientReport>) {
    const cols: IGenericColumn<IClientPeriodComparisonColKeys>[] = [{
        colKey: 'prevPeriod3',
        label: determineColumnHeaderDateLabel(getItemOfArrayFromEnd(data.report.time_series, 4)?.datetime),
        isDisabled: !YEAR_PERIOD_TYPES.includes(data.input.periodType)
            || !getItemOfArrayFromEnd(data.report.time_series, 4),
    }, {
        colKey: 'prevPeriod2',
        label: determineColumnHeaderDateLabel(getItemOfArrayFromEnd(data.report.time_series, 3)?.datetime),
        isDisabled: !YEAR_PERIOD_TYPES.includes(data.input.periodType)
            || !getItemOfArrayFromEnd(data.report.time_series, 3),
    }, {
        colKey: 'prevPeriod',
        label: determineColumnHeaderDateLabel(getItemOfArrayFromEnd(data.report.time_series, 2)?.datetime),
        isDisabled: !getItemOfArrayFromEnd(data.report.time_series, 2),
    }, {
        colKey: 'currentPeriod',
        label: determineColumnHeaderDateLabel(getLastItemOfArray(data.report.time_series)?.datetime),
        subLabel: determineColumnHeaderSubLabel(),
        markBold: true,
        trendIndicatorComparisonColKey: 'prevPeriod',
    }, {
        colKey: 'averageYTD',
        label: getAverageYtdLabel({ dataPointFrequency: data.meta.frequency }),
        isDisabled: !data.report.average_ytd,
    }, {
        colKey: 'comparisonPeriod',
        label: `${LABEL_PREFIX_GENERIC}.same_period_last_year`,
        isDisabled: !data.report.reference_period,
    }];

    const { rows } = periodComparisonTableMapper({ data });

    return (
        <MgmtReportingGenericTable
            cols={cols}
            rows={rows}
        />
    );

    function determineColumnHeaderDateLabel(date: string): TLabel {
        if (!date) {
            return {
                text: '-',
                shouldTranslate: false,
            };
        }

        return {
            text: formatDate({
                date,
                format: headerDateFormat,
                timezone,
            }),
            shouldTranslate: false,
        };
    }

    function determineColumnHeaderSubLabel(): TLabel {
        if (INCOMPLETE_PERIOD_TYPES.includes(data.input.periodType)) {
            const nrOfDays = getNrOfDaysBetweenDates(
                new Date(data.meta.strictPeriod?.endDate || data.meta.period.endDate),
                new Date(data.meta.strictPeriod?.startDate || data.meta.period.startDate),
            ) + 1;

            const labelSuffix = nrOfDays === 1
                ? 'number_of_days_single'
                : 'number_of_days';

            return {
                msg: `${LABEL_PREFIX_GENERIC}.${labelSuffix}`,
                placeholders: {
                    nrOfDays,
                },
            };
        }

        return null;
    }
}

export function toClientPeriodComparisonRowValues<ClientReport extends TClientReport>({
    data,
    fieldName,
    subtractFieldName,
    convertToNegative = false,
}: {
    data: ClientReport;
    fieldName: string;
    subtractFieldName?: string;
    convertToNegative?: boolean;
}): IClientPeriodComparisonColKeys {
    /* eslint-disable @typescript-eslint/ban-types */

    const fieldValues = {
        prevPeriod3: getFieldOfDataPoint(getItemOfArrayFromEnd(data.report.time_series, 4), fieldName),
        prevPeriod2: getFieldOfDataPoint(getItemOfArrayFromEnd(data.report.time_series, 3), fieldName),
        prevPeriod: getFieldOfDataPoint(getItemOfArrayFromEnd(data.report.time_series, 2), fieldName),
        currentPeriod: getFieldOfDataPoint(getLastItemOfArray(data.report.time_series), fieldName),
        averageYTD: getFieldOfDataPoint(data.report.average_ytd as object, fieldName),
        comparisonPeriod: getFieldOfDataPoint(data.report.reference_period as object, fieldName),
    };

    if (subtractFieldName) {
        const subtractFieldValues = {
            prevPeriod3: getFieldOfDataPoint(getItemOfArrayFromEnd(data.report.time_series, 4), subtractFieldName),
            prevPeriod2: getFieldOfDataPoint(getItemOfArrayFromEnd(data.report.time_series, 3), subtractFieldName),
            prevPeriod: getFieldOfDataPoint(getItemOfArrayFromEnd(data.report.time_series, 2), subtractFieldName),
            currentPeriod: getFieldOfDataPoint(getLastItemOfArray(data.report.time_series), subtractFieldName),
            averageYTD: getFieldOfDataPoint(data.report.average_ytd as object, subtractFieldName),
            comparisonPeriod: getFieldOfDataPoint(data.report.reference_period as object, subtractFieldName),
        };

        return {
            prevPeriod3: subtractIfBothSet(fieldValues.prevPeriod3, subtractFieldValues.prevPeriod3),
            prevPeriod2: subtractIfBothSet(fieldValues.prevPeriod2, subtractFieldValues.prevPeriod2),
            prevPeriod: subtractIfBothSet(fieldValues.prevPeriod, subtractFieldValues.prevPeriod),
            currentPeriod: subtractIfBothSet(fieldValues.currentPeriod, subtractFieldValues.currentPeriod),
            averageYTD: subtractIfBothSet(fieldValues.averageYTD, subtractFieldValues.averageYTD),
            comparisonPeriod: subtractIfBothSet(fieldValues.comparisonPeriod, subtractFieldValues.comparisonPeriod),
        };
    }

    return fieldValues;

    function getFieldOfDataPoint(dataPoint: object, propName: string): number {
        if (!dataPoint) {
            return null;
        }

        const fieldValue = (dataPoint as TAnyObject<number>)[propName];

        if (!isSet(fieldValue)) {
            return fieldValue;
        }

        return convertToNegative
            ? fieldValue * -1
            : fieldValue;
    }

    function subtractIfBothSet(numberA: number, numberB: number): number {
        if (isNumber(numberA) && isNumber(numberB)) {
            return (numberA - numberB);
        }

        return null;
    }

    /* eslint-enable @typescript-eslint/ban-types */
}
