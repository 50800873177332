import React from 'react';
import { TNumberRange } from '@console/core-api/models/api.models';
import { IExtendedInputBaseProps } from './ExtendedInputText';
import InputSliderField, {
    areSliderValuesSame,
    DEFAULT_ABSOLUTE_SLIDER_RANGE,
    IInputSliderFieldProps,
    TSliderValue,
} from '../base/InputSliderField';
import { ExtendedInputFormContext } from './ExtendedInputForm';
import ExtendedInputWrapper, { IExtendedInputWrapperProps } from './ExtendedInputWrapper';

export interface IExtendedInputSliderProps
    extends IExtendedInputBaseProps, Omit<IInputSliderFieldProps, 'id' | 'name' | 'value' | 'onChange'> {
    /** only use this when you want a toggle next to the wrapper label to show/hide (~ enable/disable) the slider */
    inputHideable?: false | {
        formFieldValueWhenHidden: TSliderValue;
    };
}

export default function ExtendedInputSlider({
    readOnly = false,
    formField,
    wrapper,
    initialValue,
    inputHideable,
    ...inputFieldProps
}: IExtendedInputSliderProps) {
    return (
        <ExtendedInputFormContext.Consumer>
            {({ readOnlyForm, setFieldValue }) => {
                const isFieldReadOnly = readOnly || readOnlyForm;

                function onChangeSliderValue(value: TSliderValue) {
                    setFieldValue({ fieldName: formField.fieldName, value });
                }

                const optionalWrapperProps: Partial<IExtendedInputWrapperProps> = {};

                if (inputHideable) {
                    optionalWrapperProps.inputHideable = {
                        isShown: !areSliderValuesSame(
                            formField.value as TSliderValue,
                            inputHideable.formFieldValueWhenHidden,
                        ),
                        onChange: (isInputShown) => {
                            onChangeSliderValue(
                                isInputShown
                                    ? getDefaultSliderValues()
                                    : inputHideable.formFieldValueWhenHidden,
                            );
                        },
                    };
                }

                return (
                    <ExtendedInputWrapper
                        readOnly={isFieldReadOnly}
                        hasError={!!formField.error && formField.emphasizeError}
                        {...wrapper}
                        {...optionalWrapperProps}
                    >
                        <InputSliderField
                            id={formField.fieldId}
                            name={formField.fieldName}
                            readOnly={isFieldReadOnly}
                            initialValue={initialValue}
                            value={formField.value as TNumberRange}
                            onChange={onChangeSliderValue}
                            error={formField.error}
                            emphasizeError={formField.emphasizeError}
                            {...inputFieldProps}
                        />
                    </ExtendedInputWrapper>
                );

                function getDefaultSliderValues() {
                    if (inputFieldProps.range) {
                        return inputFieldProps.range;
                    }

                    if (inputFieldProps.absoluteRange) {
                        return inputFieldProps.absoluteRange;
                    }

                    return DEFAULT_ABSOLUTE_SLIDER_RANGE;
                }
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
