import React from 'react';
import { useParams } from 'react-router-dom';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import {
    IOperationPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/extractUserOperationPermissions';
import {
    doUserPermissionsCoverRequiredPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/doUserPermissionsCoverRequiredPermissions';
import { formatDate, DATE_FORMAT } from '@console/common/utils/date/formatDate';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { MGMT_REPORTING_TAB_KEY, DEFAULT_MGMT_REPORTING_TAB_KEY } from 'config/tabs.config';
import {
    getManagementReportingFeatureFlags,
    IManagementReportingFeatureFlags,
} from 'state/appConfig/selectors';
import { reportInfoEntity } from 'state/entities/mgmtReporting/reportInfo';
import { getUserPermissions } from 'state/auth/selectors';
import DetailPage, {
    redirectToCurrentRouteButOverrideParams,
    TDetailPageTitleConfig,
} from 'views/common/detail/DetailPage';
import { IOnTabChangeProps, ITabItem } from 'views/common/layout/Tabs';
import RoboAdvisorMgmtReporting from 'views/mgmtReporting/roboAdvisor/RoboAdvisorMgmtReporting';
import Tag from 'views/common/widget/Tag';
import SelfInvestorMgmtReporting from './selfInvestor/SelfInvestorMgmtReporting';

function getMgmtReportingTabs({
    mgmtReportingFeatureFlags,
    userPermissions,
}: {
    mgmtReportingFeatureFlags: IManagementReportingFeatureFlags;
    userPermissions: IOperationPermissions;
}): ITabItem[] {
    return [{
        key: MGMT_REPORTING_TAB_KEY.ROBO_ADVISOR,
        label: 'mgmt_reporting.tabs.robo_advisor.title',
        component: RoboAdvisorMgmtReporting,
        wrapWithContentBox: false,
        hidden: !mgmtReportingFeatureFlags.robo || !doUserPermissionsCoverRequiredPermissions({
            userPermissions,
            requiredPermissions: [OperationPermissionKey.MANAGEMENT_REPORTING_ROBO_READ],
        }),
    }, {
        key: MGMT_REPORTING_TAB_KEY.SELF_INVESTOR,
        label: 'mgmt_reporting.tabs.self_investor.title',
        component: SelfInvestorMgmtReporting,
        wrapWithContentBox: false,
        hidden: !mgmtReportingFeatureFlags.self || !doUserPermissionsCoverRequiredPermissions({
            userPermissions,
            requiredPermissions: [OperationPermissionKey.MANAGEMENT_REPORTING_SELF_READ],
        }),
    }];
}

const MGMT_REPORTING_TITLE_CONFIG: TDetailPageTitleConfig = {
    selector: () => ({
        msg: 'mgmt_reporting.detail.title',
    }),
    notifications: [],
};

export default function ManagementReporting() {
    const { mgmtReportingTab = DEFAULT_MGMT_REPORTING_TAB_KEY } = useParams<IPossiblePathParams>();

    return (
        <DetailPage
            title={MGMT_REPORTING_TITLE_CONFIG}
            extraInfo={{
                render: renderLastUpdated,
            }}
            tabsWithEntityWrapper={{
                items: getMgmtReportingTabs({
                    mgmtReportingFeatureFlags: getManagementReportingFeatureFlags(),
                    userPermissions: getUserPermissions(),
                }),
                activeTabKey: mgmtReportingTab,
                onTabChange: onChangeMgmtReportingTab,
                entity: {
                    notifications: [StateChangeNotification.MGMT_REPORTING_INFO_DATA],
                    asyncEntitySelector: reportInfoEntity.select,
                    loaderPosition: 'fixed',
                },
            }}
        />
    );

    function onChangeMgmtReportingTab({ tabKey }: IOnTabChangeProps) {
        redirectToCurrentRouteButOverrideParams({
            newParams: {
                mgmtReportingTab: tabKey,
            },
        });
    }

    function renderLastUpdated() {
        const { lastSnapshotDatetime, timezone } = reportInfoEntity.select().data;

        if (!lastSnapshotDatetime) {
            return null;
        }

        return (
            <Tag
                label={{
                    msg: 'mgmt_reporting.detail.last_snapshot_date',
                    placeholders: {
                        date: formatDate({
                            date: lastSnapshotDatetime,
                            format: DATE_FORMAT.DAY_MONTH_YEAR_DOT_WITH_TIME_AND_ZONE,
                            timezone,
                        }),
                    },
                }}
                variant="grey"
            />
        );
    }
}
