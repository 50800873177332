import React from 'react';
import { makeStyles, mixins } from 'views/styling';
import { IBarChartBarsOptions, IBarChartOptions, TBarGroupValueLineAggregator } from 'models/barChart.models';
import { TLabel } from 'models/general.models';
import {
    getMgmtReportingBarChartColor,
    MGMT_REPORTING_BAR_CHART_CONFIG,
} from 'config/mgmtReporting/mgmtReportingCharts.config';
import {
    calculateBarChartPaddingsByAbsoluteBarWidth,
    determineBarChartSizeWithoutAxis,
} from 'utils/chart/barChartUtils';
import GenericVerticalBarChart from 'views/common/charts/GenericVerticalBarChart';
import { IGroupedBarChartDataPoint, ISingleBarWithinBarGroup } from 'views/common/charts/types';
import { ChartLegend, IChartLegendItem } from 'views/common/charts/ChartLegend';

export interface IMgmtReportingBarChartByTimeProps {
    name: string;
    variant: TBarChartByTimeVariant;
    categories: (Pick<IChartLegendItem, 'label'> & {
        key: string;
        /**
         * Only to be provided if the bars of this category have to be stacked on/under the bars of another category.
         * But do not configure this on the 'first' category of the stack!
         */
        catKeyToStackOn?: string;
        /** If not provided, a pre-configured color will be selected */
        color?: string;
        /** Only used when 'color' is NOT provided. Default false = a 'positive' color will be used */
        useNegativeColor?: boolean;
    })[];
    data: IMgmtReportingGroupedBarsDataPoint[];
    xAxisDateFormat?: string;
    timezone?: string;
    yAxisLabel?: TLabel;
    valueLineAggregator?: TBarGroupValueLineAggregator;
}

type TBarChartByTimeVariant = 'absoluteBarSize' | 'relativeBarSize';

export interface IMgmtReportingGroupedBarsDataPoint extends Pick<IGroupedBarChartDataPoint<Date, number>, 'x'> {
    bars: {
        catKey: string;
        y: number;
    }[];
}

const useStyles = makeStyles((/* theme */) => ({
    MgmtReportingBarChartByTime: {
        ...mixins.flexColCenterCenter(),
        ...mixins.widthMax(),
    },
}));

export default function MgmtReportingBarChartByTime({
    name,
    variant,
    categories,
    data,
    xAxisDateFormat,
    timezone,
    yAxisLabel,
    valueLineAggregator,
}: IMgmtReportingBarChartByTimeProps) {
    const classes = useStyles();

    const options: IBarChartOptions = {
        dimensions: {
            maxWidth: MGMT_REPORTING_BAR_CHART_CONFIG.svg.maxWidth,
            maxHeight: MGMT_REPORTING_BAR_CHART_CONFIG.svg.maxHeight,
            minWidth: MGMT_REPORTING_BAR_CHART_CONFIG.svg.minWidth,
        },
        axis: {
            x: {
                scaleType: 'timeEvenBands',
                height: MGMT_REPORTING_BAR_CHART_CONFIG.axis.x.height,
                marginRight: MGMT_REPORTING_BAR_CHART_CONFIG.axis.x.marginRight,
                dateFormat: xAxisDateFormat,
                timezone,
            },
            y: {
                width: MGMT_REPORTING_BAR_CHART_CONFIG.axis.y.width,
                marginTop: MGMT_REPORTING_BAR_CHART_CONFIG.axis.y.marginTop,
                label: yAxisLabel
                    ? {
                        text: yAxisLabel,
                        paddingLeft: MGMT_REPORTING_BAR_CHART_CONFIG.axis.y.label.paddingLeft,
                    }
                    : null,
            },
        },
        transitionDurationInMillis: MGMT_REPORTING_BAR_CHART_CONFIG.bars.transitionDurationInMillis,
    };

    options.bars = {
        ...getBarsConfig(),
        endCornerRadius: MGMT_REPORTING_BAR_CHART_CONFIG.bars.endCornerRadius,
    };

    const catKey2colorMap = getCatKey2colorMap();

    return (
        <div className={classes.MgmtReportingBarChartByTime}>
            <GenericVerticalBarChart<Date>
                id={`mgmt-reporting_bar-chart_${name}`}
                data={getColoredDataPoints()}
                options={options}
            />

            <ChartLegend
                items={getColoredLegendItems()}
            />
        </div>
    );

    function getCatKey2colorMap() {
        let positiveBarIndex = 0;
        let negativeBarIndex = 0;

        return categories.reduce(
            (accumulator, category) => {
                let { color } = category;

                if (!color) {
                    if (category.useNegativeColor) {
                        color = getMgmtReportingBarChartColor(negativeBarIndex, false);
                        negativeBarIndex += 1;
                    } else {
                        color = getMgmtReportingBarChartColor(positiveBarIndex, true);
                        positiveBarIndex += 1;
                    }
                }

                accumulator[category.key] = color;

                return accumulator;
            },
            {} as { [catKey:string]: string },
        );
    }

    function getColoredDataPoints(): IGroupedBarChartDataPoint<Date, number>[] {
        return data.map((barGroup) => {
            const adjacentBarsMap = categories
                .filter((category) => !category.catKeyToStackOn)
                .reduce(
                    (accumulator, category) => {
                        const matchingBar = barGroup.bars
                            .find((barGroupItem) => barGroupItem.catKey === category.key);

                        accumulator[category.key] = [{
                            y: matchingBar.y,
                            color: catKey2colorMap[category.key],
                        }];

                        return accumulator;
                    },
                    {} as { [key: string]: ISingleBarWithinBarGroup<number>[] },
                );

            categories
                .filter((category) => category.catKeyToStackOn)
                .forEach((category) => {
                    const matchingBar = barGroup.bars
                        .find((barGroupItem) => barGroupItem.catKey === category.key);
                    const relatedAdjacentBar = adjacentBarsMap[category.catKeyToStackOn];

                    relatedAdjacentBar.push({
                        y: matchingBar.y,
                        color: catKey2colorMap[category.key],
                    });
                });

            return {
                ...barGroup,
                bars: Object.values(adjacentBarsMap),
            };
        });
    }

    function getColoredLegendItems(): IChartLegendItem[] {
        return categories.map(({ key, label }) => ({
            label,
            color: catKey2colorMap[key],
        }));
    }

    function getBarsConfig(): IBarChartBarsOptions {
        const commonGroupsConfig = {
            barSeparatorLine: MGMT_REPORTING_BAR_CHART_CONFIG.bars.barSeparatorLine,
            valueLineAggregator,
        };

        if (variant === 'absoluteBarSize') {
            const nrOfBarGroups = data.length;
            const nrOfAdjacentBarsWithinEachGroup = categories
                .filter((category) => !category.catKeyToStackOn)
                .length;

            if ((nrOfBarGroups * nrOfAdjacentBarsWithinEachGroup) <= 5) {
                const { chartWidth } = determineBarChartSizeWithoutAxis(options);

                const {
                    groupsPaddingInner,
                    ...barChartPaddings
                } = calculateBarChartPaddingsByAbsoluteBarWidth({
                    chartWidth,
                    nrOfBarGroups,
                    nrOfAdjacentBarsWithinEachGroup,
                    barWidth: MGMT_REPORTING_BAR_CHART_CONFIG.bars.absolute.barWidth,
                    innerVsOuterPaddingRatio:
                        MGMT_REPORTING_BAR_CHART_CONFIG.bars.absolute.innerVsOuterPaddingRatio,
                    groupInnerVsOuterPaddingRatio:
                        MGMT_REPORTING_BAR_CHART_CONFIG.bars.absolute.groupInnerVsOuterPaddingRatio,
                });

                return {
                    ...barChartPaddings,
                    groups: {
                        ...commonGroupsConfig,
                        paddingInner: groupsPaddingInner,
                    },
                };
            }
        }

        /* variant 'relativeBarSize' */

        return {
            paddingInner: MGMT_REPORTING_BAR_CHART_CONFIG.bars.relative.paddingInner,
            paddingOuter: MGMT_REPORTING_BAR_CHART_CONFIG.bars.relative.paddingOuter,
            groups: {
                ...commonGroupsConfig,
                paddingInner: MGMT_REPORTING_BAR_CHART_CONFIG.bars.relative.groupsPaddingInner,
            },
        };
    }
}
