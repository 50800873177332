import getFirstItemOfArray from '@snipsonian/core/cjs/array/filtering/getFirstItemOfArray';
import getLastItemOfArray from '@snipsonian/core/cjs/array/filtering/getLastItemOfArray';
import { anyComparerDescending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { detectNumberMagnitude, TNumberMagnitudeFlag } from '@console/common/utils/float/floatUtils';
import { TYAxisAmountNrOfDecimals } from 'models/chart.models';
import { isSameYear } from '@console/common/utils/date/dateUtils';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import d3 from 'utils/libs/d3';
import { roundFloat, roundFloat2 } from '@console/common/utils/float/roundFloat';
import { formatAmount } from '@console/common/utils/number/amountUtils';

export function determineXTimeScale({
    xDates,
    chartWidth,
}: {
    xDates: Date[];
    chartWidth: number;
}) {
    return d3.scaleTime<number>()
        .domain([
            getFirstItemOfArray(xDates),
            getLastItemOfArray(xDates),
        ])
        .rangeRound([0, chartWidth]);
}

export function determineXBandScaleForDates({
    xDates,
    chartWidth,
}: {
    xDates: Date[];
    chartWidth: number;
}) {
    const rangeBand = chartWidth / (xDates.length - 1);
    let rangeAccumulator = 0;
    /**
     * the first date --> range 0
     * last date --> range "chartWidth"
     * intermediate dates --> a "linear" value in between
     */
    const rangeHavingValueForEachDate = xDates.reduce(
        (accumulator) => {
            accumulator.push(rangeAccumulator);
            rangeAccumulator += rangeBand;
            return accumulator;
        },
        [] as number[],
    );

    return d3.scaleOrdinal<Date, number>()
        .domain(xDates)
        .range(rangeHavingValueForEachDate);
}

export function determineYLinearScale({
    globalMinMaxAmount,
    chartHeight,
}: {
    globalMinMaxAmount: [number, number];
    chartHeight: number;
}) {
    return d3.scaleLinear<number>()
        .domain(globalMinMaxAmount.reverse())
        .rangeRound([0, chartHeight]);
}

type TTickFrequency = '5-evenly-spread' | 'every-month';

/**
 * - tickFrequency :
 *  > when "every-month" --> a tick will be there for each month, using the same day as the end date
 */
export function determineAxisTimeTicks({
    dates,
    tickFrequency = '5-evenly-spread',
    dateFormat,
    timezone,
}: {
    dates: Date[];
    tickFrequency?: TTickFrequency;
    dateFormat?: string;
    timezone?: string;
}) {
    const tickValues = determineAxisTimeTickValues({
        dates,
        tickFrequency,
    });

    return {
        tickFormatter: (domainValue: Date, index: number) => formatAxisDate({
            domainValue,
            tickIndex: index,
            tickValues,
            tickFrequency,
            dateFormat,
            timezone,
        }),
        tickValues,
    };
}

export function determineAxisTimeTickValues({
    dates,
    tickFrequency,
}: {
    dates: Date[];
    tickFrequency: TTickFrequency;
}): Date[] {
    if (dates.length < 1) {
        return [];
    }

    if (dates.length <= 5) {
        return dates;
    }

    if (tickFrequency === 'every-month') {
        const dayOfLastDate = getLastItemOfArray(dates).getDate();

        return dates.filter((aDate) => aDate.getDate() === dayOfLastDate);
    }

    /* tickFrequency === '5-evenly-spread' */

    const middleTickIndex = (dates.length % 2 === 0)
        ? dates.length / 2
        : Math.floor(dates.length / 2);

    const firstTickIndex = Math.floor(middleTickIndex / 3);
    const secondTickIndex = Math.floor((middleTickIndex / 3) * 2);

    return [
        dates[firstTickIndex],
        dates[secondTickIndex],
        dates[middleTickIndex],
        dates[middleTickIndex + (middleTickIndex - secondTickIndex)],
        dates[middleTickIndex + (middleTickIndex - firstTickIndex)],
    ];
}

export function determineAxisAmountTicks({
    minAmount,
    maxAmount,
    tickLinesLength,
    autoTickValues,
    widerTickValues,
    alwaysIncludeZeroTick,
}: IDetermineAxisAmountTickValuesProps & {
    tickLinesLength: number;
    /**
     * When true, the tickValues will not be determined by our code, but by underlying d3 logic.
     *
     * Important:
     * - when either 'roundTickValues' or 'alwaysIncludeZeroTick' are true, the autoTickValues is ignored
     *   and the tick values will always be determined by our code!
     */
    autoTickValues: boolean;
}) {
    const absoluteMaxAmount = Math.max(Math.abs(minAmount), Math.abs(maxAmount));

    const {
        flag: amountMagnitudeFlag,
        divider: amountDivider,
    } = detectNumberMagnitude(absoluteMaxAmount);
    const yMinDivided = minAmount / amountDivider;
    const yMaxDivided = maxAmount / amountDivider;
    const amountNrOfDecimals = (yMaxDivided - yMinDivided < 1)
        ? 2
        : (yMaxDivided - yMinDivided < 4)
            ? 1
            : 0;

    const tickValues = autoTickValues && !widerTickValues && !alwaysIncludeZeroTick
        ? undefined
        : determineAxisAmountTickValues({
            minAmount,
            maxAmount,
            widerTickValues,
            alwaysIncludeZeroTick,
        });

    return {
        tickFormatter: (domainValue: number) => formatAxisAmount({
            domainValue,
            amountMagnitudeFlag,
            amountDivider,
            amountNrOfDecimals,
        }),
        tickValues,
        nrOfTicks: 4, // only used when tickValues is not set
        tickLinesLength,
    };
}

interface IDetermineAxisAmountTickValuesProps {
    minAmount: number;
    maxAmount: number;
    /**
     * When widerTickValues = true, the highest (/ lowest) tick value will be a rounded number
     * larger (/ smaller) that the maxAmount (/ minAmount).
     * E.g.
     * - minAmount = -7 & maxAmount = 23.4 --then--> lowest tick = -10 & highest tick = 30
     */
    widerTickValues?: boolean;
    /**
     * When alwaysIncludeZeroTick = true, one of the ticks will definitely be the 0=zero tick.
     * When alwaysIncludeZeroTick = false, the 0 tick can still be there but this is not guaranteed.
     */
    alwaysIncludeZeroTick?: boolean;
}

export function determineAxisAmountTickValues({
    minAmount,
    maxAmount,
    widerTickValues = false,
    alwaysIncludeZeroTick = false,
}: IDetermineAxisAmountTickValuesProps): number[] {
    if (!widerTickValues && !alwaysIncludeZeroTick) {
        /* evenly distributed */
        return [
            roundFloat2(maxAmount),
            roundFloat2(minAmount + (((maxAmount - minAmount) / 3) * 2)),
            roundFloat2(minAmount + ((maxAmount - minAmount) / 3)),
            roundFloat2(minAmount),
        ];
    }

    const tickStep = determineTickStep();

    let maxTick = maxAmount;
    let minTick = minAmount;
    const tickValues: number[] = [];

    if (widerTickValues) {
        const maxDivided = maxTick / tickStep;
        const maxCeil = Math.ceil(maxDivided) * tickStep;
        if ((maxCeil > maxTick) || (maxCeil === 0)) {
            maxTick = maxCeil;
        } else {
            maxTick = Math.ceil(maxDivided + 1) * tickStep;
        }

        const minDivided = minTick / tickStep;
        const minFloor = Math.floor(minDivided) * tickStep;
        if ((minFloor < minTick) || (minFloor === 0)) {
            minTick = minFloor;
        } else {
            minTick = Math.floor(minDivided - 1) * tickStep;
        }

        if (maxTick === 0 && minTick === 0) {
            minTick = -1;
            maxTick = 1;
        }
    }

    if (alwaysIncludeZeroTick) {
        if (maxTick < 0) {
            maxTick = 0;
        } else if (minTick > 0) {
            minTick = 0;
        } else if (minTick !== 0 && maxTick !== 0) {
            tickValues.push(0);
        }
    }

    tickValues.push(roundFloat2(maxTick), roundFloat2(minTick));
    addIntermediateTicks();

    return tickValues.sort(anyComparerDescending);

    function determineTickStep() {
        if (widerTickValues) {
            const absoluteLargestAmount = Math.max(Math.abs(minAmount), Math.abs(maxAmount));
            const divider = 10 ** (absoluteLargestAmount.toFixed(0).length - 1);
            /* so the divider is at least 1 (= 10 to the power of 0) */
            const division = absoluteLargestAmount / divider;

            if (division > 4) {
                return roundFloat(divider * 2);
            }

            if (division > 2) {
                return divider;
            }

            if (division > 1.5) {
                return roundFloat(divider / 2);
            }

            const potentialTickStep = roundFloat(divider / 4);
            /*  making sure we don't return 0 as tick step */
            return potentialTickStep === 0
                ? 1
                : potentialTickStep;
        }

        return 1;
    }

    function addIntermediateTicks() {
        if (widerTickValues) {
            /* adding fixed steps in between */

            let tickValue = minTick + tickStep;
            while (tickValue < maxTick) {
                if (tickValue !== 0) {
                    tickValues.push(tickValue);
                }
                tickValue += tickStep;
            }
        } else {
            tickValues.push(
                roundFloat2(minTick + (((maxTick - minTick) / 4) * 3)),
                roundFloat2(minTick + (((maxTick - minTick) / 4) * 2)),
                roundFloat2(minTick + ((maxTick - minTick) / 4)),
            );
        }
    }
}

export function formatAxisDate({
    domainValue,
    tickIndex,
    tickValues,
    tickFrequency,
    dateFormat,
    timezone,
}: {
    domainValue: Date;
    tickIndex: number;
    tickValues: Date[];
    tickFrequency: TTickFrequency;
    dateFormat?: string;
    timezone?: string;
}) {
    if (dateFormat) {
        return formatDate({ date: domainValue, format: dateFormat, timezone });
    }

    if (tickFrequency === 'every-month') {
        return formatDate({ date: domainValue, format: DATE_FORMAT.MONTH_YEAR_SHORT, timezone });
    }

    /* tickFrequency === '5-evenly-spread' */

    const neighbourTicks = getNeighbourAxisDateTicks(tickIndex, tickValues);
    const isSameYearAsNeighbourTicks = isSameYear(domainValue, ...neighbourTicks);

    const format = isSameYearAsNeighbourTicks
        ? DATE_FORMAT.DAY_MONTH
        : DATE_FORMAT.DAY_MONTH_YEAR_SHORT;

    return formatDate({ date: domainValue, format, timezone });
}

export function getNeighbourAxisDateTicks(tickIndex: number, tickValues: Date[]) {
    const neighbours = [];
    if (tickValues[tickIndex - 1]) {
        neighbours.push(tickValues[tickIndex - 1]);
    }
    if (tickValues[tickIndex + 1]) {
        neighbours.push(tickValues[tickIndex + 1]);
    }
    return neighbours;
}

/**
 * Another option would be that - instead of all y ticks having the same "M" or "K" indicator,
 * that each tick decides for itself.
 * Or based on the previous tick?
 * // TODO
 */
export function formatAxisAmount({
    domainValue,
    amountMagnitudeFlag,
    amountDivider,
    amountNrOfDecimals,
}: {
    domainValue: number;
    amountMagnitudeFlag: TNumberMagnitudeFlag;
    amountDivider: number;
    amountNrOfDecimals: TYAxisAmountNrOfDecimals;
}) {
    const formattedAmount = formatAmount(domainValue / amountDivider, {
        nrOfDecimals: amountNrOfDecimals,
        currency: null,
    });
    return `${formattedAmount}${amountMagnitudeFlag}`;
}
