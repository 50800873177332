import React from 'react';
import clsx from 'clsx';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import {
    ALLOWED_PAGE_ITEM_LIMITS, DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR,
} from '@console/core-api/config/coreApi.config';
import { APP_COLORS } from 'config/styling/colors';
import { makeStyles, mixins } from 'views/styling';
import { getPages, limitPagesToShow, TOTAL_NR_OF_ITEMS_UNKNOWN } from 'views/common/list/dataUtils';
import IconButton from 'views/common/buttons/IconButton';
import TextButton from 'views/common/buttons/TextButton';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import InputSelectField, {
    IOnChangeSelectProps,
    mapValuesToInputSelectItems,
} from 'views/common/inputs/base/InputSelectField';

const ITEMS_PER_PAGE_SELECT_ITEMS = mapValuesToInputSelectItems(ALLOWED_PAGE_ITEM_LIMITS);

export interface IDataPaginationProps {
    itemsPerPage?: number; // default see DEFAULT_PAGE_ITEM_LIMIT
    pageNr?: number; // default see FIRST_PAGE_NR
    nrOfItems: number;
    totalNrOfItems?: number; // default -1 = unknown
    onChangeItemsPerPage: (props: IOnChangeItemsPerPageProps) => void;
    onChangePageNr: (props: IOnChangePageNrProps) => void;
}

/**
 * - next >> you can only fetch the next batch of records (if there are more records)
 * - pages >> pagination so that you can e.g. immediately jump to page 7
 */
type TPaginationType = 'next' | 'pages';

export interface IOnChangeItemsPerPageProps {
    itemsPerPage: number;
}

export interface IOnChangePageNrProps {
    pageNr: number;
    areItemsAvailable: boolean;
    itemsPerPage: number;
}

const useStyles = makeStyles((theme) => ({
    DataPagination: {
        ...mixins.widthMax(),
        ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
        ...mixins.typo({ size: 14 }),
        padding: theme.spacing(2, 4, 0, 4),

        '& .itemsPerPage': {
            ...mixins.flexRow({ alignCross: 'center' }),
            whiteSpace: 'nowrap',

            '& .__select': {
                marginLeft: theme.spacing(1),
            },
        },

        '& .pages': {
            ...mixins.flexRow({ alignCross: 'center' }),
            marginLeft: theme.spacing(2),
        },
        '& .pageLink': {
            ...mixins.flexCenter(),
            height: 32,
            width: 32,
            margin: 0,
            color: APP_COLORS.NAV.DARK,
        },
        '& .pageLink:not(.__current):hover': {
            color: APP_COLORS.PRIMARY['500'],
        },
        '& .pageLink.__current': {
            ...mixins.typoBold({ color: APP_COLORS.PRIMARY['500'] }),
            ...mixins.border({
                width: 2,
                color: APP_COLORS.PRIMARY['500'],
            }),
            borderRadius: 8,
            cursor: 'initial',
        },
        '& .pageArrow': {
            // color: APP_COLORS.NAV.DARK,
            padding: 4,
        },
        // '& .pageArrow.Mui-disabled': {
        //     color: hexToRgba(APP_COLORS.NAV.DARK, OPACITY.HIGH),
        // },
        // '& .pageArrow:hover,.pageArrow:focus': {
        //     color: APP_COLORS.PRIMARY['500'],
        //     backgroundColor: APP_COLORS.PRIMARY['100'],
        // },
    },
}));

export default function DataPagination({
    itemsPerPage = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    nrOfItems,
    totalNrOfItems = TOTAL_NR_OF_ITEMS_UNKNOWN,
    onChangeItemsPerPage,
    onChangePageNr,
}: IDataPaginationProps) {
    const classes = useStyles();

    const pages = limitPagesToShow({
        pages: getPages({
            nrOfItems,
            itemsPerPage,
            totalNrOfItems,
        }),
        pageNr,
    });

    const lastPageNr = pages[pages.length - 1].nr;

    return (
        <div className={classes.DataPagination}>
            <div className="itemsPerPage">
                <Translate msg="common.data_table.pagination.items_per_page" />:

                <InputWrapper className="__select" noPadding>
                    <InputSelectField<number>
                        items={ITEMS_PER_PAGE_SELECT_ITEMS}
                        value={itemsPerPage}
                        onChange={changeItemsPerPage}
                    />
                </InputWrapper>
            </div>

            {nrOfItems > 0 && (
                <div className="pages">
                    <IconButton
                        id="to-prev-page"
                        className="pageArrow"
                        variant="bare"
                        color="grey"
                        size="XS"
                        onClick={onSelectPrevPage}
                        disabled={pageNr === 1}
                        aria-label="prev page"
                        icon={<KeyboardArrowLeft />}
                    />

                    {pages.map((page) => {
                        const isCurrentPage = page.nr === pageNr;

                        return (
                            <TextButton
                                id={`to-page-${page.nr}`}
                                key={`page-nr-${page.nr}`}
                                variant="bare"
                                size="M"
                                className={clsx('pageLink', isCurrentPage && '__current')}
                                onClick={isCurrentPage ? () => {} : () => onSelectPage(page.nr)}
                                disabled={page.disabled}
                                label={{
                                    text: page.label,
                                    shouldTranslate: false,
                                }}
                            />
                        );
                    })}

                    <IconButton
                        id="to-next-page"
                        className="pageArrow"
                        variant="bare"
                        color="grey"
                        size="XS"
                        onClick={onSelectNextPage}
                        disabled={pageNr >= lastPageNr}
                        aria-label="next page"
                        icon={<KeyboardArrowRight />}
                    />
                </div>
            )}
        </div>
    );

    function changeItemsPerPage({ value }: IOnChangeSelectProps<number>) {
        const eventValue = value;

        onChangeItemsPerPage({
            itemsPerPage: eventValue,
        });
    }

    function onSelectPrevPage() {
        onSelectPage(pageNr - 1);
    }

    function onSelectNextPage() {
        onSelectPage(pageNr + 1);
    }

    function onSelectPage(newPageNr: number) {
        onChangePageNr({
            pageNr: newPageNr,
            areItemsAvailable: pages
                .find((page) => page.nr === newPageNr)
                .areItemsAvailable,
            itemsPerPage,
        });
    }
}

export function getPaginationType({
    totalNrOfItems = TOTAL_NR_OF_ITEMS_UNKNOWN,
}: {
    totalNrOfItems?: number;
}): TPaginationType {
    return totalNrOfItems === TOTAL_NR_OF_ITEMS_UNKNOWN
        ? 'next'
        : 'pages';
}
