import React from 'react';
import clsx from 'clsx';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import IconButton, { IIconButtonProps } from './IconButton';

interface IPublicProps
    // eslint-disable-next-line max-len
    extends Pick<IIconButtonProps, 'id' | 'className' | 'color' | 'onClick' | 'disabled' | 'tooltip' | 'tooltipPlacement'> {
    isSubmit?: boolean; // default true;
}

export default function IconButtonSubmit({
    id,
    className,
    color = 'grey',
    isSubmit = true,
    onClick, // normally you should not provide the 'onCLick' when isSubmit=true
    disabled,
    tooltip,
    tooltipPlacement = 'top',
}: IPublicProps) {
    return (
        <IconButton
            id={id}
            className={clsx('IconButton--Submit', className)}
            variant="filled"
            color={color}
            size="S"
            svgSize={24}
            isCircle={false}
            type={isSubmit ? 'submit' : undefined}
            onClick={onClick}
            disabled={disabled}
            tooltip={tooltip}
            tooltipPlacement={tooltipPlacement}
            icon={<KeyboardReturnIcon />}
        />
    );
}
