import React from 'react';
import clsx from 'clsx';
import { TLabel } from 'models/general.models';
import { APP_COLORS, hexToRgba } from 'config/styling/colors';
import { makeStyles, mixins } from 'views/styling';
import Text from 'views/common/widget/Text';
import Tooltip, { TTooltipParams } from 'views/common/widget/Tooltip';

export interface ITagProps extends IStyleProps {
    label: TLabel;
    addMargin?: boolean; // default true
    tooltip?: TTooltipParams;
}

interface IStyleProps {
    variant?: TTagVariant; // default 'regular'
}

export type TTagVariant = 'regular' | 'info' | 'warning' | 'error' | 'success' |
'red' | 'green' | 'blue' | 'orange' | 'grey';

interface ITagVariantConfig {
    textColor: string;
    backgroundColor: string;
}

const TAG_CONFIG: { [color: string]: ITagVariantConfig } = {
    // grey
    regular: getTagVariantConfig(APP_COLORS.TEXT['400'], APP_COLORS.TEXT['500']),
    // blue
    info: getTagVariantConfig(APP_COLORS.FEEDBACK.INFO),
    // orange
    warning: getTagVariantConfig(APP_COLORS.FEEDBACK.WARNING),
    // red
    error: getTagVariantConfig(APP_COLORS.FEEDBACK.ERROR),
    // green
    success: getTagVariantConfig(APP_COLORS.FEEDBACK.SUCCESS),
    // TODO merge the below colors with the upper ones
    red: getTagVariantConfig('#F16647', '#FCE0DA', false),
    green: getTagVariantConfig('#73BA18', '#E3F1D1', false),
    blue: getTagVariantConfig('#16B5E5', '#D0F0FA', false),
    orange: getTagVariantConfig('#FFA10A', '#FFECCE', false),
    grey: getTagVariantConfig('#CAD4DD', '#5D7388', false),
};

const useStyles = makeStyles((theme) => ({
    Tag: {
        ...mixins.typo({ size: 14, weight: 'bold' }),
        width: 'fit-content',
        height: theme.spacing(3),
        color: ({ variant }: IStyleProps) => TAG_CONFIG[variant].textColor,
        backgroundColor: ({ variant }: IStyleProps) => TAG_CONFIG[variant].backgroundColor,
        borderRadius: 8,
        padding: theme.spacing(0, 1),
        paddingTop: 2, // to center the text vertically in the tag
        whiteSpace: 'nowrap',

        '&.__margin': {
            margin: theme.spacing(1),
        },
    },
}));

export default function Tag({
    label,
    addMargin = true,
    variant = 'regular',
    tooltip,
}: ITagProps) {
    const classes = useStyles({ variant });

    return (
        <div
            className={clsx(
                classes.Tag,
                'Tag',
                addMargin && '__margin',
            )}
        >
            <TagContent />
        </div>
    );

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function TagContent() {
        if (tooltip) {
            return (
                <Tooltip {...tooltip}>
                    <Text label={label} />
                </Tooltip>
            );
        }

        return (
            <Text label={label} />
        );
    }
}

function getTagVariantConfig(baseColor: string, bgColor?: string, applyOpacity = true): ITagVariantConfig {
    return {
        textColor: baseColor,
        backgroundColor: applyOpacity
            ? hexToRgba(bgColor || baseColor, 0.08)
            : bgColor || baseColor,
    };
}
