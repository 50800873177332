import React, { useRef, useEffect } from 'react';
import d3, { AxisDomain, CurveFactory, CurveFactoryLineOnly } from 'utils/libs/d3';
import { ILineDataItem, TXScale, TYScale } from './types';

interface IPathLineProps<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number> {
    id: string;
    xScale: TXScale<XDomain>;
    yScale: TYScale<YDomain>;
    lineData: ILineDataItem<XDomain, YDomain>[];
    strokeColor: string;
    strokeWidth?: number; // default 2
    strokeRounded?: boolean; // default false
    /* default curveLinear, see https://github.com/d3/d3-shape/blob/master/README.md#curves */
    curveFactory?: CurveFactory | CurveFactoryLineOnly;
    transitionDurationInMillis?: number;
}

export default function PathLine<XDomain extends AxisDomain = Date, YDomain extends AxisDomain = number>({
    id,
    xScale,
    yScale,
    lineData,
    strokeColor,
    strokeWidth = 2,
    strokeRounded = false,
    curveFactory,
    transitionDurationInMillis = 0,
}: IPathLineProps<XDomain, YDomain>) {
    const ref = useRef();

    useEffect(
        () => {
            const lineGenerator = d3.line<ILineDataItem<XDomain, YDomain>>()
                .x((d) => xScale(d.x))
                .y((d) => yScale(d.y));

            if (curveFactory) {
                lineGenerator.curve(curveFactory);
            }

            const path = d3.select<SVGPathElement, unknown>(ref.current)
                .datum(lineData)
                .attr('fill', 'none')
                .attr('stroke', strokeColor)
                .attr('stroke-width', strokeWidth);

            if (strokeRounded) {
                path
                    // .attr('stroke-linecap', 'round')
                    .attr('stroke-linejoin', 'round');
            }

            if (transitionDurationInMillis) {
                const flatLineGenerator = d3.line<ILineDataItem<XDomain, YDomain>>()
                    .x((d) => xScale(d.x))
                    .y(() => yScale.range()[1]);

                path.attr('d', flatLineGenerator)
                    .transition()
                    .duration(transitionDurationInMillis)
                    .ease(d3.easeLinear)
                    .attr('d', lineGenerator);
            } else {
                path.attr('d', lineGenerator);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id], /* the transition will only be done again when the id changes --> should be unique across all lines */
    );

    return (
        <path ref={ref} />
    );
}
