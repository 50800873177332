import React from 'react';
import clsx from 'clsx';
import Backdrop from '@mui/material/Backdrop';
import { Z_INDEX } from 'config/styling/elevation';
import { APP_COLORS } from 'config/styling/colors';
import { hexToRgba } from 'utils/styling/colorUtils';
import { makeStyles } from 'views/styling';
import Spinner, { IPublicProps as ISpinnerProps } from './Spinner';

export type TSpinnerPosition = 'fixed' | 'absolute';

interface IPublicProps extends ISpinnerProps {
    open: boolean;
    position?: TSpinnerPosition; // default 'absolute'
    variant?: 'default' | 'white';
}

const useStyles = makeStyles((/* theme */) => ({
    SpinnerOverlay: {
        position: 'absolute',
        zIndex: Z_INDEX.SPINNER_OVERLAY,
        backgroundColor: hexToRgba(APP_COLORS.NAV.DARK, 0.05),

        '&.entireScreen': {
            position: 'fixed',
        },
        '&.withinParent': {
            alignItems: 'flex-start',

            '& .spinner-in-overlay': {
                marginTop: 30,
            },
        },
        '&.onWhiteBackground': {
            backgroundColor: hexToRgba(APP_COLORS.SYSTEM.WHITE, 0.5),
        },
    },
}));

/**
 * This will put an opacity layer on top and show a spinner in the middle of it.
 * - If position 'fixed' : the entire screen will be covered.
 * - If position 'absolute' : depending on the closest parent which has a position attribute, this 'overlay' will cover
 * just a section of the page (or even the entire page if there is no parent with a position).
 */
export default function SpinnerOverlay({
    open,
    position = 'absolute',
    variant = 'default',
    ...spinnerProps
}: IPublicProps) {
    const classes = useStyles();

    return (
        <Backdrop
            open={open}
            className={clsx(
                classes.SpinnerOverlay,
                (position === 'fixed') && 'entireScreen',
                (position === 'absolute') && 'withinParent',
                (variant === 'white') && 'onWhiteBackground',
            )}
        >
            <Spinner {...spinnerProps} className="spinner-in-overlay" />
        </Backdrop>
    );
}
