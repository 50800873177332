import React, { useState } from 'react';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import isNumber from '@snipsonian/core/cjs/is/isNumber';
import * as ConsoleBff from '@console/bff/export';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { reportInfoEntity } from 'state/entities/mgmtReporting/reportInfo';
import { selectRoboPortfolioPerformanceReport } from 'state/entities/mgmtReporting/roboPortfolioPerformanceReport';
import { makeStyles, mixins } from 'views/styling';
import { ILineChartData } from 'views/common/charts/types';
import { IRenderDataProps } from 'views/common/widget/EntityWrapper';
import MgmtReportingBox from '../blocks/MgmtReportingBox';
import { MgmtReportingNoData } from '../blocks/MgmtReportingNoData';
import MgmtReportingOverlappingLineChart from '../graphs/MgmtReportingOverlappingLineChart';

const LABEL_PREFIX = 'mgmt_reporting.portfolio_performance';

const useStyles = makeStyles((/* theme */) => ({
    PortfolioPerformanceBox: {
        ...mixins.widthMax(),
    },
}));

export default function PortfolioPerformanceBox() {
    const classes = useStyles();

    return (
        <MgmtReportingBox<ConsoleBff.IFetchRoboPortfolioPerformanceReportApiReplyBff>
            className={classes.PortfolioPerformanceBox}
            top={{
                title: `${LABEL_PREFIX}.title`,
                description: `${LABEL_PREFIX}.box_description`,
                tooltip: {
                    name: 'tooltip_portfolio-performance_twr',
                    simpleContent: {
                        info: `${LABEL_PREFIX}.box_tooltip`,
                    },
                },
            }}
            sections={[{
                title: () => `${LABEL_PREFIX}.sections.return_by_policy`,
                notifications: [StateChangeNotification.MGMT_REPORTING_ROBO_PORTFOLIO_PERFORMANCE_DATA],
                asyncEntitySelector: selectRoboPortfolioPerformanceReport,
                renderContent: renderReturnByPolicySectionContent,
            }]}
        />
    );

    function renderReturnByPolicySectionContent({ data }: IRenderDataProps<ConsoleBff.IFetchRoboPortfolioPerformanceReportApiReplyBff>) {
        if (!isArrayWithValues(data.report.time_series)) {
            return <MgmtReportingNoData />;
        }

        const lineChartData: ILineChartData<Date, number>[] = Object.entries(data.report.id_to_name_map)
            .map(([policyId, policyName]) => ({
                key: policyId,
                label: {
                    text: policyName || policyId,
                    shouldTranslate: false,
                },
                /* lineColor will be assigned automatically */
                dataPoints: data.report.time_series.map(({ datetime, items }) => ({
                    x: new Date(datetime),
                    y: convertPercentageToRelativeTo100(
                        items.find(({ id }) => policyId === id)?.twr,
                    ),
                })),
            }));

        return (
            <PortfolioPerformanceChart
                lineChartData={lineChartData}
            />
        );
    }
}

/**
 * Separate child component so that the 'selectedDate' are not on a higher level.
 * Otherwise, the transitions started again when doing these selections.
 */
function PortfolioPerformanceChart({
    lineChartData,
}: {
    lineChartData: ILineChartData<Date, number>[];
}) {
    const [selectedDate, setSelectedDate] = useState<Date>(null);

    const { timezone } = reportInfoEntity.select().data;

    /* additional check for when the period  was changed after selecting a date */
    const isSelectedDateStillInPeriod = selectedDate
        && lineChartData.some(({ dataPoints }) => dataPoints.some(({ x }) => x.getTime() === selectedDate.getTime()));

    return (
        <MgmtReportingOverlappingLineChart
            chartName="robo-twr-by-policy"
            yLabel={`${LABEL_PREFIX}.chart.y_axis`}
            yDomain="percentage"
            timezone={timezone}
            data={lineChartData}
            chartLegend={{
                variant: 'horizontal',
            }}
            selectedDate={isSelectedDateStillInPeriod ? selectedDate : null}
            onSelectDate={setSelectedDate}
        />
    );
}

function convertPercentageToRelativeTo100(pct: number) {
    if (isNumber(pct)) {
        return pct * 100;
    }

    return 0;
}
