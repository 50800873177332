import React from 'react';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import * as colors from '@mui/material/colors';
import InputAdornment from '@mui/material/InputAdornment';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isObjectPure from '@snipsonian/core/cjs/is/isObjectPure';
import isNumber from '@snipsonian/core/cjs/is/isNumber';
import isString from '@snipsonian/core/cjs/is/isString';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { makeStyles, mixins } from 'views/styling';
import CustomI18nContext from 'views/appShell/providers/CustomI18nContext';
import { GENERIC_CLASS } from 'views/assets/cssInJs/genericClasses';

const DEFAULT_MULTILINE_NR_OF_ROWS = 4;

export interface IInputTextFieldProps<Value extends TInputValue = string> {
    id?: string;
    name?: string;
    className?: string;
    type?: React.InputHTMLAttributes<unknown>['type'];
    value: Value;
    onChange: (onChangeProps: IOnChangeTextInputProps<Value>) => void;
    userInputConverter?: (userInput: unknown) => Value;
    placeholder?: TLabel;
    /* If true, then a space placeholder will be shown below the input field if there is a placeholder but no value */
    addSpaceIfPlaceholderAndNoValue?: boolean; // default true
    error?: TLabel;
    emphasizeError?: boolean; // default true - if true, then the error will be shown in red
    disabled?: boolean; // default false
    autoComplete?: string; // default 'off'
    fullWidth?: boolean; // default true
    noInnerMargin?: boolean; // default false
    /* default false - if true, then DEFAULT_MULTILINE_NR_OF_ROWS rows will be used as default */
    multilineRows?: boolean | number;
    inputProps?: {
        adornment?: TInputAdornment; // default none
        min?: number; // for number inputs - minimum value
        max?: number; // for number inputs - maximum value
        minlength?: number; // minimum nr of chars
        maxlength?: number; // maximum nr of chars
        step?: string; // for number inputs - how much it goes up/down (e.g. '0.01') when pressing up/down arrow keys
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        style?: Record<string, any>;
    };
    selectProps?: null | TAnyObject; // default null - only pass an object if this Input is actually used as a Select
    markAsWrappedInputField?: boolean; // default true
    positionHelperTextAsAbsolute?: boolean; // default false
    children?: React.ReactNode; // when type Select
    crudStylingType?: TCrudStylingType;
}

export type TInputAdornment = string;
export type TInputValue = string | number;
export type TCrudStylingType = 'edited' | 'added';

export interface IOnChangeTextInputProps<Value extends TInputValue = string> {
    value: Value;
}

const useStyles = makeStyles((theme) => ({
    InputTextField: {
        ...mixins.flexColTopLeft(),
        ...mixins.widthMax(),
        position: 'relative',

        '& .MuiInputBase-root': {
            width: 'unset',
            margin: theme.spacing(0, 1),
        },
        '&.no-inner-margin .MuiInputBase-root': {
            margin: 0,
        },
        '& .helperOrErrorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.TEXT['300'] }),
            lineHeight: 1.2,
            paddingTop: theme.spacing(1),
        },
        '& .helperOrErrorText.__position--absolute': {
            position: 'absolute',
            top: theme.spacing(5),
        },
        '&.has-error .helperOrErrorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.FEEDBACK.ERROR }),
        },
        '& .MuiInputBase-root.MuiInputBase-multiline': {
            marginRight: 0,
        },
        '& .MuiInputBase-inputMultiline': {
            paddingRight: theme.spacing(1.5),
        },
        '& .edited .MuiInputBase-root': {
            ...mixins.typo({ color: APP_COLORS.SECONDARY['400'] }),
        },
        '& .edited.wrapped-input-field': {
            backgroundColor: APP_COLORS.SECONDARY['100'],
        },
        '& .added .MuiInputBase-root': {
            ...mixins.typo({ color: colors.green['500'] }),
        },
        '& .added.wrapped-input-field': {
            backgroundColor: colors.green['200'],
        },
        '& .MuiInputBase-root:focus-within': {
            ...mixins.typo({ color: APP_COLORS.TEXT['500'] }),
        },
        '& .spacePlaceholder': {
            ...mixins.widthHeightPixels({ h: 25 }),
        },
        '& .MuiTypography-root': {
            ...mixins.typo({ color: APP_COLORS.SYSTEM.BLACK }),
        },
    },
}));

export default function InputTextField<Value extends TInputValue = string>({
    id,
    name,
    className,
    type = 'text',
    /* default value is an empty string (even if Value is e.g. 'number'), because if value is undefined,
       then this would become an "uncontrolled" component */
    value = '' as Value,
    onChange,
    userInputConverter,
    placeholder,
    addSpaceIfPlaceholderAndNoValue = true,
    error,
    emphasizeError = true,
    disabled,
    autoComplete = 'off',
    fullWidth = true,
    noInnerMargin = false,
    multilineRows = false,
    inputProps,
    selectProps = null,
    markAsWrappedInputField = true,
    positionHelperTextAsAbsolute = false,
    crudStylingType,
    children,
}: IInputTextFieldProps<Value>) {
    const classes = useStyles();

    const adjustedValue = isSet(value) ? value : '';
    const helperTextLabel = error || (isValueSet() ? placeholder : null);
    const multilineNrOfRows = isNumber(multilineRows)
        ? multilineRows
        : (multilineRows === true)
            ? DEFAULT_MULTILINE_NR_OF_ROWS
            : 0;
    const isSelect = isObjectPure(selectProps);
    const inputAdornment = isSet(inputProps?.adornment)
        ? <InputAdornment position="end">{inputProps.adornment}</InputAdornment>
        : null;

    /* eslint-disable react/jsx-no-duplicate-props */
    return (
        <CustomI18nContext.Consumer>
            {({ translatorIfNeeded }) => (
                <div
                    className={clsx(
                        classes.InputTextField,
                        'InputTextField',
                        error && emphasizeError && 'has-error',
                    )}
                >
                    <TextField
                        id={id}
                        name={name}
                        type={type}
                        variant="standard"
                        className={clsx(
                            markAsWrappedInputField && GENERIC_CLASS.WRAPPED_INPUT_FIELD,
                            noInnerMargin && 'no-inner-margin',
                            crudStylingType === 'added' && 'added',
                            crudStylingType === 'edited' && 'edited',
                            className,
                        )}
                        value={adjustedValue}
                        onChange={onChangeInput}
                        placeholder={placeholder ? translatorIfNeeded(placeholder) : null}
                        disabled={disabled}
                        autoComplete={autoComplete}
                        fullWidth={fullWidth}
                        multiline={multilineNrOfRows > 0}
                        rows={multilineNrOfRows}
                        InputProps={{
                            disableUnderline: true,
                            fullWidth: true,
                            endAdornment: inputAdornment,
                        }}
                        inputProps={inputProps}
                        select={isSelect}
                        SelectProps={selectProps}
                    >
                        {children}
                    </TextField>

                    {helperTextLabel && (
                        <div
                            className={clsx(
                                'helperOrErrorText',
                                positionHelperTextAsAbsolute && '__position--absolute',
                            )}
                        >
                            {translatorIfNeeded(helperTextLabel)}
                        </div>
                    )}
                    {!helperTextLabel && placeholder && markAsWrappedInputField && addSpaceIfPlaceholderAndNoValue && (
                        <div className="spacePlaceholder">
                            &nbsp;
                        </div>
                    )}
                </div>
            )}
        </CustomI18nContext.Consumer>
    );
    /* eslint-enable react/jsx-no-duplicate-props */

    function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
        const eventValue = event.target.value as Value;

        onChange({
            value: userInputConverter
                ? userInputConverter(eventValue)
                : eventValue,
        });
    }

    function isValueSet() {
        if (!isSet(value)) {
            return false;
        }

        if (isString(value)) {
            return value.length > 0;
        }

        return true;
    }
}
