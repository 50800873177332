import dayjs from 'dayjs';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import quarterOfYearPlugin from 'dayjs/plugin/quarterOfYear';
import calendarPlugin from 'dayjs/plugin/calendar';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import isString from '@snipsonian/core/cjs/is/isString';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isDate from '@snipsonian/core/cjs/is/isDate';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { ONE_DAY_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import { getCurrentDate } from '@snipsonian/core/cjs/date/currentDate';
import { roundFloat } from '../float/roundFloat';

dayjs.extend(advancedFormatPlugin);
dayjs.extend(quarterOfYearPlugin);
dayjs.extend(calendarPlugin);
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(isoWeekPlugin);
// This modifies the start of the week to be Monday instead of the default Sunday
dayjs.Ls.en.weekStart = 1;

export type TDateToFormat = dayjs.ConfigType;
export type TDateUnitType = dayjs.OpUnitType;
export type TDateManipulateUnitType = dayjs.ManipulateType;
export type TDateQuarterUnitType = dayjs.QUnitType;
export type TDateCombinedUnitType = TDateUnitType | TDateQuarterUnitType;
export type TDateEnhanced = dayjs.Dayjs;

/* e.g. "Europe/Brussels" */
export type TTimezone = 'LOCAL' | 'UTC' | string;
export const TIMEZONE_LOCAL = 'LOCAL';
export const TIMEZONE_UTC = 'UTC';

export interface IDayMonthYear {
    day: number; // 1-31
    month: number; // 1-12
    year: number; // e.g. 2021
}

export interface IParseDateOptions {
    treatInputDateAsOfLocalTimezone?: boolean; /* default false */
}

export function nowAsDayJs() {
    return dayjs();
}

export function parseInputDate(
    date: TDateToFormat,
    options: IParseDateOptions = {},
): dayjs.Dayjs {
    if (isString(date) && options && options.treatInputDateAsOfLocalTimezone) {
        return dayjs(stripTimezone(date as string));
    }

    return dayjs(date);
}

function stripTimezone(date: string) {
    if (date.length > 19) {
        /* strip away anything after YYYY-MM-DDTHH:mm:ss */
        return date.substr(0, 19)
            .replace('T', ' ');
    }
    return date;
}

export function isSameYear(date: Date, ...compareDates: Date[]) {
    if (!isArrayWithValues(compareDates)) {
        return false;
    }
    const year = date.getFullYear();
    return compareDates.every((compareDate) => compareDate.getFullYear() === year);
}

export function isSameMonth(date: Date, ...compareDates: Date[]) {
    if (!isArrayWithValues(compareDates)) {
        return false;
    }
    const month = date.getMonth();
    return compareDates.every((compareDate) => compareDate.getMonth() === month);
}

export function toDayMonthYear(date: TDateEnhanced): IDayMonthYear {
    return {
        day: date.get('day'),
        month: date.get('month') + 1,
        year: date.get('year'),
    };
}

export function toStartOfDay(date: TDateToFormat): TDateEnhanced {
    return parseInputDate(date).startOf('day');
}

export function toEndOfDay(date: TDateToFormat): TDateEnhanced {
    return parseInputDate(date).endOf('day');
}

export function toStartOfDayWithinTimezone(input: TToDateWithinTimezoneInput = {}): TDateEnhanced {
    return toDateWithinTimezone(input)
        .startOf('day');
}

export function toEndOfDayWithinTimezone(input: TToDateWithinTimezoneInput = {}): TDateEnhanced {
    return toDateWithinTimezone(input)
        .endOf('day');
}

type TToDateWithinTimezoneInput = {
    date?: TDateToFormat,
    timezone?: TTimezone, /* default null = 'UTC' time zone */
    keepLocalTime?: boolean; /* default false. When true, the input time part (if any) will not be converted. */
};

export function toDateWithinTimezone({
    date,
    timezone = TIMEZONE_UTC,
    keepLocalTime = false,
}: TToDateWithinTimezoneInput = {}): TDateEnhanced {
    const enhancedDate = isSet(date)
        ? parseInputDate(date)
        : nowAsDayJs();

    switch (timezone) {
        case TIMEZONE_LOCAL:
            return enhancedDate;
        case TIMEZONE_UTC:
            return enhancedDate.utc(keepLocalTime);
        default:
            return enhancedDate.tz(timezone, keepLocalTime);
    }
}

export function getStartDateOfYear(date: TDateToFormat = getCurrentDate()) {
    return parseInputDate(date).startOf('year');
}

export function getStartDateOfQuarter(date: TDateToFormat = getCurrentDate()) {
    return parseInputDate(date).startOf('Q');
}

export function getStartDateOfMonth(date: TDateToFormat = getCurrentDate()) {
    return parseInputDate(date).startOf('M');
}

export function getStartDateOfWeek(date: TDateToFormat = getCurrentDate()) {
    return parseInputDate(date).startOf('week');
}

export function getDatesOfLastFullUnitType({
    date = getCurrentDate(),
    unitType,
}: {
    date?: TDateToFormat;
    unitType: TDateManipulateUnitType;
}) {
    const parsedDate = parseInputDate(date).add(-1, unitType);

    return {
        startDate: parsedDate.startOf(unitType),
        endDate: parsedDate.endOf(unitType),
    };
}

export function getDatesOfLastFullWeek(date?: TDateToFormat) {
    return getDatesOfLastFullUnitType({ date, unitType: 'w' });
}

export function getDatesOfLastFullMonth(date?: TDateToFormat) {
    return getDatesOfLastFullUnitType({ date, unitType: 'M' });
}

/*
    Roman: Due to the fact that the quarter logic is part of an extension of dayjs I couldn't figure out a way
    to make one function that would allow dayjs.OpUnitType and dayjs.QUnitType. That's why this function does not use
    getDatesOfLastFullNonQuarterUnitType().
*/
export function getDatesOfLastFullQuarter(date: TDateToFormat = getCurrentDate()) {
    const parsedDate = parseInputDate(date).add(-1, 'Q');

    return {
        startDate: parsedDate.startOf('Q'),
        endDate: parsedDate.endOf('Q'),
    };
}

export function getDatesOfLastFullYear(date?: TDateToFormat) {
    return getDatesOfLastFullUnitType({ date, unitType: 'y' });
}

export function getLaterDate(firstDate: TDateToFormat, secondDate: TDateToFormat) {
    if (!isSet(firstDate)) {
        return secondDate;
    }

    if (!isSet(secondDate)) {
        return firstDate;
    }

    return parseInputDate(firstDate).isAfter(parseInputDate(secondDate))
        ? firstDate
        : secondDate;
}

export function getLatestDate(dates: Date[]) {
    return dates.sort((dateA, dateB) => (getLaterDate(dateA, dateB) === dateA ? -1 : 1))[0];
}

export function getNrOfDaysBetweenDates(dateA: Date, dateB: Date): number {
    const millisBetween = Math.abs(dateA.getTime() - dateB.getTime());
    const daysBetween = millisBetween / ONE_DAY_IN_MILLIS;
    return roundFloat(daysBetween);
}

export function ensureDate(date: string | Date): Date {
    if (isDate(date)) {
        return date;
    }

    return new Date(date);
}

export function findClosestDate({
    candidates,
    date,
}: {
    candidates: Date[];
    date: Date;
}): Date {
    const inputTime = date.getTime();

    let smallestDiff: number = null;

    const indexOfClosestCandidate = candidates
        .map((candidateDate) => Math.abs(candidateDate.getTime() - inputTime))
        .reduce(
            (accumulator, candidateDiff, index) => {
                if (!isSet(smallestDiff) || candidateDiff < smallestDiff) {
                    smallestDiff = candidateDiff;
                    return index;
                }
                return accumulator;
            },
            0,
        );

    return candidates[indexOfClosestCandidate];
}
