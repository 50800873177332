import { ONE_MINUTE_IN_MILLIS } from '@snipsonian/core/cjs/time/periodsInMillis';
import { RESPONSE_TYPES } from '@snipsonian/axios/cjs/request/types';
import {
    IUnderlyingRequestApiInput,
} from '@typsy/rest-api/dist/client/underlyingApi/initUnderlyingApiRequestConfigFromRequest';
import {
    IApiEntityListResponse,
    IApiEntityResponseSupportingEmbedding,
    IBaseSingleEntityApiInput,
    IDownloadableDocument,
} from '../../models/api.models';
import {
    IFetchPortfoliosApiInput,
    ISinglePortfolioApiInput,
    IPortfolioEntityData,
    TPortfoliosData,
    TPortfolio, TPortfolioPatch, TPortfolioCreate,
    TPortfolioEmbeddableFields,
    IPortfolioCreateTransactionsFlag,
} from '../../models/portfolioMgmt/portfolio.models';
import {
    IPortfolioPerformancePast,
    IPortfolioPerformanceFuture,
    TFetchPortfolioPerformancePastApiInput,
    TFetchPortfolioPerformanceFutureApiInput,
} from '../../models/portfolioMgmt/portfolioPerformance.models';
import {
    DEFAULT_PAGE_ITEM_LIMIT, FIRST_PAGE_NR,
    DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
} from '../../config/coreApi.config';
import { CoreApiPath } from '../../config/coreApiUrls.config';
import { getDownloadFileResponseMapper } from '../../utils/file/fileDownloadUtils';
import { conditionallyAskCount } from '../../utils/fetch/entityFetchUtils';
import fetchApiEntityUrlParamBuilder from '../../utils/fetch/fetchApiEntityUrlParamBuilder';
import { toExactMatch } from '../../utils/fetch/customQueryParamValueFormatter';
import { get, patch, post, remove } from '../coreApiRequestWrapper';

export function fetchPortfolios({
    email,
    userLastName,
    ownerId,
    portfolioName,
    virtualIban,
    externalId,
    offset,
    ids,
    limit = DEFAULT_PAGE_ITEM_LIMIT,
    pageNr = FIRST_PAGE_NR,
    orderBy,
    embed,
    count,
    underlyingApiRequestConfig,
}: IFetchPortfoliosApiInput & IUnderlyingRequestApiInput = {}) {
    return get<TPortfoliosData, IApiEntityListResponse<IPortfolioEntityData> & IApiEntityResponseSupportingEmbedding>({
        url: CoreApiPath.PORTFOLIOS,
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                // .contains({ field: 'name', value: portfolioName })
                .exactMatch({ field: 'name', value: portfolioName })
                .exactMatch({ field: 'external_id', value: externalId })
                .exactMatch({ field: 'owned_by_user_id', value: ownerId })
                .fieldIn({ field: 'id', value: ids })
                .orderBy(orderBy)
                .embed<TPortfolioEmbeddableFields>({ fields: embed })
                .build(),
            email: toExactMatch(email),
            last_name: toExactMatch(userLastName),
            iban: toExactMatch(virtualIban),
            offset,
            limit,
            ...conditionallyAskCount({ count, pageNr }),
        },
        mapResponse: ({ data }) => ({
            pageNr,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}

export function fetchPortfolioDetails({
    portfolioId,
    embed,
    underlyingApiRequestConfig,
}: ISinglePortfolioApiInput & IUnderlyingRequestApiInput) {
    return get<TPortfolio>({
        url: CoreApiPath.PORTFOLIO_DETAILS,
        pathParams: {
            portfolioId,
        },
        queryParams: {
            ...fetchApiEntityUrlParamBuilder()
                .embed({ fields: embed })
                .build(),
        },
        ...underlyingApiRequestConfig,
    });
}

export function patchPortfolio({
    id,
    shouldCreateTransactions,
    underlyingApiRequestConfig,
    ...patchFields
}: TPortfolioPatch & IPortfolioCreateTransactionsFlag & IUnderlyingRequestApiInput) {
    return patch<TPortfolio>({
        url: CoreApiPath.PORTFOLIO_DETAILS,
        pathParams: {
            portfolioId: id,
        },
        body: patchFields,
        queryParams: {
            create_transactions: shouldCreateTransactions,
        },
        ...underlyingApiRequestConfig,
    });
}

export function createPortfolio({
    underlyingApiRequestConfig,
    ...portfolioToCreate
}: TPortfolioCreate & IUnderlyingRequestApiInput) {
    return post<TPortfolio>({
        url: CoreApiPath.PORTFOLIOS,
        body: portfolioToCreate,
        ...underlyingApiRequestConfig,
    });
}

export function deletePortfolio({ id }: IBaseSingleEntityApiInput) {
    return remove<unknown>({
        url: CoreApiPath.PORTFOLIO_DETAILS,
        pathParams: {
            portfolioId: id,
        },
    });
}

export function fetchPortfolioPerformancePast({
    portfolioId,
    underlyingApiRequestConfig,
    ...other
}: TFetchPortfolioPerformancePastApiInput & IUnderlyingRequestApiInput) {
    return post<IPortfolioPerformancePast, Omit<IPortfolioPerformancePast, 'portfolioId'>>({
        url: CoreApiPath.PORTFOLIO_PERFORMANCE_PAST,
        pathParams: {
            portfolioId,
        },
        body: {
            method: 'PORTFOLIO_PAST_PERFORMANCE',
            method_settings: {
                base_currency: other.baseCurrency,
                start_date: other.startDate,
                end_date: other.endDate,
                sample_frequency: other.sampleFrequency,
            },
        },
        mapResponse: ({ data }) => ({
            portfolioId,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}

export function fetchPortfolioPerformanceFuture({
    portfolioId,
    useExpectedReturns = DEFAULT_FUTURE_PERFORMANCE_USE_EXPECTED_RETURNS,
    underlyingApiRequestConfig,
    ...other
}: TFetchPortfolioPerformanceFutureApiInput & IUnderlyingRequestApiInput) {
    return post<IPortfolioPerformanceFuture, Omit<IPortfolioPerformanceFuture, 'portfolioId'>>({
        url: CoreApiPath.PORTFOLIO_PERFORMANCE_FUTURE,
        pathParams: {
            portfolioId,
        },
        body: {
            method: 'PORTFOLIO_FUTURE_PERFORMANCE',
            method_settings: {
                start_date: other.startDate,
                end_date: other.endDate,
                start_amount: other.startAmount,
                recurring_deposit_amount: other.recurringDepositAmount,
                recurring_deposit_frequency: other.recurringDepositFrequency,
                quantiles: other.quantiles,
                sample_frequency: other.sampleFrequency,
                use_expected_returns: useExpectedReturns,
            },
        },
        mapResponse: ({ data }) => ({
            portfolioId,
            ...data,
        }),
        ...underlyingApiRequestConfig,
    });
}

/**
 * TODO: Would be better to make this call asynchronous (incl. notification) instead of a large timeout
 * so that the user does not have to wait.
 * Also "exportUsers".
 */
export function exportPortfolios() {
    return get<IDownloadableDocument, Blob>({
        url: CoreApiPath.PORTFOLIOS_EXPORT,
        headers: {
            Accept: 'text/csv',
        },
        responseType: RESPONSE_TYPES.blob,
        timeoutInMillis: 5 * ONE_MINUTE_IN_MILLIS,
        mapResponse: getDownloadFileResponseMapper('portfolios.csv'),
    });
}
