import React from 'react';
import { AxisDomain, ScaleTime } from 'utils/libs/d3';
import { getElementDimensionsById } from 'utils/dom/selectorUtils';
import { TXScale } from 'views/common/charts/types';

export function determineXValueThatCorrespondsWithClick<XDomain extends AxisDomain = Date>({
    event,
    xScale,
    clickableArea,
}: {
    event: React.MouseEvent;
    xScale: TXScale<XDomain>;
    clickableArea: {
        id: string;
        width: number;
    };
}): XDomain {
    const mouseClientX = event.clientX;
    const { left, width } = getElementDimensionsById(clickableArea.id);
    const chartResizeRatio = width / clickableArea.width;

    const xCoordinateWithinXAxis = (mouseClientX - left) / chartResizeRatio;

    if (!(xScale as unknown as ScaleTime<number, unknown>).invert) {
        throw new Error('The XAxis is unexpectedly not of type "ScaleTime".');
    }

    const xDateEquivalent = (xScale as unknown as ScaleTime<number, unknown>).invert(xCoordinateWithinXAxis);

    return xDateEquivalent as XDomain;
}
