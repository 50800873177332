import React from 'react';
import clsx from 'clsx';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import * as colors from '@mui/material/colors';
import TextField from '@mui/material/TextField';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isArray from '@snipsonian/core/cjs/is/isArray';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { getStore } from 'state';
import { getTranslatorIfNeeded } from 'state/i18n/selectors';
import { hexToRgba } from 'utils/styling/colorUtils';
import { makeStyles, mixins } from 'views/styling';
import { GENERIC_CLASS } from 'views/assets/cssInJs/genericClasses';
import { getInputSelectItemDisplayLabel, IInputSelectFieldProps } from 'views/common/inputs/base/InputSelectField';
import { CloseIcon } from 'views/common/icons';
import { stringComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';

const DEFAULT_NO_OPTIONS_LABEL = 'common.multi_select_input.no_options_label';

export interface IInputSearchableSelectItem<Value = string> {
    label: TLabel;
    value: Value;
}

export type TInputSearchableSelectValue = string[] | string;

export interface IInputSearchableSelectFieldProps<Value extends TInputSearchableSelectValue>
    extends Pick<IInputSelectFieldProps<string>, 'error' | 'emphasizeError' | 'disabled' | 'crudStylingType'> {
    items?: IInputSearchableSelectItem[];
    /* If you provide a string-array the input will allow multiple values,
        however if you provide a single string it will only allow single values */
    value: Value;
    placeholder?: TLabel;
    onChange: (onChangeProps: IOnChangeSearchableSelectProps<Value>) => void;
    shouldAllowCustomInput?: boolean; // default false
    itemLabelsAreTranslationKeys?: boolean; // default false
    labelPrefix?: string;
    noOptionsLabel?: TLabel;
    sortItemsByLabel?: boolean; // default true
    fixedValues?: string[];
}

export interface IOnChangeSearchableSelectProps<Value extends TInputSearchableSelectValue> {
    value: Value;
}

const useStyles = makeStyles((theme) => ({
    InputMultiSelectField: {
        ...mixins.widthMax(),

        '& .MuiAutocomplete-tag': {
            height: 24,
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),

            borderRadius: 8,
            color: APP_COLORS.TEXT['500'],
            backgroundColor: hexToRgba(APP_COLORS.TEXT['500'], 0.08),
            fontSize: 15,

            '& svg': {
                width: 20,
                height: 20,
            },
        },
        '& .MuiAutocomplete-clearIndicator svg': {
            transform: 'translate(0px, -2px)',
        },
        '&.has-error .helperOrErrorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.FEEDBACK.ERROR }),
        },
        '& .helperOrErrorText': {
            ...mixins.typo({ size: 14, color: APP_COLORS.TEXT['300'] }),
            lineHeight: 1.2,
            paddingTop: theme.spacing(1),
        },
    },
    InnerTextInput: {
        '& .MuiInputBase-root': {
            width: 'unset',
            margin: theme.spacing(0, 1),
        },
        '& .edited .MuiInputBase-root': {
            ...mixins.typo({ color: APP_COLORS.SECONDARY['400'] }),
        },
        '& .edited .MuiInputBase-root.Mui-focused': {
            ...mixins.typo({ color: APP_COLORS.TEXT['900'] }),
        },
        '& .edited.wrapped-input-field': {
            backgroundColor: APP_COLORS.SECONDARY['100'],
        },
        '& .added .MuiInputBase-root': {
            ...mixins.typo({ color: colors.green['500'] }),
        },
        '& .added .MuiInputBase-root.Mui-focused': {
            ...mixins.typo({ color: APP_COLORS.TEXT['900'] }),
        },
        '& .added.wrapped-input-field': {
            backgroundColor: colors.green['200'],
        },
    },
}));

export default function InputSearchableSelectField<Value extends TInputSearchableSelectValue>({
    placeholder,
    itemLabelsAreTranslationKeys = false,
    shouldAllowCustomInput = false,
    labelPrefix,
    value,
    onChange,
    items,
    noOptionsLabel,
    sortItemsByLabel = true,
    disabled,
    error,
    emphasizeError,
    crudStylingType,
    fixedValues,
}: IInputSearchableSelectFieldProps<Value>) {
    const classes = useStyles();
    const areItemsSet = isArrayWithValues(items);
    const translatorIfNeeded = getTranslatorIfNeeded(getStore().getState());
    const shouldAllowMultipleValues = isArray(value);
    const shouldCloseModalAfterSelect = !shouldAllowMultipleValues;

    return (
        <div className={clsx(
            classes.InputMultiSelectField,
            error && emphasizeError && 'has-error',
        )}
        >
            <Autocomplete
                freeSolo={shouldAllowCustomInput}
                filterSelectedOptions
                multiple={shouldAllowMultipleValues}
                onChange={(event, inputValue) => {
                    if (!shouldAllowMultipleValues || !fixedValues?.length) {
                        onChange({ value: inputValue as Value });
                    } else {
                        const inputValueAsArray = inputValue as string[];
                        onChange({
                            value: [
                                ...fixedValues,
                                ...inputValueAsArray.filter((option) => fixedValues.indexOf(option) === -1),
                            ] as Value,
                        });
                    }
                }}
                value={value}
                options={getOptions()}
                disabled={disabled}
                forcePopupIcon={false}
                getOptionLabel={getDisplayLabelForItemValue}
                noOptionsText={getTranslatedNoOptionsText()}
                disableCloseOnSelect={!shouldCloseModalAfterSelect}
                clearIcon={<CloseIcon />}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                        if (items?.length > 0) {
                            const inputItem = items.find((item) => item.value === option);
                            return (
                                <Chip
                                    label={getDisplayLabelForItem(inputItem)}
                                    {...getTagProps({ index })}
                                    disabled={
                                        (shouldAllowMultipleValues && fixedValues?.length > 0)
                                        && fixedValues?.indexOf(option) !== -1}
                                    deleteIcon={<CloseIcon />}
                                />
                            );
                        }

                        return (
                            <Chip
                                key={`searchable-select-chip_${option}`}
                                label={option}
                                {...getTagProps({ index })}
                                deleteIcon={<CloseIcon />}
                            />
                        );
                    })
                }
                renderInput={(params) => (
                    <div className={classes.InnerTextInput}>
                        <TextField
                            {...params}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                ...params.InputProps,
                            }}
                            placeholder={getTranslatedPlaceholder()}
                            className={clsx(
                                GENERIC_CLASS.WRAPPED_INPUT_FIELD,
                                crudStylingType === 'added' && 'added',
                                crudStylingType === 'edited' && 'edited',
                            )}
                        />
                        {error && (
                            <div className="helperOrErrorText">
                                {translatorIfNeeded(error)}
                            </div>
                        )}
                    </div>
                )}
            />
        </div>
    );

    function getTranslatedNoOptionsText() {
        return isSet(noOptionsLabel)
            ? translatorIfNeeded(noOptionsLabel)
            : translatorIfNeeded(DEFAULT_NO_OPTIONS_LABEL);
    }

    function getTranslatedPlaceholder() {
        return isSet(placeholder)
            ? translatorIfNeeded(placeholder)
            : null;
    }

    function getOptions() {
        if (!areItemsSet) {
            return [];
        }

        return getOptionallySortedItems()
            .map((displayItem) => displayItem.value);
    }

    function getOptionallySortedItems() {
        if (sortItemsByLabel) {
            return items
                .map((item) => ({
                    ...item,
                    displayLabel: getDisplayLabelForItem(item),
                }))
                .sort((itemA, itemB) =>
                    stringComparerAscending(itemA.displayLabel, itemB.displayLabel));
        }

        return items;
    }

    function getDisplayLabelForItem(item: IInputSearchableSelectItem) {
        if (!item) {
            return '';
        }
        return getInputSelectItemDisplayLabel({
            item,
            itemLabelsAreTranslationKeys,
            labelPrefix,
        });
    }

    function getItemByValue(itemValue: string) {
        return items.find((item) => item.value === itemValue);
    }

    function getDisplayLabelForItemValue(itemValue: string) {
        return areItemsSet
            ? getDisplayLabelForItem(getItemByValue(itemValue))
            : itemValue;
    }
}
