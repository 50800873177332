import React from 'react';
import { TStoryManagerStatus } from '@console/bff/models/storyteller/storymanager.models';
import { makeStyles } from 'views/styling';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    getStoryManagerFilters,
    getStoryManagerEditorMode,
    getStoryManagerAlwaysOnLocales,
} from 'state/ui/storyManager.selector';
import { setStoryManagerPageVars } from 'state/ui/uiPages.actions';
import InputSearchableMultiSelectField, {
    IOnChangeSearchableMultiSelectProps,
} from 'views/common/inputs/base/InputSearchableMultiSelectField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import DataSearch from 'views/common/list/DataSearch';
import { IObserveProps, observe } from 'views/observe';
import { IInputSearchableSelectItem } from 'views/common/inputs/base/InputSearchableSelectField';
import { getStoryManagerLocales } from 'state/entities/storyTeller/storyManagerDatabaseDetail';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.side_nav.filters';

const useStyles = makeStyles((theme) => ({
    Filters: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        marginBottom: theme.spacing(1),

        '& > :first-child': {
            paddingTop: 0,
        },

        '& .DataSearch': {
            padding: theme.spacing(0, 0, 1),
            '& .helperOrErrorText': {
                display: 'none',
            },
        },
    },
}));

const STATUS_ITEMS: IInputSearchableSelectItem<TStoryManagerStatus>[] = [{
    label: 'to_do',
    value: 'TO_DO',
}, {
    label: 'in_progress',
    value: 'IN_PROGRESS',
}, {
    label: 'in_review',
    value: 'IN_REVIEW',
}, {
    label: 'done',
    value: 'DONE',
}];

function SideNavigationFilters({
    state,
    dispatch,
}: IObserveProps) {
    const editorMode = getStoryManagerEditorMode(state);
    const filters = getStoryManagerFilters(state);
    const classes = useStyles();

    const storyManagerLocales = getStoryManagerLocales();
    const storyManagerAlwaysOnLocales = getStoryManagerAlwaysOnLocales();

    const LOCALE_ITEMS: IInputSearchableSelectItem[] =
        storyManagerLocales
            .map((locale) => ({
                label: locale,
                value: locale,
            })) || [];

    return (
        <div className={classes.Filters}>
            {editorMode === 'text' && storyManagerLocales.length > 0 && (
                <InputWrapper label={`${TRANSLATION_PREFIX}.locale.label`}>
                    <InputSearchableMultiSelectField
                        onChange={onLocaleFilterChange}
                        values={filters.locale || []}
                        items={LOCALE_ITEMS}
                        placeholder={`${TRANSLATION_PREFIX}.locale.label`}
                        fixedValues={storyManagerAlwaysOnLocales}
                    />
                </InputWrapper>
            )}
            <InputWrapper label={`${TRANSLATION_PREFIX}.status.label`}>
                <InputSearchableMultiSelectField
                    onChange={onStatusFilterChange}
                    values={filters.status || []}
                    items={STATUS_ITEMS}
                    labelPrefix="apps.story_teller.output_keys.status"
                    itemLabelsAreTranslationKeys
                    placeholder={`${TRANSLATION_PREFIX}.status.label`}
                />
            </InputWrapper>
            <InputWrapper label={`${TRANSLATION_PREFIX}.search.label`}>
                <DataSearch
                    listPageVars={{
                        search: {
                            simple: {
                                filterValue: filters?.outputKey || '',
                                extraFilterToggles: {},
                            },
                        },
                    }}
                    onSetSearchMode={() => null}
                    simple={{
                        onSearch: onOutputKeySearch,
                        onSetSearchValue: onOutputKeySearch,
                        tipTranslationKey: `${TRANSLATION_PREFIX}.search.label`,
                        minInputChars: 1,
                        isClientSideSearch: true,
                    }}
                />
            </InputWrapper>
        </div>
    );

    function onOutputKeySearch(searchValue: string) {
        dispatch(setStoryManagerPageVars({
            filters: {
                ...filters,
                outputKey: searchValue,
            },
        }, [StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER]));
    }

    function onStatusFilterChange({
        values,
    }: IOnChangeSearchableMultiSelectProps) {
        dispatch(setStoryManagerPageVars({
            filters: {
                ...filters,
                status: values,
            },
        }, [StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER]));
    }

    function onLocaleFilterChange({
        values,
    }: IOnChangeSearchableMultiSelectProps) {
        dispatch(setStoryManagerPageVars({
            filters: {
                ...filters,
                locale: values,
            },
        }, [StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER]));
    }
}

export default observe(
    [
        StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER,
        StateChangeNotification.STORY_MANAGER_DATABASES,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
    ],
    SideNavigationFilters,
);
