import React from 'react';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TLabel } from 'models/general.models';
import { isUntranslatableLabelTypeGuard, toI18nLabel } from 'utils/i18n/i18nUtils';

export interface ITextProps {
    label: TLabel;
}

export default function Text({
    label,
}: ITextProps) {
    if (isUntranslatableLabelTypeGuard(label)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return (<>{label.text}</>);
    }

    return (
        <Translate {...toI18nLabel(label)} />
    );
}
