import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import getConfiguredStore from 'state/setup/getConfiguredStore';
import 'state/onRouteConfig';
import 'views/routeConfigs';
import { StoreProvider } from 'views/observe';
import AppThemeProvider from './providers/AppThemeProvider';
import I18nProvider from './providers/I18nProvider';
import AppTemplate from './AppTemplate';

function App() {
    return (
        <div className="App">
            <StoreProvider value={getConfiguredStore()}>
                <AppThemeProvider>
                    <Router>
                        <I18nProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <AppTemplate />
                            </LocalizationProvider>
                        </I18nProvider>
                    </Router>
                </AppThemeProvider>
            </StoreProvider>
        </div>
    );
}

export default App;
