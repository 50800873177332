import React, { MouseEvent, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { gsap } from 'gsap';
import getRandomNumber from '@snipsonian/core/cjs/number/getRandomNumber';
import { MENU_COLORS, APP_COLORS } from 'config/styling/colors';
import { makeStyles, mixins } from 'views/styling';
import executePeriodicallyWhenBrowserTabActive
    from '@snipsonian/browser/cjs/tabVisibility/executePeriodicallyWhenBrowserTabActive';
import generateUniqueKey from 'utils/string/generateUniqueKey';
// import { CustomEase } from 'utils/libs/gsap/CustomEase';

// gsap.registerPlugin(CustomEase);

const DIAMOND_FILL_COLOR: { [index: number]: string } = {
    1: MENU_COLORS.ICON.BASIC.A,
    2: MENU_COLORS.ICON.BASIC.C,
    3: MENU_COLORS.ICON.BASIC.E,
    4: APP_COLORS.PRIMARY['500'],
};

const NR_OF_DIAMONDS = 20;
const MAX_POSITION_PERCENTAGE = 3;
const MAX_POSITION_PERCENTAGE_VARIATION = 4;
const DIAMOND_OPACITY = 0.6;

const PX_MOVEMENT_OUTSIDE = 2;

interface IDiamond {
    fill?: string;
    size?: number;
    position: {
        /* percentages from 3 to 97 (also see MAX_POSITION_PERCENTAGE_DIFF) */
        left?: number;
        top?: number;
    };
    key?: string;
}

/* each attribute can be left out o that it will be determined randomly */
const DIAMONDS: IDiamond[] = [
    { position: getRandomizePosition({ left: 4, top: 16 }), fill: DIAMOND_FILL_COLOR[1], size: 15 },
    { position: getRandomizePosition({ left: 16, top: 57 }), fill: DIAMOND_FILL_COLOR[3], size: 12 },
    { position: getRandomizePosition({ left: 22, top: 84 }) },
    { position: getRandomizePosition({ left: 56, top: 5 }), fill: DIAMOND_FILL_COLOR[2], size: 7 },
    { position: getRandomizePosition({ left: 60, top: 92 }), fill: DIAMOND_FILL_COLOR[4], size: 10 },
    { position: getRandomizePosition({ left: 77, top: 10 }) },
    { position: getRandomizePosition({ left: 94, top: 50 }) },
    { position: getRandomizePosition({ left: 88, top: 83 }) },
];

const useStyles = makeStyles((/* theme */) => ({
    AnimatedDiamonds: {
        ...mixins.widthHeightMax(),
        position: 'absolute',
        top: 0,
        left: 0,

        '& .diamond': {
            position: 'absolute',
            fill: '#FFFFFF',
            opacity: DIAMOND_OPACITY,

            '&.shootingStar': {
                opacity: 1,
            },
        },
    },
}));

export default function AnimatedDiamonds() {
    const classes = useStyles();
    const wrapperRef = useRef(null);
    const diamonds = randomiseMissingDiamondFields(DIAMONDS, NR_OF_DIAMONDS);

    useLayoutEffect(() => {
        const stopTwinklingStars = executePeriodicallyWhenBrowserTabActive({
            executeImmediatelyInActiveTab: true,
            intervalInMillis: 6000,
            toBeExecuted: () => {
                if (!wrapperRef || !wrapperRef.current) {
                    return;
                }

                const randomDiamondIndex = getRandomNumber({ min: 0, max: diamonds.length - 1 });

                gsap.timeline()
                    .to(`.diamond-${randomDiamondIndex}`, {
                        duration: 0.2,
                        opacity: 1,
                    })
                    .to(`.diamond-${randomDiamondIndex}`, {
                        delay: 0.3,
                        duration: 0.5,
                        opacity: 0,
                    })
                    .to(`.diamond-${randomDiamondIndex}`, {
                        delay: 1.5,
                        duration: 2,
                        opacity: DIAMOND_OPACITY,
                    });
            },
        });

        const stopShootingStars = executePeriodicallyWhenBrowserTabActive({
            executeImmediatelyInActiveTab: false,
            intervalInMillis: 10000,
            toBeExecuted: () => {
                if (!wrapperRef || !wrapperRef.current) {
                    return;
                }

                const wrapperElem = wrapperRef.current;
                const wrapperDimensions = getElementDimensions(wrapperElem);

                const size = getRandomNumber({ min: 7, max: 15 });
                const startingPosition = getRandomStartingPositionForShootingStar();

                const diamondElem = getNewInvestSuiteDiamondElement();
                diamondElem.classList.add('diamond');
                diamondElem.classList.add('shootingStar');
                diamondElem.style.left = `${startingPosition.left}%`;
                diamondElem.style.top = `${startingPosition.top}%`;
                diamondElem.style.fill = DIAMOND_FILL_COLOR[getRandomNumber({ min: 1, max: 4 })];
                diamondElem.style.width = `${size}px`;
                diamondElem.style.height = `${size}px`;

                wrapperElem.appendChild(diamondElem);

                // 1 = top / 2 = right / 3 = bottom / 4 = left
                const randomTargetPosition = getRandomTargetPositionForShootingStar(wrapperDimensions);

                gsap.to(diamondElem, {
                    left: randomTargetPosition.left,
                    top: randomTargetPosition.top,
                    duration: 1,
                    ease: 'none',
                    onComplete: () => {
                        diamondElem.remove();
                    },
                });
            },
        });

        return () => {
            stopTwinklingStars();
            stopShootingStars();
        };
    });

    return (
        <div
            className={classes.AnimatedDiamonds}
            ref={wrapperRef}
            onMouseMove={onMouseMove}
        >
            {diamonds.map((diamond, index) => (
                <InvestSuiteDiamond
                    key={diamond.key}
                    diamond={diamond}
                    index={index}
                />
            ))}
        </div>
    );

    function onMouseMove(event: MouseEvent) {
        if (wrapperRef && wrapperRef.current) {
            const wrapperElem = wrapperRef.current;
            const wrapperDimensions = getElementDimensions(wrapperElem);
            const wrapperMiddleX = wrapperDimensions.width / 2;
            const wrapperMiddleY = wrapperDimensions.height / 2;

            const leftVsMiddlePercentage = (event.clientX - wrapperMiddleX) / wrapperMiddleX;
            const topVsMiddlePercentage = (event.clientY - wrapperMiddleY) / wrapperMiddleY;

            gsap.to('.diamond-mouse-move', {
                x: leftVsMiddlePercentage * -15,
                y: topVsMiddlePercentage * -15,
                duration: 0.2,
            });
        }
    }

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function InvestSuiteDiamond({ diamond, index }: { diamond: IDiamond; index: number }) {
        return (
            <svg
                width={`${diamond.size}px`}
                height={`${diamond.size}px`}
                viewBox="0 0 15 15"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={clsx('diamond', 'diamond-mouse-move', `diamond-${index}`)}
                style={{
                    left: `${diamond.position.left}%`,
                    top: `${diamond.position.top}%`,
                }}
            >
                <title>Snowflake</title>
                <g stroke="none" strokeWidth="1" fillRule="evenodd" fill={diamond.fill}>
                    <rect
                        transform="translate(7.500000, 7.500000) rotate(45.000000) translate(-271.500000, -365.500000)"
                        x="266"
                        y="360"
                        width="11"
                        height="11"
                        rx="3"
                    />
                </g>
            </svg>
        );
    }

    function getNewInvestSuiteDiamondElement() {
        const elem = document.createElement('div');
        elem.innerHTML = `
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 15 15"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>Snowflake</title>
                <g stroke="none" strokeWidth="1" fillRule="evenodd">
                    <rect
                        transform="translate(7.500000, 7.500000) rotate(45.000000) translate(-271.500000, -365.500000)"
                        x="266"
                        y="360"
                        width="11"
                        height="11"
                        rx="3"
                    />
                </g>
            </svg>
        `;
        return elem;
    }
}

function randomiseMissingDiamondFields(diamonds: IDiamond[], nrOfDiamonds: number): IDiamond[] {
    const extraFullyRandomDiamonds: IDiamond[] = [];

    for (let i = 0; i <= nrOfDiamonds - diamonds.length; i++) {
        extraFullyRandomDiamonds.push({ position: {} });
    }

    return diamonds
        .concat(extraFullyRandomDiamonds)
        .map((diamond) => ({
            fill: diamond.fill || DIAMOND_FILL_COLOR[getRandomNumber({ min: 1, max: 4 })],
            size: diamond.size || getRandomNumber({ min: 7, max: 15 }),
            position: {
                left: diamond.position.left || getRandomNumber({ min: 3, max: 97 }),
                top: diamond.position.top || getRandomNumber({ min: 3, max: 97 }),
            },
            key: diamond.key || generateUniqueKey({ prefix: 'diamond' }),
        }));
}

function getRandomizePosition({ left, top }: { left: number; top: number }) {
    return {
        left: getRandomNumber({
            min: Math.max(MAX_POSITION_PERCENTAGE, left - MAX_POSITION_PERCENTAGE_VARIATION),
            max: Math.min(100 - MAX_POSITION_PERCENTAGE, left + MAX_POSITION_PERCENTAGE_VARIATION),
        }),
        top: getRandomNumber({
            min: Math.max(MAX_POSITION_PERCENTAGE, top - MAX_POSITION_PERCENTAGE_VARIATION),
            max: Math.min(100 - MAX_POSITION_PERCENTAGE, top + MAX_POSITION_PERCENTAGE_VARIATION),
        }),
    };
}

function getRandomStartingPositionForShootingStar() {
    const randomSection = getRandomNumber({ min: 1, max: 9 });

    switch (randomSection) {
        case 1: // top-left
            return getRandomizePosition({ left: 15, top: 15 });
        case 2: // top-middle
            return getRandomizePosition({ left: 47, top: 12 });
        case 3: // top-right
            return getRandomizePosition({ left: 76, top: 17 });
        case 4: // middle-left
            return getRandomizePosition({ left: 21, top: 51 });
        case 5: // middle-middle
            return getRandomizePosition({ left: 55, top: 58 });
        case 6: // middle-right
            return getRandomizePosition({ left: 78, top: 49 });
        case 7: // bottom-left
            return getRandomizePosition({ left: 17, top: 77 });
        case 8: // bottom-middle
            return getRandomizePosition({ left: 40, top: 86 });
        case 9: // bottom-right
            return getRandomizePosition({ left: 80, top: 73 });
        default:
            return null;
    }
}

function getRandomTargetPositionForShootingStar({
    width,
    height,
}: {
    width: number;
    height: number;
}) {
    // 1 = top / 2 = right / 3 = bottom / 4 = left
    const randomDirection = getRandomNumber({ min: 1, max: 4 });

    return {
        left: randomDirection === 4
            ? -1 * PX_MOVEMENT_OUTSIDE
            : randomDirection === 2
                ? width + PX_MOVEMENT_OUTSIDE
                : getRandomNumber({ min: 0, max: width }),
        top: randomDirection === 1
            ? -1 * PX_MOVEMENT_OUTSIDE
            : randomDirection === 3
                ? height + PX_MOVEMENT_OUTSIDE
                : getRandomNumber({ min: 0, max: height }),
    };
}

function getElementDimensions(element: Element) {
    const rect = element.getBoundingClientRect();
    return {
        width: rect.left + rect.width,
        height: rect.top + rect.height,
    };
}
