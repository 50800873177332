import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import InputTextField, {
    IInputTextFieldProps,
    IOnChangeTextInputProps,
    TInputValue,
} from 'views/common/inputs/base/InputTextField';
import ExtendedInputWrapper, {
    IExtendedInputWrapperProps,
    prependLabelWithPrefix,
} from 'views/common/inputs/extended/ExtendedInputWrapper';
import { TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { NO_DATA_CHARACTER } from 'config/styling/typography';
import { makeStyles, mixins } from 'views/styling';
import {
    ExtendedInputFormContext,
    IExtendedFormField,
    TFormFieldValue,
} from 'views/common/inputs/extended/ExtendedInputForm';
import Text from 'views/common/widget/Text';

interface IExtendedInputTextProps<Value extends TInputValue = string>
    // eslint-disable-next-line max-len
    extends IExtendedInputBaseProps, Omit<IInputTextFieldProps<Value>, 'id' | 'name' | 'value' | 'onChange' | 'error' | 'emphasizeError'> {
    shouldPrefixPlaceholder?: boolean; // default true
    noDataLabel?: TLabel;
}

export interface IExtendedInputBaseProps<Value extends TFormFieldValue = TFormFieldValue> {
    readOnly?: boolean; // default false
    formField: IExtendedFormField<Value>;
    wrapper: Omit<IExtendedInputWrapperProps, 'disabled' | 'children' | 'readOnly' | 'hasError' | 'inputHideable'>;
    hideIfValueUnset?: boolean; // default false
}

const useStyles = makeStyles((theme) => ({
    ExtendedInputText: {
        '& .readOnly': {
            paddingBottom: theme.spacing(1),
        },
        '& .noDataLabel': {
            ...mixins.typo({ size: 15, color: APP_COLORS.GREY['300'] }),
        },
    },
}));

export default function ExtendedInputText<Value extends TInputValue = string>({
    readOnly = false,
    formField,
    wrapper,
    placeholder,
    noDataLabel,
    shouldPrefixPlaceholder = true,
    hideIfValueUnset = false,
    ...inputFieldProps
}: IExtendedInputTextProps<Value>) {
    const classes = useStyles();
    const doesInputHaveValue = isSet(formField.value);

    return (
        <ExtendedInputFormContext.Consumer>
            {({ labelPrefix, readOnlyForm, setFieldValue }) => {
                const adjustedPlaceholder = prependLabelWithPrefix({
                    label: placeholder,
                    labelPrefix,
                    shouldPrefixLabel: shouldPrefixPlaceholder,
                });
                const shouldNotDisplay = hideIfValueUnset && !doesInputHaveValue;
                const isFieldReadOnly = readOnly || readOnlyForm;

                function onChange({ value }: IOnChangeTextInputProps<Value>) {
                    setFieldValue({ fieldName: formField.fieldName, value });
                }

                return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                        {!shouldNotDisplay && (
                            <ExtendedInputWrapper
                                disabled={inputFieldProps.disabled}
                                readOnly={isFieldReadOnly}
                                hasError={!!formField.error && formField.emphasizeError}
                                {...wrapper}
                                className={clsx(wrapper.className, classes.ExtendedInputText)}
                            >
                                {!isFieldReadOnly && (
                                    <InputTextField<Value>
                                        id={formField.fieldId}
                                        name={formField.fieldName}
                                        value={formField.value as Value}
                                        onChange={onChange}
                                        error={formField.error}
                                        emphasizeError={formField.emphasizeError}
                                        placeholder={adjustedPlaceholder}
                                        crudStylingType={formField.isDiff ? 'edited' : null}
                                        {...inputFieldProps}
                                    />
                                )}
                                {isFieldReadOnly && (
                                    <div className="readOnly">
                                        {renderReadOnlyComponent()}
                                    </div>
                                )}
                            </ExtendedInputWrapper>
                        )}
                    </>
                );

                function renderReadOnlyComponent() {
                    if (!doesInputHaveValue && noDataLabel) {
                        return (
                            // eslint-disable-next-line jsx-a11y/label-has-associated-control
                            <label className="noDataLabel">
                                <Text
                                    label={prependLabelWithPrefix({
                                        label: noDataLabel,
                                        labelPrefix,
                                        shouldPrefixLabel: true,
                                    })}
                                />
                            </label>
                        );
                    }

                    if (!doesInputHaveValue && !noDataLabel) {
                        return (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>{NO_DATA_CHARACTER}</>
                        );
                    }

                    return (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>{formField.value}</>
                    );
                }
            }}
        </ExtendedInputFormContext.Consumer>
    );
}
