import React from 'react';
import { IExtendedInputFormContext } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputSearchableSelect from 'views/common/inputs/extended/ExtendedInputSearchableSelect';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import clsx from 'clsx';
import { IConditionGroup } from '@console/bff/models/storyteller/storymanager.models';
import { makeStyles, mixins } from 'views/styling';
import ActionButtons from 'views/common/buttons/ActionButtons';
import { TrashIcon } from 'views/common/icons';
import TextButton from 'views/common/buttons/TextButton';
import { mapArrayLikeObjectToArray, reshiftArrayLikeObject } from '@console/common/utils/object/objectUtils';
import cloneDeep from 'lodash/cloneDeep';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import { CONDITION_OPERATORS } from 'config/storyTeller/storymanager.config';
import { IInputSearchableSelectItem } from 'views/common/inputs/base/InputSearchableSelectField';
import { IScenarioDetailsFormValues } from './shared.models';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.scenario_detail';

const useStyles = makeStyles((theme) => ({
    ConditionRow: {
        display: 'grid',
        alignItems: 'start',
        gridTemplateColumns: '32px 1fr 100px 1fr 32px 40px 48px',
        gap: theme.spacing(1),

        '& > .label': {
            ...mixins.typoText({ weight: 700 }),
            padding: theme.spacing(2.25, 0, 0),
        },
        '&.or > .label': {
            ...mixins.typoText({ size: 12, weight: 700 }),
        },

        '&:not(:first-child):not(.or)': {
            borderTop: `1px solid ${theme.palette.grey[300]}`,
        },

        '& > .actions': {
            '& button': {
                margin: 0,
                marginTop: theme.spacing(1.5),
            },
        },
        '& > .text-button': {
            margin: 0,
            marginTop: theme.spacing(1.5),
            height: 32,
        },
    },
    EmptyConditions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    ReadOnlyConditions: {
        padding: theme.spacing(1.5, 0, 2),

        '& > .rowWrapper': {
            '&:not(:last-child)': {
                marginBottom: theme.spacing(1),
            },
            '& > .row': {
                display: 'flex',
                '& > span': {
                    '&:first-child': {
                        width: 32,
                    },
                },
            },
        },
    },
}));

interface IPublicProps {
    availableVariables: string[];
}

export function ConditionEditorForm({
    fields,
    setFieldValue,
    availableVariables,
}: IExtendedInputFormContext<IScenarioDetailsFormValues> & IPublicProps) {
    const classes = useStyles();
    const conditions = (fields.conditions.value || {}) as { [index: string]: IConditionGroup; };

    if (Object.keys(conditions).length === 0) {
        return (
            <div className={classes.EmptyConditions}>
                <Translate msg={`${TRANSLATION_PREFIX}.fields.conditions.empty`} />
                <TextButton
                    id="condition-editor-empty-add-new"
                    label={`${TRANSLATION_PREFIX}.fields.conditions.actions.add_first`}
                    onClick={addFirstCondition}
                    size="S"
                />
            </div>

        );
    }

    const selectItems: IInputSearchableSelectItem[] = availableVariables.map((item) => ({
        label: item,
        value: item,
    }));

    return (
        <div>
            {mapArrayLikeObjectToArray(conditions).map((condition, conditionIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                mapArrayLikeObjectToArray(condition).map((subcondition, subconditionIndex) => {
                    const itemFormFieldName = `conditions.${conditionIndex}.${subconditionIndex}`;
                    const leftFieldName = `${itemFormFieldName}.left`;
                    const operatorFieldName = `${itemFormFieldName}.operator`;
                    const rightFieldName = `${itemFormFieldName}.right`;

                    const isOrCondition = subconditionIndex !== 0;

                    const rightFieldValue = fields[rightFieldName].value as string || '';
                    const leftFieldValue = fields[leftFieldName].value as string || '';

                    return (
                        <div // eslint-disable-next-line react/no-array-index-key
                            key={`${conditionIndex}-${subconditionIndex}`}
                            className={clsx(classes.ConditionRow, { or: isOrCondition })}
                        >
                            <div className="label">
                                {isOrCondition ?
                                    <Translate msg={`${TRANSLATION_PREFIX}.fields.conditions.or`} /> :
                                    `${(conditionIndex + 1).toString().padStart(2, '0')}.`}
                            </div>
                            <ExtendedInputSearchableSelect
                                formField={fields[leftFieldName]}
                                wrapper={{}}
                                items={selectItems}
                                placeholder={{
                                    text: leftFieldValue,
                                    shouldTranslate: false,
                                }}
                            />
                            <ExtendedInputSelect
                                formField={fields[operatorFieldName]}
                                wrapper={{}}
                                items={CONDITION_OPERATORS}
                            />
                            <ExtendedInputSearchableSelect
                                formField={fields[rightFieldName]}
                                wrapper={{}}
                                items={selectItems}
                                shouldAllowCustomInput
                                placeholder={{
                                    text: rightFieldValue,
                                    shouldTranslate: false,
                                }}
                            />
                            <ActionButtons
                                className="actions"
                                actions={[{
                                    id: `${conditionIndex}-${subconditionIndex}-condition-editor-delete`,
                                    label: `${TRANSLATION_PREFIX}.fields.conditions.actions.delete`,
                                    onExecute: deleteConditionRow,
                                    icon: <TrashIcon />,
                                    variant: 'main-icon-bare',
                                }]}
                            />
                            {subconditionIndex === 0 && (
                                <TextButton
                                    id={`${conditionIndex}-${subconditionIndex}-or`}
                                    label={`${TRANSLATION_PREFIX}.fields.conditions.actions.or`}
                                    className="text-button"
                                    variant="outlined"
                                    onClick={addOrConditionRow}
                                    size="M"
                                />
                            )}
                            {(conditionIndex === Object.keys(conditions).length - 1) && subconditionIndex === 0 && (
                                <TextButton
                                    id={`${conditionIndex}-${subconditionIndex}-and`}
                                    label={`${TRANSLATION_PREFIX}.fields.conditions.actions.and`}
                                    className="text-button"
                                    onClick={addAndConditionRow}
                                    size="M"
                                />
                            )}
                        </div>
                    );

                    function addOrConditionRow() {
                        const newConditions = cloneDeep(conditions);

                        newConditions[conditionIndex][Object.keys(newConditions[conditionIndex]).length] = {
                            left: '',
                            operator: '',
                            right: '',
                        };

                        setFieldValue({
                            fieldName: 'conditions',
                            value: newConditions,
                            resetChildFields: true,
                        });
                    }

                    function addAndConditionRow() {
                        const newConditions = cloneDeep(conditions);

                        newConditions[Object.keys(newConditions).length] = {
                            0: {
                                left: '',
                                operator: '',
                                right: '',
                            },
                        };

                        setFieldValue({
                            fieldName: 'conditions',
                            value: newConditions,
                            resetChildFields: true,
                        });
                    }

                    function deleteConditionRow() {
                        let newConditions = cloneDeep(conditions);

                        delete newConditions[conditionIndex][subconditionIndex];
                        newConditions[conditionIndex] = reshiftArrayLikeObject(newConditions[conditionIndex]);

                        if (Object.keys(newConditions[conditionIndex]).length === 0) {
                            delete newConditions[conditionIndex];
                            newConditions = reshiftArrayLikeObject(newConditions);
                        }

                        setFieldValue({
                            fieldName: 'conditions',
                            value: newConditions,
                            resetChildFields: true,
                        });
                    }
                })
            ))}
        </div>
    );

    function addFirstCondition() {
        const newConditions: { [index: string]: IConditionGroup; } = {
            0: {
                0: {
                    left: '',
                    operator: '',
                    right: '',
                },
            },
        };

        setFieldValue({
            fieldName: 'conditions',
            value: newConditions,
            resetChildFields: true,
        });
    }
}

export function ReadOnlyConditions({
    fields,
}: IExtendedInputFormContext<IScenarioDetailsFormValues>) {
    const classes = useStyles();
    const conditions = (fields.conditions.value || {}) as { [index: string]: IConditionGroup; };

    return (
        <div className={classes.ReadOnlyConditions}>
            {Object.keys(conditions).length === 0 && (
                <Translate msg={`${TRANSLATION_PREFIX}.fields.conditions.empty`} />
            )}
            {mapArrayLikeObjectToArray(conditions).map((condition, conditionIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="rowWrapper" key={conditionIndex}>
                    {mapArrayLikeObjectToArray(condition).map((subcondition, subconditionIndex) => {
                        const isOrCondition = subconditionIndex !== 0;

                        return ( // eslint-disable-next-line react/no-array-index-key
                            <div className="row" key={`${conditionIndex}-${subconditionIndex}`}>
                                <span>
                                    {isOrCondition ?
                                        <Translate msg={`${TRANSLATION_PREFIX}.fields.conditions.or`} /> :
                                        `${(conditionIndex + 1).toString().padStart(2, '0')}.`}
                                </span>
                                <span>
                                    {`${subcondition.left} ${getOpLabel(subcondition.operator)} ${subcondition.right}`}
                                </span>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );

    function getOpLabel(operator: string) {
        return CONDITION_OPERATORS.find((item) => item.value === operator)?.label || operator;
    }
}
