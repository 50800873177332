import { Styles } from '@mui/styles/withStyles';
import { Theme as DefaultTheme } from '@mui/material/styles/createTheme';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { layoutClasses, getLayoutStyles } from './layout';
import { tableClasses, getTableStyles } from './table';

/**
 * Mechanism to define re-usable utility classes on components.
 *
 * IMPORTANT:
 * - These utility classes don't work within modals/popovers/poppers/etc.
 *   So not for any component that is directly on the html root.
 *   For those you can use the 'mixin' mechanism instead.
 *   // TODO a generic Modal component that also incorporates these utility classes
 */

const CLASS_PREFIX = 'utility-';

const allClasses = {
    layout: layoutClasses,
    table: tableClasses,
};

export const UtilityClass = prependClassNames<typeof allClasses>(allClasses);

const childStyles = convertToChildStyles({
    ...getLayoutStyles(),
    ...getTableStyles(),
});

export function getUtilityChildStyles(/* theme: DefaultTheme */): Styles<DefaultTheme, TAnyObject> {
    return childStyles;
}

function convertToChildStyles(styles: Styles<DefaultTheme, TAnyObject>): Styles<DefaultTheme, TAnyObject> {
    return Object.keys(styles)
        .reduce(
            (accumulator, styleKey) => {
                accumulator[`& .${CLASS_PREFIX}${styleKey}`] = (styles as TObjectWithProps)[styleKey];
                return accumulator;
            },
            {} as TObjectWithProps,
        );
}

function prependClassNames<AllClasses>(allClassesTpPrepend: AllClasses): AllClasses {
    const prefixedClasses = Object.keys(allClassesTpPrepend)
        .reduce(
            (groupAccumulator, groupKey) => {
                const groupClasses = (allClassesTpPrepend as TObjectWithProps)[groupKey];

                // eslint-disable-next-line no-param-reassign
                groupAccumulator[groupKey] = Object.keys(groupClasses)
                    .reduce(
                        (classAccumulator, classKey) => {
                            const className = groupClasses[classKey];

                            // eslint-disable-next-line no-param-reassign
                            classAccumulator[classKey] = `${CLASS_PREFIX}${className}`;

                            return classAccumulator;
                        },
                        {} as TObjectWithProps,
                    );

                return groupAccumulator;
            },
            {} as TObjectWithProps,
        );

    return prefixedClasses as AllClasses;
}
