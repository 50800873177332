import React from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { SortOrder } from '@console/common/models/sort.models';
import { formatAmount } from '@console/common/utils/number/amountUtils';
import { anyComparerAscending, stringComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { BaseApiErrorCode } from '@console/api-base/server/error/apiBaseError.models';
import { IUserEntityData } from '@console/core-api/models/userMgmt/user.models';
import {
    IInstrumentRecommendationWithUnits,
    InstrumentRecommendationType,
    IPortfolioOptimizationComparison,
    TInstrumentRecommendation,
} from '@console/bff/models/portfolios/portfolioOptimizationComparison.models';
import { EnhancedOptimizationStatus } from '@console/bff/models/enhancedOptimization.models';
import { calculateInstrumentUnits } from '@console/bff/utils/portfolios/enhancedPortfolioHoldingsUtils';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { INavigateToRoute } from 'models/routing.models';
import { IPossiblePathParams } from 'models/ui/routeParams.ui.models';
import {
    IColValues,
    IDataItem,
    IRenderPreHeaderProps,
    IToDynamicCellClassProps,
    TDataColumns,
} from 'models/list.models';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { portfolioOptimizationComparisonEntity } from 'state/entities/portfolioMgmt/portfolioOptimizationComparison';
import { portfolioOptimizationEntity } from 'state/entities/portfolioMgmt/portfolioOptimization';
import { extractEmbeddedEntity } from 'utils/entities/entityEmbedUtils';
import { getUserFullName } from 'utils/entities/userMgmt/userUtils';
import { formatPercentage } from 'utils/number/percentageUtils';
import {
    isPortfolioOptimizationOwnerChoiceApplicable,
} from 'utils/entities/portfolioMgmt/portfolioOptimizationUtils';
import { exportListAsCsv } from 'utils/file/exportListAsCsv';
import { ROUTE_KEY } from 'views/routeKeys';
import { makeStyles, mixins, UtilityClass } from 'views/styling';
import GoBack from 'views/common/routing/GoBack';
import Text from 'views/common/widget/Text';
import {
    initEntityWrapper,
    IRenderDataProps,
    ITCustomizeEntityOperationErrorInput,
    TCustomizeEntityOperationErrorOutcome,
} from 'views/common/widget/EntityWrapper';
import { initPageTitleBasedOnState } from 'views/common/layout/PageTitleBasedOnState';
import DataTable from 'views/common/list/DataTable';
import Tag, { ITagProps, TTagVariant } from 'views/common/widget/Tag';
import LabelWithValue from 'views/common/layout/LabelWithValue';
import AmountDisplay from 'views/common/widget/AmountDisplay';
import TextButton from 'views/common/buttons/TextButton';
import { TABLE_CELL_BORDER } from 'views/assets/cssInJs/utilityClasses/table';
import OptimizationNrOfOrders
    from 'views/portfolioMgmt/Portfolios/PortfolioDetail/PortfolioOptimizations/OptimizationNrOfOrders';
import OptimizationOwnerChoiceTag
    from 'views/portfolioMgmt/Portfolios/PortfolioDetail/PortfolioOptimizations/OptimizationOwnerChoiceTag';
import EntityFiche from 'views/common/genericApiEntity/EntityFiche';

const PF_OPTIM_COMP_TRANSLATIONS_PREFIX = 'portfolio_mgmt.portfolios.detail.optimization_comparison';
const COL_LABEL_PREFIX = `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.columns`;
const ROW_LABEL_PREFIX = `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.rows`;
const INSTR_RECOMM_PREFIX = `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.instrument_recommendation`;

const DATA_ITEM_ID = {
    CASH: 'CASH',
    INSTRUMENTS: 'INSTRUMENTS',
};
const NON_INSTRUMENT_DATA_ITEM_IDS = Object.values(DATA_ITEM_ID);
const NR_OF_DECIMALS = {
    UNITS: 4,
    AMOUNT: 2,
};

const DEFAULT_CLIENT_SIDE_FILTER = {
    alwaysOnTopFilter: isNonInstrumentDataItem,
};

interface IOptimizationComparisonCols extends IColValues {
    label: string;
    currentUnits: number;
    currentAmount: number;
    currentPct: number;
    recommendation: TInstrumentRecommendation;
    optimalUnits: number;
    optimalAmount: number;
    optimalPct: number;
}

function getCols({
    currency,
    isLatestOptimization,
}: {
    currency: string;
    isLatestOptimization: boolean;
}): TDataColumns<IOptimizationComparisonCols> {
    return {
        label: {
            label: {
                msg: `${COL_LABEL_PREFIX}.label`,
                className: UtilityClass.table.cellRightBorder,
            },
            data: {
                render: ({ cellValue, item, translator }) => {
                    if (isNonInstrumentDataItem(item)) {
                        return translator(cellValue as string);
                    }
                    return (
                        <EntityFiche
                            id={item.id}
                            name={cellValue as string}
                            variant="instrument"
                        />
                    );
                },
            },
            percentWidth: 30,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: DEFAULT_CLIENT_SIDE_FILTER,
            },
        },
        currentUnits: {
            label: {
                msg: `${COL_LABEL_PREFIX}.current_units`,
            },
            data: {
                render: ({ cellValue }) => (
                    <AmountDisplay
                        value={cellValue as number}
                        nrOfDecimals={NR_OF_DECIMALS.UNITS}
                    />
                ),
            },
            align: 'right',
            percentWidth: 10,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: DEFAULT_CLIENT_SIDE_FILTER,
            },
        },
        currentAmount: {
            label: {
                msg: `${COL_LABEL_PREFIX}.current_amount`,
                placeholders: {
                    currency,
                },
            },
            data: {
                render: ({ cellValue, item }) => (
                    <AmountDisplay
                        value={cellValue as number}
                        nrOfDecimals={NR_OF_DECIMALS.AMOUNT}
                        disableValue={(item.id !== DATA_ITEM_ID.CASH) && !isLatestOptimization}
                    />
                ),
            },
            align: 'right',
            percentWidth: 10,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: DEFAULT_CLIENT_SIDE_FILTER,
            },
        },
        currentPct: {
            label: {
                msg: `${COL_LABEL_PREFIX}.current_pct`,
            },
            data: {
                render: ({ cellValue }) => formatComparisonPct({
                    pct: cellValue as number,
                    disableValue: !isLatestOptimization,
                }),
            },
            align: 'right',
            percentWidth: 10,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: DEFAULT_CLIENT_SIDE_FILTER,
            },
        },
        recommendation: {
            label: {
                msg: `${COL_LABEL_PREFIX}.recommendation`,
                className: clsx(UtilityClass.table.cellLeftBorder, UtilityClass.table.cellRightBorder),
            },
            data: {
                render: ({ cellValue }) => (
                    <InstrumentRecommendationTag {...(cellValue as TInstrumentRecommendation)} />
                ),
                className: '__instrumentRecommendationTagWrapper',
            },
            align: 'right',
            percentWidth: 10,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: {
                    ...DEFAULT_CLIENT_SIDE_FILTER,
                    customSort: (itemA, itemB) => {
                        if (!itemA.colValues.recommendation || !itemB.colValues.recommendation) {
                            return 0;
                        }

                        const typeComparerResult = stringComparerAscending(
                            itemA.colValues.recommendation.type,
                            itemB.colValues.recommendation.type,
                        );

                        if (typeComparerResult !== 0) {
                            return typeComparerResult;
                        }

                        return anyComparerAscending(
                            (itemA.colValues.recommendation as IInstrumentRecommendationWithUnits).units || 0,
                            (itemB.colValues.recommendation as IInstrumentRecommendationWithUnits).units || 0,
                        );
                    },
                },
            },
        },
        optimalUnits: {
            label: {
                msg: `${COL_LABEL_PREFIX}.optimal_units`,
            },
            data: {
                render: ({ cellValue }) => (
                    <AmountDisplay
                        value={cellValue as number}
                        nrOfDecimals={NR_OF_DECIMALS.UNITS}
                    />
                ),
            },
            align: 'right',
            percentWidth: 10,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: DEFAULT_CLIENT_SIDE_FILTER,
            },
        },
        optimalAmount: {
            label: {
                msg: `${COL_LABEL_PREFIX}.optimal_amount`,
                placeholders: {
                    currency,
                },
                tooltip: {
                    name: 'pf-optimization-comparison-optimalAmount-tooltip',
                    simpleContent: {
                        info: `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.tooltip.optimal_amount`,
                    },
                },
            },
            data: {
                render: ({ cellValue, item }) => (
                    <AmountDisplay
                        value={cellValue as number}
                        nrOfDecimals={NR_OF_DECIMALS.AMOUNT}
                        disableValue={(item.id !== DATA_ITEM_ID.CASH) && !isLatestOptimization}
                    />
                ),
            },
            align: 'right',
            percentWidth: 10,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: DEFAULT_CLIENT_SIDE_FILTER,
            },
        },
        optimalPct: {
            label: {
                msg: `${COL_LABEL_PREFIX}.optimal_pct`,
            },
            data: {
                render: ({ cellValue }) => formatComparisonPct({
                    pct: cellValue as number,
                    disableValue: !isLatestOptimization,
                }),
            },
            align: 'right',
            percentWidth: 10,
            sort: {
                initialOrder: SortOrder.Ascending,
                clientSide: DEFAULT_CLIENT_SIDE_FILTER,
            },
        },
    };
}

const useStyles = makeStyles((theme) => ({
    PortfolioOptimizationComparisonWrapper: {
        ...mixins.flexColTopLeft(),
        marginTop: theme.spacing(4),
        position: 'relative', /* the __optimizationDate will be positioned absolutely to this */

        '& .__title': {},
        '& .__goBack': {
            paddingBottom: theme.spacing(3),
        },
        '& .__contentWrapper': {
            ...mixins.widthMax(),
        },
        '& .__content': {
            ...mixins.flexColTopLeft(),
            ...mixins.widthMax(),
            paddingTop: theme.spacing(2),

            '& .__optimizationDate': {
                ...mixins.typo({ weight: 'bold', size: 14 }),
                position: 'absolute',
                top: 0,
                right: 0,
                whiteSpace: 'nowrap',
            },
        },
        '& .__extraInfoRow': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            ...mixins.widthMax(),
            ...mixins.typo({ size: 15 }),
            height: theme.spacing(8),
            borderBottom: TABLE_CELL_BORDER,

            '& .__extraInfoEmphasis': {
                ...mixins.typo({ size: 18 }),
            },
            '& .__nrOfOrdersAndCosts': {
                ...mixins.flexRowCenterLeft(),
                ...mixins.typoBold(),

                '& > div:first-child': {
                    marginRight: theme.spacing(3),
                },
            },
        },
        '& .__instrumentRecommendationTagWrapper .Tag': {
            position: 'relative',
            float: 'right',
        },
        '& .__comparisonTable': {
            margin: 'auto', /* to center the table */
        },
        '& .__comparisonTable .__preHeaderCell': {
            ...mixins.typo({ size: 14 }),
            textTransform: 'none',
            height: theme.spacing(8),
        },
    },
}));

export default function PortfolioOptimizationComparisonWrapper() {
    const classes = useStyles();

    const { portfolioId } = useParams<IPossiblePathParams>();

    const defaultGoBackRoute: INavigateToRoute = {
        routeKey: ROUTE_KEY.R_PORTFOLIO_DETAIL,
        params: {
            portfolioId,
        },
    };

    return (
        <div className={classes.PortfolioOptimizationComparisonWrapper}>
            <GoBack
                id="pf-optimization-comparison-go-back"
                targetRouteWhenNoPrevious={defaultGoBackRoute}
                className="__goBack"
            />

            <PortfolioOptimizationComparisonTitle />

            <PortfolioOptimizationComparison />
        </div>
    );
}

function PortfolioOptimizationComparisonTitle() {
    const PortfolioOptimizationComparisonTitleBasedOnState = initPageTitleBasedOnState({
        notifications: [
            StateChangeNotification.PORTFOLIO_DETAILS_DATA,
            StateChangeNotification.PORTFOLIO_OPTIMIZATION_COMPARISON,
        ],
    });

    return (
        <PortfolioOptimizationComparisonTitleBasedOnState
            className="__title"
            labelSelector={selectTitleLabel}
            tagSelector={selectRecommendedTag}
        />
    );

    function selectTitleLabel() {
        if (!portfolioDetailsEntity.select().data) {
            return `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.title_without_name`;
        }

        const { name, owned_by_user_id, _embedded } = portfolioDetailsEntity.select().data;

        const titlePlaceholderParts = [name];

        const owner = extractEmbeddedEntity<IUserEntityData>({
            data: _embedded,
            id: owned_by_user_id,
        });

        if (owner) {
            titlePlaceholderParts.push(
                getUserFullName(owner),
            );
        }

        return {
            msg: `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.title`,
            placeholders: {
                portfolioNameAndOwner: titlePlaceholderParts.join(' - '),
            },
        };
    }

    function selectRecommendedTag(): ITagProps {
        if (portfolioOptimizationComparisonEntity.select().data) {
            const { status } = portfolioOptimizationComparisonEntity.select().data;

            if (status === EnhancedOptimizationStatus.RECOMMENDED) {
                return {
                    label: `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.tag.recommended`,
                    variant: 'regular',
                };
            }

            if (status === EnhancedOptimizationStatus.NOT_RECOMMENDED) {
                return {
                    label: `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.tag.not_recommended`,
                    variant: 'orange',
                };
            }
        }

        return null;
    }
}

function PortfolioOptimizationComparison() {
    const PortfolioOptimizationComparisonEntityWrapper = initEntityWrapper({
        notifications: [
            StateChangeNotification.PORTFOLIO_OPTIMIZATION_COMPARISON,
            StateChangeNotification.PORTFOLIO_DETAILS_DATA,
            /* also the 'latest' PF optimization so that we can compare and know if it is the latest or not */
            StateChangeNotification.PORTFOLIO_OPTIMIZATION,
        ],
    });

    return (
        <PortfolioOptimizationComparisonEntityWrapper
            className="__contentWrapper"
            asyncEntitySelector={portfolioOptimizationComparisonEntity.select}
            renderData={renderPortfolioOptimizationComparison}
            fetch={{
                showErrorInline: true,
                customizeError: customizeFetchOptimizationComparisonError,
            }}
        />
    );

    function renderPortfolioOptimizationComparison({
        data: portfolioOptimizationComparison,
    }: IRenderDataProps<IPortfolioOptimizationComparison>) {
        const { currency } = portfolioOptimizationComparison;

        const shouldShowOwnerChoice = portfolioOptimizationComparison && isPortfolioOptimizationOwnerChoiceApplicable({
            portfolioManagerType: portfolioDetailsEntity.select()?.data?.config?.manager,
        });

        const isLatestOptimization = getLatestOptimizationId() === portfolioOptimizationComparison.optimizationId;

        return (
            <div className="__content">
                <div className="__optimizationDate">
                    <Text
                        label={{
                            msg: `${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.extra_info.creation_date`,
                            placeholders: {
                                date: formatDateRelativeToNow({
                                    date: portfolioOptimizationComparison.creationDatetime,
                                }),
                            },
                        }}
                    />
                </div>

                <div className="__extraInfoRow">
                    {shouldShowOwnerChoice && (
                        <LabelWithValue
                            label={`${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.extra_info.owner_choice`}
                            value={(
                                <OptimizationOwnerChoiceTag
                                    ownerChoice={portfolioOptimizationComparison.ownerChoice}
                                />
                            )}
                            variant="row"
                            labelInBold
                        />
                    )}
                    {!shouldShowOwnerChoice && <div>&nbsp;</div>}

                    <LabelWithValue
                        className="__extraInfoEmphasis"
                        label={`${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.extra_info.portfolio_value`}
                        value={formatAmount(portfolioOptimizationComparison.comparison.portfolioValue, { currency })}
                        variant="row"
                        labelInBold
                        valueInBold
                    />
                </div>

                <div className="__extraInfoRow">
                    <div className="__nrOfOrdersAndCosts">
                        <OptimizationNrOfOrders
                            status={portfolioOptimizationComparison.status}
                            nrOfBuys={portfolioOptimizationComparison.portfolioUpdate.nrOfBuys}
                            nrOfSells={portfolioOptimizationComparison.portfolioUpdate.nrOfSells}
                        />
                        <LabelWithValue
                            label={`${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.extra_info.expected_transaction_costs`}
                            value={formatAmount(
                                portfolioOptimizationComparison.comparison.expectedTransactionCosts,
                                { currency },
                            )}
                            variant="row"
                            labelInBold
                            valueInBold
                        />
                    </div>

                    {isLatestOptimization && (
                        <TextButton
                            id="pf-optimization-comparison-export-orders"
                            label={`${PF_OPTIM_COMP_TRANSLATIONS_PREFIX}.extra_info.export_orders`}
                            size="S"
                            fontSize="M"
                            icon={<KeyboardArrowDown />}
                            iconPlacement="end"
                            onClick={exportOrdersToCsv}
                        />
                    )}
                </div>

                <ComparisonTable
                    portfolioOptimizationComparison={portfolioOptimizationComparison}
                    isLatestOptimization={isLatestOptimization}
                />
            </div>
        );

        function exportOrdersToCsv() {
            const orderList = Object.entries(portfolioOptimizationComparison.portfolioUpdate.orders)
                .map(([isin, optimizationInstrumentOrder]) => {
                    const matchingInstrumentComparison = portfolioOptimizationComparison.comparison.instruments
                        .find((instrumentComparison) => instrumentComparison.isin === isin);
                    const instrumentUnits = calculateInstrumentUnits({
                        quantityType: optimizationInstrumentOrder.quantity_type,
                        quantity: isSet(optimizationInstrumentOrder.quantity)
                            ? optimizationInstrumentOrder.quantity
                            : optimizationInstrumentOrder.shares,
                        pricePer: optimizationInstrumentOrder.expected_share_price,
                    });

                    return {
                        ISIN: isin,
                        name: matchingInstrumentComparison?.name || '-',
                        'buy quantity': instrumentUnits >= 0
                            ? formatFloatForCsv(instrumentUnits)
                            : 0,
                        'sell quantity': instrumentUnits < 0
                            ? formatFloatForCsv(instrumentUnits * -1)
                            : 0,
                        'expected share price':
                            formatFloatForCsv(optimizationInstrumentOrder.expected_share_price),
                        'expected transaction cost':
                            formatFloatForCsv(optimizationInstrumentOrder.expected_transaction_cost),
                    };
                });

            exportListAsCsv({
                baseFilename: `orders_pf-${portfolioOptimizationComparison.portfolioId}`,
                list: orderList,
            });
        }
    }

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function ComparisonTable({
        portfolioOptimizationComparison,
        isLatestOptimization,
    }: {
        portfolioOptimizationComparison: IPortfolioOptimizationComparison;
        isLatestOptimization: boolean;
    }) {
        const comparisonItems: IDataItem<IOptimizationComparisonCols>[] = [{
            id: DATA_ITEM_ID.CASH,
            colValues: {
                label: `${ROW_LABEL_PREFIX}.cash`,
                currentUnits: null,
                currentAmount: portfolioOptimizationComparison.comparison.cash.current.amount,
                currentPct: portfolioOptimizationComparison.comparison.cash.current.pct,
                recommendation: null,
                optimalUnits: null,
                optimalAmount: portfolioOptimizationComparison.comparison.cash.optimal.amount,
                optimalPct: portfolioOptimizationComparison.comparison.cash.optimal.pct,
            },
        }, {
            id: DATA_ITEM_ID.INSTRUMENTS,
            colValues: {
                label: `${ROW_LABEL_PREFIX}.instruments`,
                currentUnits: null,
                currentAmount: portfolioOptimizationComparison.comparison.instrumentsTotalValue.current,
                currentPct: portfolioOptimizationComparison.comparison.instrumentsPercentageWithinHoldings.current,
                recommendation: null,
                optimalUnits: null,
                optimalAmount: portfolioOptimizationComparison.comparison.instrumentsTotalValue.optimal,
                optimalPct: portfolioOptimizationComparison.comparison.instrumentsPercentageWithinHoldings.optimal,
            },
        }].concat(
            portfolioOptimizationComparison.comparison.instruments.map((instrumentComparison) => ({
                id: instrumentComparison.isin,
                colValues: {
                    label: instrumentComparison.name,
                    currentUnits: instrumentComparison.current.units,
                    currentAmount: instrumentComparison.current.amount,
                    currentPct: instrumentComparison.current.pct,
                    recommendation: instrumentComparison.recommendation,
                    optimalUnits: instrumentComparison.optimal.units,
                    optimalAmount: instrumentComparison.optimal.amount,
                    optimalPct: instrumentComparison.optimal.pct,
                },
            })),
        );

        return (
            <DataTable
                className="__comparisonTable"
                cols={getCols({ currency: portfolioOptimizationComparison.currency, isLatestOptimization })}
                items={comparisonItems}
                renderPreHeader={renderCurrentAndOptimalPreHeader}
                toDynamicCellClass={styleComparisonTableCells}
            />
        );
    }

    function renderCurrentAndOptimalPreHeader({ RowComponent, CellComponent }: IRenderPreHeaderProps) {
        return (
            <RowComponent>
                <CellComponent
                    className={clsx(
                        UtilityClass.table.cellRightBorder,
                        UtilityClass.table.cellTopAndBottomBorder,
                        '__preHeaderCell',
                    )}
                />
                <CellComponent
                    colSpan={3}
                    className={clsx(UtilityClass.table.cellTopAndBottomBorder, '__preHeaderCell')}
                >
                    <Text label={`${COL_LABEL_PREFIX}.pre_header.before`} />
                </CellComponent>
                <CellComponent
                    className={clsx(UtilityClass.table.cellBorder, '__preHeaderCell')}
                >
                    <Text label={`${COL_LABEL_PREFIX}.pre_header.recommendation`} />
                </CellComponent>
                <CellComponent
                    colSpan={3}
                    className={clsx(UtilityClass.table.cellTopAndBottomBorder, '__preHeaderCell')}
                >
                    <Text label={`${COL_LABEL_PREFIX}.pre_header.after`} />
                </CellComponent>
            </RowComponent>
        );
    }

    function styleComparisonTableCells({
        colKey,
        dataItem,
    }: IToDynamicCellClassProps<IOptimizationComparisonCols>) {
        const classes: string[] = [];

        if (isNonInstrumentDataItem(dataItem)) {
            classes.push(UtilityClass.table.cellBold);
        }

        if (colKey === 'label') {
            classes.push(UtilityClass.table.cellRightBorder);
        } else if (colKey === 'recommendation') {
            classes.push(UtilityClass.table.cellLeftBorder);
            classes.push(UtilityClass.table.cellRightBorder);
        }

        return classes.length > 0
            ? classes
            : null;
    }

    function customizeFetchOptimizationComparisonError({
        error,
    }: ITCustomizeEntityOperationErrorInput): TCustomizeEntityOperationErrorOutcome {
        if (error.response?.code === BaseApiErrorCode.NO_OPTIMIZATION_ORDERS_AVAILABLE) {
            return (
                <Text label="error.code.no_optimization_orders_available" />
            );
        }

        return false;
    }
}

function formatComparisonUnits(units: number) {
    if (!isSet(units)) {
        return null;
    }

    return formatFloat(units, {
        nrOfDecimals: 4,
        stripTrailingDecimalZeros: false,
    });
}

function formatComparisonPct({
    pct,
    disableValue = false,
}: {
    pct: number;
    disableValue?: boolean;
}) {
    if (disableValue) {
        return '-';
    }

    return formatPercentage(pct * 100, {
        nrOfDecimals: 2,
        stripTrailingDecimalZeros: false,
    });
}

function InstrumentRecommendationTag(instrumentRecommendation: TInstrumentRecommendation) {
    if (!instrumentRecommendation) {
        return null;
    }

    const { type } = instrumentRecommendation;
    if (!type) {
        return null;
    }

    const units = type === InstrumentRecommendationType.HOLD
        ? 0
        : formatComparisonUnits(instrumentRecommendation.units);
    const variant: TTagVariant = type === InstrumentRecommendationType.SELL
        ? 'red'
        : type === InstrumentRecommendationType.BUY
            ? 'green'
            : 'blue';

    return (
        <Tag
            label={{
                msg: `${INSTR_RECOMM_PREFIX}.${type.toLowerCase()}`,
                placeholders: {
                    units,
                },
            }}
            variant={variant}
        />
    );
}

function isNonInstrumentDataItem(item: IDataItem<IOptimizationComparisonCols>) {
    return NON_INSTRUMENT_DATA_ITEM_IDS.includes(item.id);
}

function formatFloatForCsv(float: number, stripTrailingDecimalZeros = false) {
    return formatFloat(float, {
        nrOfDecimals: 4,
        stripTrailingDecimalZeros,
    });
}

/**
 * The fetch of the latest portfolio optimization is triggered (or should already be present)
 * when opening this screen.
 */
function getLatestOptimizationId() {
    return portfolioOptimizationEntity.select()?.data?.optimizationId;
}
