import React, { useState } from 'react';
import clsx from 'clsx';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import isSet from '@snipsonian/core/cjs/is/isSet';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { TI18nLabelOrString } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { toI18nLabel } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';
import IconButton, { IIconButtonProps } from 'views/common/buttons/IconButton';
import TextButton from 'views/common/buttons/TextButton';
import Modal, { TModalMaxWidth } from 'views/common/layout/Modal';
import ExtendedInputForm, {
    IExtendedInputFormProps,
    IOnSubmitProps,
} from 'views/common/inputs/extended/ExtendedInputForm';

export interface IManageManyViaModalProps<Item extends TObjectWithProps> {
    name: string; // name of the 'manage-many', will e.g. be used to make unique id's
    className?: string;
    readOnly?: boolean; // default false
    items: Item[];
    renderItem: (renderItemProps: IRenderSingleItemProps<Item>) => React.ReactNode;
    remove?: {
        onRemoveItem: (onRemoveItemProps: IOnRemoveSingleItemProps<Item>) => void;
    } & Pick<IIconButtonProps, 'tooltip' | 'tooltipPlacement'>;
    error?: TI18nLabelOrString; // for 'list-level' errors
    emphasizeError?: boolean; // default true
    add: {
        openModalActionLabel?: TI18nLabelOrString;
        modalTitle: TI18nLabelOrString;
        modalMaxWidth?: TModalMaxWidth;
    } & Omit<IExtendedInputFormProps<Item>, 'name' | 'cancel' | 'readOnly'>;
}

export interface IRenderSingleItemProps<Item extends TObjectWithProps> {
    index: number;
    item: Item;
}

export interface IOnRemoveSingleItemProps<Item extends TObjectWithProps> {
    index: number;
    item: Item;
}

const useStyles = makeStyles((theme) => ({
    ManageManyViaModal: {
        ...mixins.flexColTopLeft(),

        '& .__list-error': {
            ...mixins.typo({ size: 14 }),
            padding: theme.spacing(1, 0),
        },
        '& .__list-error.__emphasized-error': {
            ...mixins.typo({ size: 14, color: APP_COLORS.FEEDBACK.ERROR }),
        },

        '& .__current-items': {
            ...mixins.flexColTopLeft(),
            ...mixins.widthMax(),
            minHeight: theme.spacing(1), // for when no current items yet
        },
        '& .__current-item': {
            ...mixins.flexRow({ alignMain: 'space-between', alignCross: 'center' }),
            ...mixins.widthMax(),
            paddingBottom: theme.spacing(1),
        },
        '& .__current-item:not(:last-child)': {
            ...mixins.borderBottomGrey(),
        },
        '& .removeItemIcon': {
            ...mixins.widthHeightPixelsSame(40),
            paddingRight: 0,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(4),
        },

        '& .__actions': {
            ...mixins.flexRowCenterRight(),
        },
        '& .readOnly': {
            paddingBottom: theme.spacing(7) + 1,
            paddingTop: theme.spacing(1) + 2,
        },
        '& .noRemove': {
            minHeight: 50,
        },
    },
}));

export default function ManageManyViaModal<Item extends TObjectWithProps>({
    name,
    className,
    readOnly = false,
    items = [],
    renderItem,
    remove,
    error,
    emphasizeError = true,
    add,
}: IManageManyViaModalProps<Item>) {
    const classes = useStyles();
    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

    return (
        <div className={clsx(classes.ManageManyViaModal, className)}>
            {error && (
                <div className={clsx('__list-error', emphasizeError && '__emphasized-error')}>
                    <Translate {...toI18nLabel(error)} />
                </div>
            )}

            <div className="__current-items">
                {items.map((item, index) => {
                    const key = `${name}_manage-many-item_${index}`;

                    return (
                        <div
                            key={key}
                            className={clsx(
                                '__current-item',
                                readOnly && 'readOnly',
                                !remove && 'noRemove',
                            )}
                        >
                            {renderItem({ item, index })}

                            {!readOnly && remove && isSet(items) && (
                                <div className="removeItemIcon">
                                    <IconButton
                                        id={`${key}_close`}
                                        variant="bare"
                                        color="grey"
                                        size="M"
                                        isCircle={false}
                                        onClick={onRemoveItemClicked}
                                        icon={<CloseOutlinedIcon />}
                                        tooltip={remove.tooltip || 'common.action.remove'}
                                        tooltipPlacement={remove.tooltipPlacement || 'top-end'}
                                    />
                                </div>
                            )}
                        </div>
                    );

                    function onRemoveItemClicked() {
                        remove.onRemoveItem({ item, index });
                    }
                })}
            </div>

            {!readOnly && (
                <div className="__actions">
                    <TextButton
                        id={`${name}_open-add-modal`}
                        label={add.openModalActionLabel || 'common.action.add'}
                        onClick={openAddModal}
                        size="M"
                        fontSize="L"
                    />
                </div>
            )}

            <Modal
                id={`${name}_add-modal`}
                title={add.modalTitle}
                emphasizeTitle
                open={isAddModalOpen}
                onClose={closeAddModal}
                maxWidth={add.modalMaxWidth}
            >
                <ExtendedInputForm<Item>
                    name={`${name}_add-modal-form`}
                    readOnly={readOnly}
                    {...add}
                    submit={{
                        onSubmit: onSubmitAddModal,
                        actionLabel: 'common.action.add',
                    }}
                    cancel={{
                        onCancel: closeAddModal,
                    }}
                    checkUnsavedChangesOnRouteChange={false}
                />
            </Modal>
        </div>
    );

    function openAddModal() {
        setIsAddModalOpen(true);
    }

    function closeAddModal() {
        setIsAddModalOpen(false);
    }

    function onSubmitAddModal(onSubmitProps: IOnSubmitProps<Item>) {
        closeAddModal();

        add.submit.onSubmit(onSubmitProps);

        return Promise.resolve();
    }
}
