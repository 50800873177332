import React, { useState, CSSProperties } from 'react';
import clsx from 'clsx';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import {
    IColValues,
    TColumnAlignment,
    TDataColumns,
    IDataColumn,
    IDataItem,
    TDataItemValue,
    IToDynamicCellClassProps,
    IBaseTableRowProps, IBaseTableCellProps, IDataItemSubRow,
} from 'models/list.models';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import CustomI18nContext from 'views/appShell/providers/CustomI18nContext';
import IconButtonCollapse from '../buttons/IconButtonCollapse';
import ActionButtons, { IActionItem } from '../buttons/ActionButtons';

export interface IDataTableRowProps<
    ColValues extends IColValues,
    ExtraData extends TObjectWithProps,
    AsyncItemData = unknown,
> {
    className: string;
    columnKeys: string[];
    cols: TDataColumns<ColValues, ExtraData, AsyncItemData>;
    dataItem: IDataItem<ColValues, ExtraData>;
    prevDataItem: null | IDataItem<ColValues, ExtraData>;
    itemIndex: number;
    nrOfCols: number;
    subRow?: IDataItemSubRow<ColValues, ExtraData, AsyncItemData>;
    getActions?: (dataItem: IDataItem<ColValues, ExtraData>) => IActionItem[];
    onCellClick: () => void;
    getColumnAlignment: (column: IDataColumn<ColValues, ExtraData, AsyncItemData>) => TColumnAlignment;
    shouldFetchAsyncItem?: (dataItem: IDataItem<ColValues, ExtraData>) => boolean; // default true
    asyncItemFetcher?: (dataItem: IDataItem<ColValues, ExtraData>) => Promise<AsyncItemData>;
    toDynamicCellClass?: (props: IToDynamicCellClassProps<ColValues, ExtraData>) => string[];
}

export default function DataTableRow<
    ColValues extends IColValues,
    ExtraData extends TObjectWithProps,
    AsyncItemData = unknown,
>({
    className,
    columnKeys,
    cols,
    dataItem,
    prevDataItem,
    itemIndex,
    onCellClick,
    getColumnAlignment,
    shouldFetchAsyncItem,
    asyncItemFetcher,
    toDynamicCellClass,
    subRow,
    nrOfCols,
    getActions,
}: IDataTableRowProps<ColValues, ExtraData, AsyncItemData>) {
    const [asyncItem] = useAsyncFetchOnMount<AsyncItemData>({
        shouldFetch: () => asyncItemFetcher
            && (!shouldFetchAsyncItem || shouldFetchAsyncItem(dataItem)),
        fetcher: () => asyncItemFetcher(dataItem),
    });

    const [isSubRowCollapsed, setIsSubRowCollapsed] = useState(getInitialIsSubRowCollapsed);

    const shouldAddCollapsibleIcon = subRow?.isCollapsible
        && subRow?.isRowCollapsible && subRow?.isRowCollapsible(dataItem);

    return (
        <CustomI18nContext.Consumer>
            {({ translator }) => (
                <>
                    <BaseTableRow
                        className={className}
                    >
                        {columnKeys.map((columnKey, index) => {
                            const cellValue = dataItem.colValues[columnKey];
                            const column = cols[columnKey];
                            const isLastColumn = (columnKeys.length - 1 === index);

                            return (
                                <BaseTableCell
                                    key={`data-table-cell_${dataItem.id}_${columnKey}`}
                                    align={getColumnAlignment(column)}
                                    className={clsx(
                                        column.data && column.data.className,
                                        toDynamicCellClass && toDynamicCellClass({
                                            colKey: columnKey,
                                            dataItem,
                                            prevDataItem,
                                        }),
                                    )}
                                    sx={getTableCellStyle(column)}
                                    onClick={onCellClick}
                                >
                                    {renderItemDataCell({
                                        cellValue,
                                        column,
                                        translator,
                                    })}

                                    {shouldAddCollapsibleIcon
                                        && !subRow?.shouldAddExtraColumnForCollapsibleIcon && isLastColumn && (
                                        <IconButtonCollapse
                                            id={`data-table-collapse_${dataItem.id}`}
                                            isCollapsed={isSubRowCollapsed}
                                            onCollapseChange={setIsSubRowCollapsed}
                                            className="__inlineCollapseButton"
                                        />
                                    )}
                                </BaseTableCell>
                            );
                        })}
                        {getActions && (
                            <BaseTableCell>
                                <ActionButtons
                                    actions={getActions(dataItem)}
                                />
                            </BaseTableCell>
                        )}
                        {subRow?.isCollapsible && subRow?.shouldAddExtraColumnForCollapsibleIcon && (
                            shouldAddCollapsibleIcon
                                ? (
                                    <BaseTableCell>
                                        <IconButtonCollapse
                                            id={`data-table-collapse_${dataItem.id}`}
                                            isCollapsed={isSubRowCollapsed}
                                            onCollapseChange={setIsSubRowCollapsed}
                                        />
                                    </BaseTableCell>
                                )
                                : <BaseTableCell />
                        )}
                    </BaseTableRow>
                    {subRow?.render({
                        asyncItem,
                        itemIndex,
                        dataItem,
                        CellComponent: BaseTableCell,
                        RowComponent: BaseTableSubRow,
                        nrOfCols,
                        isCollapsed: isSubRowCollapsed,
                    })}
                </>
            )}
        </CustomI18nContext.Consumer>
    );

    function getInitialIsSubRowCollapsed(): boolean {
        if (subRow) {
            const {
                isCollapsible = false,
                isInitiallyCollapsed = true,
            } = subRow;

            return isCollapsible && isInitiallyCollapsed;
        }

        return false;
    }

    function renderItemDataCell({ cellValue, column, translator }: {
        cellValue: TDataItemValue;
        column: IDataColumn<ColValues, ExtraData, AsyncItemData>;
        translator: TTranslator;
    }): React.ReactNode {
        if (column.data && column.data.render) {
            return column.data.render({
                cellValue,
                item: dataItem,
                prevItem: prevDataItem,
                index: itemIndex,
                asyncItem,
                translator,
            });
        }

        return cellValue as React.ReactNode;
    }

    function getTableCellStyle(column: IDataColumn<ColValues, ExtraData, AsyncItemData>): CSSProperties {
        const style: CSSProperties = {
            width: `${column.percentWidth}%`,
            ...((column.data && column.data.style) || {}),
        };

        if (column.minPxWidth) {
            style.minWidth = `${column.minPxWidth}px`;
        }

        return style;
    }
}

export function BaseTableRow({
    className,
    children,
    size,
    ...otherTableRowProps
}: IBaseTableRowProps) {
    return (
        <TableRow
            className={clsx(
                '__tableRow',
                size === 'small' && 'small',
                className,
            )}
            {...otherTableRowProps}
        >
            {children}
        </TableRow>
    );
}

export function BaseTableSubRow({
    children,
    className,
    ...otherTableRowProps
}: IBaseTableRowProps) {
    return (
        <BaseTableRow className={clsx('__tableSubRow', className)} {...otherTableRowProps}>
            {children}
        </BaseTableRow>
    );
}

export function BaseTableCell({
    className,
    children,
    ...otherTableCellProps
}: IBaseTableCellProps) {
    return (
        <TableCell
            className={clsx(
                '__tableCell',
                className,
            )}
            {...otherTableCellProps}
        >
            {children}
        </TableCell>
    );
}
