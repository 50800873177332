import React from 'react';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { IOutputKeyWithId } from '@console/bff/models/storyteller/storymanager.models';
import { getStore } from 'state';
import { getStoryManagerScenarioStatus } from 'state/ui/storyManager.selector';
import {
    triggerAddScenario,
    triggerDeleteScenarios,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import { DuplicateIcon, TrashIcon } from 'views/common/icons';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import ActionButtons, { IActionItem } from 'views/common/buttons/ActionButtons';
import {
    conditionallyDisableStoryManagerActions,
} from 'views/apps/StoryTeller/StoryManager/storyManagerActions';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.side_nav';

interface IPublicProps {
    outputKey: IOutputKeyWithId;
    isScenarioEditorMode: boolean;
    canUserModifyScenarios: boolean;
    scenarioIndex: number;
    className: string;
    active: boolean;
}

export default function Scenario({
    outputKey,
    isScenarioEditorMode,
    canUserModifyScenarios,
    scenarioIndex,
    className,
    active,
}: IPublicProps) {
    const state = getStore().getState();
    const { databaseId, outputKeyId: routeOutputKeyId, scenarioIndex: routeScenarioIndex } = useRouteParams();
    const scenario = outputKey?.scenarios[scenarioIndex];
    if (!scenario) {
        return null;
    }

    return (
        <div
            className={clsx(className, {
                selected: (routeOutputKeyId === outputKey.id) && Number(routeScenarioIndex) === scenarioIndex,
                disable: active,
            })}
        >
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
                type="button"
                className="labelButton"
                onClick={selectScenario}
            >
                <StoryManagerStatus
                    className="status"
                    status={getStoryManagerScenarioStatus(state, scenario)}
                    small
                />
                <Typography className="label">
                    <Translate
                        msg="apps.story_teller.output_keys.side_nav.scenario.label"
                        placeholders={{ index: scenarioIndex + 1 }}
                    />
                </Typography>
            </button>
            {isScenarioEditorMode && (
                <ActionButtons
                    className="actions"
                    actions={getScenarioActions()}
                    moreVariant="bare"
                />
            )}
        </div>
    );

    function selectScenario() {
        redirectTo({
            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_SCENARIO_DETAIL,
            params: {
                databaseId,
                outputKeyId: outputKey.id,
                scenarioIndex,
            },
        });
    }

    function getScenarioActions(): IActionItem[] {
        return conditionallyDisableStoryManagerActions({
            canUserModify: canUserModifyScenarios,
            actions: [{
                id: 'side-nav-scenario-duplicate',
                label: `${TRANSLATION_PREFIX}.scenario.actions.duplicate`,
                onExecute: async () => triggerAddScenario(outputKey.id, scenario),
                icon: <DuplicateIcon />,
                variant: 'extra',
            }, {
                id: 'side-nav-scenario-delete',
                label: `${TRANSLATION_PREFIX}.scenario.actions.delete`,
                onExecute: async () => {
                    triggerDeleteScenarios(routeOutputKeyId, [scenarioIndex.toString()]);
                    if (routeOutputKeyId === outputKey.id && routeScenarioIndex === scenarioIndex.toString()) {
                        redirectTo({
                            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
                            params: {
                                databaseId,
                                outputKeyId: routeOutputKeyId,
                            },
                        });
                    }
                },
                icon: <TrashIcon />,
                variant: 'extra',
            }],
        });
    }
}
