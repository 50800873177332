import React from 'react';
import { DATE_FORMAT, formatDate } from '@console/common/utils/date/formatDate';
import { IPeriodFilter, PeriodType } from '@console/common/models/period.models';
import { reportInfoEntity } from 'state/entities/mgmtReporting/reportInfo';
import { makeStyles, mixins } from 'views/styling';
import InputPeriodField from 'views/common/inputs/base/InputPeriodField';
import InputWrapper from 'views/common/inputs/base/InputWrapper';
import ContentBox from 'views/common/layout/ContentBox';
import Text from 'views/common/widget/Text';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';

interface IMgmtReportingFilterBlockProps {
    currentPeriod: IPeriodFilter<string>;
    updatePeriodData: (currentPeriod: IPeriodFilter<string>) => void;
}

const LABEL_PREFIX_DETAIL = 'mgmt_reporting.detail';
const LABEL_PREFIX_FILTER = 'mgmt_reporting.filter';

const useStyles = makeStyles((theme) => ({
    MgmtReportingFilterBlock: {
        ...mixins.flexRowCenterLeft(),

        '& .inputPeriodFilter': {
            padding: theme.spacing(0, 3, 0, 3),
            width: 300,
        },

        '& .periodPrefix': {
            fontWeight: 'bold',
        },

        '& .__infoTooltip': {
            position: 'absolute',
            right: theme.spacing(3),
        },
    },
}));

export default function MgmtReportingFilterBlock({
    currentPeriod,
    updatePeriodData,
}: IMgmtReportingFilterBlockProps) {
    const classes = useStyles();

    const periodDatesLabel = currentPeriod.type === PeriodType.LAST_DAY
        ? `${LABEL_PREFIX_FILTER}.period_dates.single`
        : `${LABEL_PREFIX_FILTER}.period_dates.multiple`;

    const {
        availablePeriods: selectablePeriods,
        timezone,
    } = reportInfoEntity.select().data;

    const periodDates = {
        startDate: formatDate({
            date: currentPeriod.startDate,
            format: DATE_FORMAT.DAY_MONTH_YEAR_DOT,
            timezone,
        }),
        endDate: formatDate({
            date: currentPeriod.endDate,
            format: DATE_FORMAT.DAY_MONTH_YEAR_DOT,
            timezone,
        }),
    };

    return (
        <ContentBox>
            <div className={classes.MgmtReportingFilterBlock}>
                <div className="periodPrefix">
                    <Text
                        label={`${LABEL_PREFIX_FILTER}.period_prefix`}
                    />
                </div>
                <InputWrapper className="inputPeriodFilter">
                    <InputPeriodField<string>
                        currentPeriod={currentPeriod}
                        selectablePeriods={selectablePeriods}
                        onChange={updatePeriodData}
                    />
                </InputWrapper>
                <Text
                    label={{
                        msg: periodDatesLabel,
                        placeholders: periodDates,
                    }}
                />

                <InfoIconTooltip
                    className="__infoTooltip"
                    name="mgmt-reporting-tooltip_top"
                    simpleContent={{
                        info: {
                            msg: `${LABEL_PREFIX_DETAIL}.tooltip.info`,
                            placeholders: {
                                timezone,
                            },
                            raw: true,
                        },
                    }}
                    infoIconColor="primary"
                    arrowFromRightPercentage={11}
                />
            </div>
        </ContentBox>
    );
}
