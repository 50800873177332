import React from 'react';
import isSet from '@snipsonian/core/cjs/is/isSet';
import { anyComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import addItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/addItemAndGetResultingArray';
import removeItemAndGetResultingArray from '@snipsonian/core/cjs/array/manipulation/removeItemAndGetResultingArray';
import { PolicyAlgorithmType, TEnhancedPolicy } from '@console/bff/models/policies/enhancedPolicyDetails.models';
import { apiCacheManager } from 'api/cache/apiCacheManager';
import {
    findSpecificPolicy,
    triggerFetchPoliciesForSearch,
} from 'state/entities/portfolioMgmt/policies';
import useAsyncFetchOnMount from 'utils/react/hooks/useAsyncFetchOnMount';
import useExecuteOnMount from 'utils/react/hooks/useExecuteOnMount';
import { IInputSelectItem, IRenderReadOnlyProps } from 'views/common/inputs/base/InputSelectField';
import { IRenderSelectedItemProps } from 'views/common/inputs/base/SelectOneViaModal';
import { IExtendedInputFormContext, IOnSubmitProps } from 'views/common/inputs/extended/ExtendedInputForm';
import ExtendedInputSelect from 'views/common/inputs/extended/ExtendedInputSelect';
import ExtendedInputText from 'views/common/inputs/extended/ExtendedInputText';
import ExtendedSelectOneViaModal from 'views/common/inputs/extended/ExtendedSelectOneViaModal';
import { getDefaultTranslationFromApiLabel } from 'state/i18n/selectors';
import ExtendedInputWrapper from 'views/common/inputs/extended/ExtendedInputWrapper';
import InputCheckboxField, { IOnChangeCheckboxProps } from 'views/common/inputs/base/InputCheckboxField';
import { SupportedCurrency } from '@console/common/config/currencies.config';
import { PolicyManagerTag } from '@console/core-api/models/portfolioMgmt/policy.models';
import { IPolicySettingsRules } from 'models/ui/policySettings.ui.models';
import { SIZES } from 'config/styling/sizes';
import TextButton from 'views/common/buttons/TextButton';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import { DEFAULT_POLICY_TAB_KEY, DEFAULT_RISK_PROFILE_TAB_KEY } from 'config/tabs.config';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { getPolicyManagerTagLabel } from 'utils/entities/portfolioMgmt/policyUtils';
import { makeStyles, mixins } from 'views/styling';
import { APP_COLORS } from 'config/styling/colors';
import GenericPolicySelect, { IOnPolicySelectedProps } from '../../GenericPolicySelect';
import { getParentPolicySchema } from '../policyDetailsSchema';
import {
    IPolicySettingsFormValues,
    IParentPolicyFormValues,
    TAddPolicyFormValues,
} from './types';

const ALL_POLICY_MANAGER_TAG_VALUES = Object.values(PolicyManagerTag);

function renderPolicyNameFormContent({
    fields,
}: IExtendedInputFormContext<Pick<IPolicySettingsFormValues, 'name'>>) {
    return (
        <ExtendedInputText<string>
            formField={fields.name}
            wrapper={{
                label: 'fields.name.label',
                minHeight: SIZES.INPUT.HEIGHT.S,
            }}
        />
    );
}

function PolicyRiskProfileFormContent({
    fields,
}: IExtendedInputFormContext<Pick<IPolicySettingsFormValues, 'riskProfileId'>>) {
    const [asyncAllRiskProfiles] = useAsyncFetchOnMount({
        fetcher: apiCacheManager.fetchAllRiskProfiles,
    });

    return (
        <ExtendedInputSelect<string>
            formField={fields.riskProfileId}
            wrapper={{
                label: 'fields.risk_profile_id.label',
                tooltip: {
                    msg: 'fields.risk_profile_id.tooltip',
                },
                showTooltipUntilDismissed: true,
                minHeight: SIZES.INPUT.HEIGHT.S,
            }}
            items={[]}
            renderReadOnly={renderRiskProfileLink}
            async={{
                itemsData: asyncAllRiskProfiles,
                dataToSelectItemsMapper: mapRiskProfilesToSelectItems,
            }}
            noDataLabel={{
                msg: 'fields.risk_profile_id.no_data',
            }}
            addNoDataSelectItem="fields.risk_profile_id.no_data"
        />
    );

    function renderRiskProfileLink({ value: riskProfileId }: IRenderReadOnlyProps<string>) {
        const selectedRiskProfileName = getSelectedRiskProfileLabel();

        return (
            <TextButton
                id="linked_risk_profile_link_button"
                label={{
                    text: selectedRiskProfileName,
                    shouldTranslate: false,
                }}
                variant="bare"
                onClick={navigateToRiskProfileDetail}
                fontWeight="initial"
                size="MD"
            />
        );

        function navigateToRiskProfileDetail() {
            redirectTo({
                routeKey: ROUTE_KEY.R_RISK_PROFILE_DETAIL,
                params: {
                    riskProfileId,
                    riskProfileTab: DEFAULT_RISK_PROFILE_TAB_KEY,
                },
            });
        }

        function getSelectedRiskProfileLabel(): string {
            const selectedRiskProfile = asyncAllRiskProfiles.data.results.find(
                (riskProfile) => riskProfile.id === riskProfileId,
            );

            if (!selectedRiskProfile) {
                /* The current risk profile could not be found (probably because the cache is outdated) */
                return riskProfileId;
            }

            return getDefaultTranslationFromApiLabel(
                selectedRiskProfile.name,
            );
        }
    }

    function mapRiskProfilesToSelectItems(): IInputSelectItem<string>[] {
        return asyncAllRiskProfiles.data.results
            .sort((riskProfileA, riskProfileB) =>
                anyComparerAscending(riskProfileA.score_range[0], riskProfileB.score_range[0]))
            .map((riskProfile) => ({
                value: riskProfile.id,
                label: getDefaultTranslationFromApiLabel(riskProfile.name),
            }));
    }
}

const useStyles = makeStyles(() => ({
    NoParentPolicy: {
        ...mixins.typo({ color: APP_COLORS.GREY['400'] }),
    },
}));

export function ParentPolicyFormContent({
    fields,
    setFieldValue,
    readOnlyForm,
    policySettingsRules,
    labelPrefix,
}: IExtendedInputFormContext<TAddPolicyFormValues> & {
    policySettingsRules: IPolicySettingsRules;
}) {
    const classes = useStyles();
    const currentSelectedParentPolicy = fields.selectedParentPolicy.value as TEnhancedPolicy;
    const currentParentPolicyId = fields.parentPolicyId.value as string;
    const currentParentPolicyFormValues: IParentPolicyFormValues = {
        parentPolicyId: currentParentPolicyId,
    };
    const isParentPolicyRequired = policySettingsRules.parent.isRequired;
    const shouldAllowRemove = policySettingsRules.hasParentPolicy && !isParentPolicyRequired;

    return (
        <ExtendedSelectOneViaModal<IParentPolicyFormValues>
            formField={fields.parentPolicyId}
            label="fields.parent_policy_id.add_label"
            tooltip="fields.parent_policy_id.tooltip"
            showTooltipUntilDismissed
            currentSelectedItem={currentParentPolicyFormValues}
            renderSelectedItem={renderSelectedPolicy}
            remove={{
                shouldAllow: shouldAllowRemove,
                label: 'common.action.unlink',
                onRemoveItem: removeSelectedParentPolicy,
            }}
            edit={{
                modalTitle: 'fields.parent_policy_id.select',
                modalMaxWidth: 'md',
                label: 'common.action.choose',
                initialValues: currentParentPolicyFormValues,
                schema: getParentPolicySchema({ isParentPolicyRequired }),
                renderFormFields: renderSelectParentPolicyFormContent,
                submit: {
                    onSubmit: selectParentPolicy,
                },
            }}
        />
    );

    function renderSelectParentPolicyFormContent(
        context: IExtendedInputFormContext<IParentPolicyFormValues>,
    ) {
        return (
            <SelectParentPolicyFormContent
                {...context}
                policyAlgorithmType={fields.algorithm.value as PolicyAlgorithmType}
            />
        );
    }

    function selectParentPolicy({ values }: IOnSubmitProps<IParentPolicyFormValues>) {
        const selectedParentPolicyId = values.parentPolicyId;
        const selectedParentPolicy = isSet(selectedParentPolicyId)
            ? findSpecificPolicy({ policyId: selectedParentPolicyId })
            : null;

        setFieldValue({
            fieldName: fields.parentPolicyId.fieldName,
            value: selectedParentPolicyId,
        }, {
            fieldName: fields.selectedParentPolicy.fieldName,
            value: selectedParentPolicy,
        });

        return Promise.resolve();
    }

    function removeSelectedParentPolicy() {
        setFieldValue({
            fieldName: fields.parentPolicyId.fieldName,
            value: null,
        }, {
            fieldName: fields.selectedParentPolicy.fieldName,
            value: null,
        });
    }

    function renderSelectedPolicy({ item }: IRenderSelectedItemProps<IParentPolicyFormValues>) {
        if (!isSet(item.parentPolicyId)) {
            return (
                <div className={classes.NoParentPolicy}>
                    <Translate msg={`${labelPrefix}.fields.parent_policy_id.no_data`} />
                </div>
            );
        }
        if (!currentSelectedParentPolicy) {
            return (
                <div>{item.parentPolicyId}</div>
            );
        }
        if (readOnlyForm) {
            return (
                <TextButton
                    id="parent_policy_link_button"
                    label={`${currentSelectedParentPolicy.name}`}
                    variant="bare"
                    onClick={navigateToPolicyDetail}
                    fontWeight="initial"
                    size="MD"
                />
            );
        }
        return (
            <div>{currentSelectedParentPolicy.name}</div>
        );

        function navigateToPolicyDetail() {
            redirectTo({
                routeKey: ROUTE_KEY.R_POLICY_DETAIL,
                params: {
                    policyId: item.parentPolicyId,
                    policyTab: DEFAULT_POLICY_TAB_KEY,
                },
            });
        }
    }
}

function renderPolicyManagerTagFormContent({
    fields,
    readOnlyForm,
    setFieldValue,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    const tagsField = fields.tags;
    const currentTags = tagsField.value as PolicyManagerTag[];

    return (
        <ExtendedInputWrapper
            label="fields.tags.label"
            tooltip="fields.tags.tip"
            shouldStyleInputWrapped={false}
        >
            {ALL_POLICY_MANAGER_TAG_VALUES.map((managerTag, index) => {
                const key = `manager-tag_${managerTag}`;

                return (
                    <InputCheckboxField<PolicyManagerTag>
                        key={key}
                        name={managerTag}
                        disabled={readOnlyForm}
                        checked={currentTags.indexOf(managerTag) > -1}
                        description={getPolicyManagerTagLabel(managerTag)}
                        onChange={onChangeManagerTagCheckbox}
                        showValueIndicatorsIfDisabled
                        error={(index === ALL_POLICY_MANAGER_TAG_VALUES.length - 1) && tagsField.error}
                        emphasizeError={tagsField.emphasizeError}
                    />
                );
            })}
        </ExtendedInputWrapper>
    );

    function onChangeManagerTagCheckbox({ name: managerTag, checked }: IOnChangeCheckboxProps<PolicyManagerTag>) {
        const newTags = checked
            ? addItemAndGetResultingArray<PolicyManagerTag>(currentTags, managerTag, { resultInNewArray: true })
            : removeItemAndGetResultingArray<PolicyManagerTag>(currentTags, managerTag, { resultInNewArray: true });

        setFieldValue({
            fieldName: fields.tags.fieldName,
            value: newTags,
        });
    }
}

export function SelectParentPolicyFormContent({
    fields,
    setFieldValue,
    initialValues,
    childPolicyId,
    currency,
    policyAlgorithmType,
}: IExtendedInputFormContext<IParentPolicyFormValues> & {
    childPolicyId?: string;
    currency?: SupportedCurrency;
    policyAlgorithmType?: PolicyAlgorithmType;
}) {
    useExecuteOnMount({
        execute: () => triggerFetchPoliciesForSearch({
            currencies: isSet(currency) ? [currency] : null,
            algorithmType: policyAlgorithmType,
        }),
    });

    const policyIdField = fields.parentPolicyId;

    return (
        <GenericPolicySelect
            selectedPolicyId={policyIdField.value as string}
            onPolicySelected={updateFormWithSelectedPolicy}
            initialSelectedPolicyId={initialValues.parentPolicyId as string}
            isPolicySelectable={(policyId) => childPolicyId !== policyId}
            preFilledPolicyAlgorithmTypeFilter={policyAlgorithmType}
        />
    );

    function updateFormWithSelectedPolicy({ policy }: IOnPolicySelectedProps) {
        setFieldValue({
            fieldName: policyIdField.fieldName,
            value: policy.id,
        });
    }
}

function renderPolicyExternalIdFormContent({
    fields,
}: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    return (
        <ExtendedInputText<string>
            formField={fields.externalId}
            wrapper={{
                label: 'fields.external_id.label',
                tooltip: 'fields.external_id.tooltip',
                minHeight: SIZES.INPUT.HEIGHT.S,
            }}
            noDataLabel={{
                msg: 'fields.external_id.no_data',
            }}
        />
    );
}

export function renderPolicyDetailsSectionFormContent(context: IExtendedInputFormContext<IPolicySettingsFormValues>) {
    return (
        <>
            {renderPolicyNameFormContent(context)}
            {renderPolicyExternalIdFormContent(context)}
            <PolicyRiskProfileFormContent {...context} />
            {renderPolicyManagerTagFormContent(context)}
        </>
    );
}
