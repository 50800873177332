import React from 'react';
import clsx from 'clsx';
import MuiButton from '@mui/material/Button';
import isPromise from '@snipsonian/core/cjs/is/isPromise';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { BOX_SHADOW, FOCUS_OUTLINE, FOCUS_OUTLINE_OFFSET } from 'config/styling/elevation';
import { makeStyles } from 'views/styling';
import ConfirmationModal from 'views/common/layout/ConfirmationModal';
import SpinnerOverlay from 'views/common/loading/SpinnerOverlay';
import useIsMounted from 'utils/react/hooks/useIsMounted';
import { IBaseButtonProps, IBaseButtonStyleProps } from './types';
import { getButtonBackgroundColor, getButtonBorder, getButtonIconColor, toAskConfirmationConfig } from './buttonUtils';

export type { IBaseButtonProps } from './types';

const useStyles = makeStyles((/* theme */) => ({
    BaseButton: {
        minWidth: 'unset',

        border: ({ variant, color, disabled }: IBaseButtonStyleProps) => {
            if (variant === 'bare') {
                return 'unset';
            }
            return getButtonBorder({ color, state: disabled ? 'disabled' : undefined });
        },
        backgroundColor: ({ variant, color, disabled }: IBaseButtonStyleProps) => {
            if (variant === 'filled') {
                return getButtonBackgroundColor({ color, state: disabled ? 'disabled' : undefined });
            }
            return 'unset';
        },

        '&:hover': {
            border: ({ variant, color, disabled }: IBaseButtonStyleProps) => {
                if (variant === 'bare') {
                    return 'unset';
                }
                return getButtonBorder({ color, state: disabled ? 'disabled' : 'hover' });
            },
            backgroundColor: ({ variant, color, disabled }: IBaseButtonStyleProps) => {
                if (variant === 'filled') {
                    return getButtonBackgroundColor({ color, state: disabled ? 'disabled' : 'hover' });
                }
                return 'unset';
            },
            boxShadow: ({ addBoxShadowOnHover }: IBaseButtonStyleProps) => {
                if (addBoxShadowOnHover) {
                    return BOX_SHADOW.BUTTON;
                }
                return 'unset';
            },
        },

        '&:focus:not(:hover)': {
            border: ({ variant, color, disabled }: IBaseButtonStyleProps) => {
                if (variant === 'bare') {
                    return 'unset';
                }
                return getButtonBorder({ color, state: disabled ? 'disabled' : 'focus' });
            },
            backgroundColor: ({ variant, color, disabled }: IBaseButtonStyleProps) => {
                if (variant === 'filled') {
                    return getButtonBackgroundColor({ color, state: disabled ? 'disabled' : 'focus' });
                }
                return 'unset';
            },
            outline: ({ variant, disabled }: IBaseButtonStyleProps) => {
                if (variant === 'bare') {
                    if (!disabled) {
                        return FOCUS_OUTLINE;
                    }
                }

                return 'unset';
            },
            outlineOffset: FOCUS_OUTLINE_OFFSET,
        },

        '&:hover svg': {
            fill: ({ variant, color, setSvgFillColor }: IBaseButtonStyleProps) => {
                if (setSvgFillColor) {
                    return getButtonIconColor({ variant, color, state: 'hover' });
                }
                return undefined;
            },
        },
        '&:focus:not(:hover) svg': {
            fill: ({ variant, color, setSvgFillColor }: IBaseButtonStyleProps) => {
                if (setSvgFillColor) {
                    return getButtonIconColor({ variant, color, state: 'focus' });
                }
                return undefined;
            },
        },

        '& svg': {
            width: ({ svgSize }: IBaseButtonStyleProps) => svgSize,
            height: ({ svgSize }: IBaseButtonStyleProps) => svgSize,
            fill: ({ variant, color, disabled, setSvgFillColor }: IBaseButtonStyleProps) => {
                if (setSvgFillColor) {
                    return getButtonIconColor({ variant, color, state: disabled ? 'disabled' : undefined });
                }
                return undefined;
            },
        },
    },
    ConfirmationModal: {},
}));

export default function BaseButton({
    id,
    type,
    onClick,
    showLoaderWhenAsync = true,
    loaderPosition = 'absolute',
    classNames,
    disabled,
    variant,
    color,
    addBoxShadowOnHover,
    setSvgFillColor = true,
    svgSize = 32,
    children,
    askConfirmation = false,
    onTriggerAskConfirmation,
}: IBaseButtonProps) {
    const classes = useStyles({ disabled, variant, color, addBoxShadowOnHover, setSvgFillColor, svgSize });
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<boolean>(false);
    const [asyncActionStatus, setAsyncActionStatus] = React.useState<AsyncStatus>(AsyncStatus.Initial);
    const isMounted = useIsMounted();

    const askConfirmationConfig = toAskConfirmationConfig(askConfirmation);
    const isAsyncActionStatusBusy = (asyncActionStatus === AsyncStatus.Busy);

    return (
        <>
            <MuiButton
                id={id}
                className={clsx(classes.BaseButton, `BaseButton-${variant}`, ...classNames)}
                type={type}
                onClick={onClickButton}
                disabled={disabled || isAsyncActionStatusBusy}
                disableRipple
                disableFocusRipple
            >
                {children}
            </MuiButton>

            {askConfirmation && (
                <ConfirmationModal
                    id={`${id}_confirm-modal`}
                    {...askConfirmationConfig}
                    open={isConfirmationModalOpen}
                    onClose={closeConfirmationModal}
                    onConfirm={confirmConfirmationModal}
                />
            )}

            {showLoaderWhenAsync && (
                <SpinnerOverlay
                    open={isAsyncActionStatusBusy}
                    position={loaderPosition}
                    variant="white"
                />
            )}
        </>
    );

    function onClickButton(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        /* auto 'unfocus' the button so that we don't keep the focus styling afterwards */
        event.currentTarget.blur();

        if (askConfirmation) {
            openConfirmationModal();

            if (onTriggerAskConfirmation) {
                onTriggerAskConfirmation();
            }
        } else {
            executeClickAction(event);
        }
    }

    function openConfirmationModal() {
        setIsConfirmationModalOpen(true);
    }

    function closeConfirmationModal() {
        setIsConfirmationModalOpen(false);
    }

    function confirmConfirmationModal() {
        closeConfirmationModal();

        executeClickAction(null);
    }

    function setAsyncActionStatusIfMounted(value: AsyncStatus) {
        if (isMounted.current) {
            setAsyncActionStatus(value);
        }
    }

    function executeClickAction(event: React.MouseEvent<HTMLButtonElement>) {
        if (onClick) {
            const actionResult = onClick(event);

            if (showLoaderWhenAsync && isPromise<unknown>(actionResult)) {
                setAsyncActionStatusIfMounted(AsyncStatus.Busy);

                (actionResult as unknown as Promise<unknown>)
                    .then((result) => {
                        setAsyncActionStatusIfMounted(AsyncStatus.Success);
                        return result;
                    })
                    .catch((error) => {
                        setAsyncActionStatusIfMounted(AsyncStatus.Error);
                        throw error;
                    });
            }
        }
    }
}
