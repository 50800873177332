import React from 'react';
import List from '@mui/material/List';
import isArrayWithValues from '@snipsonian/core/cjs/array/verification/isArrayWithValues';
import { IMenuItemBase } from 'models/state/ui.models';
import {
    getTopMenuItemBySelectedMenuItem,
    IMenuItemToContextMenu,
    IMenuItemWithSubItems,
    isMenuItemToContextMenu,
    TContextMenuItem,
    TMenuItem,
} from 'config/menu.config';
import { MENU_COLORS } from 'config/styling/colors';
import { SIZES } from 'config/styling/sizes';
import { IObserveProps, observe } from 'views/observe';
import { makeStyles } from 'views/styling';
import MenuListItem from './MenuListItem';

const useStyles = makeStyles((theme) => ({
    ContextMenu: {
        width: `${SIZES.MENU.CONTEXT_WIDTH}px`,
        backgroundColor: MENU_COLORS.CONTEXT.BACKGROUND,
    },
    MenuList: {
        padding: theme.spacing(1, 0, 0, 0),
    },
}));

interface IPublicProps {
    isCollapsed?: boolean;
    showContextMenu: boolean;
    mainMenuItem: IMenuItemBase;
    allowedMenuItems: TMenuItem[];
    activeBreadcrumbMenuItemIds: number[];
    selectedBreadcrumbMenuItemIds: number[];
}

function ContextMenu({
    isCollapsed = false,
    showContextMenu,
    mainMenuItem,
    allowedMenuItems,
    activeBreadcrumbMenuItemIds,
    selectedBreadcrumbMenuItemIds,
}: IPublicProps & IObserveProps) {
    const classes = useStyles();

    if (!showContextMenu) {
        return null;
    }

    const topLevelItem = getTopMenuItemBySelectedMenuItem(mainMenuItem);

    if (!isMenuItemToContextMenu(topLevelItem as TMenuItem)) {
        return null;
    }

    return (
        <div className={classes.ContextMenu}>
            <List
                className={classes.MenuList}
                component="nav"
                aria-label="context menu"
            >
                {
                    getAllowedContextItems().map((contextMenuItem) => (
                        <MenuListItem
                            key={`context-menu-item_${contextMenuItem.id}`}
                            menuItem={contextMenuItem}
                            isCollapsed={isCollapsed}
                            activeBreadcrumbMenuItemIds={activeBreadcrumbMenuItemIds}
                            selectedBreadcrumbMenuItemIds={selectedBreadcrumbMenuItemIds}
                        />
                    ))
                }
            </List>
        </div>
    );

    function getAllowedContextItems(): TContextMenuItem[] {
        let allowedTopMenuItem;
        for (let i = 0; i < allowedMenuItems.length; i++) {
            allowedTopMenuItem = allowedMenuItems[i];
            if (topLevelItem.isSubItem) {
                const allowedTopMenuSubItems = (allowedTopMenuItem as IMenuItemWithSubItems).subItems;
                if (isArrayWithValues(allowedTopMenuSubItems)) {
                    const matchingTopSubItem = allowedTopMenuSubItems
                        .find((topMenuSubItem) => topMenuSubItem.id === topLevelItem.id);
                    if (matchingTopSubItem) {
                        return (matchingTopSubItem as IMenuItemToContextMenu).contextItems;
                    }
                }
            } else if (allowedTopMenuItem.id === topLevelItem.id) {
                return (allowedTopMenuItem as IMenuItemToContextMenu).contextItems;
            }
        }
        return [];
    }
}

export default observe<IPublicProps>(
    [],
    ContextMenu,
);
