import React, { useState } from 'react';
import { makeStyles } from 'views/styling';
import { mapArrayLikeObjectToArray } from '@console/common/utils/object/objectUtils';
import {
    TStoryManagerStatus,
    IOutputKeyWithId,
} from '@console/bff/models/storyteller/storymanager.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IColValues, IDataItem, TDataColumns } from 'models/list.models';
import { UiPageKey } from 'models/state/ui.models';
import {
    triggerDeleteOutputKeys,
    triggerDuplicateOutputKey,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import { canUserModifyStoryManagerScenarios } from 'state/auth/apiEntityAuthorization.selectors';
import {
    isStoryManagerInScenarioMode,
    getStoryManagerOutputKeysBasedOnActiveFilters,
    getStoryManagerOutputKeysSortFunction,
    getStoryManagerOutputKeyStatus,
} from 'state/ui/storyManager.selector';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import { observe, IObserveProps } from 'views/observe';
import ContentTitle from 'views/common/layout/ContentTitle';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import { AddIcon, DuplicateIcon, TrashIcon } from 'views/common/icons';
import ActionButtons, { IActionItem } from 'views/common/buttons/ActionButtons';
import DataTable from 'views/common/list/DataTable';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import ListPageForFixedItems from 'views/common/list/ListPageForFixedItems';
import {
    conditionallyDisableStoryManagerActions,
    conditionallyDisableStoryManagerAddAction,
} from 'views/apps/StoryTeller/StoryManager/storyManagerActions';
import AddOutputKeyModal from './OutputKeyDetails/AddOutputKeyModal';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.list';
const COL_TRANSLATION_PREFIX = `${TRANSLATION_PREFIX}.columns`;

interface IOutputKeysCols extends IColValues {
    name: string;
    status: TStoryManagerStatus;
}
const COLUMNS: TDataColumns<IOutputKeysCols> = {
    name: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.name`,
        },
        percentWidth: 70,
    },
    status: {
        label: {
            msg: `${COL_TRANSLATION_PREFIX}.status`,
        },
        percentWidth: 30,
        data: {
            render: ({ cellValue }) => (
                <StoryManagerStatus
                    status={cellValue as TStoryManagerStatus}
                />
            ),
        },
    },
};

const useStyles = makeStyles((theme) => ({
    OutputKeysList: {
        '& > .header': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: theme.spacing(3, 0),

            '& > .title': {
                margin: 0,
            },
        },

        '& .data-table': {
            marginTop: theme.spacing(1),
        },
    },
}));

function OutputKeysList({ state }: IObserveProps) {
    const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
    const [isAddOutputKeyModalOpen, setAddOutputKeyModalOpen] = useState<boolean>(false);
    const classes = useStyles();
    const { databaseId } = useRouteParams();
    const outputKeys = getStoryManagerOutputKeysBasedOnActiveFilters(state);
    const isScenarioEditorMode = isStoryManagerInScenarioMode(state);
    const canUserModifyScenarios = canUserModifyStoryManagerScenarios(state);

    const listItems = mapArrayLikeObjectToArray(
        outputKeys, { addKeyAsId: true },
    ) as IOutputKeyWithId[];

    return (
        <>
            <div className={classes.OutputKeysList}>
                <div className="header">
                    <ContentTitle className="title" variant="section" label={`${TRANSLATION_PREFIX}.title`} />
                    {isScenarioEditorMode && (
                        <ActionButtons
                            actions={getPageTitleActions()}
                        />
                    )}
                </div>
                <ListPageForFixedItems
                    list={{
                        items: listItems.sort(getStoryManagerOutputKeysSortFunction),
                        renderData: renderOutputKeyTable,
                    }}
                    notificationToTriggerOnUiVarChanges={StateChangeNotification.STORY_MANAGER_OUTPUT_KEYS_UI_VARS}
                    uiPageKey={UiPageKey.storyManagerOutputKeysList}
                    selectConfig={isScenarioEditorMode && canUserModifyScenarios && {
                        selectedItemIds,
                        onChange: setSelectedItemIds,
                        withSelectAll: true,
                        selectActions: getListSelectActions(),
                    }}
                />
            </div>
            <AddOutputKeyModal
                open={isAddOutputKeyModalOpen}
                onClose={() => setAddOutputKeyModalOpen(false)}
            />
        </>
    );

    function renderOutputKeyTable({
        data,
        extraData,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }: { data: IOutputKeyWithId[]; extraData: any; }) {
        const outputKeyItems: IDataItem<IOutputKeysCols>[] =
            data.map((outputKey) => ({
                id: outputKey.id,
                colValues: {
                    name: outputKey.name,
                    status: getStoryManagerOutputKeyStatus(state, outputKey),
                },
            }));

        return (
            <DataTable
                className="data-table"
                cols={COLUMNS}
                items={outputKeyItems}
                getActions={getOutputKeyItemActions}
                selectData={extraData?.selectData}
                onItemRowClicked={navigateToOutputKeyDetail}
            />
        );
    }

    function navigateToOutputKeyDetail(item: IDataItem<IOutputKeysCols>) {
        redirectTo({
            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
            params: {
                databaseId,
                outputKeyId: item.id,
            },
        });
    }

    function getListSelectActions(): IActionItem[] {
        return [{
            id: 'output-key-overview-delete-output-key-multiple',
            label: `${COL_TRANSLATION_PREFIX}.actions.delete`,
            onExecute: async () => triggerDeleteOutputKeys(selectedItemIds),
            icon: <TrashIcon />,
            variant: 'main-icon-bare',
        }];
    }

    function getPageTitleActions(): IActionItem[] {
        return [
            conditionallyDisableStoryManagerAddAction({
                canUserModify: canUserModifyScenarios,
                action: {
                    id: 'output-key-overview-add-output-key',
                    label: `${TRANSLATION_PREFIX}.actions.add`,
                    onExecute: async () => setAddOutputKeyModalOpen(true),
                    icon: <AddIcon />,
                    variant: 'main-icon-grey',
                },
            }),
        ];
    }

    function getOutputKeyItemActions(item: IDataItem<IOutputKeysCols>): IActionItem[] {
        if (!isScenarioEditorMode) {
            return [];
        }

        return conditionallyDisableStoryManagerActions({
            canUserModify: canUserModifyScenarios,
            actions: [{
                id: `${item.id}-output-key-overview-actions-duplicate`,
                label: `${COL_TRANSLATION_PREFIX}.actions.duplicate`,
                onExecute: async () => triggerDuplicateOutputKey(item.id, outputKeys[item.id]),
                icon: <DuplicateIcon />,
                variant: 'extra',
            }, {
                id: `${item.id}-output-key-overview-actions-delete`,
                label: `${COL_TRANSLATION_PREFIX}.actions.delete`,
                onExecute: async () => triggerDeleteOutputKeys([item.id]),
                icon: <TrashIcon />,
                variant: 'extra',
            }],
        });
    }
}

export default observe(
    [
        StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
    ],
    OutputKeysList,
);
