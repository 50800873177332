import React, { useState } from 'react';
import clsx from 'clsx';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Typography from '@mui/material/Typography';
import { IOutputKeyWithId } from '@console/bff/models/storyteller/storymanager.models';
import { makeStyles } from 'views/styling';
import { SIDE_NAV_OUTPUT_KEY_HEIGHT } from 'config/storyTeller/storymanager.config';
import { getStore } from 'state';
import { getStoryManagerOutputKeyStatus } from 'state/ui/storyManager.selector';
import { setStoryManagerOutputKeyCollapsePageVar } from 'state/ui/uiPages.actions';
import {
    triggerAddScenario,
    triggerDeleteOutputKeys,
    triggerDuplicateOutputKey,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import { AddIcon, ArrowRightIcon, DuplicateIcon, TrashIcon, InsertIcon } from 'views/common/icons';
import IconButtonCollapse from 'views/common/buttons/IconButtonCollapse';
import Tooltip from 'views/common/widget/Tooltip';
import { redirectTo } from 'views/routes';
import { ROUTE_KEY } from 'views/routeKeys';
import ActionButtons, { IActionItem } from 'views/common/buttons/ActionButtons';
import {
    conditionallyDisableStoryManagerActions,
} from 'views/apps/StoryTeller/StoryManager/storyManagerActions';
import AddOutputKeyModal from '../../../OutputKeyDetails/AddOutputKeyModal';
import Scenario from './Scenario';

const TRANSLATION_PREFIX = 'apps.story_teller.output_keys.side_nav';

interface IPublicProps {
    outputKey: IOutputKeyWithId;
    isScenarioEditorMode: boolean;
    canUserModifyScenarios: boolean;
    isCollapsed: boolean;
}

const useStyles = makeStyles((theme) => ({
    CollapsableOutputKey: {
        position: 'relative',
        overflow: 'hidden',
    },
    ListItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        background: 'none',
        border: 'none',
        borderRadius: '4px',

        width: '100%',
        minHeight: SIDE_NAV_OUTPUT_KEY_HEIGHT,
        cursor: 'pointer',

        '&.grab': {
            cursor: 'grab',
            '& .labelButton': {
                cursor: 'grab',
            },
        },

        '& .collapse.hide': {
            opacity: 0,
            pointerEvents: 'none',
        },

        '&:not(.disable)': {
            '&:hover': {
                backgroundColor: theme.palette.grey[200],

                '& > .actions': {
                    width: 'auto',
                    opacity: 1,
                    pointerEvents: 'all',
                },
            },
            '&.selected:not(:hover)': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        '& .labelButton': {
            width: '100%',
            flex: '1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

            background: 'none',
            border: 'none',

            cursor: 'pointer',

            '& > .status': {
                flex: '0 0 8px',
                marginRight: theme.spacing(1),
            },

            '& > .label': {
                flex: '1',
                textAlign: 'left',
            },
        },

        '& .tooltip': {
            flex: 1,
            overflow: 'hidden',
        },

        '& > .actions': {
            width: 0,
            opacity: 0,
            pointerEvents: 'none',
            paddingRight: theme.spacing(1),
        },
    },
    ActiveDragComponent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        opacity: 0.5,

        width: '100%',
        height: SIDE_NAV_OUTPUT_KEY_HEIGHT,

        backgroundColor: theme.palette.grey[200],
        borderRadius: '4px',
        paddingLeft: theme.spacing(4),

        overflow: 'hidden',
        cursor: 'grab',

        '& > .status': {
            flex: '0 0 8px',
            marginRight: theme.spacing(1),
        },
    },
    ScenarioList: {
        marginTop: theme.spacing(0.5),

        '& .labelButton': {
            padding: theme.spacing(0.5, 1, 0.5, 6.5),
        },

        '& > div': {
            cursor: 'pointer',
            '& > .labelButton': {
                cursor: 'pointer',
            },
        },
    },
}));

function CollapsableOutputKey({
    outputKey,
    isScenarioEditorMode,
    canUserModifyScenarios,
    isCollapsed,
}: IPublicProps) {
    const store = getStore();
    const state = store.getState();
    const [isAddOutputKeyModalOpen, setAddOutputKeyModalOpen] = useState(false);
    const classes = useStyles();
    const { databaseId, outputKeyId, scenarioIndex } = useRouteParams();

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
        active,
    } = useSortable({ id: outputKey.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
    };

    return (
        <>
            <div
                className={classes.CollapsableOutputKey}
                ref={setNodeRef}
                style={style}
                {...attributes}
                {...listeners}
            >
                {isDragging ? (
                    <div className={classes.ActiveDragComponent}>
                        <StoryManagerStatus
                            className="status"
                            status={getStoryManagerOutputKeyStatus(state, outputKey)}
                            small
                        />
                        <Typography className="label">{outputKey.name}</Typography>
                    </div>
                ) : (
                    <>
                        <div
                            className={clsx(classes.ListItem, {
                                selected: ((outputKeyId === outputKey.id) && !scenarioIndex),
                                disable: !!active,
                                grab: isScenarioEditorMode && canUserModifyScenarios,
                            })}
                        >
                            <IconButtonCollapse
                                className={clsx('collapse', { hide: Object.keys(outputKey.scenarios).length === 0 })}
                                id={`output-key-collapse-${outputKey.id}`}
                                isCollapsed={isCollapsed}
                                onCollapseChange={toggleCollapse}
                                isNotCollapsedIcon={<ArrowRightIcon />}
                            />
                            <Tooltip
                                label={{
                                    text: outputKey.name,
                                    shouldTranslate: false,
                                }}
                                enterDelay={350}
                                className="tooltip"
                            >
                                <button className="labelButton" onClick={selectOutputKey} type="button">
                                    <StoryManagerStatus
                                        className="status"
                                        status={getStoryManagerOutputKeyStatus(state, outputKey)}
                                        small
                                    />
                                    <Typography className="label">{outputKey.name}</Typography>
                                </button>
                            </Tooltip>
                            {!active && isScenarioEditorMode && (
                                <ActionButtons
                                    className="actions"
                                    actions={getOutputKeyActions()}
                                    moreVariant="bare"
                                />
                            )}
                        </div>
                        {isCollapsed && (
                            <div className={classes.ScenarioList}>
                                {Object.keys(outputKey.scenarios).map((key, i) => (
                                    <Scenario
                                        key={key}
                                        outputKey={outputKey}
                                        isScenarioEditorMode={isScenarioEditorMode}
                                        canUserModifyScenarios={canUserModifyScenarios}
                                        scenarioIndex={i}
                                        active={!!active}
                                        className={classes.ListItem}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )}
            </div>
            {isAddOutputKeyModalOpen && (
                <AddOutputKeyModal
                    open={isAddOutputKeyModalOpen}
                    onClose={() => setAddOutputKeyModalOpen(false)}
                    addOptions={{
                        insertBeforeOutputKeyId: outputKey.id,
                    }}
                />
            )}
        </>
    );

    function onInsertOutputKey() {
        setAddOutputKeyModalOpen(true);
    }

    function getOutputKeyActions(): IActionItem[] {
        return conditionallyDisableStoryManagerActions({
            canUserModify: canUserModifyScenarios,
            actions: [{
                id: 'side-nav-output-key-add-scenario',
                label: `${TRANSLATION_PREFIX}.output_key.actions.add_scenario`,
                onExecute: async () => triggerAddScenario(outputKey.id),
                icon: <AddIcon />,
                variant: 'extra',
            }, {
                id: 'side-nav-output-key-insert',
                label: `${TRANSLATION_PREFIX}.output_key.actions.insert`,
                onExecute: async () => onInsertOutputKey(),
                icon: <InsertIcon />,
                variant: 'extra',
            }, {
                id: 'side-nav-output-key-duplicate',
                label: `${TRANSLATION_PREFIX}.output_key.actions.duplicate`,
                onExecute: async () => triggerDuplicateOutputKey(outputKey.id, outputKey),
                icon: <DuplicateIcon />,
                variant: 'extra',
            }, {
                id: 'side-nav-output-key-delete',
                label: `${TRANSLATION_PREFIX}.output_key.actions.delete`,
                onExecute: async () => {
                    triggerDeleteOutputKeys([outputKey.id]);
                    if (outputKeyId === outputKey.id) {
                        redirectTo({
                            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEYS,
                            params: {
                                databaseId,
                            },
                        });
                    }
                },
                icon: <TrashIcon />,
                variant: 'extra',
            }],
        });
    }

    function selectOutputKey() {
        redirectTo({
            routeKey: ROUTE_KEY.R_STORY_MANAGER_DATABASE_OUTPUT_KEY_DETAIL,
            params: {
                databaseId,
                outputKeyId: outputKey.id,
            },
        });
    }

    function toggleCollapse(newIsCollapsed: boolean) {
        store.dispatch(
            setStoryManagerOutputKeyCollapsePageVar({ outputKeyId: outputKey.id, collapsed: newIsCollapsed }),
        );
    }
}

export default CollapsableOutputKey;
