import { Locale } from '@console/common/config/i18n.config';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state/index';

export function getTranslatedLocale(locale: Locale) {
    if (!locale) {
        return null;
    }

    const translator = getTranslator(getStore().getState());

    return translator(getLocaleTranslationLabel(locale));
}

export function getLocaleTranslationLabel(locale: Locale) {
    return `common.locale.${locale.toLowerCase()}`;
}
