import React from 'react';
import { TObjectWithProps } from '@console/common/models/genericTypes.models';
import { SortOrder } from '@console/common/models/sort.models';
import { stringComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { TApiEntityId } from '@console/core-api/models/api.models';
import { OperationPermissionKey } from '@typsy/console-api-client/dist/config/operationPermissionKeys';
import { IColValues, IDataItem, IToDynamicCellClassProps, TDataColumns } from 'models/list.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import {
    IFetchUserGroupsApiInput, TUserGroup, TUserGroupsData,
} from '@console/core-api/models/userMgmt/userGroup.models';
import { UiPageKey } from 'models/state/ui.models';
import { formatDateRelativeToNow } from '@console/common/utils/date/formatDate';
import { getOperationPermissionDisplayName } from 'utils/entities/userMgmt/userGroupUtils';
import { triggerFetchUserGroups, userGroupsEntity } from 'state/entities/userMgmt/userGroups';
import { UtilityClass } from 'views/assets/cssInJs/utilityClasses';
import ListPageForApiEntity, {
    IListPageForApiEntityProps,
    ISortConfigFunctions,
} from 'views/common/list/ListPageForApiEntity';
import DataTable from 'views/common/list/DataTable';

const DEFAULT_COL_TRANSLATION_PREFIX = 'user_mgmt.user_groups.list.columns';

interface IPublicProps<ColValues extends IColValues, ExtraUserGroupItemData extends TObjectWithProps = unknown>
    // eslint-disable-next-line max-len
    extends Pick<IListPageForApiEntityProps<IFetchUserGroupsApiInput, TUserGroupsData>, 'className' | 'create' | 'actions' | 'box' | 'includeRefreshButton'> {
    overrideEntity?: Partial<IOverrideEntity>;
    overrideUiVars?: IOverrideUiVars;
    overrideCols?: IOverrideCols<ColValues, ExtraUserGroupItemData>;
    onUserGroupRowClicked?: (userGroupId: TApiEntityId) => void;
    isUserGroupRowClickable?: (userGroup: IDataItem<ColValues, ExtraUserGroupItemData>) => boolean;
}

// eslint-disable-next-line max-len
interface IOverrideEntity extends Pick<IListPageForApiEntityProps<IFetchUserGroupsApiInput, TUserGroupsData>, 'asyncListEntity' | 'asyncListEntityFetchTrigger' | 'setStateOnPageNrChange'> {
    dataNotification: StateChangeNotification; // default USERGROUPS_DATA
}

// eslint-disable-next-line max-len
interface IOverrideUiVars extends Pick<IListPageForApiEntityProps<IFetchUserGroupsApiInput, TUserGroupsData>, 'uiPageKey'> {
    uiVarsNotification: StateChangeNotification; // default USERGROUPS_UI_VARS
}

interface IOverrideCols<ColValues extends IColValues, ExtraUserGroupItemData extends TObjectWithProps> {
    cols: TDataColumns<ColValues, ExtraUserGroupItemData>;
    toUserGroupListItem: TToUserGroupListItem<ColValues, ExtraUserGroupItemData>;
}

type TToUserGroupListItem<ColValues extends IColValues, ExtraUserGroupItemData> =
    (props: IToUserGroupListItemProps) => IDataItem<ColValues, ExtraUserGroupItemData>;

interface IToUserGroupListItemProps {
    userGroup: TUserGroup;
    defaultUserGroupCols: IDefaultUserGroupCols;
}

function toDefaultUserGroupListItem({
    userGroup,
    defaultUserGroupCols,
}: IToUserGroupListItemProps): IDataItem<IDefaultUserGroupCols> {
    return {
        id: userGroup.id,
        colValues: defaultUserGroupCols,
    };
}

export interface IDefaultUserGroupCols extends IColValues {
    name: string;
    permissions: string[];
    updated: string;
}

interface IDesiredCol {
    colName: keyof IDefaultUserGroupCols;
    percentWidth: number;
}

const DEFAULT_DESIRED_USER_GROUP_COLS: IDesiredCol[] = [
    { colName: 'name', percentWidth: 20 },
    { colName: 'permissions', percentWidth: 60 },
    { colName: 'updated', percentWidth: 20 },
];

export function getDefaultUserGroupCols({
    desiredCols = DEFAULT_DESIRED_USER_GROUP_COLS,
}: {
    desiredCols?: IDesiredCol[];
} = {}): TDataColumns<Partial<IDefaultUserGroupCols>> {
    return desiredCols.reduce(
        (accumulator, { colName, percentWidth }) => {
            if (percentWidth > 0) {
                switch (colName) {
                    case 'name': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_COL_TRANSLATION_PREFIX}.name`,
                            },
                            data: {
                                className: UtilityClass.table.cellBold,
                            },
                            percentWidth,
                            sort: {
                                initialOrder: SortOrder.Ascending,
                                serverSide: {
                                    field: 'name',
                                },
                            },
                        };
                        break;
                    }
                    case 'permissions': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_COL_TRANSLATION_PREFIX}.permissions`,
                            },
                            data: {
                                render: ({ item, translator }) => (
                                    <>
                                        {item.colValues.permissions
                                            .map((permission: string) =>
                                                getOperationPermissionDisplayName(
                                                    permission as OperationPermissionKey,
                                                    translator,
                                                ))
                                            .sort(stringComparerAscending)
                                            .join(', ')
                                        }
                                    </>
                                ),
                            },
                            align: 'left',
                            percentWidth,
                        };
                        break;
                    }
                    case 'updated': {
                        accumulator[colName] = {
                            label: {
                                msg: `${DEFAULT_COL_TRANSLATION_PREFIX}.updated`,
                            },
                            percentWidth,
                            sort: {
                                initialOrder: SortOrder.Descending,
                                serverSide: {
                                    field: 'version_datetime',
                                },
                            },
                        };
                        break;
                    }
                    default: throw new Error(`Unexpected col name '${colName}'`);
                }
            }

            return accumulator;
        },
        {} as TDataColumns<Partial<IDefaultUserGroupCols>>,
    );
}

export default function GenericUserGroupsList<
    ColValues extends IColValues = IDefaultUserGroupCols,
    ExtraUserGroupItemData extends TObjectWithProps = unknown,
>({
    overrideEntity = {},
    overrideUiVars,
    overrideCols,
    onUserGroupRowClicked,
    isUserGroupRowClickable,
    ...otherListPageProps
}: IPublicProps<ColValues, ExtraUserGroupItemData>) {
    const entityCofig: IOverrideEntity = {
        dataNotification: StateChangeNotification.USERGROUPS_DATA,
        asyncListEntity: userGroupsEntity,
        asyncListEntityFetchTrigger: triggerFetchUserGroups,
        setStateOnPageNrChange: (pageNr) => ({
            toState: (draftState) => {
                // eslint-disable-next-line no-param-reassign
                draftState.entities.userGroups.data.pageNr = pageNr;
            },
            notificationsToTrigger: [StateChangeNotification.USERGROUPS_DATA],
        }),
        ...overrideEntity,
    };
    const uiVarsConfig: IOverrideUiVars = overrideUiVars || {
        uiVarsNotification: StateChangeNotification.USERGROUPS_UI_VARS,
        uiPageKey: UiPageKey.userGroupsList,
    };
    const colsConfig: IOverrideCols<ColValues, ExtraUserGroupItemData> = overrideCols || {
        cols: getDefaultUserGroupCols() as TDataColumns<ColValues>,
        toUserGroupListItem: toDefaultUserGroupListItem as unknown as
        TToUserGroupListItem<ColValues, ExtraUserGroupItemData>,
    };

    let sortConfigFunctions: ISortConfigFunctions;

    return (
        <ListPageForApiEntity
            notifications={[entityCofig.dataNotification, uiVarsConfig.uiVarsNotification]}

            asyncListEntity={entityCofig.asyncListEntity}
            asyncListEntityFetchTrigger={entityCofig.asyncListEntityFetchTrigger}
            setStateOnPageNrChange={entityCofig.setStateOnPageNrChange}

            uiPageKey={uiVarsConfig.uiPageKey}
            notificationToTriggerOnUiVarChanges={uiVarsConfig.uiVarsNotification}

            list={{
                renderData: renderUserGroupsList,
            }}

            sort={{
                getSortConfigFunctionsCallback: (newSortConfigFunctions) => {
                    sortConfigFunctions = newSortConfigFunctions;
                },
            }}

            {...otherListPageProps}
        />
    );

    function renderUserGroupsList({ data }: { data: TUserGroup[] }) {
        if (!data) {
            return null;
        }

        const userGroupItems: IDataItem<ColValues, ExtraUserGroupItemData>[] =
        data.map((userGroup) => colsConfig.toUserGroupListItem({
            userGroup,
            defaultUserGroupCols: {
                name: userGroup.name,
                permissions: userGroup.permissions,
                updated: formatDateRelativeToNow({ date: userGroup.version_datetime }),
            },
        }));

        return (
            <DataTable
                cols={colsConfig.cols}
                items={userGroupItems}
                onItemRowClicked={onUserGroupRowClicked
                    ? (userGroup) => onUserGroupRowClicked(userGroup.id)
                    : null
                }
                isItemClickable={isUserGroupRowClickable}
                toDynamicCellClass={styleItemIfNotClickable}
                serverSideSorting={{
                    activeSortColumn: sortConfigFunctions.getActiveSortColumn,
                    onSelectSortColumn: (selectedSortCol) => {
                        sortConfigFunctions.setStateOnSortColumnChange(selectedSortCol);
                    },
                }}
            />
        );

        function styleItemIfNotClickable({ dataItem }: IToDynamicCellClassProps<ColValues, ExtraUserGroupItemData>) {
            if (isUserGroupRowClickable && !isUserGroupRowClickable(dataItem)) {
                return [UtilityClass.table.cellDisabled];
            }

            return null;
        }
    }
}
