import isSetString from '@snipsonian/core/cjs/string/isSetString';
import convertArrayToListString from '@snipsonian/core/cjs/array/listString/convertArrayToListString';
import isObjectWithProps from '@snipsonian/core/cjs/object/verification/isObjectWithProps';
import { AsyncOperation } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { formatDateForBE } from '@console/common/utils/date/formatDate';
import { PeriodType } from '@console/common/models/period.models';
import {
    IAdvancedPortfolioTransactionsFilters,
    IPortfolioTransactionsApiInput,
} from '@console/core-api/models/portfolioMgmt/portfolioTransaction.models';
import { FIRST_PAGE_NR } from '@console/core-api/config/coreApi.config';
import { CoreApiEntityType } from '@console/core-api/config/coreEntities.config';
import {
    TEnhancedPortfolioTransactionsData,
    TFetchEnhancedPortfolioTransactionsApiInput,
} from '@console/bff/models/portfolios/enhancedPortfolioTransactions.models';
import { AsyncEntityKeys, IForceStateRefreshFilter } from 'models/state/entities.models';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { UiPageKey } from 'models/state/ui.models';
import { api } from 'api';
import { getEntitiesManager } from 'state/entities/entitiesManager';
import { enrichApiInputWithListPageVars } from 'state/entities/apiEntityEnricher';
import { portfolioDetailsEntity } from 'state/entities/portfolioMgmt/portfolioDetails';
import { validateEntityIdBeforeFetch } from 'utils/entities/entityTypeUtils';

export const portfolioTransactionsEntity = getEntitiesManager().registerEntity<TEnhancedPortfolioTransactionsData>({
    asyncEntityKey: AsyncEntityKeys.portfolioTransactions,
    operations: [AsyncOperation.fetch],
    notificationsToTrigger: [StateChangeNotification.PORTFOLIO_TRANSACTIONS_DATA],
    includeUpdaters: true,
});

export const triggerResetPortfolioTransactionsFetch = () =>
    portfolioTransactionsEntity.updaters.fetch.resetWithoutDataReset();

export function triggerFetchPortfolioTransactions({
    forceRefresh = false,
    portfolioId,
    ...apiInput
}: IPortfolioTransactionsApiInput & IForceStateRefreshFilter) {
    if (!validateEntityIdBeforeFetch({ entityId: portfolioId, entityType: CoreApiEntityType.portfolios })) {
        return null;
    }

    if (!portfolioDetailsEntity.select().data) {
        throw new Error('The portfolio detail is not loaded!');
    }

    return portfolioTransactionsEntity.async.fetch<TFetchEnhancedPortfolioTransactionsApiInput>({
        api: api.bff.portfolios.fetchEnhancedPortfolioTransactions,
        apiInputSelector: ({ state }) => {
            const { startDate, endDate, movementStatuses, movementTypes, ...otherApiInput } = apiInput;

            const optionalPeriodApiInput = isSetString(startDate) && isSetString(endDate)
                ? {
                    startDate: formatDateForBE(startDate),
                    endDate: formatDateForBE(endDate),
                }
                : {};

            // eslint-disable-next-line max-len
            return enrichApiInputWithListPageVars<TFetchEnhancedPortfolioTransactionsApiInput, IAdvancedPortfolioTransactionsFilters>({
                pageKey: UiPageKey.portfolioTransactionsList,
                state,
                apiInput: {
                    portfolioId,
                    currency: portfolioDetailsEntity.select().data.base_currency,
                    managerType: portfolioDetailsEntity.select().data.config.manager,
                    movementStatuses: convertArrayToListString(movementStatuses),
                    movementTypes: convertArrayToListString(movementTypes),
                    ...optionalPeriodApiInput,
                    ...otherApiInput,
                },
                mapAdvancedFilterValues: (advancedFilterValues) => ({
                    movementTypes: convertArrayToListString(advancedFilterValues.movementTypes),
                    movementStatuses: convertArrayToListString(advancedFilterValues.movementStatuses),
                    ...(advancedFilterValues.period.type !== PeriodType.ALL_TIME
                        ? {
                            startDate: formatDateForBE(advancedFilterValues.period.startDate),
                            endDate: formatDateForBE(advancedFilterValues.period.endDate),
                        } : {}),
                }),
            });
        },
        mapApiResponse: ({ response }) => {
            if (response.pageNr > FIRST_PAGE_NR) {
                /* not the first page was retrieved >> append results to existing results array */
                return {
                    ...response,
                    results: portfolioTransactionsEntity.select().data.results.concat(response.results),
                    instrumentMap: {
                        ...portfolioTransactionsEntity.select().data.instrumentMap,
                        ...response.instrumentMap,
                    },
                };
            }

            /* initial/first page >> just overwrite the data results */
            return response;
        },
        refreshMode: () => forceRefresh || isObjectWithProps(apiInput)
            || isRequestedPortfolioIdDifferentThanBefore(portfolioId),
        resetDataOnTriggerMode: () => isRequestedPortfolioIdDifferentThanBefore(portfolioId),
    });
}

function isRequestedPortfolioIdDifferentThanBefore(portfolioId: string) {
    return portfolioTransactionsEntity.select().data.portfolioId !== portfolioId;
}
