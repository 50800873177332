import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import VirtualList from 'react-tiny-virtual-list';
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    DragOverlay,
    UniqueIdentifier,
} from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
    restrictToVerticalAxis,
    restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { IObserveProps, observe } from 'views/observe';
import {
    getStoryManagerOutputKeysBasedOnActiveFilters,
    getStoryManagerOutputKeysSortFunction,
    getStoryManagerOutputKeyStatus,
    isStoryManagerInScenarioMode,
    isStoryManagerOutputKeyCollapsed,
} from 'state/ui/storyManager.selector';
import { canUserModifyStoryManagerScenarios } from 'state/auth/apiEntityAuthorization.selectors';
import Translate from '@snipsonian/react/cjs/components/i18n/Translate';
import { mapArrayLikeObjectToArray } from '@console/common/utils/object/objectUtils';
import { IOutputKeyWithId } from '@console/bff/models/storyteller/storymanager.models';
import {
    triggerPatchStoryManagerConfig,
    getStoryManagerOutputKeysOrder,
    isBusyUpdatingStoryManagerDatabase,
} from 'state/entities/storyTeller/storyManagerDatabaseDetail';
import StoryManagerStatus from 'views/apps/StoryTeller/StoryManager/StoryManagerStatus';
import { SIDE_NAV_OUTPUT_KEY_HEIGHT } from 'config/storyTeller/storymanager.config';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'views/styling';
import useRouteParams from 'utils/react/hooks/useRouteParams';
import CollapsableOutputKey from './CollapsableOutputKey';

const useStyles = makeStyles((theme) => ({
    VirtualList: {
        '& > div': {
            overflow: 'hidden',
        },
    },
    ListContainer: {
        padding: theme.spacing(1, 0, 2),
        margin: theme.spacing(1, 0, 10),
    },
    ActiveDragComponent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        width: '100%',
        height: 40,

        backgroundColor: theme.palette.grey[200],
        borderRadius: '4px',
        paddingLeft: theme.spacing(4),

        overflow: 'hidden',
        cursor: 'grab',

        '& > .status': {
            flex: '0 0 8px',
            marginRight: theme.spacing(1),
        },
    },
}));

function SideNavigationOutputKeys({
    state,
}: IObserveProps) {
    const { outputKeyId: routeOutputKeyId } = useRouteParams();
    const classes = useStyles();
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 16,
            },
        }),
    );

    const isScenarioEditorMode = isStoryManagerInScenarioMode(state);
    const canUserModifyScenarios = canUserModifyStoryManagerScenarios(state);
    const outputKeys = getStoryManagerOutputKeysBasedOnActiveFilters(state);
    const order = getStoryManagerOutputKeysOrder();

    const outputKeysAsArray =
        mapArrayLikeObjectToArray(outputKeys, { addKeyAsId: true }) as IOutputKeyWithId[];

    const activeOutputKey = outputKeysAsArray.find((item) => item.id === activeId);
    const listItems = outputKeysAsArray.sort(getStoryManagerOutputKeysSortFunction);
    const [scrollToIndex, setScrollToIndex] = useState<number | null>(
        listItems.findIndex((item) => item.id === routeOutputKeyId),
    );

    useEffect(() => {
        setScrollToIndex(null);
    }, []);

    if (!outputKeys || Object.keys(outputKeys).length <= 0) {
        return (
            <Translate msg="common.data_table.no_results" />
        );
    }

    return (
        <div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={({ active }) => {
                    setActiveId(active.id);
                    setActiveIndex(listItems.findIndex((item) => item.id === active.id));
                }}
                onDragEnd={handleDragEnd}
                onDragCancel={() => {
                    setActiveId(null);
                    setActiveIndex(null);
                }}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <SortableContext
                    items={outputKeysAsArray}
                    strategy={verticalListSortingStrategy}
                    disabled={!isScenarioEditorMode || !canUserModifyScenarios || isBusyUpdatingStoryManagerDatabase()}
                >
                    <div className={classes.ListContainer}>
                        <VirtualList
                            height={500}
                            className={classes.VirtualList}
                            itemCount={listItems.length}
                            scrollToIndex={scrollToIndex}
                            itemSize={(index) => {
                                const outputKey = listItems[index];
                                if (isStoryManagerOutputKeyCollapsed(state, outputKey.id)) {
                                    return SIDE_NAV_OUTPUT_KEY_HEIGHT
                                        + (Object.keys(outputKey.scenarios).length * SIDE_NAV_OUTPUT_KEY_HEIGHT);
                                }
                                return SIDE_NAV_OUTPUT_KEY_HEIGHT;
                            }}
                            stickyIndices={activeId ? [activeIndex] : undefined}
                            renderItem={({ index, style }) => {
                                const outputKey = listItems[index];

                                return (
                                    <div key={outputKey.id} style={style}>
                                        <CollapsableOutputKey
                                            outputKey={outputKey}
                                            isScenarioEditorMode={isScenarioEditorMode}
                                            canUserModifyScenarios={canUserModifyScenarios}
                                            isCollapsed={isStoryManagerOutputKeyCollapsed(state, outputKey.id)}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>
                </SortableContext>
                {createPortal(
                    <DragOverlay adjustScale={false}>
                        {activeId ? (
                            <div className={classes.ActiveDragComponent}>
                                <StoryManagerStatus
                                    className="status"
                                    status={getStoryManagerOutputKeyStatus(state, activeOutputKey)}
                                    small
                                />
                                <Typography className="label">{activeOutputKey.name}</Typography>
                            </div>
                        ) : null}
                    </DragOverlay>,
                    document.body,
                )}
            </DndContext>
        </div>
    );

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (active.id !== over.id) {
            const newOrder = [...order];

            const indexToMove = newOrder.indexOf(active.id as string);
            const indexToInsertAt = newOrder.indexOf(over.id as string);

            newOrder.splice(indexToMove, 1);
            newOrder.splice(indexToInsertAt, 0, active.id as string);

            // Do not update if any value in the order is falsy
            if (newOrder.find((item) => !item)) {
                return;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            triggerPatchStoryManagerConfig({
                jsonPatch: [{
                    op: 'replace',
                    path: '/order',
                    value: newOrder,
                }],
            });
        }
    }
}

export default observe(
    [
        StateChangeNotification.STORY_MANAGER_DATABASE_DETAIL,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_EDITOR_MODE,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_FILTER,
        StateChangeNotification.UI_PAGE_STORY_MANAGER_SIDE_NAV_OUTPUT_KEYS,
        StateChangeNotification.STORY_MANAGER_DATABASES,
    ],
    SideNavigationOutputKeys,
);
