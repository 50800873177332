import React from 'react';
import isBoolean from '@snipsonian/core/cjs/is/isBoolean';
import {
    TRequiredPermissions,
} from '@typsy/console-api-client/dist/utils/entities/userGroups/doUserPermissionsCoverRequiredPermissions';
import { StateChangeNotification } from 'models/stateChangeNotifications';
import { TI18nLabelOrString } from 'models/general.models';
import { mayUser } from 'state/ui/selectors';
import { IObserveProps, observe } from 'views/observe';
import { ROUTE_KEY } from 'views/routeKeys';

/**
 * Mandatory input:
 * - either routeKey : child(ren) will only be visible if user is allowed to navigate to the route
 *                     (based on the 'requiredPermissions' of the route)
 * - or requiredPermissions : child(ren) only visible if user has these input permissions
 *                            (can be combo of AND / OR relations between the provided permissions)
 * - or both : child(ren) only visible if both validations are valid
 *
 * Optional input:
 * - disableInsteadOfHide : if true (or set with a tooltip), this component will pass a disabled=true property
 *                          to the child(ren) and will also set a (default) tooltip.
 *                          Its intended use is for when this <ShowIfAllowed> component wraps a button.
 */
interface IPublicProps {
    children: React.ReactNode;
    routeKey?: ROUTE_KEY;
    requiredPermissions?: TRequiredPermissions;
    /* default false - if true, a default tooltip will be used (which can be overridden) */
    disableInsteadOfHide?: TDisableInsteadOfHide;
}

type TDisableInsteadOfHide = boolean | IDisableInsteadOfHideConfig;

interface IDisableInsteadOfHideConfig {
    tooltip: TI18nLabelOrString;
}

function ShowIfAllowed({
    state,
    children,
    routeKey,
    requiredPermissions,
    disableInsteadOfHide = false,
}: IObserveProps & IPublicProps) {
    const isAllowed = mayUser({
        state,
        routeKey,
        requiredPermissions,
    });

    if (isAllowed) {
        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                {children}
            </>
        );
    }

    const { shouldDisable, disableTooltip } = parseDisableInsteadOfHideInput(disableInsteadOfHide);

    if (shouldDisable) {
        const childrenWithExtraProps = React.Children.toArray(children)
            .map((childElement) => {
                if (React.isValidElement(childElement)) {
                    /**
                     * Setting extra properties on the child(ren).
                     * See https://frontarm.com/james-k-nelson/passing-data-props-children/
                     */
                    return React.cloneElement(childElement, {
                        // @ts-expect-error To avoid the "No overload matches this call" error
                        disabled: true,
                        tooltip: disableTooltip,
                    });
                }

                return null; // hide the element (a string for example) as we can not set its props
            });

        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                {childrenWithExtraProps}
            </>
        );
    }

    return null;
}

export default observe<IPublicProps>(
    [StateChangeNotification.AUTH_USER],
    ShowIfAllowed,
);

function parseDisableInsteadOfHideInput(disableInsteadOfHide: TDisableInsteadOfHide): {
    shouldDisable: boolean;
    disableTooltip: TI18nLabelOrString;
} {
    if (isBoolean(disableInsteadOfHide)) {
        return {
            shouldDisable: disableInsteadOfHide,
            disableTooltip: 'error.auth.not_allowed',
        };
    }

    return {
        shouldDisable: true,
        disableTooltip: disableInsteadOfHide.tooltip,
    };
}
