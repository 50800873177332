import React from 'react';
import clsx from 'clsx';
import MenuItem from '@mui/material/MenuItem';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isBoolean from '@snipsonian/core/cjs/is/isBoolean';
import { TTranslator } from '@snipsonian/react/cjs/components/i18n/translator/types';
import { AsyncStatus } from '@snipsonian/observable-state/cjs/actionableStore/entities/types';
import { TAnyObject } from '@snipsonian/core/cjs/typings/object';
import { stringComparerAscending } from '@snipsonian/core/cjs/array/sorting/comparers';
import { IAsyncItem } from '@console/core-api/models/api.models';
import { TI18nLabelOrString, TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { NO_DATA_CHARACTER } from 'config/styling/typography';
import { getTranslator } from 'state/i18n/selectors';
import { getStore } from 'state';
import { isUntranslatableLabelTypeGuard, toI18nLabel } from 'utils/i18n/i18nUtils';
import { makeStyles, mixins } from 'views/styling';
import { prependLabelWithPrefix } from 'views/common/inputs/extended/ExtendedInputWrapper';
import InputTextField, { IInputTextFieldProps, IOnChangeTextInputProps } from 'views/common/inputs/base/InputTextField';
import Spinner from 'views/common/loading/Spinner';
import { ArrowDownIcon } from 'views/common/icons';
import Text from 'views/common/widget/Text';

export interface IInputSelectFieldProps<Value extends TInputSelectValue, AsyncItemData = unknown>
    extends Omit<IInputTextFieldProps<Value>, 'type' | 'multilineRows' | 'selectProps' | 'selectItems'> {
    items: IInputSelectItem<Value>[];
    itemLabelsAreTranslationKeys?: boolean; // default false
    sortItemsByLabel?: boolean; // default false
    // in case you need to render something else in read only mode when a value is set
    renderReadOnly?: (props: IRenderReadOnlyProps<Value>) => React.ReactNode;
    labelPrefix?: string;
    /* If 'async' provided, it will overrule the items attribute */
    async?: {
        itemsData: IAsyncItem<AsyncItemData>;
        dataToSelectItemsMapper: (asyncItemData: AsyncItemData) => IInputSelectItem<Value>[];
        errorLabel?: TLabel;
    };
    /**
     * addNoDataSelectItem -->
     * - when false, no item to select 'nothing' (= to leave the field empty) will be added
     * - when true, such an item will be automatically added for which a default label will be used
     * - when a i18n label (or string) the item will be added with this label
     */
    addNoDataSelectItem?: boolean | TLabel; // default false
    noDataLabel?: TLabel;
    /**
     * only to be provided if additional props are to be passed to the underlying material ui component
     * See https://mui.com/api/select/
     */
    extraSelectProps?: null | TAnyObject;
    renderMenuItemLabel?: TMenuItemLabelRenderer<Value>;
    menuItemClassName?: string;
}

export interface ISelectItemsState<Value extends TInputSelectValue> {
    selectItems: IInputSelectItem<Value>[];
    isLoading: boolean;
    asyncError: TLabel;
}

export type TInputSelectValue = string | number;

export interface IInputSelectItem<Value extends TInputSelectValue> {
    value: Value;
    label: TLabel;
}

export interface IRenderReadOnlyProps<Value extends TInputSelectValue> {
    value: Value;
}

export interface IOnChangeSelectProps<Value extends TInputSelectValue> {
    value: Value;
}

type TMenuItemLabelRenderer<Value extends TInputSelectValue> =
    (renderProps: IRenderMenuItemLabelProps<Value>) => React.ReactNode;
export interface IRenderMenuItemLabelProps<Value extends TInputSelectValue> {
    value: Value;
    label: string;
}

const useStyles = makeStyles((theme) => ({
    InputSelectField: {
        '& .__asyncLoading': {
            paddingLeft: theme.spacing(2),
        },
        '& .__asyncError': {
            paddingLeft: theme.spacing(1),
            color: APP_COLORS.FEEDBACK.ERROR,
        },
        '& .MuiSelect-root': {
            backgroundColor: 'unset',
        },

        '& .MuiSelect-icon': {
            fill: APP_COLORS.TEXT['500'],
        },
    },
    ReadOnly: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        '& .noDataLabel': {
            ...mixins.typo({ size: 15, color: APP_COLORS.GREY['300'] }),
        },
    },
    NoDataSelectItem: {
        color: APP_COLORS.GREY['300'],
    },
}));

const NO_DATA_ITEM_VALUE = '--NO_DATA--';

export default function InputSelectField<Value extends TInputSelectValue>({
    items,
    itemLabelsAreTranslationKeys = false,
    sortItemsByLabel = false,
    labelPrefix,
    addNoDataSelectItem,
    value,
    onChange,
    noDataLabel,
    disabled,
    renderReadOnly,
    async,
    extraSelectProps = {},
    renderMenuItemLabel,
    menuItemClassName,
    ...otherProps
}: IInputSelectFieldProps<Value>) {
    const classes = useStyles();
    const isValueSet = isSet(value);
    const shouldUseRenderReadOnly = disabled && renderReadOnly && isValueSet;
    const selectItemsState = determineSelectItemsState();
    const selectProps = {
        IconComponent: ArrowDownIcon,
        ...extraSelectProps,
    };
    const translator = itemLabelsAreTranslationKeys && getTranslator(getStore().getState());

    if (selectItemsState.isLoading) {
        return (
            <Spinner className="__asyncLoading" size="M" />
        );
    }

    if (selectItemsState.asyncError) {
        return (
            <div className="__asyncError">
                <Text label={selectItemsState.asyncError} />
            </div>
        );
    }

    return (
        <>
            {disabled && (
                <>
                    {shouldUseRenderReadOnly && renderReadOnly({ value })}
                    {!shouldUseRenderReadOnly && (
                        <div className={classes.ReadOnly}>
                            {renderReadOnlyComponent()}
                        </div>
                    )}
                </>
            )}
            {!disabled && (
                <InputTextField<Value>
                    selectProps={selectProps}
                    value={getValue()}
                    onChange={onChangeSelected}
                    className={classes.InputSelectField}
                    {...otherProps}
                >
                    {addNoDataSelectItem && (
                        <MenuItem
                            key="select-item-no-data"
                            value={NO_DATA_ITEM_VALUE}
                            className={clsx(classes.NoDataSelectItem, menuItemClassName)}
                        >
                            <Text label={getNoDataItemLabel()} />
                        </MenuItem>
                    )}
                    {toInputSelectItems({
                        selectItems: selectItemsState.selectItems,
                        itemLabelsAreTranslationKeys,
                        labelPrefix,
                        sortItemsByLabel,
                        translator,
                        renderMenuItemLabel,
                        menuItemClassName,
                    })}
                </InputTextField>
            )}
        </>
    );

    function renderReadOnlyComponent() {
        if (noDataLabel && !isValueSet) {
            return (
                // eslint-disable-next-line jsx-a11y/label-has-associated-control
                <label className="noDataLabel">
                    <Text
                        label={prependLabelWithPrefix({
                            label: noDataLabel,
                            labelPrefix,
                            shouldPrefixLabel: true,
                        })}
                    />
                </label>
            );
        }

        const selectedItem = selectItemsState.selectItems.find((item) =>
            item.value === value);

        return selectedItem?.label
            ? getInputSelectItemDisplayLabel({
                item: selectedItem,
                itemLabelsAreTranslationKeys,
                labelPrefix,
                translator,
            })
            : NO_DATA_CHARACTER;
    }

    function determineSelectItemsState(): ISelectItemsState<Value> {
        if (!async) {
            return {
                selectItems: items,
                isLoading: false,
                asyncError: null,
            };
        }

        const { status: asyncStatus, data: asyncData } = async.itemsData;

        switch (asyncStatus) {
            case AsyncStatus.Success: {
                return {
                    selectItems: async.dataToSelectItemsMapper(asyncData),
                    isLoading: false,
                    asyncError: null,
                };
            }
            case AsyncStatus.Error: {
                return {
                    selectItems: null,
                    isLoading: false,
                    asyncError: async.errorLabel || 'error.operation_failed.fetch',
                };
            }
            default: {
                return {
                    selectItems: null,
                    isLoading: true,
                    asyncError: null,
                };
            }
        }
    }

    function getValue() {
        if (addNoDataSelectItem && !isValueSet) {
            // TODO or should we have a separate value in case of number vs string ?
            return NO_DATA_ITEM_VALUE as Value;
        }

        return value;
    }

    function onChangeSelected(onChangeProps: IOnChangeTextInputProps<Value>) {
        if (onChangeProps.value === NO_DATA_ITEM_VALUE) {
            /**
             * Has to be "undefined" instead of "null", otherwise the yup validation thinks that the value is set.
             */
            onChange({ value: undefined });
        } else {
            onChange(onChangeProps);
        }
    }

    function getNoDataItemLabel(): TLabel {
        if (isBoolean(addNoDataSelectItem)) {
            return 'common.select.no_data_item';
        }

        return prependLabelWithPrefix({
            label: addNoDataSelectItem,
            labelPrefix,
            shouldPrefixLabel: true,
        });
    }
}

export function mapValuesToInputSelectItems<Value extends TInputSelectValue = TInputSelectValue>(
    values: Value[],
): IInputSelectItem<Value>[] {
    return values.map((value) => ({
        value,
        label: value.toString(),
    }));
}

export function toInputSelectItems<Value extends TInputSelectValue>({
    selectItems,
    itemLabelsAreTranslationKeys,
    labelPrefix,
    sortItemsByLabel,
    translator,
    renderMenuItemLabel,
    menuItemClassName,
}: {
    selectItems: IInputSelectItem<Value>[];
    itemLabelsAreTranslationKeys: boolean;
    labelPrefix: string;
    sortItemsByLabel: boolean;
    translator?: TTranslator;
    renderMenuItemLabel?: TMenuItemLabelRenderer<Value>;
    menuItemClassName?: string;
}) {
    return getSelectItemsToShow().map((item) => (
        <MenuItem
            key={`select-item-${item.value}`}
            value={item.value}
            className={menuItemClassName}
        >
            {renderLabel(item)}
        </MenuItem>
    ));

    function getSelectItemsToShow() {
        const items = itemLabelsAreTranslationKeys
            ? selectItems.map((item) => ({
                value: item.value,
                label: getInputSelectItemDisplayLabel({
                    item,
                    itemLabelsAreTranslationKeys,
                    labelPrefix,
                    translator,
                }),
            }))
            : selectItems;

        return sortItemsByLabel
            ? items.sort((itemA, itemB) =>
                stringComparerAscending(itemA.label as string, itemB.label as string))
            : items;
    }

    function renderLabel(item: IInputSelectItem<Value>): React.ReactNode {
        if (renderMenuItemLabel) {
            return renderMenuItemLabel({
                value: item.value,
                label: item.label as string,
            });
        }

        return item.label as React.ReactNode;
    }
}

export function getInputSelectItemDisplayLabel<Value extends TInputSelectValue>({
    item,
    itemLabelsAreTranslationKeys,
    labelPrefix,
    translator,
}: {
    item: IInputSelectItem<Value>;
    itemLabelsAreTranslationKeys: boolean;
    labelPrefix: string;
    translator?: TTranslator;
}): string {
    if (isUntranslatableLabelTypeGuard(item.label)) {
        return item.label.text;
    }

    if (itemLabelsAreTranslationKeys) {
        return (translator || getTranslator(getStore().getState()))(
            toI18nLabel(prependLabelWithPrefix({
                label: item.label,
                labelPrefix,
                shouldPrefixLabel: true,
            }) as TI18nLabelOrString),
        );
    }

    return item.label as string;
}
