import { REPORTING_FEATURE_FLAGS } from '@console/bff/client/mgmtReporting/mgmtReporting.console.bff';
import { MGMT_REPORTING_TAB_KEY } from 'config/tabs.config';
import { IForceStateRefreshFilter } from 'models/state/entities.models';
import { triggerFetchRoboAumReport } from 'state/entities/mgmtReporting/roboAumReport';
import { triggerFetchRoboClientGrowthReport } from 'state/entities/mgmtReporting/roboClientGrowthReport';
import { triggerFetchRoboClientCashTransferReport } from 'state/entities/mgmtReporting/roboClientCashTransferReport';
import { triggerFetchSelfAumReport } from 'state/entities/mgmtReporting/selfAumReport';
import { triggerFetchSelfClientGrowthReport } from 'state/entities/mgmtReporting/selfClientGrowthReport';
import { triggerFetchSelfClientCashTransferReport } from 'state/entities/mgmtReporting/selfClientCashTransferReport';
import { isThereReportData, triggerFetchReportInfo } from 'state/entities/mgmtReporting/reportInfo';
import { triggerFetchRoboPortfolioPerformanceReport } from 'state/entities/mgmtReporting/roboPortfolioPerformanceReport';

export function triggerFetchMgmtReportsRobo(input: IForceStateRefreshFilter = {}) {
    return triggerFetchMgmtReportsGeneric({
        ...input,
        mgmtReportingTab: MGMT_REPORTING_TAB_KEY.ROBO_ADVISOR,
    });
}

export function triggerFetchMgmtReportsSelf(input: IForceStateRefreshFilter = {}) {
    return triggerFetchMgmtReportsGeneric({
        ...input,
        mgmtReportingTab: MGMT_REPORTING_TAB_KEY.SELF_INVESTOR,
    });
}

export function triggerFetchMgmtReportsGeneric({
    forceRefresh = false,
    mgmtReportingTab,
}: {
    mgmtReportingTab: string;
} & IForceStateRefreshFilter): Promise<unknown> {
    /**
     * We first make sure the generic info (containing the available periods etc.)
     * is present.
     */
    return triggerFetchReportInfo()
        // eslint-disable-next-line arrow-body-style
        .then(() => {
            /** Then we fetch the tab-specific data if there is any report data available */

            if (!isThereReportData()) {
                return Promise.resolve();
            }

            return triggerTabSpecificFetches() as Promise<unknown>;
        });

    function triggerTabSpecificFetches() {
        switch (mgmtReportingTab) {
            case MGMT_REPORTING_TAB_KEY.ROBO_ADVISOR:
                return Promise.all([
                    triggerFetchRoboAumReport({ forceRefresh }),
                    triggerFetchRoboClientGrowthReport({ forceRefresh }),
                    REPORTING_FEATURE_FLAGS.client_cash_transfer
                        ? triggerFetchRoboClientCashTransferReport({ forceRefresh })
                        : Promise.resolve(null),
                    triggerFetchRoboPortfolioPerformanceReport({ forceRefresh }),
                ]);

            case MGMT_REPORTING_TAB_KEY.SELF_INVESTOR:
                return Promise.all([
                    REPORTING_FEATURE_FLAGS.self_aum
                        ? triggerFetchSelfAumReport({ forceRefresh })
                        : Promise.resolve(null),
                    triggerFetchSelfClientGrowthReport({ forceRefresh }),
                    REPORTING_FEATURE_FLAGS.client_cash_transfer
                        ? triggerFetchSelfClientCashTransferReport({ forceRefresh })
                        : Promise.resolve(null),
                ]);

            default:
                return Promise.resolve();
        }
    }
}
