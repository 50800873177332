import isSet from '@snipsonian/core/cjs/is/isSet';
import isSetString from '@snipsonian/core/cjs/string/isSetString';

export const DEFAULT_DECIMAL_SEPARATOR = ',';
export const DEFAULT_DIGIT_BLOCK_SEPARATOR = '.';

let currentDecimalSeparator = DEFAULT_DECIMAL_SEPARATOR;
let currentDigitBlockSeparator = DEFAULT_DIGIT_BLOCK_SEPARATOR;

export function setNumbersDisplayCustomizationConfig({
    decimalSeparator,
    digitBlockSeparator,
}: {
    decimalSeparator: string;
    digitBlockSeparator: string;
}) {
    currentDecimalSeparator = decimalSeparator;
    currentDigitBlockSeparator = digitBlockSeparator;
}

export type TNumberMagnitudeFlag = 'B' | 'M' | 'K' | '';

export interface INumberMagnitude {
    flag: TNumberMagnitudeFlag;
    divider: number;
}

export function formatFloat(
    input: number,
    {
        nrOfDecimals = 2,
        stripTrailingDecimalZeros = false,
        useMagnitudeFlags = false,
    }: {
        nrOfDecimals?: number;
        stripTrailingDecimalZeros?: boolean;
        /**
         * if true, the K (thousand), M (million) and B (billion) flags may be used in the result:
         * - if amount >= 1.000.000.000 --> B flag & amount divided by 1.000.000.000
         * - if amount >= 1.000.000 --> M flag & amount divided by 1.000.000
         * - if amount >= 1.000 --> K flag & amount divided by 1.000
         * - if amount < 1.000 --> no flag
         */
        useMagnitudeFlags?: boolean;
    } = {},
): string {
    if (!isSet(input)) {
        return null;
    }

    let numberToFormat = input;
    let flag: TNumberMagnitudeFlag = '';

    if (useMagnitudeFlags) {
        const numberMagnitude = detectNumberMagnitude(numberToFormat);
        flag = numberMagnitude.flag;
        numberToFormat /= numberMagnitude.divider;
    }

    return [numberToFormat.toFixed(nrOfDecimals)]
        .map((intermediate) => pointToDecimalSeparator(intermediate))
        .map((intermediate) => addSeparatorBetweenBlocksOf3Digits(intermediate))
        // eslint-disable-next-line no-confusing-arrow
        .map((intermediate) => stripTrailingDecimalZeros ? removeTrailingDecimalZeros(intermediate) : intermediate)
        // eslint-disable-next-line no-confusing-arrow
        .map((intermediate) => isSetString(flag) ? `${intermediate}${flag}` : intermediate)
        .pop();
}

export function pointToDecimalSeparator(
    input: string | number,
    { decimalSeparator = currentDecimalSeparator }: { decimalSeparator?: string } = {},
) {
    if (!isSet(input)) {
        return '';
    }

    return `${input}`.replace(/\./g, decimalSeparator);
}

export function addSeparatorBetweenBlocksOf3Digits(
    input: string,
    {
        decimalSeparator = currentDecimalSeparator,
        digitBlockSeparator = currentDigitBlockSeparator,
    }: { decimalSeparator?: string; digitBlockSeparator?: string } = {},
) {
    const parts = input.toString().split(decimalSeparator);
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, digitBlockSeparator);
    return parts.join(decimalSeparator);
}

export function removeTrailingDecimalZeros(
    input: string,
    {
        decimalSeparator = currentDecimalSeparator,
    }: { decimalSeparator?: string } = {},
) {
    const parts = input.toString().split(decimalSeparator);

    if (parts.length > 1) {
        parts[1] = parts[1].replace(/0+$/, '');
        if (parts[1].length > 0) {
            return parts.join(decimalSeparator);
        }

        return parts[0];
    }

    return input;
}

export function splitAtDecimalSeparator(
    input: string,
    {
        decimalSeparator = currentDecimalSeparator,
    }: { decimalSeparator?: string } = {},
) {
    return input.split(decimalSeparator);
}

export function prefixWithDecimalSeparator(
    input: string,
    {
        decimalSeparator = currentDecimalSeparator,
    }: { decimalSeparator?: string } = {},
) {
    return `${decimalSeparator}${input}`;
}

export function detectNumberMagnitude(
    number: number,
    {
        atLeastK = false,
    }: {
        atLeastK?: boolean; /** if true, the empty '' flag will never be returned, even if amount < 1000 */
    } = {},
): INumberMagnitude {
    if (number >= 1000000000) {
        return {
            flag: 'B',
            divider: 1000000000,
        };
    }
    if (number >= 1000000) {
        return {
            flag: 'M',
            divider: 1000000,
        };
    }
    if (atLeastK || (number >= 1000)) {
        return {
            flag: 'K',
            divider: 1000,
        };
    }
    return {
        flag: '',
        divider: 1,
    };
}
