import React from 'react';
import clsx from 'clsx';
import isSet from '@snipsonian/core/cjs/is/isSet';
import isNumber from '@snipsonian/core/cjs/is/isNumber';
import { TLabel } from 'models/general.models';
import { APP_COLORS } from 'config/styling/colors';
import { formatFloat } from '@console/common/utils/float/floatUtils';
import { formatPercentage } from 'utils/number/percentageUtils';
import { determineTrendDirection } from 'utils/entities/mgmtReporting/mgmtReporting.utils';
import { makeStyles, mixins } from 'views/styling';
import Text from 'views/common/widget/Text';
import { TrendIndicator } from 'views/common/charts/TrendIndicator';
import { IInfoIconTooltipProps } from 'views/common/widget/InfoIconTooltip.models';
import InfoIconTooltip from 'views/common/widget/InfoIconTooltip';

export interface IMgmtReportingGenericTableProps<ColKeys extends IColKeys> {
    className?: string;
    cols: IGenericColumn<ColKeys>[];
    rows: IGenericRow<ColKeys>[];
}

export interface IColKeys {
    [columnKey: string]: TItemValue;
}

type TItemValue = number;

export interface IGenericColumn<ColKeys> {
    colKey: keyof ColKeys;
    label: TLabel;
    subLabel?: TLabel;
    className?: string;
    isDisabled?: boolean; /* default false */
    markBold?: boolean; /* default false */
    /* provide only if you want to show a trendIndicator */
    trendIndicatorComparisonColKey?: keyof ColKeys;
    /* only provide 'renderCell' if the default behaviour is not what you need */
    renderCell?: (renderProps: {
        cellValue: TItemValue;
        rowValues: ColKeys;
    }) => React.ReactNode;
}

export interface IGenericRow<ColKeys> {
    label: TLabel;
    className?: string;
    values: ColKeys;
    /* default false - when true, the + or - sign will be displayed separate from the value itself */
    isGrowthNumber?: boolean;
    /* default false - when true, the number will be displayed as a percentage, otherwise as a regular number/float */
    isPercentage?: boolean;
    /* the number of decimals to show - default 2 for percentages and default 0 for regular numbers */
    nrOfDecimals?: number;
    tooltip?: Pick<IInfoIconTooltipProps, 'name' | 'simpleContent'>;
}

const useStyles = makeStyles((theme) => ({
    MgmtReportingGenericTable: {
        border: 0,
        borderSpacing: 0,
        borderCollapse: 'collapse',

        '& .__cell': {
            textAlign: 'right',
            height: 48,
            padding: theme.spacing(0, 1, 0, 2),
            lineHeight: 1.1,
        },
        '& tr td.__cell': {
            ...mixins.borderTop({ color: APP_COLORS.GREY['200'] }),
        },
        '& tr .__cell:not(:last-child)': {
            ...mixins.borderRight({ color: APP_COLORS.GREY['200'] }),
        },

        '& th': {
            fontSize: 12,
            fontWeight: 400,

            '&.--bold': {
                fontWeight: 700,
            },

            '& .__subHeader': {
                fontSize: 10,
                fontWeight: 400,
                marginTop: 4,
                whiteSpace: 'nowrap',
            },
        },
        '& td': {
            fontSize: 14,

            '&.--rowLabel': {
                textAlign: 'left',
                fontWeight: 700,
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),

                '& > div': {
                    ...mixins.flexRowCenterLeft(),
                },
            },
            '&.--bold': {
                fontWeight: 700,
            },
        },

        '& .__cellValue': {
            ...mixins.flexRow({ alignMain: 'flex-end', alignCross: 'center', wrap: 'nowrap' }),
            whiteSpace: 'nowrap',
        },
        '& .__cellValue .TrendIndicator': {
            marginLeft: theme.spacing(1),
        },
    },
}));

export function MgmtReportingGenericTable<ColKeys extends IColKeys>({
    className,
    cols,
    rows,
}: IMgmtReportingGenericTableProps<ColKeys>) {
    const classes = useStyles();

    const colsToShow = cols.filter((col) => !col.isDisabled);

    return (
        <table
            className={clsx(
                classes.MgmtReportingGenericTable,
                className,
            )}
        >
            <thead>
                <tr>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th className="__cell">&nbsp;</th>
                    {colsToShow.map(({ colKey, ...col }) => (
                        <th
                            key={`mgmt-reporting-table_col_${colKey as string}`}
                            className={clsx(
                                '__cell',
                                col.markBold && '--bold',
                            )}
                        >
                            <Text label={col.label} />

                            {col.subLabel && (
                                <div className="__subHeader">
                                    <Text label={col.subLabel} />
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {rows.map((row, index) => {
                    const rowKey = `mgmt-reporting-table_row_${index}`;

                    return (
                        <tr
                            key={rowKey}
                        >
                            <td
                                className={clsx('__cell', '--rowLabel')}
                            >
                                <div>
                                    <Text label={row.label} />

                                    {row.tooltip && (
                                        <InfoIconTooltip
                                            name={row.tooltip.name}
                                            simpleContent={row.tooltip.simpleContent}
                                            infoIconColor="primary"
                                        />
                                    )}
                                </div>
                            </td>
                            {colsToShow.map(({ colKey, ...col }) => {
                                const cellKey = `${rowKey}_${colKey as string}`;
                                const cellValue = row.values[colKey];

                                return (
                                    <td
                                        key={cellKey}
                                        className={clsx(
                                            '__cell',
                                            col.markBold && '--bold',
                                            row.className,
                                            col.className,
                                        )}
                                    >
                                        {col.renderCell
                                            ? col.renderCell({
                                                cellValue,
                                                rowValues: row.values,
                                            })
                                            : (
                                                <TableCell
                                                    cellValue={cellValue}
                                                    rowValues={row.values}
                                                    isGrowthNumber={row.isGrowthNumber}
                                                    isPercentage={row.isPercentage}
                                                    nrOfDecimals={row.nrOfDecimals}
                                                    trendIndicatorComparisonColKey={col.trendIndicatorComparisonColKey}
                                                />
                                            )
                                        }
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );

    // TODO
    // eslint-disable-next-line react/no-unstable-nested-components
    function TableCell({
        cellValue,
        rowValues,
        isGrowthNumber,
        isPercentage,
        nrOfDecimals,
        trendIndicatorComparisonColKey,
    }: Pick<IGenericColumn<ColKeys>, 'trendIndicatorComparisonColKey'>
    & Pick<IGenericRow<ColKeys>, 'isGrowthNumber' | 'isPercentage' | 'nrOfDecimals'> & {
        cellValue: number;
        rowValues: ColKeys;
    }) {
        if (!isNumber(cellValue)) {
            return null;
        }

        const trendIndicatorComparisonValue = trendIndicatorComparisonColKey && rowValues
            && isNumber(rowValues[trendIndicatorComparisonColKey])
            ? rowValues[trendIndicatorComparisonColKey]
            : null;
        const trendIndicator = isNumber(trendIndicatorComparisonValue) && (trendIndicatorComparisonValue !== cellValue)
            ? (
                <TrendIndicator
                    direction={determineTrendDirection(trendIndicatorComparisonValue, cellValue)}
                    size="S"
                />
            )
            : null;

        return (
            <div className="__cellValue">
                {getCellDisplayValue()}
                {trendIndicator}
            </div>
        );

        function getCellDisplayValue(): string {
            const cellValueToUse = isGrowthNumber
                ? Math.abs(cellValue) /* without any negative sign as that is already shown in the sign prefix */
                : cellValue; /* could still be a negative value */

            return [
                isGrowthNumber ? getGrowthSignPrefix(cellValue) : '',
                isPercentage
                    ? formatPercentage(cellValueToUse, { nrOfDecimals: numberOr(nrOfDecimals, 2) })
                    : formatFloat(cellValueToUse, { nrOfDecimals: numberOr(nrOfDecimals, 0) }),
            ].join('');
        }
    }

    function getGrowthSignPrefix(value: number) {
        return value > 0
            ? '+ '
            : value < 0
                ? '- '
                : '';
    }
}

function numberOr(nrToUseIfSet: number, defaultNr: number) {
    if (isSet(nrToUseIfSet)) {
        return nrToUseIfSet;
    }

    return defaultNr;
}
