import { CSSProperties } from 'react';
import {
    ColorProperty,
    FontWeightProperty, TextTransformProperty,
} from 'csstype';
import { TFontSize } from 'config/styling/typography';
import { THEME } from 'config/styling/theme';
import { APP_COLORS } from 'config/styling/colors';

interface ITypoProps {
    size?: TFontSize;
    weight?: FontWeightProperty;
    color?: ColorProperty;
    textTransform?: TextTransformProperty;
}

export const typo = ({
    size,
    weight,
    color,
    textTransform,
}: ITypoProps): CSSProperties => ({
    fontSize: size ? THEME.typography.pxToRem(size) : undefined,
    fontWeight: weight,
    color,
    textTransform,
});

export const typoText = (props: Omit<ITypoProps, 'color'> = {}): CSSProperties => typo({
    ...props,
    color: APP_COLORS.TEXT['500'],
});

export const typoBold = (props: Omit<ITypoProps, 'weight'> = {}): CSSProperties => typo({
    ...props,
    weight: 'bold',
});

export const typoSmallCaps = ({ color }: Pick<ITypoProps, 'color'> = {}): CSSProperties => typo({
    size: 10,
    weight: 800,
    color,
    textTransform: 'uppercase',
});
